import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import APIClient from "../../models/APIClient";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import { Box, Grid, Typography } from "@mui/material";
import RTable from "../components/RTable";
import MessageHelper from "../helper/MessageHelper";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";

const styles = (theme) => ({
    root: {},
    paper: {
        padding: 10,
    },
    section: {
        marginTop: theme.spacing(5),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paragraph: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    description: {
        paddingLeft: 30,
        paddingRight: 30,
    },
    box: {
        padding: 10,
    },
});

let SFTPManager = WithGoogleAuth(
    class SFTPManagerions extends Component {
        constructor(props) {
            super(props);
            this.state = {
                exavaultUsers: [],
                thingLookup: {},
                isLoading: true,
            };
        }

        componentDidMount() {
            this.loadState();
        }
        async loadState() {
            let result = await new APIClient(
                    this.props.authState
                ).getAllThings(),
                result2 = await new APIClient(
                    this.props.authState
                ).getAllExavaultUsers(),
                things = await result.json(),
                exavaultUsers = await result2.json(),
                thingLookup = {};

            things.forEach(({ id, name }) => {
                thingLookup[id] = `${name} - ${id}`;
            });
            this.setState({ thingLookup: thingLookup });
            this.setState({ exavaultUsers: exavaultUsers });
            this.setState({ isLoading: false });
        }

        render() {
            const { classes } = this.props;
            return (
                <Grid container spacing={3}>
                    <Grid item lg={1} xs={12}>
                        <MessageHelper
                            message={this.state.message}
                            errorMessage={this.state.errorMessage}
                            open={this.state.messageOpen}
                            setState={(a) => this.setState(a)}
                        />
                    </Grid>
                    <Grid item lg={12} xs={12} className={classes.description}>
                        <div className={classes.description}>
                            <Typography className={classes.title} variant="h6">
                                SFTP
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                                variant="body1"
                            >
                                In this tab, you can create an SFTP login where
                                a thing(collection of sensors) can drop their
                                data on a regular interval
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Box>
                            <RTable
                                className={classes.table}
                                title={
                                    <Typography variant="h3">
                                        SFTP Manager
                                    </Typography>
                                }
                                columns={[
                                    {
                                        title: "Thing *",
                                        field: "thing_id",
                                        lookup: this.state.thingLookup,
                                        editable: "onAdd",
                                    },
                                    {
                                        title: "Username (unique)",
                                        field: "username",
                                        editable: "onAdd",
                                    },
                                    {
                                        title: "Password",
                                        field: "password",
                                        render: () => "*****",
                                    },
                                    {
                                        title: "Email",
                                        field: "email",
                                    },
                                    {
                                        title: "Host",
                                        field: "host",
                                        editable: "never",
                                    },
                                    {
                                        title: "Directory",
                                        field: "home_resource",
                                        editable: "never",
                                    },
                                ]}
                                data={this.state.exavaultUsers}
                                editable={{
                                    isEditable: () => true,
                                    onRowAddCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowUpdateCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowAdd: (newData) =>
                                        new Promise((resolve, reject) => {
                                            //add thing
                                            new APIClient(this.props.authState)
                                                .createExavaultUser(newData)
                                                .then(async (res) => {
                                                    if (res.status !== 200) {
                                                        res = await res.json();
                                                        this.setState(
                                                            errorMessage(res)
                                                        );
                                                        reject();
                                                        return;
                                                    }
                                                    //Update State
                                                    res = await res.json();
                                                    newData.id = res.id;
                                                    newData.username =
                                                        res.username;
                                                    newData.password =
                                                        res.password;
                                                    newData.email = res.email;
                                                    newData.host = res.host;
                                                    newData.remote_user_id =
                                                        res.remote_user_id;
                                                    newData.home_resource =
                                                        res.home_resource;
                                                    let exavaultUsers = [
                                                        ...this.state
                                                            .exavaultUsers,
                                                    ];
                                                    exavaultUsers.unshift(
                                                        newData
                                                    );
                                                    this.setState({
                                                        exavaultUsers,
                                                    });
                                                    this.setState(
                                                        successMessage(
                                                            "Successfully added"
                                                        )
                                                    );
                                                    resolve();
                                                })
                                                .catch((e) => {
                                                    this.setState(
                                                        errorMessage(e)
                                                    );
                                                    reject();
                                                });
                                        }),
                                    onRowUpdate: (newData) =>
                                        new Promise((resolve, reject) => {
                                            //Update everything
                                            function isExavaultUser(user) {
                                                return user.id === newData.id;
                                            }
                                            new APIClient(this.props.authState)
                                                .updateExavaultUser(newData)
                                                .then(async (res) => {
                                                    if (res.status !== 200) {
                                                        res = await res.json();
                                                        this.setState(
                                                            errorMessage(res)
                                                        );
                                                        reject();
                                                        return;
                                                    }
                                                    //Update State
                                                    let exavaultUsers = [
                                                            ...this.state
                                                                .exavaultUsers,
                                                        ],
                                                        i =
                                                            exavaultUsers.findIndex(
                                                                isExavaultUser
                                                            );
                                                    exavaultUsers[i] = newData;
                                                    this.setState({
                                                        exavaultUsers,
                                                    });
                                                    this.setState(
                                                        successMessage(
                                                            "Successfully updated"
                                                        )
                                                    );
                                                    resolve();
                                                })
                                                .catch((e) => {
                                                    this.setState(
                                                        errorMessage(e)
                                                    );
                                                    reject();
                                                });
                                        }),
                                    onRowDelete: (oldData) =>
                                        new Promise((resolve) => {
                                            new APIClient(this.props.authState)
                                                .removeExavaultUser(oldData)
                                                .then(async (res) => {
                                                    if (res.status !== 200) {
                                                        res = await res.json();
                                                        this.setState(
                                                            errorMessage(res)
                                                        );
                                                        resolve();
                                                        return;
                                                    }
                                                    //Update State
                                                    let exavaultUsers = [
                                                        ...this.state
                                                            .exavaultUsers,
                                                    ];
                                                    exavaultUsers =
                                                        exavaultUsers.filter(
                                                            (user) =>
                                                                user.id !==
                                                                oldData.id
                                                        );
                                                    this.setState({
                                                        exavaultUsers,
                                                    });
                                                    this.setState(
                                                        successMessage(
                                                            "Successfully deleted"
                                                        )
                                                    );
                                                    resolve();
                                                })
                                                .catch((e) => {
                                                    this.setState(
                                                        errorMessage(e)
                                                    );
                                                    resolve();
                                                });
                                        }),
                                }}
                                isLoading={this.state.isLoading}
                                options={{
                                    search: true,
                                    maxColumnSort: 1,
                                    filtering: true,
                                    paging: true,
                                    addRowPosition: "first",
                                }}
                            ></RTable>
                        </Box>
                    </Grid>
                </Grid>
            );
        }
    }
);

export default withStyles(styles)(SFTPManager);
