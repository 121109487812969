import React, { Component } from "react";
import { useParams } from "react-router-dom";
import { IconButton, Grid, Link, Stack, Typography } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import RTable from "../components/RTable";
import MessageHelper from "../helper/MessageHelper";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";

class ProductInstance extends Component {
    state = {
        details: [],
        properties: [],
        tests: [],
        propertyTypes: {},
    };

    async fetchProductInstance(apiClient) {
        const productInstance = await apiClient.getProductInstance(
            this.props.params.product_instance_id
        );
        const properties = Object.keys(productInstance.properties).map(
            (key) => {
                return { key, value: productInstance.properties[key] };
            }
        );
        properties.sort();

        this.setState({
            details: [
                { key: "Creation Date", value: productInstance.creationDate },
                { key: "Batch Index", value: productInstance.batchIndex },
                { key: "Index", value: productInstance.index },
            ],
            properties,
        });
    }

    async fetchTestInstances(apiClient) {
        const testInstances = await apiClient.getTestInstancesByProduct(
            this.props.params.product_instance_id
        );
        const tests = testInstances.map((testInstance) => {
            const { id, testConceptName, testConceptId, startDate, endDate } =
                testInstance;
            return {
                id,
                testConceptName,
                testConceptId,
                startDate,
                endDate,
            };
        });
        this.setState({ tests });
    }

    async componentDidMount() {
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        this.fetchProductInstance(apiClient);
        this.fetchTestInstances(apiClient);
        this.getPropertyTypeLookupDropdown(apiClient);
    }

    async getPropertyTypeLookupDropdown(client) {
        const propertyNames = await client.getSubstrateProperties();
        const returnObj = {};
        for (const ele of propertyNames) {
            returnObj[ele] = ele;
        }
        this.setState({ propertyTypes: returnObj });
    }

    delay = (time) => {
        return new Promise((resolve) => setTimeout(resolve, time));
    };

    async clearMessageAfterDelay() {
        await this.delay(3000);
        this.setState(clearMessage());
    }

    updatePropertyValuesFromResponse = (res) => {
        // Assumes no deletions of properties in response -> WILL BREAK SPEC IF DELETIONS
        // Assumes no edits of properties in response -> WILL BREAK SPEC IF EDITS
        const properties = [...this.state.properties];
        for (const [property, val] of Object.entries(res.properties)) {
            if (
                properties.findIndex(
                    (x) => x.key === property && x.value === val
                ) === -1
            ) {
                properties.push({ key: property, value: val });
            }
        }
        this.setState({ properties });
    };

    render() {
        return (
            <Grid container sx={{ rowGap: 3 }}>
                <Grid item xs={12}>
                    <Typography variant="h3">Product Instance</Typography>
                </Grid>
                <Grid item xs={6}>
                    <RTable
                        disableAltRowColor
                        title={<Typography variant="h3">Details</Typography>}
                        columns={[{ field: "key" }, { field: "value" }]}
                        data={this.state.details}
                        options={{
                            header: false,
                            paging: false,
                            search: false,
                            maxColumnSort: 0,
                            addRowPosition: "first",
                        }}
                    />
                </Grid>
                <Grid item lg={4}>
                    <RTable
                        disableAltRowColor
                        title={<Typography variant="h3">Properties</Typography>}
                        columns={[
                            { field: "key", lookup: this.state.propertyTypes },
                            { field: "value" },
                        ]}
                        data={this.state.properties}
                        options={{
                            header: false,
                            paging: this.state.properties.length > 5,
                            emptyRowsWhenPaging: true,
                            paginationType: "normal",
                            paginationPosition: "bottom",
                            search: false,
                            maxColumnSort: 0,
                        }}
                        editable={{
                            isEditable: () => true,
                            onRowAddCancelled: () => {
                                this.setState(clearMessage());
                            },
                            onRowAdd: async (newData) => {
                                let properties = {};
                                properties[newData.key] = newData.value;
                                try {
                                    const res =
                                        await new CarbonAccountingAPIClient(
                                            this.props.authState
                                        ).updateProductInstance(
                                            this.props.params
                                                .product_instance_id,
                                            properties
                                        );
                                    this.updatePropertyValuesFromResponse(res);
                                    this.setState(
                                        successMessage("Successfully added")
                                    );
                                    this.clearMessageAfterDelay();
                                } catch (e) {
                                    this.setState(errorMessage(e));
                                }
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RTable
                        title={
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <Typography variant="h3">Tests</Typography>
                                <IconButton
                                    color="primary"
                                    href={`/test/create-instance?productInstanceId=${this.props.params.product_instance_id}`}
                                >
                                    <AddBoxIcon />
                                </IconButton>
                            </Stack>
                        }
                        columns={[
                            {
                                title: "Concept",
                                field: "testConceptName",
                                render: (rowData) => (
                                    <Link
                                        href={`/test/concept/${rowData.testConceptId}`}
                                    >
                                        {rowData.testConceptName}
                                    </Link>
                                ),
                            },
                            { title: "Start Date", field: "startDate" },
                            { title: "End Date", field: "endDate" },
                            {
                                render: (rowData) => (
                                    <Link href={`/test/instance/${rowData.id}`}>
                                        Test Results
                                    </Link>
                                ),
                            },
                        ]}
                        data={this.state.tests}
                        options={{ search: false, paging: false }}
                    />
                </Grid>
                <MessageHelper
                    message={this.state.message}
                    errorMessage={this.state.errorMessage}
                    open={this.state.messageOpen}
                    setState={(a) => this.setState(a)}
                />
            </Grid>
        );
    }
}

export default WithGoogleAuth((props) => (
    <ProductInstance {...props} params={useParams()} />
));
