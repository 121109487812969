import React, { Component } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import MessageHelper from "../helper/MessageHelper";
import APIClient from "../../models/APIClient";
import { errorMessage } from "./MessageMethodHelper";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";

const styles = (theme) => ({
    container: {
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: "30%",
    },
    titleBar: {
        display: "flex",
        width: "30%",
        flexFlow: "row",
        justifyContent: "space-between",
    },
});

const BuoyConfigForm = WithGoogleAuth(
    class BuoyConfigForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                configTemplates: [],
            };
        }

        async componentDidMount() {
            this.getConfigTemplates();
        }

        async getConfigTemplates() {
            const apiClient = new APIClient(this.props.authState);
            try {
                const configTemplates = await apiClient.fetchConfigTemplates();
                this.setState({ configTemplates });
            } catch (e) {
                console.error(e);
            }
        }

        prettyPrint(text, id) {
            try {
                const con = JSON.parse(text.contents);
                const pretty = JSON.stringify(con, undefined, 2);
                switch (id) {
                    case "schedule":
                        this.props.setScheduleConfigText(pretty);
                        break;
                    case "logs":
                        this.props.setLogConfigText(pretty);
                        break;
                    case "sensors":
                        this.props.setSensorConfigText(pretty);
                        break;
                    case "buoy":
                        this.props.setBuoyConfigText(pretty);
                        break;
                    default:
                        console.error(`Unexpected ID: ${id}`);
                }
            } catch (e) {
                this.setState(errorMessage("Invalid JSON"));
            }
        }

        render() {
            const { classes } = this.props;
            const sampleBuoyProtocolVersion = `""`;

            return (
                <Paper>
                    <div className={classes.container}>
                        <Typography className={classes.title} variant="h5">
                            Config Push
                        </Typography>

                        <Typography className={classes.title}>
                            Schedule Config
                        </Typography>

                        <Box className={classes.titleBar}>
                            <select
                                value={this.props.scheduleConfigText.name}
                                onChange={(event) => {
                                    const { name, contents } =
                                        this.state.configTemplates
                                            .filter(
                                                (x) =>
                                                    x.config_type === "schedule"
                                            )
                                            .filter(
                                                (x) =>
                                                    x.name ===
                                                    event.target.value
                                            )[0];

                                    this.props.handleScheduleConfigTextChange({
                                        name,
                                        contents: JSON.stringify(
                                            contents,
                                            undefined,
                                            2
                                        ),
                                    });
                                }}
                            >
                                <option value="" disabled>
                                    Choose One
                                </option>
                                {this.state.configTemplates
                                    .filter((x) => x.config_type === "schedule")
                                    .map(({ name }) => {
                                        return (
                                            <option key={name} value={name}>
                                                {name}
                                            </option>
                                        );
                                    })}
                            </select>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    this.prettyPrint(
                                        this.props.scheduleConfigText,
                                        "schedule"
                                    )
                                }
                            >
                                Pretty Print
                            </Button>
                        </Box>
                        <textarea
                            id="schedule"
                            className={classes.textField}
                            rows="11"
                            value={this.props.scheduleConfigText.contents}
                            onChange={(event) =>
                                this.props.handleScheduleConfigTextChange({
                                    name: "",
                                    contents: event.target.value,
                                })
                            }
                        />

                        <Typography className={classes.title}>
                            Sensors Config
                        </Typography>
                        <Box className={classes.titleBar}>
                            <select
                                value={this.props.sensorsConfigText.name}
                                onChange={(event) => {
                                    const { name, contents } =
                                        this.state.configTemplates
                                            .filter(
                                                (x) =>
                                                    x.config_type === "sensors"
                                            )
                                            .filter(
                                                (x) =>
                                                    x.name ===
                                                    event.target.value
                                            )[0];

                                    this.props.handleSensorConfigTextChange({
                                        name,
                                        contents: JSON.stringify(
                                            contents,
                                            undefined,
                                            2
                                        ),
                                    });
                                }}
                            >
                                <option value="" disabled>
                                    Choose One
                                </option>
                                {this.state.configTemplates
                                    .filter((x) => x.config_type === "sensors")
                                    .map(({ name }) => {
                                        return (
                                            <option key={name} value={name}>
                                                {name}
                                            </option>
                                        );
                                    })}
                            </select>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    this.prettyPrint(
                                        this.props.sensorsConfigText,
                                        "sensors"
                                    )
                                }
                            >
                                Pretty Print
                            </Button>
                        </Box>
                        <textarea
                            id="sensors"
                            className={classes.textField}
                            rows="20"
                            value={this.props.sensorsConfigText.contents}
                            onChange={(event) =>
                                this.props.handleSensorConfigTextChange({
                                    name: "",
                                    contents: event.target.value,
                                })
                            }
                        />

                        <Typography className={classes.title}>
                            Logs Config
                        </Typography>
                        <Box className={classes.titleBar}>
                            <select
                                value={this.props.logsConfigText.name}
                                onChange={(event) => {
                                    const { name, contents } =
                                        this.state.configTemplates
                                            .filter(
                                                (x) => x.config_type === "logs"
                                            )
                                            .filter(
                                                (x) =>
                                                    x.name ===
                                                    event.target.value
                                            )[0];

                                    this.props.handleLogConfigTextChange({
                                        name,
                                        contents: JSON.stringify(
                                            contents,
                                            undefined,
                                            2
                                        ),
                                    });
                                }}
                            >
                                <option value="" disabled>
                                    Choose One
                                </option>
                                {this.state.configTemplates
                                    .filter((x) => x.config_type === "logs")
                                    .map(({ name }) => {
                                        return (
                                            <option key={name} value={name}>
                                                {name}
                                            </option>
                                        );
                                    })}
                            </select>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    this.prettyPrint(
                                        this.props.logsConfigText,
                                        "logs"
                                    )
                                }
                            >
                                Pretty Print
                            </Button>
                        </Box>
                        <textarea
                            id="logs"
                            className={classes.textField}
                            rows="9"
                            value={this.props.logsConfigText.contents}
                            onChange={(event) =>
                                this.props.handleLogConfigTextChange({
                                    name: "",
                                    contents: event.target.value,
                                })
                            }
                        />

                        <Typography className={classes.title}>
                            Buoy Config
                        </Typography>
                        <Box className={classes.titleBar}>
                            <select
                                value={this.props.buoyConfigText.name}
                                onChange={(event) => {
                                    const { name, contents } =
                                        this.state.configTemplates
                                            .filter(
                                                (x) => x.config_type === "buoy"
                                            )
                                            .filter(
                                                (x) =>
                                                    x.name ===
                                                    event.target.value
                                            )[0];

                                    this.props.handleBuoyConfigTextChange({
                                        name,
                                        contents: JSON.stringify(
                                            contents,
                                            undefined,
                                            2
                                        ),
                                    });
                                }}
                            >
                                <option value="" disabled>
                                    Choose One
                                </option>
                                {this.state.configTemplates
                                    .filter((x) => x.config_type === "buoy")
                                    .map(({ name }) => {
                                        return (
                                            <option key={name} value={name}>
                                                {name}
                                            </option>
                                        );
                                    })}
                            </select>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    this.prettyPrint(
                                        this.props.buoyConfigText,
                                        "buoy"
                                    )
                                }
                            >
                                Pretty Print
                            </Button>
                        </Box>
                        <textarea
                            id="buoy"
                            className={classes.textField}
                            rows="20"
                            value={this.props.buoyConfigText.contents}
                            onChange={(event) =>
                                this.props.handleBuoyConfigTextChange({
                                    name: "",
                                    contents: event.target.value,
                                })
                            }
                        />

                        <Typography className={classes.title}>
                            Buoy Protocol Version
                        </Typography>
                        <textarea
                            className={classes.textField}
                            rows="1"
                            placeholder={sampleBuoyProtocolVersion}
                            value={this.props.buoyProtocolVersionText}
                            onChange={(event) =>
                                this.props.handleBuoyProtocolVersionChange(
                                    event.target.value
                                )
                            }
                        />
                        {/*make checkbox null if we don't want to display it */}
                        {this.props.displayCheckbox ? (
                            <label>
                                <input
                                    className={classes.title}
                                    type="checkbox"
                                    onChange={(event) =>
                                        this.props.handleCheck(
                                            event.target.checked
                                        )
                                    }
                                    defaultChecked={this.props.sendToBuoy}
                                />
                                <span>Send To Buoy</span>
                            </label>
                        ) : null}
                    </div>

                    {/*Show an error if the push request is not valid.*/}
                    <MessageHelper
                        message={this.state.message}
                        errorMessage={this.state.errorMessage}
                        open={this.state.messageOpen}
                        setState={(a) => this.setState(a)}
                    />
                </Paper>
            );
        }
    }
);

// display checkbox for sendToBuoy by default
BuoyConfigForm.defaultProps = {
    displayCheckbox: true,
};

export default withStyles(styles)(BuoyConfigForm);
