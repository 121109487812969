import React, { Component } from "react";
import {
    Button,
    TextField,
    Typography,
    Grid,
    Stack,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormGroup,
} from "@mui/material";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import withStyles from "@mui/styles/withStyles";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";

import { errorMessage, successMessage } from "../helper/MessageMethodHelper";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import MessageHelper from "../helper/MessageHelper";
import {
    CARBON_SEQUESTRATION,
    Action,
} from "@running-tide/rt-api-access-control/build/PermissionValidator/constants";

const styles = (theme) => ({
    formControl: {
        minWidth: 200,
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(3),
    },
    overheadLabel: {
        minWidth: 200,
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
    createButton: {
        marginTop: theme.spacing(1),
    },
});

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    "&:not(:last-child)": {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<AddCircleOutlineIcon color="primary" />}
        {...props}
    />
))(() => ({
    "& .MuiAccordionSummary-content.Mui-expanded": {
        marginBottom: 0,
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(45deg)",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
        marginBottom: 0,
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    paddingTop: 0,
}));

class DeploymentCreator extends Component {
    state = {
        deploymentVessels: [],
        deploymentPorts: [],
        deploymentName: "",
        targetDepartureDate: "",
        departurePortId: "",
        arrivalPortId: "",
        vesselId: "",
        vesselName: "",
        portName: "",
        portLat: "",
        portLong: "",
        isCreating: false,
        accordionExpanded: {
            deployment: false,
            vessel: false,
            port: false,
        },
    };

    async componentDidMount() {
        // Fetch lists of vessels & ports to populate the dropdowns.
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        const deploymentVessels = await apiClient.getDeploymentVessels();
        const deploymentPorts = await apiClient.getDeploymentPorts();
        this.setState({
            deploymentVessels: deploymentVessels.data,
            deploymentPorts: deploymentPorts.data,
        });
    }

    async createDeployment() {
        this.setState({ isCreating: true });
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);

        try {
            const deployment = await apiClient.createDeployment(
                this.state.deploymentName,
                this.state.targetDepartureDate,
                this.state.departurePortId,
                this.state.arrivalPortId,
                this.state.vesselId
            );
            this.setState({
                ...successMessage(`Deployment ${deployment.title} created!`),
                deploymentName: "",
                targetDepartureDate: "",
                departurePortId: "",
                arrivalPortId: "",
                vesselId: "",
                isCreating: false,
            });
        } catch (error) {
            this.setState({
                ...errorMessage(error),
                isCreating: false,
            });
        }
    }

    async createVessel() {
        this.setState({ isCreating: true });
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);

        try {
            const vessel = await apiClient.createVessel(this.state.vesselName);
            this.setState({
                ...successMessage(`Vessel ${vessel.name} created!`),
                vesselName: "",
                isCreating: false,
                deploymentVessels: [...this.state.deploymentVessels, vessel],
            });
        } catch (error) {
            this.setState({
                ...errorMessage(error),
                isCreating: false,
            });
        }
    }

    async createPort() {
        this.setState({ isCreating: true });
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);

        try {
            const port = await apiClient.createPort(
                this.state.portName,
                this.state.portLat,
                this.state.portLong
            );
            this.setState({
                ...successMessage(`Port ${port.title} created!`),
                portName: "",
                portLat: "",
                portLong: "",
                isCreating: false,
                deploymentPorts: [...this.state.deploymentPorts, port],
            });
        } catch (error) {
            this.setState({
                ...errorMessage(error),
                isCreating: false,
            });
        }
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Accordion
                        expanded={this.state.accordionExpanded.deployment}
                        onChange={(_, expanded) => {
                            this.setState({
                                accordionExpanded: {
                                    ...this.state.accordionExpanded,
                                    deployment: expanded,
                                },
                            });
                        }}
                    >
                        <AccordionSummary
                            aria-controls="create-deployment"
                            id="create-deployment-header"
                        >
                            <Typography variant="h3">
                                Create Deployment
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <FormGroup>
                                    <TextField
                                        label="Name"
                                        value={this.state.deploymentName}
                                        onChange={(event) =>
                                            this.setState({
                                                deploymentName:
                                                    event.target.value,
                                            })
                                        }
                                    />
                                    <FormControl
                                        className={
                                            this.props.classes.formControl
                                        }
                                    >
                                        <InputLabel id="label-select-departurePortId">
                                            Departure Port
                                        </InputLabel>
                                        <Select
                                            onChange={(event) =>
                                                this.setState({
                                                    departurePortId:
                                                        event.target.value,
                                                })
                                            }
                                            id="departurePortId-select"
                                            value={this.state.departurePortId}
                                        >
                                            {this.state.deploymentPorts.map(
                                                (port) => (
                                                    <MenuItem
                                                        key={port.id}
                                                        value={port.id}
                                                    >
                                                        {port.title}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                        className={
                                            this.props.classes.formControl
                                        }
                                    >
                                        <InputLabel id="label-select-arrivalPortId">
                                            Arrival Port
                                        </InputLabel>
                                        <Select
                                            onChange={(event) =>
                                                this.setState({
                                                    arrivalPortId:
                                                        event.target.value,
                                                })
                                            }
                                            id="arrivalPortId-select"
                                            value={this.state.arrivalPortId}
                                        >
                                            {this.state.deploymentPorts.map(
                                                (port) => (
                                                    <MenuItem
                                                        key={port.id}
                                                        value={port.id}
                                                    >
                                                        {port.title}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                        className={
                                            this.props.classes.formControl
                                        }
                                    >
                                        <InputLabel id="label-select-vesselId">
                                            Vessel
                                        </InputLabel>
                                        <Select
                                            onChange={(event) =>
                                                this.setState({
                                                    vesselId:
                                                        event.target.value,
                                                })
                                            }
                                            id="vesselId-select"
                                            value={this.state.vesselId}
                                        >
                                            {this.state.deploymentVessels.map(
                                                (vessel) => (
                                                    <MenuItem
                                                        key={vessel.id}
                                                        value={vessel.id}
                                                    >
                                                        {vessel.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        label="Target Departure Date"
                                        type="date"
                                        value={this.state.targetDepartureDate}
                                        onChange={(event) => {
                                            this.setState({
                                                targetDepartureDate:
                                                    event.target.value,
                                            });
                                        }}
                                        InputLabelProps={{
                                            shrink: false,
                                            variant: "overhead",
                                        }}
                                        className={
                                            this.props.classes.overheadLabel
                                        }
                                    />
                                </FormGroup>
                                <Button
                                    variant="secondary"
                                    onClick={() => this.createDeployment()}
                                    disabled={
                                        this.state.isCreating ||
                                        !this.state.deploymentName ||
                                        !this.state.targetDepartureDate ||
                                        !this.state.departurePortId ||
                                        !this.state.arrivalPortId ||
                                        !this.state.vesselId
                                    }
                                    className={this.props.classes.createButton}
                                >
                                    Create
                                </Button>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={this.state.accordionExpanded.vessel}
                        onChange={(_, expanded) => {
                            this.setState({
                                accordionExpanded: {
                                    ...this.state.accordionExpanded,
                                    vessel: expanded,
                                },
                            });
                        }}
                    >
                        <AccordionSummary
                            aria-controls="create-vessel"
                            id="create-vessel-header"
                        >
                            <Typography variant="h3">Create Vessel</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <FormGroup>
                                    <TextField
                                        label="Name"
                                        value={this.state.vesselName}
                                        onChange={(event) =>
                                            this.setState({
                                                vesselName: event.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>
                                <Button
                                    variant="secondary"
                                    onClick={() => this.createVessel()}
                                    disabled={
                                        this.state.isCreating ||
                                        !this.state.vesselName
                                    }
                                    className={this.props.classes.createButton}
                                >
                                    Create
                                </Button>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={this.state.accordionExpanded.port}
                        onChange={(_, expanded) => {
                            this.setState({
                                accordionExpanded: {
                                    ...this.state.accordionExpanded,
                                    port: expanded,
                                },
                            });
                        }}
                    >
                        <AccordionSummary
                            aria-controls="create-port"
                            id="create-port-header"
                        >
                            <Typography variant="h3">Create Port</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <FormGroup>
                                    <TextField
                                        label="Name"
                                        value={this.state.portName}
                                        onChange={(event) =>
                                            this.setState({
                                                portName: event.target.value,
                                            })
                                        }
                                    />
                                    <TextField
                                        label="Latitude"
                                        type="number"
                                        value={this.state.portLat}
                                        onChange={(event) =>
                                            this.setState({
                                                portLat: event.target.value,
                                            })
                                        }
                                    />
                                    <TextField
                                        label="Longitude"
                                        type="number"
                                        value={this.state.portLong}
                                        onChange={(event) =>
                                            this.setState({
                                                portLong: event.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>
                                <Button
                                    variant="secondary"
                                    onClick={() => this.createPort()}
                                    disabled={
                                        this.state.isCreating ||
                                        !this.state.portName ||
                                        !this.state.portLat ||
                                        !this.state.portLong
                                    }
                                    className={this.props.classes.createButton}
                                >
                                    Create
                                </Button>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <MessageHelper
                    message={this.state.message}
                    errorMessage={this.state.errorMessage}
                    open={this.state.messageOpen}
                    setState={(a) => this.setState(a)}
                />
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedInUser: state.data.loggedInUser,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withStyles(styles)(
        WithGoogleAuth(DeploymentCreator, [
            `${CARBON_SEQUESTRATION}:${Action.CREATE}`,
            `${CARBON_SEQUESTRATION}:${Action.UPDATE}`,
        ])
    )
);
