// src/Login.jsx
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, signInWithGoogle } from "../../config/firebase";
import RTLogo from "../../static/rt-logo-dark.png";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
    container: {
        backgroundColor: "black",
        height: "100vh",
        alignItems: "center",
        display: "flex",
    },
    marginAutoItem: {
        margin: "auto",
        [theme.breakpoints.only("xs")]: {
            width: "300px",
        },
        [theme.breakpoints.up("sm")]: {
            width: "392px",
        },
        alignItems: "center",
        display: "flex",
    },
    rtLogo: {
        [theme.breakpoints.only("xs")]: {
            width: "300px",
            height: "32px",
        },
        [theme.breakpoints.up("sm")]: {
            width: "392px",
            height: "42px",
        },
    },
    buttonCenter: {
        display: "block",
        margin: "auto",
        marginTop: 20,
    },
    googleSignInButton: {
        height: 46,
        width: 191,
        backgroundImage:
            'url("images/buttons/btn_google_signin_dark_normal_web.png")',
    },
}));

function Login() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            // maybe trigger a loading screen
            return;
        }
        if (user) navigate("/");
    }, [user, loading, navigate]);

    const classes = useStyles();

    return (
        <Container
            maxWidth={"true"}
            className={classes.container}
            justify="center"
        >
            <Grid container className={classes.marginAutoItem}>
                <Grid item xs={12} className={classes.marginAutoItem}>
                    <img
                        className={classes.rtLogo}
                        src={RTLogo}
                        alt="Dashboard"
                    ></img>
                </Grid>
                <Grid item xs={12} className={classes.marginAutoItem}>
                    <Box className={classes.buttonCenter}>
                        <Button
                            className={classes.googleSignInButton}
                            onClick={signInWithGoogle}
                        ></Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Login;
