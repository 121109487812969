import React, { Component } from "react";
import PropTypes from "prop-types";
import { WithGoogleAuth } from "../../../config/WithGoogleAuth";

import APIClient from "../../../models/APIClient";
import moment from "moment-timezone";
import GraphComponentWithoutData from "./GraphComponentWithoutData";

class GraphComponentWithData extends Component {
    constructor(props) {
        super(props);
        this.foi_id =
            props.foi_id ||
            (props.match ? props.match.params.foi_id : undefined);
        this.state = {
            graphName: "Loading...",
            graphDesc: "Loading...",
            latestNumeric: [],
        };
        this.loadNewDataset = this.loadNewDataset.bind(this);
    }

    componentDidMount() {
        this.loadState();
    }

    async loadState() {
        let from = moment().subtract(1, "weeks"),
            to = moment(),
            { data, graphName, graphDesc } = await this.loadNewDataset(
                from,
                to
            );

        this.setState({
            graphName,
            graphDesc,
            latestNumeric: data.result_numeric,
        });
    }

    async loadNewDataset(from, to, unit) {
        let result = null;
        let graphName = "";
        let graphDesc = "";

        const requestObj = {
            foi_id: this.foi_id,
            from: from,
            to: to,
            /*
             * We currently collect datapoints from the hatchery at 1 datapoint/5min.
             * This amounts to 288 datapoints per day and 576 datapoints for 2 days.
             * Limit is thus 576 since we can see all datapoints for a time range of 2 days.
             */
            limit: 576,
        };

        result = await new APIClient(this.props.authState).getFOIData(
            requestObj
        );
        result = await result.json();

        if (result != null && result.length > 0) {
            graphName = `Data for feature of interest: ${result[0].foi_name}`;
            graphDesc = result[0].foi_desc;

            let modeDataset = result.filter(
                (dataset) =>
                    (dataset.ds_name === "Current_Mode_1" ||
                        dataset.ds_name === "Current_Mode_2") &&
                    dataset.op_unit === "cells/mL"
            );
            let modeDatasetVoltage = Object.assign({}, modeDataset[0]);
            let modeDatasetChlorophyll = Object.assign({}, modeDataset[0]);
            let modeDatasetFlowrate = Object.assign({}, modeDataset[0]);
            if (Object.keys(modeDatasetVoltage).length > 0) {
                modeDatasetVoltage["op_unit"] = "V";
                modeDatasetChlorophyll["op_unit"] = "ug/L";
                modeDatasetFlowrate["op_unit"] = "L/min";
                result.push(modeDatasetVoltage);
                result.push(modeDatasetChlorophyll);
                result.push(modeDatasetFlowrate);
            }
        }
        if (unit) {
            result = result.filter((dataset) => dataset.op_unit === unit);
        }
        return {
            data: {
                result_numeric: result.filter((g) => g.result_numeric != null),
            },
            graphName,
            graphDesc,
        };
    }

    render() {
        return (
            <GraphComponentWithoutData
                name={this.state.graphName}
                description={this.state.graphDesc}
                numericGraphData={this.state.latestNumeric}
                loadNewDataset={this.loadNewDataset}
                splitByUnit={this.props.splitByUnit}
                size={this.props.size}
                picker={this.props.picker}
            />
        );
    }
}

GraphComponentWithData.propTypes = {
    foi_id: PropTypes.string,
    thingSelector: PropTypes.string,
    hideTimeline: PropTypes.bool,
    splitByUnit: PropTypes.bool,
    size: PropTypes.string,
    picker: PropTypes.bool,
};

export default WithGoogleAuth(GraphComponentWithData);
