/**
 * A list component that handles "infinite" scrolling.
 */

import React, { Component } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import PropTypes from "prop-types";

class InfiniteScrollList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paginatedData: null,
            isLoadingData: false,
        };
        this.maybeLoadMoreDataOnScroll =
            this.maybeLoadMoreDataOnScroll.bind(this);
    }

    componentDidMount() {
        this.loadMoreData();
    }

    async loadMoreData() {
        const { paginatedData } = this.state;
        // Don't request more data if we're already requesting data or if we
        // already have the last page of data. (Note that page is 0-indexed.)
        if (
            this.state.isLoadingData ||
            (paginatedData != null &&
                paginatedData.page + 1 === paginatedData.totalPages)
        ) {
            return;
        }

        this.setState({ isLoadingData: true });

        const newPaginatedData = await this.props.fetchData(paginatedData);

        this.setState({
            paginatedData: {
                data: [
                    ...(paginatedData ? paginatedData.data : []),
                    ...newPaginatedData.data,
                ],
                page: newPaginatedData.page,
                totalPages: newPaginatedData.totalPages,
            },
            isLoadingData: false,
        });
    }

    maybeLoadMoreDataOnScroll(event) {
        if (
            event.target.scrollHeight - event.target.scrollTop ===
            this.props.maxHeight
        ) {
            this.loadMoreData();
        }
    }

    render() {
        return (
            <List
                onScroll={this.maybeLoadMoreDataOnScroll}
                style={{
                    maxHeight: this.props.maxHeight,
                    overflowY: "auto",
                }}
            >
                {this.state.paginatedData != null &&
                    this.state.paginatedData.data.map((data, i) => (
                        <ListItem key={i}>
                            {this.props.renderData(data)}
                        </ListItem>
                    ))}
                {this.state.isLoadingData && (
                    <ListItem>
                        <CircularProgress size={24} />
                    </ListItem>
                )}
                {this.state.paginatedData != null &&
                    this.state.paginatedData.data.length === 0 &&
                    this.props.renderEmpty && (
                        <ListItem>{this.props.renderEmpty()}</ListItem>
                    )}
            </List>
        );
    }
}

// The additional comments describe the expected parameters and return types of
// the functions.
InfiniteScrollList.propTypes = {
    maxHeight: PropTypes.number.isRequired,
    // fetchData: (?{data: T[], page: number, totalPages: number}) => {data: T[], page: number, totalPages: number}
    fetchData: PropTypes.func.isRequired,
    // renderData: (T) => React.Node
    renderData: PropTypes.func.isRequired,
    // renderEmpty: () => React.Node
    renderEmpty: PropTypes.func,
};

export default InfiniteScrollList;
