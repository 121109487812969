import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import RTable from "./components/RTable";
import Typography from "@mui/material/Typography";
import RTDisplayUtils from "@running-tide/rt-frontend-helpers/helpers";
import CurrentAllocationsGradingHistoryDetails from "./CurrentAllocationsGradingHistoryDetails";
import ShellfishInventoryAPIClient from "../models/ShellfishInventoryAPIClient";
import { WithGoogleAuth } from "../config/WithGoogleAuth";
import NumberFormat from "react-number-format";

let cAllocations = WithGoogleAuth(
    class CurrentAllocations extends Component {
        constructor(props) {
            super(props);
            this.state = { data: [] };
        }

        componentDidMount() {
            console.log("mounted");
            let apiClient = new ShellfishInventoryAPIClient(
                this.props.authState
            );
            apiClient
                .getBinAllocations()
                .then((res) => res.json())
                .then((res) => {
                    this.setState({ data: res });
                })
                .then(() => apiClient.getActiveStreams())
                .then((res) => res.json())
                .then((res) => {
                    //Merge the stream data into the bin data
                    return this.state.data.map((data) => {
                        let streams = res.filter((stream) =>
                            stream.bins.includes(data.binId)
                        );
                        data.stream =
                            streams != null && streams.length > 0
                                ? streams[0]
                                : {};
                        return data;
                    });
                })
                .then((res) => {
                    console.log(res);
                    this.setState({ data: res });
                });
        }

        render() {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <RTable
                            title={
                                <Typography variant="h3">
                                    Reef Data - (ALL VALUES ARE ROUGH ESTIMATES)
                                </Typography>
                            }
                            detailPanel={[
                                {
                                    render: ({ rowData }) => (
                                        <CurrentAllocationsGradingHistoryDetails
                                            binId={rowData.binId}
                                            binOrder={rowData.bin.order}
                                        />
                                    ),
                                },
                            ]}
                            columns={[
                                {
                                    title: "Farm",
                                    field: "farmName",
                                },
                                {
                                    title: "Reef",
                                    field: "reefName",
                                },
                                {
                                    title: "Bin",
                                    field: "bin.name",
                                },
                                {
                                    title: "Stream Name",
                                    field: "stream.name",
                                    render: (rowData) => {
                                        return (
                                            <div>
                                                {rowData.stream != null
                                                    ? rowData.stream.name
                                                    : ""}
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: "Grade",
                                    field: "grade",
                                    render: (rowData) => {
                                        return (
                                            <div>
                                                {RTDisplayUtils.prettyPrintGrade(
                                                    rowData.grade
                                                )}
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: "Count",
                                    field: "count",
                                    customFilterAndSearch: (term, rowData) => {
                                        return term === rowData.count;
                                    },
                                    render: (rowData) => {
                                        return (
                                            <NumberFormat
                                                thousandSeparator={true}
                                                displayType={"text"}
                                                value={rowData.count}
                                            />
                                        );
                                    },
                                },
                                {
                                    title: "Fill Percent",
                                    field: "fillPercentage",
                                    render: (rowData) => {
                                        return rowData.fillPercentage + "%";
                                    },
                                },
                                {
                                    title: "Last Count Update",
                                    field: "allocationDate",
                                    render: (rowData) => {
                                        return RTDisplayUtils.printDate(
                                            rowData.allocationDate
                                        );
                                    },
                                },
                            ]}
                            data={this.state.data}
                            options={{
                                search: false,
                                filtering: true,
                                paging: false,
                                idSynonym: "bin.name",
                            }}
                        ></RTable>
                    </Grid>
                </Grid>
            );
        }
    }
);
export default cAllocations;
