import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import {
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    TextField,
    FormGroup,
    Typography,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import BinSelectionForm from "./BinSelectionForm";
import ShellfishInventoryAPIClient from "../../models/ShellfishInventoryAPIClient";
import { connect } from "react-redux";
import { v1 as uuidv1 } from "uuid";
import RTDisplayUtils from "@running-tide/rt-frontend-helpers/helpers";
import NumberFormat from "react-number-format";

const styles = (theme) => ({
    section: {
        marginTop: theme.spacing(3),
    },
    extraspace: {
        marginTop: theme.spacing(3),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    formControl: {
        minWidth: 120,
        marginRight: theme.spacing(2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    inlineInputs: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(2),
    },
});

let FEntry = WithGoogleAuth(
    class OysterCountForm extends Component {
        constructor(props) {
            super(props);

            let binSelectErrors = { farm: false, reef: false, bin: false };
            let errors = {
                shellfishType: false,
                grade: false,
                count: false,
                binSelection: binSelectErrors,
            };

            this.initialState = {
                countType: "count",
                shellfishType: 0,
                grade: "",
                count: "",
                occuranceDate: null,
                errors: errors,
                measurement: "",
                newCounts: false,
            };
            this.state = this.initialState;
            this.handleBinSelected = this.handleBinSelected.bind(this);
            this.handleReefSelected = this.handleReefSelected.bind(this);
            this.handleFarmSelected = this.handleFarmSelected.bind(this);
            this.renderNumberInput = this.renderNumberInput.bind(this);
            this.handleGradeValueChange =
                this.handleGradeValueChange.bind(this);
            this.handleShellfishTypeValueChange =
                this.handleShellfishTypeValueChange.bind(this);
            this.handleBinSelected = this.handleBinSelected.bind(this);
            this.handleMeasurmentValueChanged =
                this.handleMeasurmentValueChanged.bind(this);
            this.handleNewCountsChanged =
                this.handleNewCountsChanged.bind(this);
            this.key = uuidv1();
        }

        componentDidMount() {}

        handleFarmSelected = (value) => {
            this.setState({ farm: value });
        };

        handleReefSelected = (value) => {
            this.setState({ reef: value });
        };

        handleBinSelected = (value) => {
            this.setState({ bin: value });
        };

        onCountTypeChange = (event) => {
            this.setState({
                countType: event.target.value,
                measurement: "",
                count: "",
            });
        };

        handleGradeValueChange = (event) => {
            this.setState({ grade: event.target.value });
            this.calculateCount(this.state.measurement, event.target.value);
        };

        handleShellfishTypeValueChange = (event) => {
            this.setState({ shellfishType: event.target.value });
        };

        handleBinSelected = (value) => {
            this.setState({ bin: value });
        };

        handleMeasurmentValueChanged = (event) => {
            this.setState({ measurement: event.target.value });
            this.calculateCount(event.target.value, this.state.grade);
        };

        handleOccuranceDateChanged = (event) => {
            this.setState({ occuranceDate: new Date(event.target.value) });
        };

        handleNewCountsChanged = (event) => {
            this.setState({ newCounts: event.target.checked });
        };

        calculateCount(num, grade) {
            // TODO This should do a different estimate with clams
            let apiClient = new ShellfishInventoryAPIClient(
                this.props.authState
            );

            if (this.state.countType === "count") {
                this.setState({ count: num });
                return;
            }

            if (grade == null || num == null) {
                return;
            }

            if (this.state.countType === "volumeL") {
                apiClient
                    .getOysterVolumeEstimate(num * 1000, grade)
                    .then((res) => {
                        this.setState({ count: res.count });
                    })
                    .catch((e) => {
                        this.setState({ count: "" });
                        try {
                            e.json().then((body) => {
                                this.props.showErrorMessage(body.error);
                            });
                        } catch (e) {
                            console.log(e);
                            this.props.showErrorMessage(
                                "An unknown error occurred"
                            );
                        }
                    });
            } else if (this.state.countType === "volumeDepth") {
                apiClient
                    .getOysterVolumeEstimateFromDepth(num, grade, true)
                    .then((res) => {
                        this.setState({ count: res.count });
                    })
                    .catch((e) => {
                        this.setState({ count: "" });
                        try {
                            e.json().then((body) => {
                                this.props.showErrorMessage(body.error);
                            });
                        } catch (e) {
                            console.log(e);
                            this.props.showErrorMessage(
                                "An unknown error occurred"
                            );
                        }
                    });
            }
        }

        submitForm(event) {
            event.preventDefault();

            if (!this.validate()) {
                return;
            }

            let oDate =
                this.state.occuranceDate == null
                    ? new Date()
                    : this.state.occuranceDate;

            let t = this;
            let apiClient = new ShellfishInventoryAPIClient(
                this.props.authState
            );
            apiClient
                .createOysterCount(
                    this.state.bin,
                    null,
                    this.state.grade,
                    "volume",
                    this.state.count,
                    oDate,
                    this.props.loggedInUser.id,
                    new Date(),
                    this.state.newCounts,
                    this.state.shellfishType
                )
                .then(() => {
                    let newState = this.initialState;
                    newState.occuranceDate = this.state.occuranceDate;
                    newState.bin = this.state.bin;
                    newState.reef = this.state.reef;
                    newState.farm = this.state.farm;

                    t.setState(newState);
                    t.props.showSubmittedMessage();
                })
                .catch((e) => {
                    try {
                        e.json().then((body) => {
                            this.props.showErrorMessage(body.error);
                        });
                    } catch (e) {
                        this.props.showErrorMessage(
                            "An unknown error occurred"
                        );
                    }
                });
        }

        renderNumberInput() {
            const { classes } = this.props;
            if (this.state.countType === "count") {
                return (
                    <TextField
                        id="count"
                        label="Count"
                        type="number"
                        value={this.state.measurement}
                        onChange={this.handleMeasurmentValueChanged}
                        error={this.state.errors.count}
                        InputLabelProps={{
                            shrink: false,
                            variant: "overhead",
                        }}
                        className={classes.formControl}
                    />
                );
            } else if (this.state.countType === "volumeL") {
                return (
                    <TextField
                        id="volumeL"
                        label="Volume in Liters"
                        type="number"
                        value={this.state.measurement}
                        onChange={this.handleMeasurmentValueChanged}
                        error={this.state.errors.count}
                        InputLabelProps={{
                            shrink: false,
                            variant: "overhead",
                        }}
                        className={classes.formControl}
                    />
                );
            } else {
                return (
                    <TextField
                        id="volumeDepth"
                        label="Depth in Inches"
                        type="number"
                        value={this.state.measurement}
                        onChange={this.handleMeasurmentValueChanged}
                        error={this.state.errors.count}
                        InputLabelProps={{
                            shrink: false,
                            variant: "overhead",
                        }}
                        className={classes.formControl}
                    />
                );
            }
        }

        validate() {
            let errors = this.state.errors;
            errors.binSelection.farm =
                this.state.farm == null || this.state.farm === "";
            errors.binSelection.reef =
                this.state.reef == null || this.state.reef === "";
            errors.binSelection.bin =
                this.state.bin == null || this.state.bin === "";
            errors.shellfishType =
                this.state.shellfishType == null ||
                this.state.shellfishType === "";
            errors.grade = this.state.grade == null || this.state.grade === "";
            errors.count = this.state.count == null || this.state.count === "";

            console.log(`Count: ${this.state.count}`);
            console.log(`Measurement: ${this.state.measurement}`);
            console.log(errors);
            this.setState({ errors: errors });
            return (
                Object.values(errors).filter((val) => val === true).length ===
                    0 &&
                Object.values(errors.binSelection).filter((val) => val === true)
                    .length === 0
            );
        }

        render() {
            const { classes } = this.props;
            return (
                <Box className={classes.section}>
                    <Typography variant="body1" color="textPrimary">
                        This form is used when you want to manually enter a
                        count of a bin by not using the automated system in the
                        grading vessel. Adding a grade will replace the
                        previously existing count for that grade. Therefore if
                        you have multiple counts for the same grade, you need to
                        first add them together manually and then enter the
                        cumulative amount.
                    </Typography>
                    <form
                        className={classes.container}
                        noValidate
                        onSubmit={(e) => {
                            this.submitForm(e);
                        }}
                    >
                        <BinSelectionForm
                            key={this.key + "bsf"}
                            onBinSelected={this.handleBinSelected}
                            onReefSelected={this.handleReefSelected}
                            onFarmSelected={this.handleFarmSelected}
                            errors={this.state.errors.binSelection}
                        ></BinSelectionForm>
                        <Box className={classes.extraspace}>
                            <TextField
                                id="date"
                                label="Occurrence Date"
                                type="date"
                                onChange={this.handleOccuranceDateChanged}
                                defaultValue={RTDisplayUtils.printDatePickerDate(
                                    this.state.occuranceDate == null
                                        ? new Date()
                                        : this.state.occuranceDate
                                )}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: false,
                                    variant: "overhead",
                                }}
                            />
                        </Box>
                        <Box className={classes.section}>
                            <Typography
                                className={classes.header}
                                variant="h3"
                                color="tertiaryUtility"
                            >
                                BIN DATA
                            </Typography>
                            <div>
                                <Typography
                                    className={classes.header}
                                    variant="body1"
                                    color="textPrimary"
                                >
                                    If you are entering brand new information
                                    about a bin then you&apos;ll want to check
                                    this box. This will previous counts across
                                    all grades for this bin and use your new
                                    count. If you&apos;ve already entered
                                    information for one grade for this bin and
                                    would like to add more grades to it then do
                                    not check this box.
                                </Typography>
                            </div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.newCounts}
                                        onChange={this.handleNewCountsChanged}
                                        inputProps={{
                                            "aria-label": "primary checkbox",
                                        }}
                                        name="newCounts"
                                    />
                                }
                                label="Brand new counts"
                            />
                        </Box>
                        <Box className={classes.section}>
                            <Typography
                                className={classes.header}
                                variant="h3"
                                color="tertiaryUtility"
                            >
                                SHELLFISH INFO
                            </Typography>
                            <FormGroup
                                row={true}
                                className={classes.extraspace}
                            >
                                <FormControl className={classes.formControl}>
                                    <InputLabel
                                        variant="overhead"
                                        shrink={false}
                                        id="label-select-shellfish-type"
                                    >
                                        Shellfish Type
                                    </InputLabel>
                                    <Select
                                        labelId="label-select-shellfish-type"
                                        id="shellfishType"
                                        value={this.state.shellfishType}
                                        onChange={
                                            this.handleShellfishTypeValueChange
                                        }
                                        error={this.state.errors.shellfishType}
                                    >
                                        <MenuItem value={0}>Oyster</MenuItem>
                                        <MenuItem value={1}>Surf Clam</MenuItem>
                                        <MenuItem value={2}>Scallop</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel
                                        id="label-select-grade"
                                        variant="overhead"
                                        shrink={false}
                                    >
                                        Grade
                                    </InputLabel>
                                    <Select
                                        labelId="label-select-grade"
                                        id="grade"
                                        value={this.state.grade}
                                        onChange={this.handleGradeValueChange}
                                        error={this.state.errors.grade}
                                    >
                                        <MenuItem value="0">W0</MenuItem>
                                        <MenuItem value="1">W1</MenuItem>
                                        <MenuItem value="2">W2</MenuItem>
                                        <MenuItem value="3">W3</MenuItem>
                                        <MenuItem value="4">W4</MenuItem>
                                        <MenuItem value="5">W5</MenuItem>
                                        <MenuItem value="6">W6</MenuItem>
                                        <MenuItem value="7">W7</MenuItem>
                                        <MenuItem value="8">W8</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel
                                        variant="overhead"
                                        shrink={false}
                                        id="label-select-count-type"
                                    >
                                        Measurement
                                    </InputLabel>
                                    <Select
                                        labelId="label-select-count-type"
                                        id="countType"
                                        onChange={this.onCountTypeChange}
                                        defaultValue={"count"}
                                    >
                                        <MenuItem value="count">Count</MenuItem>
                                        <MenuItem value="volumeL">
                                            Volume in Liters
                                        </MenuItem>
                                        <MenuItem value="volumeDepth">
                                            Volume from depth
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {this.renderNumberInput()}
                                <Typography
                                    className={classes.inlineInputs}
                                    variant="body1"
                                    color="textPrimary"
                                >
                                    That&apos;s estimated to{" "}
                                    <b>
                                        <NumberFormat
                                            thousandSeparator={true}
                                            displayType={"text"}
                                            value={this.state.count || 0}
                                        />
                                    </b>{" "}
                                    {RTDisplayUtils.printShellfishType(
                                        this.state.shellfishType
                                    ).toLowerCase()}
                                    s
                                </Typography>
                            </FormGroup>
                        </Box>
                        <Button variant="secondary" type="submit">
                            Submit
                        </Button>
                    </form>
                </Box>
            );
        }
    }
);

const mapStateToProps = (state) => ({
    loggedInUser: state.data.loggedInUser,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(FEntry));
