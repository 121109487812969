import React, { Component } from "react";
import {
    Autocomplete,
    IconButton,
    Button,
    TextField,
    Typography,
    Popover,
    Stack,
    Tooltip,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import UploadFileRounded from "@mui/icons-material/UploadFileRounded";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Grid from "@mui/material/Grid";
import {
    KeyboardRounded,
    PrecisionManufacturingRounded,
} from "@mui/icons-material";

import { errorMessage, successMessage } from "../helper/MessageMethodHelper";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import MessageHelper from "../helper/MessageHelper";
import SubstratePropertyCreator from "./SubstratePropertyCreator";
import RTable from "../components/RTable";

// 1MB = 8000000 bits
const MAX_FILE_UPLOAD_SIZE_IN_BITS = 8000000;
// filenames should not be too long, this number is chosen arbitrarily
const maxChar = 200;

const styles = (theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    overheadTextField: {
        marginTop: "2rem",
    },
    propertiesTitle: {
        marginTop: theme.spacing(1),
    },
    clearButton: {
        marginTop: "1.5rem",
        marginBottom: "0.5rem",
    },
    propertyName: {
        minWidth: 170,
        marginRight: theme.spacing(2),
    },
    createButton: {
        marginTop: theme.spacing(1),
    },
});

class ProductInstanceCreator extends Component {
    state = {
        propertyNames: [],
        isCreating: false,
        templateProperties: [],
        toCreate: [],
        selectedFile: undefined,
        successfulCreations: [],
        failedCreations: [],
        creationMode: "manual",
        helpDialogueOpen: false,
        tableId: 1,
    };

    async fetchProperties() {
        // Fetch a list of substrate properties to populate the property name
        // dropdowns.
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        const propertyNames = await apiClient.getSubstrateProperties();
        this.setState({ propertyNames: propertyNames.sort() });
    }

    async componentDidMount() {
        this.fetchProperties();
    }

    /**
     * Creates a singular product instance based off values in state
     */
    async createProductInstances() {
        this.setState({ isCreating: true });

        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        const headerRow = [
            "creationDate",
            "batch",
            "index",
            ...this.state.templateProperties,
        ];
        const data = [
            headerRow,
            ...this.state.toCreate.map((rowObj) =>
                headerRow.map((header) => rowObj[header])
            ),
        ];

        try {
            const { successes, failures } =
                await apiClient.createProductsFromData(data);

            if (failures.length > 0) {
                //TODO integrate more in depth error messaging
                this.setState({ ...errorMessage("some creations failed") });
            } else {
                this.setState({
                    ...successMessage(`product instances created`),
                });
            }
            this.setState({
                successfulCreations: [...successes],
                failedCreations: [...failures],
                isCreating: false,
                toCreate: [],
            });
        } catch (error) {
            this.setState({
                ...errorMessage(error),
                isCreating: false,
            });
        }
    }

    /**
     * Creates a set of product instances from the csv file in state
     */
    async createProductsFromFile() {
        this.setState({ isCreating: true });

        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        try {
            if (this.state.selectedFile.size > MAX_FILE_UPLOAD_SIZE_IN_BITS) {
                throw new Error(`file too large: > 1MB`);
            }
            if (this.state.selectedFile.name.length > maxChar) {
                throw new Error(`filename too long`);
            }
            const uploadStats = await apiClient.createProductsFromCsv(
                this.state.selectedFile
            );
            if (uploadStats.failures.length > 0) {
                //TODO integrate more in depth error messaging
                this.setState({ ...errorMessage("some creations failed") });
            } else {
                this.setState({
                    ...successMessage(`product instances created`),
                });
            }
            this.setState({
                successfulCreations: [...uploadStats.successes],
                failedCreations: [...uploadStats.failures],
                isCreating: false,
            });
        } catch (err) {
            this.setState({
                ...errorMessage(err),
                isCreating: false,
            });
        }
    }

    onAddProperty = () => {
        this.setState({
            properties: [...this.state.properties, { name: null, value: "" }],
        });
    };

    onRemoveProperty = (index) => {
        const properties = this.state.properties.filter((_, i) => i !== index);
        this.setState({
            properties,
        });
    };

    onChangeProperty = (name, value, index) => {
        const properties = this.state.properties;
        properties[index] = { name, value };
        this.setState({ properties });
    };

    onChangeTemplatedProperties = (templateProperties) => {
        this.setState({ templateProperties: templateProperties });
    };

    onFileChange = (file) => {
        this.setState({ selectedFile: file });
    };

    render() {
        return (
            <Stack
                direction="column"
                spacing={3}
                justifyContent="start"
                alignItems="start"
                minWidth="100%"
            >
                <ToggleButtonGroup
                    color="primary"
                    value={this.state.creationMode}
                    exclusive
                    onChange={(_, value) => {
                        this.setState({ creationMode: value });
                    }}
                    aria-label="creation mode"
                >
                    <ToggleButton value="manual" aria-label="manual creation">
                        <KeyboardRounded />
                    </ToggleButton>
                    <ToggleButton
                        value="CSV"
                        aria-label="automatic creation from CSV"
                    >
                        <PrecisionManufacturingRounded />
                    </ToggleButton>
                </ToggleButtonGroup>
                {this.state.creationMode === "manual" && (
                    <Stack
                        minWidth="100%"
                        alignItems="start"
                        justifyContent="start"
                        justifyItems="start"
                        alignContent="start"
                    >
                        <Typography variant="h3">
                            Manual Product Creation
                        </Typography>
                        <Stack
                            minWidth="100%"
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            justifyContent="start"
                            className={this.props.classes.propertiesTitle}
                        >
                            <Autocomplete
                                multiple
                                value={this.state.templateProperties}
                                onChange={(_, newNames) =>
                                    this.setState({
                                        templateProperties: [...newNames],
                                    })
                                }
                                options={this.state.propertyNames}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        multiline
                                        label="Properties"
                                    />
                                )}
                                className={this.props.classes.propertyName}
                            />
                            <Button
                                variant="secondary"
                                startIcon={<LibraryAddIcon />}
                                onClick={(event) =>
                                    this.setState({
                                        anchorEl: event.currentTarget,
                                    })
                                }
                                disabled={this.state.isCreating}
                            >
                                New Property Type
                            </Button>
                            <Popover
                                open={this.state.anchorEl != null}
                                onClose={() => {
                                    this.setState({ anchorEl: null });
                                    this.fetchProperties();
                                }}
                                anchorEl={this.state.anchorEl}
                                anchorOrigin={{
                                    vertical: "center",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <SubstratePropertyCreator
                                    style={{
                                        bgcolor: "primaryUtility.main",
                                        p: 2,
                                    }}
                                />
                            </Popover>
                        </Stack>
                        <Stack
                            minWidth="100%"
                            direction="row"
                            justifyContent="start"
                        >
                            <RTable
                                style={{
                                    width: "100%",
                                }}
                                title={
                                    <Typography variant="h3">
                                        Instances to Create
                                    </Typography>
                                }
                                columns={[
                                    {
                                        title: "Creation Date",
                                        field: "creationDate",
                                        editComponent: (props) => (
                                            <TextField
                                                type="date"
                                                value={props.value}
                                                onChange={(e) =>
                                                    props.onChange(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        ),
                                    },
                                    {
                                        title: "Batch Index",
                                        field: "batch",
                                        editComponent: (props) => {
                                            return (
                                                <TextField
                                                    value={props.value}
                                                    onChange={(e) =>
                                                        props.onChange(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "Product Index",
                                        field: "index",
                                        editComponent: (props) => {
                                            return (
                                                <TextField
                                                    value={props.value}
                                                    onChange={(e) =>
                                                        props.onChange(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            );
                                        },
                                    },
                                    ...this.state.templateProperties.map(
                                        (property) => {
                                            return {
                                                title: property,
                                                field: property,
                                                editComponent: (props) => {
                                                    return (
                                                        <TextField
                                                            value={props.value}
                                                            onChange={(e) =>
                                                                props.onChange(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    );
                                                },
                                            };
                                        }
                                    ),
                                ]}
                                data={this.state.toCreate}
                                editable={{
                                    onRowAdd: (newData) => {
                                        return new Promise((resolve) => {
                                            const dataWithId = newData;
                                            dataWithId["id"] =
                                                this.state.tableId;
                                            const newId =
                                                this.state.tableId + 1;
                                            const newArr = this.state.toCreate;
                                            newArr.push(dataWithId);
                                            this.setState({
                                                toCreate: newArr,
                                                tableId: newId,
                                            });
                                            resolve();
                                        });
                                    },
                                    onRowUpdate: (newData, oldData) => {
                                        return new Promise((resolve) => {
                                            const replacementIndex =
                                                this.state.toCreate.findIndex(
                                                    (row) =>
                                                        row.id === oldData.id
                                                );
                                            const newState =
                                                this.state.toCreate;
                                            newState[replacementIndex] =
                                                newData;
                                            this.setState({
                                                toCreate: [...newState],
                                            });
                                            resolve();
                                        });
                                    },
                                    onRowDelete: (oldData) => {
                                        return new Promise((resolve) => {
                                            const prevData = [
                                                ...this.state.toCreate,
                                            ];
                                            const newArr = prevData.filter(
                                                (element) =>
                                                    element.id !== oldData.id
                                            );
                                            this.setState({
                                                toCreate: newArr,
                                            });
                                            resolve();
                                        });
                                    },
                                }}
                            />
                        </Stack>

                        <Button
                            variant="secondary"
                            onClick={() => this.createProductInstances()}
                            disabled={
                                // we're creating
                                this.state.isCreating ||
                                this.state.toCreate.length === 0
                            }
                            className={this.props.classes.createButton}
                        >
                            Create
                        </Button>
                        {(this.state.successfulCreations.length !== 0 ||
                            this.state.failedCreations.length !== 0) && (
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <RTable
                                        disableAltRowColor
                                        title={
                                            <Typography variant="h3">
                                                Successful Creations
                                            </Typography>
                                        }
                                        columns={[
                                            { title: "ID", field: "prodId" },
                                            {
                                                title: "Creation Date",
                                                field: "creationDate",
                                            },
                                            {
                                                title: "Batch Index",
                                                field: "batch",
                                            },
                                            { title: "Index", field: "index" },
                                        ]}
                                        data={this.state.successfulCreations}
                                        options={{
                                            search: false,
                                            filtering: true,
                                            paging:
                                                this.state.successfulCreations
                                                    .length > 10,
                                            maxColumnSort: 0,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <RTable
                                        disableAltRowColor
                                        title={
                                            <Typography variant="h3">
                                                Failed Creations
                                            </Typography>
                                        }
                                        columns={[
                                            {
                                                title: "Creation Date",
                                                field: "creationDate",
                                            },
                                            {
                                                title: "Batch Index",
                                                field: "batch",
                                            },
                                            { title: "Index", field: "index" },
                                        ]}
                                        data={this.state.failedCreations}
                                        options={{
                                            search: false,
                                            filtering: true,
                                            paging:
                                                this.state.failedCreations
                                                    .length > 10,
                                            maxColumnSort: 0,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Stack>
                )}
                {this.state.creationMode === "CSV" && (
                    <Stack alignItems="start" justifyContent="start">
                        {!this.state.selectedFile ? (
                            <Stack
                                spacing={2}
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography variant="h3">
                                    Bulk Upload
                                </Typography>
                                <Tooltip
                                    title="Create from CSV"
                                    placement="right"
                                >
                                    <IconButton
                                        color="primary"
                                        component="label"
                                        onChange={(event) => {
                                            this.onFileChange(
                                                event.target.files[0]
                                            );
                                        }}
                                    >
                                        <input
                                            hidden
                                            accept=".csv"
                                            type="file"
                                        />
                                        <UploadFileRounded />
                                    </IconButton>
                                </Tooltip>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        this.setState({
                                            helpDialogueOpen: true,
                                        });
                                    }}
                                >
                                    <HelpOutlineRoundedIcon />
                                </IconButton>
                                <Dialog
                                    open={this.state.helpDialogueOpen}
                                    onClose={() => {
                                        this.setState({
                                            helpDialogueOpen: false,
                                        });
                                    }}
                                >
                                    <Typography padding={5}>
                                        {
                                            "This allows you to upload product \
                                        instances to be created by CSV. The \
                                        uploaded CSV should include columns for \
                                        'creationDate', 'batch', \
                                        'index', and any properties."
                                        }
                                    </Typography>
                                </Dialog>
                            </Stack>
                        ) : (
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography variant="h3">
                                    Bulk Upload
                                </Typography>
                                <Typography>
                                    {this.state.selectedFile.name}
                                </Typography>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        this.setState({
                                            selectedFile: undefined,
                                        });
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Stack>
                        )}
                        <Button
                            variant="secondary"
                            onClick={() => this.createProductsFromFile()}
                            disabled={
                                // we're creating or there isn't a file uploaded
                                this.state.isCreating ||
                                !this.state.selectedFile
                            }
                            className={this.props.classes.createButton}
                        >
                            Create
                        </Button>
                        {(this.state.successfulCreations.length !== 0 ||
                            this.state.failedCreations.length !== 0) && (
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <RTable
                                        disableAltRowColor
                                        title={
                                            <Typography variant="h3">
                                                Successful Creations
                                            </Typography>
                                        }
                                        columns={[
                                            { title: "ID", field: "prodId" },
                                            {
                                                title: "Creation Date",
                                                field: "creationDate",
                                            },
                                            {
                                                title: "Batch Index",
                                                field: "batch",
                                            },
                                            { title: "Index", field: "index" },
                                        ]}
                                        data={this.state.successfulCreations}
                                        options={{
                                            search: false,
                                            filtering: true,
                                            paging:
                                                this.state.successfulCreations
                                                    .length > 10,
                                            maxColumnSort: 0,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <RTable
                                        disableAltRowColor
                                        title={
                                            <Typography variant="h3">
                                                Failed Creations
                                            </Typography>
                                        }
                                        columns={[
                                            {
                                                title: "Creation Date",
                                                field: "creationDate",
                                            },
                                            {
                                                title: "Batch Index",
                                                field: "batch",
                                            },
                                            { title: "Index", field: "index" },
                                        ]}
                                        data={this.state.failedCreations}
                                        options={{
                                            search: false,
                                            filtering: true,
                                            paging:
                                                this.state.failedCreations
                                                    .length > 10,
                                            maxColumnSort: 0,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Stack>
                )}
                {/** TODO(hannah): Fix styling. */}
                <MessageHelper
                    message={this.state.message}
                    errorMessage={this.state.errorMessage}
                    open={this.state.messageOpen}
                    setState={(a) => this.setState(a)}
                />
            </Stack>
        );
    }
}

export default withStyles(styles)(WithGoogleAuth(ProductInstanceCreator));
