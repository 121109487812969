import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormGroup,
    Typography,
    Box,
} from "@mui/material";
import ShellfishInventoryAPIClient from "../../models/ShellfishInventoryAPIClient";

const styles = (theme) => ({
    section: {
        marginTop: theme.spacing(3),
    },
    formControl: {
        minWidth: 120,
        marginRight: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        color: theme.palette.header,
    },
});

let BSF = WithGoogleAuth(
    class BinSelectionForm extends Component {
        constructor(props) {
            super(props);
            this.initialState = {
                data: [],
                selectedFarmId: "",
                selectedReefId: "",
                selectedBinId: "",
                selectedBinIds: [],
                errors: props.errors,
            };
            this.state = this.initialState;
            this.onFarmSelected = this.onFarmSelected.bind(this);
            this.onReefSelected = this.onReefSelected.bind(this);
            this.onBinSelected = this.onBinSelected.bind(this);
        }

        componentDidMount() {
            let t = this;
            new ShellfishInventoryAPIClient(this.props.authState)
                .getFarmSystem()
                .then((farms) => {
                    t.setState({ farms: farms });
                    return farms;
                })
                .then((farms) => {
                    if (this.props.onFarmSystemReceived !== undefined) {
                        this.props.onFarmSystemReceived(farms);
                    }
                });
        }

        getBinForm() {
            if (this.props.multiple) {
                return (
                    <Select
                        labelId="demo-simple-select-label"
                        id="bin"
                        multiple
                        value={this.state.selectedBinIds}
                        onChange={this.onBinSelected}
                        error={this.state.errors.bin}
                    >
                        {/* <MenuItem key={"all-bins"} value={"all"}>Select All</MenuItem> */}
                        {this.binOptions()}
                    </Select>
                );
            } else {
                return (
                    <Select
                        labelId="demo-simple-select-label"
                        id="bin"
                        value={this.state.selectedBinId}
                        onChange={this.onBinSelected}
                        error={this.state.errors.bin}
                    >
                        {this.binOptions()}
                    </Select>
                );
            }
        }

        clear() {
            this.setState(this.initialState);
        }

        onFarmSelected = (event) => {
            this.setState({ selectedFarmId: event.target.value });
            if (this.props.onFarmSelected !== undefined) {
                this.props.onFarmSelected(event.target.value);
            }
            this.onReefSelected({ target: { value: "" } });
        };

        onReefSelected = (event) => {
            this.setState({ selectedReefId: event.target.value });
            if (this.props.multiple) {
                this.onBinSelected({ target: { value: [] } });
            } else {
                this.onBinSelected({ target: { value: "" } });
            }
            if (this.props.onReefSelected !== undefined) {
                this.props.onReefSelected(event.target.value);
            }
        };

        onBinSelected = (event) => {
            if (this.props.multiple) {
                this.setState({ selectedBinIds: event.target.value });
            } else {
                this.setState({ selectedBinId: event.target.value });
            }

            if (this.props.onBinSelected !== undefined) {
                this.props.onBinSelected(event.target.value);
            }
        };

        getSelectedFarm() {
            if (this.state.farms == null) return null;

            for (var i = 0; i < this.state.farms.length; i++) {
                if (this.state.selectedFarmId === this.state.farms[i].id)
                    return this.state.farms[i];
            }
            return null;
        }

        getSelectedReef() {
            let farm = this.getSelectedFarm();
            if (farm == null) return null;

            for (var i = 0; i < farm.reefs.length; i++) {
                if (this.state.selectedReefId === farm.reefs[i].id)
                    return farm.reefs[i];
            }
            return null;
        }

        farmOptions() {
            if (this.state.farms == null) return;
            return this.state.farms.map((farm) => (
                <MenuItem key={farm.id} value={farm.id}>
                    {farm.name}
                </MenuItem>
            ));
        }

        reefOptions() {
            //Check if it's a new row
            let farm = this.getSelectedFarm();
            if (farm == null) return;
            return farm.reefs.map((reef) => (
                <MenuItem key={reef.id} value={reef.id}>
                    {reef.name}
                </MenuItem>
            ));
        }

        binOptions() {
            let reef = this.getSelectedReef();
            if (reef == null) return;
            return reef.bins.map((bin) => (
                <MenuItem key={bin.id} value={bin.id}>
                    {bin.name}
                </MenuItem>
            ));
        }

        render() {
            const { classes } = this.props;
            return (
                <Box className={classes.section}>
                    <Typography
                        className={classes.header}
                        variant="h3"
                        color="tertiaryUtility"
                    >
                        {this.props.title != null
                            ? this.props.title
                            : "FARM INFO"}
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {this.props.description ?? ""}
                    </Typography>
                    <FormGroup row={true}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                Farm
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="farm"
                                value={this.state.selectedFarmId}
                                onChange={this.onFarmSelected}
                                error={this.state.errors.farm}
                            >
                                {this.farmOptions()}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                Reef
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="reef"
                                value={this.state.selectedReefId}
                                onChange={this.onReefSelected}
                                error={this.state.errors.reef}
                            >
                                {this.reefOptions()}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                Bin
                            </InputLabel>
                            {this.getBinForm()}
                        </FormControl>
                    </FormGroup>
                </Box>
            );
        }
    }
);

export default withStyles(styles)(BSF);
