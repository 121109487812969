import { UPDATE_USER } from "../actions";
const initialState = { loggedInUser: { id: null, groups: [] } };
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_USER: {
            return { ...state, loggedInUser: action.data };
        }
        default: {
            return state;
        }
    }
}
