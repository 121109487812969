import React, { Component } from "react";
import { IconButton, Link, Stack, Typography } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

import RTable from "../components/RTable";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";

class TestConceptList extends Component {
    state = {
        testConcepts: [],
    };

    async componentDidMount() {
        // Fetch a list of all of the test concepts that exist
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        const testConcepts = await apiClient.getTestConcepts();
        this.setState({ testConcepts });
    }

    render() {
        return (
            <RTable
                title={
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="h3">Test Concepts</Typography>
                        <IconButton color="primary" href="/test/create-concept">
                            <AddBoxIcon />
                        </IconButton>
                    </Stack>
                }
                columns={[
                    {
                        title: "Name",
                        render: (rowData) => (
                            <Link href={`/test/concept/${rowData.id}`}>
                                {rowData.name}
                            </Link>
                        ),
                    },
                    { title: "Description", field: "description" },
                ]}
                data={this.state.testConcepts}
            />
        );
    }
}

export default WithGoogleAuth(TestConceptList);
