import React, { Component } from "react";
import {
    CircularProgress,
    IconButton,
    Link,
    Stack,
    Typography,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

import RTable from "../components/RTable";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";

const limit = 20;
class ProductInstanceList extends Component {
    state = {
        productInstances: [],
        loading: true,
    };

    async componentDidMount() {
        // Fetch a list of all of the product instances that exist
        const fetchInstances = async () => {
            if (productInstances.length === limit) {
                productInstances = await apiClient.getProductInstances(
                    limit,
                    productInstances.at(-1).id
                );
                this.setState(
                    {
                        productInstances:
                            this.state.productInstances.concat(
                                productInstances
                            ),
                    },
                    fetchInstances
                );
            } else {
                this.setState({ loading: false });
            }
        };
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        let productInstances = await apiClient.getProductInstances(limit);
        this.setState({ productInstances }, fetchInstances);
    }

    render() {
        return (
            <RTable
                title={
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="h3">Product Instances</Typography>
                        {this.state.loading && (
                            <CircularProgress size="1.5rem" />
                        )}
                        <IconButton color="primary" href="/product/create">
                            <AddBoxIcon />
                        </IconButton>
                    </Stack>
                }
                columns={[
                    { title: "Creation Date", field: "creationDate" },
                    { title: "Batch Index", field: "batchIndex" },
                    { title: "Index", field: "index" },
                    {
                        render: (rowData) => (
                            <Link href={`/product/${rowData.id}`}>Details</Link>
                        ),
                    },
                ]}
                data={this.state.productInstances}
                options={{ pageSize: 10, filtering: true }}
            />
        );
    }
}

export default WithGoogleAuth(ProductInstanceList);
