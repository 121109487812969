import React, { Component } from "react";
import { Autocomplete, TextField, Typography, Stack } from "@mui/material";
import moment from "moment-timezone";

import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import RTable from "../components/RTable";

class TestDataEntry extends Component {
    state = {
        // Options
        testConcepts: null,
        testInstances: null,
        productInstancesById: null,

        // User selections
        testConcept: null,
        dataFields: [],

        // Derived state
        creationDateLookup: {},
        batchIndexLookup: {},
        dataColumns: [],
    };

    async componentDidMount() {
        this.fetchTestConcepts();
    }

    async fetchTestConcepts() {
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        const testConcepts = await apiClient.getTestConcepts();
        this.setState({ testConcepts });
    }

    async fetchTestConceptDetails() {
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        let { testInstances, productInstances } =
            await apiClient.getTestConcept(this.state.testConcept.id, true);

        // Create a map from product instance IDs to product instances.
        const productInstancesById = {};
        productInstances.forEach((productInstance) => {
            productInstancesById[productInstance.id] = productInstance;
        });

        // Add product instance data to test instance data.
        testInstances = testInstances.map((data) => {
            const { creationDate, batchIndex, index } =
                productInstancesById[data.productInstanceId];
            return {
                ...data,
                productCreationDate: creationDate,
                productBatchIndex: batchIndex,
                productIndex: index,
            };
        });

        // Create "lookup" maps so that creation date and batch index columns
        // can be filter-able.
        const creationDateLookup = {};
        const batchIndexLookup = {};
        testInstances.forEach(({ productCreationDate, productBatchIndex }) => {
            creationDateLookup[productCreationDate] = productCreationDate;
            batchIndexLookup[productBatchIndex] = productBatchIndex;
        });

        this.setState({
            testInstances,
            productInstancesById,
            creationDateLookup,
            batchIndexLookup,
        });
    }

    getDataCols = (testInstances, dataFields) => {
        // When the user selects a data field, we want to show all historical
        // data with that name. We start by creating a list of unique
        // timestamps and names.
        let timestampsAndNames = (testInstances || [])
            .map(({ data }) =>
                data
                    .filter(({ name }) => dataFields.includes(name))
                    .map(({ timestamp, name }) =>
                        // Stringify for easier deduplication.
                        JSON.stringify({ timestamp, name })
                    )
            )
            .flat();
        timestampsAndNames = [...new Set(timestampsAndNames)];
        timestampsAndNames = timestampsAndNames.map(JSON.parse);
        timestampsAndNames.sort((a, b) =>
            // Sort by timestamp then name.
            a.timestamp === b.timestamp
                ? a.name.localeCompare(b.name)
                : a.timestamp.localeCompare(b.timestamp)
        );

        // Create a new data column for each timestamp / name.
        const historicalDataColumns = timestampsAndNames.map(
            ({ timestamp, name }) => ({
                title: `${moment(timestamp)
                    .tz(moment.tz.guess())
                    .format("YYYY-MM-DD HH:MM")} - ${name}`,
                render: (rowData) => {
                    const values = rowData.data.filter(
                        (dataEntry) =>
                            dataEntry.timestamp === timestamp &&
                            dataEntry.name === name
                    );
                    return values.length === 1 ? values[0].value : null;
                },
                editable: "never",
            })
        );

        // Add columns for new data.
        dataFields.sort();
        const newDataColumns = dataFields
            .map((name) => [
                {
                    title: `${name} timestamp`,
                    field: `${name} timestamp`,
                    editComponent: (props) => (
                        <TextField
                            type="datetime-local"
                            value={props.value || ""}
                            onChange={(e) => props.onChange(e.target.value)}
                        />
                    ),
                    filtering: false,
                },
                {
                    title: `${name} value`,
                    field: `${name} value`,
                    filtering: false,
                },
            ])
            .flat();

        return [...historicalDataColumns, ...newDataColumns];
    };

    onBulkUpdate = async (changes) => {
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);

        // Gather any test data that was added.
        const newTestData = Object.values(changes).map(({ newData }) => ({
            id: newData.id,
            data: this.state.dataFields
                .map((name) => {
                    // Convert the timestamp to an ISO string.
                    let timestamp = newData[`${name} timestamp`];
                    if (timestamp != null && timestamp.length > 0) {
                        timestamp = moment(timestamp).isValid()
                            ? moment(timestamp).toISOString()
                            : null;
                    }
                    return { name, value: newData[`${name} value`], timestamp };
                })
                .filter(
                    // Ignore test data if a value or timestamp was not provided.
                    ({ value, timestamp }) =>
                        value != null &&
                        value.length > 0 &&
                        timestamp != null &&
                        timestamp.length > 0
                ),
        }));

        // Send new data to the API.
        const updates = await Promise.all(
            newTestData.map(async ({ id, data }) => {
                try {
                    await apiClient.updateTestInstance(id, null, data);
                    return { id, data };
                } catch (e) {
                    console.error(e);
                }
            })
        );

        // Update the test instance data to include the newly added data.
        const newTestInstances = [...this.state.testInstances];
        updates
            // Skip any failed updates.
            .filter((update) => update != null)
            .forEach(({ id, data }) => {
                const index = this.state.testInstances.findIndex(
                    (testInstance) => testInstance.id === id
                );
                newTestInstances[index].data =
                    newTestInstances[index].data.concat(data);
            });

        this.setState({
            testInstances: newTestInstances,
            dataColumns: this.getDataCols(
                newTestInstances,
                this.state.dataFields
            ),
        });
    };

    render() {
        return (
            <Stack>
                <Typography variant="h3">Data Entry</Typography>
                <Autocomplete
                    value={this.state.testConcept}
                    onChange={(_, testConcept) =>
                        this.setState(
                            {
                                testConcept,
                                testInstances: null,
                                productInstancesById: null,
                                dataFields: [],
                                creationDateLookup: {},
                                batchIndexLookup: {},
                                dataColumns: [],
                            },
                            this.fetchTestConceptDetails
                        )
                    }
                    options={this.state.testConcepts || []}
                    renderInput={(params) => (
                        <TextField {...params} label="Test Concept" />
                    )}
                    getOptionLabel={(option) => option.name}
                />
                <Autocomplete
                    multiple
                    disabled={!this.state.testConcept}
                    value={this.state.dataFields}
                    onChange={(_, dataFields) =>
                        this.setState({
                            dataFields,
                            dataColumns: this.getDataCols(
                                this.state.testInstances,
                                dataFields
                            ),
                        })
                    }
                    options={
                        this.state.testConcept
                            ? Object.keys(this.state.testConcept.data)
                            : []
                    }
                    renderInput={(params) => (
                        <TextField {...params} label="Data Fields" />
                    )}
                />
                {this.state.testInstances && (
                    <RTable
                        title={<Typography variant="h3">Data</Typography>}
                        columns={[
                            {
                                title: "Creation Date",
                                field: "productCreationDate",
                                lookup: this.state.creationDateLookup,
                                editable: "never",
                            },
                            {
                                title: "Batch Index",
                                field: "productBatchIndex",
                                lookup: this.state.batchIndexLookup,
                                editable: "never",
                            },
                            {
                                title: "Index",
                                field: "productIndex",
                                filtering: false,
                                editable: "never",
                            },
                            ...this.state.dataColumns,
                        ]}
                        editable={{
                            isDeleteHidden: () => true,
                            isAddHiddent: () => true,
                            onBulkUpdate: this.onBulkUpdate,
                        }}
                        data={this.state.testInstances}
                        options={{ search: false, filtering: true }}
                    />
                )}
            </Stack>
        );
    }
}

export default WithGoogleAuth(TestDataEntry);
