import React, { Component } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

export class EditGroups extends Component {
    constructor(props) {
        super(props);
        this.client = props.client;
        this.state = {
            allGroups: [],
            selectedGroups: [],
        };
    }

    async componentDidMount() {
        await this.loadGroupsState();
    }

    async loadGroupsState() {
        const groupsResponse = await this.client.getGroups(1, 100);
        if (groupsResponse.error) return;
        const selectedGroups = groupsResponse.data.filter((group) =>
            this.props.groups.some((selectedId) => group.id === selectedId)
        );
        this.setState((s) => ({
            ...s,
            allGroups: groupsResponse.data,
            selectedGroups: selectedGroups,
        }));
    }

    render() {
        const availableGroups = this.state?.allGroups ?? [];
        return (
            <Autocomplete
                disablePortal
                multiple
                options={availableGroups}
                getOptionLabel={(o) => o.name}
                value={this.state.selectedGroups}
                onChange={(event, newValue) => {
                    this.props.onChange(newValue?.map((g) => g.id) ?? []);
                    this.setState((s) => ({
                        ...s,
                        selectedGroups: newValue,
                    }));
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label="Group"
                            multiline
                            placeholder="Group"
                        />
                    );
                }}
            />
        );
    }
}
