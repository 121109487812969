/* eslint-disable */

module.exports = {
    serverPath: process.env.REACT_APP_SERVER_URL,
    carbonAccountingServerPath:
        process.env.REACT_APP_CARBON_ACCOUNTING_SERVER_URL,
    shellfishInventoryServerPath:
        process.env.REACT_APP_SHELLFISH_INVENTORY_SERVER_URL,
    mapBoxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
};
