import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    Grid,
    Typography,
    Stack,
    ListItem,
    IconButton,
    ListItemText,
    List,
    ListItemIcon,
    ListItemButton,
    ListSubheader,
    Button,
} from "@mui/material";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

import { WithGoogleAuth } from "../../../config/WithGoogleAuth";
import MultiFileUpload from "../../components/MultiFileUpload";
import { errorMessage } from "../../helper/MessageMethodHelper";
import IconButtonWithTooltip from "../../helper/IconButtonWithTooltip";
import CarbonAccountingAPIClient from "../../../models/CarbonAccountingAPIClient";

const FILE_UPLOAD_WIDTH = 1; // 100% in MUI terms
const EVIDENCE_HEIGHT = 200;

function DeploymentEstimateEvidence(props) {
    const {
        params,
        evidence,
        reloadEvidence,
        message,
        setMessage,
        authState,
        aaUser,
    } = props;
    const [editing, setEditing] = useState(false);

    const [existingEvidence, setExistingEvidence] = useState([]);
    const [deletedEvidenceIds, setDeletedEvidenceIds] = useState([]);
    const [uploadedEvidence, setUploadedEvidence] = useState([]);

    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        resetEvidenceFields();
    }, [evidence]);

    const resetEvidenceFields = () => {
        setUploadedEvidence([]);
        setExistingEvidence(evidence);
        setDeletedEvidenceIds([]);

        setEditing(false);
    };

    const onEvidenceUpload = (filenames) => {
        const uniqueFilenames = [];
        for (const file of filenames) {
            if (evidence.findIndex((f) => f.title === file.name) >= 0) {
                setMessage(
                    errorMessage(
                        `Attempted to upload duplicate filename: file ${file.name} matches preexisting evidence`
                    )
                );
            } else if (
                uploadedEvidence.findIndex((f) => f.name === file.name) < 0
            ) {
                uniqueFilenames.push(file);
            }
        }
        setUploadedEvidence([...uploadedEvidence, ...uniqueFilenames]);
    };

    const saveEdits = async () => {
        setIsSaving(true);
        try {
            const apiClient = new CarbonAccountingAPIClient(authState);
            await Promise.all([
                apiClient.addEvidenceToEstimate(
                    params.estimate_id,
                    uploadedEvidence
                ),
                ...deletedEvidenceIds.map((id) =>
                    apiClient.deleteEstimateEvidence(id)
                ),
            ]);
            setMessage({ ...message, messageOpen: false });
            reloadEvidence();
            setEditing(false);
        } catch (error) {
            setMessage(errorMessage(error));
        }
        setIsSaving(false);
    };

    return (
        <Grid item lg={57} xs={100}>
            <Stack variant="splitHeader">
                <Typography variant="h3">Associated Evidence</Typography>
                {editing ? (
                    <Stack
                        alignSelf="flex-end"
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        spacing={3}
                        mt={2}
                    >
                        <Button
                            variant="string"
                            onClick={() => {
                                setMessage({ ...message, messageOpen: false });
                                resetEvidenceFields();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            id="save-estimate"
                            variant="secondary"
                            onClick={() => {
                                saveEdits();
                            }}
                            disabled={isSaving}
                        >
                            Save
                        </Button>
                    </Stack>
                ) : (
                    <IconButtonWithTooltip
                        id="edit-evidence"
                        tooltipText={
                            aaUser
                                ? "Edit & upload new evidence"
                                : "User not authorized"
                        }
                        color="primary"
                        onClick={() => setEditing(true)}
                        disabled={!aaUser}
                    >
                        <PlaylistAddIcon />
                    </IconButtonWithTooltip>
                )}
            </Stack>
            {editing ? (
                <Stack justifyContent="flex-start" alignItems="flex-start">
                    <MultiFileUpload
                        onFilesUpload={onEvidenceUpload}
                        sxWidth={FILE_UPLOAD_WIDTH}
                    />
                    <List
                        sx={{
                            width: "100%",
                            maxWidth: "100%",
                            bgcolor: "background.paper",
                            position: "relative",
                            overflow: "auto",
                            maxHeight: EVIDENCE_HEIGHT,
                            "& ul": { padding: 0 },
                            overflowWrap: "break-word",
                        }}
                        subheader={
                            <ListSubheader id="local-files-subheader">
                                Existing File Uploads
                            </ListSubheader>
                        }
                        dense={true}
                    >
                        <ul>
                            {existingEvidence.map((file) => (
                                <ListItem
                                    key={`local-file-${file.title}`}
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => {
                                                setExistingEvidence(
                                                    [
                                                        ...existingEvidence,
                                                    ].filter(
                                                        (e) =>
                                                            e.title !==
                                                            file.title
                                                    )
                                                );
                                                setDeletedEvidenceIds([
                                                    ...deletedEvidenceIds,
                                                    file.id,
                                                ]);
                                            }}
                                        >
                                            <DeleteOutlinedIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText primary={file.title} />
                                </ListItem>
                            ))}
                            <ListSubheader id="local-files-subheader">
                                New File Uploads
                            </ListSubheader>
                            {uploadedEvidence.map((file) => (
                                <ListItem
                                    key={`local-file-${file.name}`}
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => {
                                                setUploadedEvidence(
                                                    [
                                                        ...uploadedEvidence,
                                                    ].filter(
                                                        (e) =>
                                                            e.name !== file.name
                                                    )
                                                );
                                            }}
                                        >
                                            <DeleteOutlinedIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText primary={file.name} />
                                </ListItem>
                            ))}
                        </ul>
                    </List>
                </Stack>
            ) : (
                <List
                    sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: EVIDENCE_HEIGHT,
                        "& ul": { padding: 0 },
                        overflowWrap: "break-word",
                    }}
                >
                    <ul>
                        {evidence.map((file) => (
                            <ListItem
                                key={`evidence-${file.title}`}
                                disablePadding
                            >
                                <ListItemButton
                                    component="a"
                                    href={file.signedUrl}
                                    target="_blank"
                                    dense
                                >
                                    <ListItemIcon>
                                        <FileDownloadIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={file.title} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </ul>
                </List>
            )}
        </Grid>
    );
}

export default WithGoogleAuth((props) => (
    <DeploymentEstimateEvidence {...props} params={useParams()} />
));
