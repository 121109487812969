// src/Login.jsx
import React, { Component } from "react";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import mapboxgl from "mapbox-gl";
import config from "../../config";
import ShellfishInventoryAPIClient from "../../models/ShellfishInventoryAPIClient";
import { Button, Grid, Divider } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import VesselStatus from "../VesselStatus";
import OysterData from "../OysterData";

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    markerVessel: {
        backgroundImage: "url('/images/vessels/ship-boat-svgrepo-com.svg')",
        backgroundSize: "cover",
        width: 50,
        height: 50,
        borderRadius: "50%",
        cursor: "pointer",
    },
    "marker-virginica": {
        filter: "invert(51%) sepia(92%) saturate(2432%) hue-rotate(85deg) brightness(126%) contrast(117%)",
    },
    "marker-nautico": {
        filter: "invert(78%) sepia(44%) saturate(6115%) hue-rotate(146deg) brightness(111%) contrast(103%)",
    },
    "marker-gradingvessel": {
        filter: "invert(77%) sepia(9%) saturate(382%) hue-rotate(58deg) brightness(89%) contrast(88%)",
    },
    mapContainer: {
        height: "800px",
        width: "100%",
    },
    mapOverlay: {
        position: "relative",
        width: "210px",
        top: 0,
        left: 0,
        padding: "10px",
        zIndex: 1,
    },
    mapOverlayInner: {
        backgroundColor: theme.palette.background.mapOverlay,
        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
        borderRadius: "3px",
        padding: "10px",
        marginBottom: "10px",
    },
    popup: {
        color: theme.palette.darkUtility.main,
    },
});

let lmap = WithGoogleAuth(
    class LiveMap extends Component {
        constructor(props) {
            super(props);

            let lastUpdate = new Date();
            lastUpdate.setHours(0, 0, 0, 0);
            this.state = {
                lng: -70.19238,
                lat: 43.726751,
                zoom: 11,
                vesselRoutes: [],
                lastUpdate: lastUpdate,
                markers: {},
            };
            mapboxgl.accessToken = config.mapBoxAccessToken;
            this.pullLatestVesselLocation =
                this.pullLatestVesselLocation.bind(this);
            this.updateVesselRoute = this.updateVesselRoute.bind(this);
            this.moveToDoughtysPoint = this.moveToDoughtysPoint.bind(this);
            this.moveToWilsonsCove = this.moveToWilsonsCove.bind(this);
            this.vesselColorMap = {
                Virginica: "#0f0",
                "Grading Vessel": "#A3AF9E",
                Nautico: "#00E5FF",
                Other: "#00ACFF",
            };
        }

        componentDidMount() {
            this.map = new mapboxgl.Map({
                container: this.mapContainer,
                style: "mapbox://styles/mapbox/dark-v9",
                center: [this.state.lng, this.state.lat],
                zoom: this.state.zoom,
            });

            let t = this;
            this.map.on("load", () => {
                t.map.resize();
                // t.pullLatestVesselLocation()
                t.pullFarmData();
            });

            this.map.on("error", function (e) {
                console.log("A error event occurred.");
                console.log(e);
            });

            this.vesselUpdater = setInterval(function () {
                t.pullLatestVesselLocation();
            }, 5000);
        }

        componentWillUnmount() {
            clearInterval(this.vesselUpdater);
        }

        pullFarmData() {
            let t = this;
            new ShellfishInventoryAPIClient(this.props.authState)
                .getFarmSystem()
                .then((res) => {
                    res.forEach((farm) => {
                        farm.reefs.forEach((reef) => {
                            t.drawReef(reef);
                        });
                    });
                });
        }

        pullLatestVesselLocation() {
            let lastUpdate = new Date();
            lastUpdate.setHours(0, 0, 0, 0);
            new ShellfishInventoryAPIClient(this.props.authState)
                .getLiveVesselLocations(lastUpdate)
                .then((res) => res.json())
                .then((res) => {
                    this.setState({ vesselRoutes: res }, () => {
                        this.updateVesselRoute();
                    });
                });
        }

        getVesselRouteColorForName(name) {
            let color = this.vesselColorMap[name];
            if (color === undefined || color == null)
                return this.vesselColorMap["Other"];
            else return color;
        }

        updateVesselRoute() {
            this.state.vesselRoutes.forEach((vessel) => {
                let coordinates = vessel.locations.map((loc) => [
                    loc.long,
                    loc.lat,
                ]);
                let geoJsonDate = {
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "LineString",
                        coordinates: coordinates,
                    },
                };

                let existingRoute = this.map.getSource(vessel.id + "-route");
                if (existingRoute !== undefined) {
                    existingRoute.setData(geoJsonDate);
                } else {
                    //Add the new route
                    this.map.addSource(vessel.id + "-route", {
                        type: "geojson",
                        data: geoJsonDate,
                    });
                    this.map.addLayer({
                        id: vessel.id + "-route",
                        type: "line",
                        source: vessel.id + "-route",
                        layout: {
                            "line-join": "round",
                            "line-cap": "round",
                        },
                        paint: {
                            "line-color": this.getVesselRouteColorForName(
                                vessel.name
                            ),
                            "line-width": 5,
                        },
                    });

                    //TODO: Could be used to show location data of vessel on hover
                    // this.map.on('mousemove', vessel.id + '-route', (e) => {
                    //     var features = this.map.queryRenderedFeatures(e.point);
                    //     console.log(features)
                    // });
                }

                if (this.state.markers[vessel.id] !== undefined) {
                    this.state.markers[vessel.id].marker.setLngLat(
                        coordinates[vessel.locations.length - 1]
                    );
                    this.state.markers[vessel.id].marker.addTo(this.map);
                } else {
                    const { classes } = this.props;

                    var popup = new mapboxgl.Popup({
                        offset: 25,
                        className: classes.popup,
                    }).setText(vessel.name);

                    var el = document.createElement("div");
                    el.className = classes["markerVessel"];
                    el.className +=
                        " " +
                        classes[
                            "marker-" +
                                vessel.name.toLowerCase().replace(" ", "")
                        ];

                    var marker = new mapboxgl.Marker(el)
                        .setPopup(popup)
                        .setLngLat(coordinates[vessel.locations.length - 1])
                        .addTo(this.map);

                    let markers = this.state.markers;
                    markers[vessel.id] = { marker: marker, popup: popup };
                    this.setState({ markers: markers });
                }
            });
        }

        getBinForDrawingIndex(bins, index) {
            if (index < 4) {
                //Top Left
                return bins[index + 4];
            } else if (index < 8) {
                return bins[7 - index];
            } else if (index < 12) {
                return bins[index + 4];
            } else {
                return bins[index - (index - 12) * 2 - 1];
            }
            //How it's stored in the db
            //7 15
            //6 14
            //5 13
            //4 12

            //3 11
            //2 10
            //1 9
            //0 8

            //How it's drawn
            //3 11
            //2 10
            //1 9
            //0 8

            //4 12
            //5 13
            //6 14
            //7 15
        }

        drawReef(reef) {
            let reefLoc = { lat: reef.lat, long: reef.long };
            let reefHeight = 100;
            let reefWidth = 50;
            let precision = 0.00001;

            let binHeight = reefHeight / 8;
            let binWidth = reefWidth / 2;

            let markerLoc = {
                lat: reefLoc.lat + 10 * precision,
                long: reef.long - binWidth * 2 * precision,
            };

            let bins = [];
            for (let i = 0; i < 2; i++) {
                //Start on the left side
                for (let j = 0; j < 8; j++) {
                    let bin = this.getBinForDrawingIndex(reef.bins, i * 8 + j);
                    //Reef loc is in the middle. First 4 are below, next 4 are above
                    if (j < 4) {
                        let binBottomLeft = {
                            lat: reefLoc.lat - (3 - j) * binHeight * precision,
                            long: reefLoc.long - (2 - i) * binWidth * precision,
                        };
                        let binTopLeft = {
                            lat:
                                reefLoc.lat -
                                (3 - (j - 1)) * binHeight * precision,
                            long: reefLoc.long - (2 - i) * binWidth * precision,
                        };
                        let binTopRight = {
                            lat:
                                reefLoc.lat -
                                (3 - (j - 1)) * binHeight * precision,
                            long:
                                reefLoc.long -
                                (2 - (i - 1)) * binWidth * precision,
                        };
                        let binBottomRight = {
                            lat: reefLoc.lat - (3 - j) * binHeight * precision,
                            long:
                                reefLoc.long -
                                (2 - (i - 1)) * binWidth * precision,
                        };
                        bins.push({
                            id: bin.id,
                            name: bin.name,
                            coordinates: [
                                binBottomLeft,
                                binTopLeft,
                                binTopRight,
                                binBottomRight,
                                binBottomLeft,
                            ],
                        });
                    } else {
                        let binBottomLeft = {
                            lat:
                                reefLoc.lat -
                                (j - 4 + 4) * binHeight * precision,
                            long: reefLoc.long - (2 - i) * binWidth * precision,
                        };
                        let binTopLeft = {
                            lat:
                                reefLoc.lat -
                                (j - 4 + 5) * binHeight * precision,
                            long: reefLoc.long - (2 - i) * binWidth * precision,
                        };
                        let binTopRight = {
                            lat:
                                reefLoc.lat -
                                (j - 4 + 5) * binHeight * precision,
                            long:
                                reefLoc.long -
                                (2 - (i - 1)) * binWidth * precision,
                        };
                        let binBottomRight = {
                            lat:
                                reefLoc.lat -
                                (j - 4 + 4) * binHeight * precision,
                            long:
                                reefLoc.long -
                                (2 - (i - 1)) * binWidth * precision,
                        };
                        bins.push({
                            id: bin.id,
                            name: bin.name,
                            coordinates: [
                                binBottomLeft,
                                binTopLeft,
                                binTopRight,
                                binBottomRight,
                                binBottomLeft,
                            ],
                        });
                    }
                }
            }

            this.addMarkerWithPopup(markerLoc, reef.name);

            bins.forEach((bin) => {
                let polygon = bin.coordinates.map((coordinate) => [
                    coordinate.long,
                    coordinate.lat,
                ]);
                this.map.addSource(bin.id, {
                    type: "geojson",
                    data: {
                        type: "Feature",
                        geometry: {
                            type: "Polygon",
                            coordinates: [polygon],
                        },
                    },
                });

                this.map.addLayer({
                    id: bin.id,
                    type: "fill",
                    source: bin.id,
                    layout: {},
                    paint: {
                        "fill-color": "#088",
                        "fill-opacity": 0.8,
                    },
                });

                let t = this;
                // When a click event occurs on a feature in the states layer, open a popup at the
                // location of the click, with description HTML from its properties.

                this.map.on("click", bin.id, function (e) {
                    new mapboxgl.Popup()
                        .setLngLat(e.lngLat)
                        .setHTML(bin.name)
                        .addTo(t.map);
                });
                // Change the cursor to a pointer when the mouse is over the states layer.
                this.map.on("mouseenter", bin.vesselId, function () {
                    t.map.getCanvas().style.cursor = "pointer";
                });

                // Change it back to a pointer when it leaves.
                this.map.on("mouseleave", bin.id, function () {
                    t.map.getCanvas().style.cursor = "";
                });
            });
        }

        addMarkerWithPopup(coordinates, text) {
            // create the popup
            var popup = new mapboxgl.Popup({
                offset: 25,
                className: this.props.classes.popup,
            }).setText(text);

            //Zoom to the the marker when it's clicked
            let t = this;
            popup.on("open", function () {
                t.map.flyTo({
                    center: [coordinates.long, coordinates.lat],
                    zoom: 15,
                });
            });

            new mapboxgl.Marker()
                .setLngLat([coordinates.long, coordinates.lat])
                .setPopup(popup) // sets a popup on this marker
                .addTo(this.map);
        }

        moveToWilsonsCove() {
            this.map.flyTo({ center: [-69.980009, 43.824467], zoom: 15 });
        }

        moveToDoughtysPoint() {
            this.map.flyTo({ center: [-69.943056, 43.833611], zoom: 15 });
        }

        render() {
            const { classes } = this.props;
            return (
                <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item lg={3} xs={12}>
                            <VesselStatus
                                colorMap={this.vesselColorMap}
                            ></VesselStatus>
                        </Grid>
                        <Grid item lg={9} xs={12}>
                            <div
                                ref={(el) => (this.mapContainer = el)}
                                className={
                                    "mapContainer " + classes.mapContainer
                                }
                            >
                                <div className={classes.mapOverlay}>
                                    <div className={classes.mapOverlayInner}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="secondary"
                                                    type="submit"
                                                    onClick={
                                                        this.moveToWilsonsCove
                                                    }
                                                >
                                                    Wilson&apos;s Cove
                                                </Button>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Button
                                                    variant="secondary"
                                                    type="submit"
                                                    onClick={
                                                        this.moveToDoughtysPoint
                                                    }
                                                >
                                                    Doughty&apos;s Point
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider></Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <OysterData></OysterData>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }
);

export default withStyles(styles)(lmap);
