import React from "react";
import { createRoot } from "react-dom/client";

import "./index.css";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import App from "./components/App";
import { configureStore } from "./store";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import Theme from "./config/theme";

const store = configureStore();
const rootElement = document.getElementById("root");

const root = createRoot(rootElement);
const mode = "dark"; // Todo implement this as a system preference read?
const theme = Theme(mode);

root.render(
    <Provider store={store}>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </StyledEngineProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
