import React from "react";
import SensorsIcon from "@mui/icons-material/Sensors";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import { Chip, Tooltip } from "@mui/material";
import moment from "moment-timezone";

function BuoyStatus(props) {
    const {
        lastContact,
        batteryReading,
        ignoreBatteryHealth,
        batteryThreshold,
    } = props;
    let contactTooltip = "No contact with buoy in the last 60 days";
    if (lastContact) {
        if (moment(lastContact).isAfter(moment().subtract(15, "days"))) {
            contactTooltip = null;
        } else if (
            moment(lastContact).isBefore(moment().subtract(15, "days")) &&
            moment(lastContact).isAfter(moment().subtract(60, "days"))
        ) {
            contactTooltip = "Buoy last contact is 15-60 days prior";
        }
    }

    let batteryTooltip = !ignoreBatteryHealth;
    if (!ignoreBatteryHealth) {
        if (batteryReading) {
            batteryTooltip =
                batteryReading < batteryThreshold &&
                `Last reported battery status is below ${batteryThreshold}V`;
        } else {
            batteryTooltip = "Battery health unreported";
        }
    }

    let tooltipText;
    if (batteryTooltip && contactTooltip) {
        tooltipText = (
            <div>
                {batteryTooltip} <br /> {contactTooltip}
            </div>
        );
    } else {
        tooltipText = batteryTooltip || contactTooltip;
    }
    return !contactTooltip && !batteryTooltip ? (
        <Chip icon={<SensorsIcon />} label="OK" color="success" />
    ) : contactTooltip !== "No contact with buoy in the last 60 days" ? (
        <Tooltip title={tooltipText} placement="right">
            <Chip icon={<EventRepeatIcon />} label="Warning" color="warning" />
        </Tooltip>
    ) : (
        <Tooltip title={tooltipText} placement="right">
            <Chip
                icon={<RunningWithErrorsIcon />}
                label="Error"
                color="error"
            />
        </Tooltip>
    );
}

export default BuoyStatus;
