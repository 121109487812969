import config from "../config";
import { rtFetch } from "./APIClient";

export default class AdministrationAPIClient {
    constructor(authState) {
        this.accessToken = authState.accessToken;
        this.baseUrl = config.serverPath;
        this.userCache = [];
        this.groupCache = [];
    }
    removeFromCache(cache, id) {
        const cachedIdx = cache.findIndex((u) => u.id === id);
        if (cachedIdx >= 0) cache.splice(cachedIdx);
    }
    getHeaders() {
        const header = {
            Authorization: `Bearer ${this.accessToken}`,
            "x-request-origin": "firebase",
        };
        header["Content-Type"] = "application/json";
        return header;
    }
    async handleResponse(res) {
        if (res.status === 200) {
            return await res.json();
        } else {
            throw res;
        }
    }
    /**
     * Remove a user from a group
     * @param userId id of user to remove from group
     * @param groupId id of group to remove user from
     * @returns {Promise<Response | *>} a user or an error
     */
    async removeUserFromGroup(userId, groupId) {
        this.removeFromCache(this.userCache, userId);
        return rtFetch(this.baseUrl + `/user/${userId}/group/${groupId}`, {
            method: "DELETE",
            headers: this.getHeaders(),
        }).then(this.handleResponse);
    }

    /**
     * Add a user to a group
     * @param userId id of user to add to a group
     * @param groupId id of group to add user to
     * @returns {Promise<Response | *>} a user or an error
     */
    async addUserToGroup(userId, groupId) {
        this.removeFromCache(this.userCache, userId);
        return rtFetch(this.baseUrl + `/user/${userId}/group/${groupId}`, {
            method: "POST",
            headers: this.getHeaders(),
        }).then(this.handleResponse);
    }

    /**
     * Get a paged list of groups
     * @param pageNumber the page number to fetch users for
     * @param pageSize the page size
     * @param orderBy the field to order by
     * @param orderByDirection the direction to order by, asc | desc
     * @param name optional filter by name of group
     * @param isDefault optional filter by group default or not
     * @returns {Promise<Response | *>} a paged response object or an error
     */
    async getGroups(
        pageNumber = 1,
        pageSize = 20,
        orderBy = "name",
        orderByDirection = "desc",
        name = null,
        isDefault = null
    ) {
        let queryString =
            this.baseUrl +
            `/user/group?pageNumber=${pageNumber}&` +
            `pageSize=${pageSize}&` +
            `orderBy=${orderBy}&` +
            `orderDirection=${orderByDirection}`;
        if (name) queryString += `&name=${name}`;
        if (isDefault) queryString += `&default=${isDefault}`;
        return await rtFetch(queryString, {
            method: "GET",
            headers: this.getHeaders(),
        }).then(this.handleResponse);
    }

    /**
     * Gets a list of all known resources
     * @returns {Promise<Response|*|{error: *}>} a list of all known resources
     */
    async getKnownResources() {
        return await rtFetch(`${this.baseUrl}/user/permission/resource`, {
            method: "GET",
            headers: this.getHeaders(),
        })
            .then(this.handleResponse)
            .catch((e) => ({ error: e }));
    }

    /**
     * Adds the provided permission to a group
     * @returns {Promise<Response|*|{error: *}>} the added permission or an error
     */
    async addPermissionToGroup(groupId, resourceAccess) {
        this.removeFromCache(this.groupCache, groupId);
        return await rtFetch(
            `${this.baseUrl}/user/group/${groupId}/permission`,
            {
                method: "POST",
                body: JSON.stringify({
                    resource: resourceAccess.resource,
                    accessLevel: resourceAccess.accessLevel,
                }),
                headers: this.getHeaders(),
            }
        )
            .then(this.handleResponse)
            .catch((e) => ({ error: e }));
    }
    /**
     * Removes a permission from a group
     * @returns {Promise<Response|*|{error: *}>} the removed permission or an error
     */
    async removePermissionFromGroup(groupId, permissionId) {
        this.removeFromCache(this.groupCache, groupId);
        return await rtFetch(
            `${this.baseUrl}/user/group/${groupId}/permission/${permissionId}`,
            {
                method: "DELETE",
                headers: this.getHeaders(),
            }
        )
            .then(this.handleResponse)
            .catch((e) => ({ error: e }));
    }

    /**
     * Gets a single group
     * @param groupId - Id of group to get
     * @returns {Promise<Response|*|{error: *}>} the group or an error
     */
    async getGroup(groupId) {
        const cached = this.groupCache.find((g) => g.id === groupId);
        if (cached) return cached;
        const response = await rtFetch(
            `${this.baseUrl}/user/group/${groupId}`,
            {
                method: "GET",
                headers: this.getHeaders(),
            }
        )
            .then(this.handleResponse)
            .catch((e) => ({ error: e }));
        if (!response.error) this.groupCache.push(response);
        return response;
    }

    /**
     * Gets user from specified id
     * @param userId id of user to get
     * @returns {Promise<Response|*|{error: *}>} a user or an error
     */
    async getUser(userId) {
        const cached = this.userCache.find((u) => u.id === userId);
        if (cached) return cached;
        const response = await rtFetch(`${this.baseUrl}/user/${userId}`, {
            method: "GET",
            headers: this.getHeaders(),
        })
            .then(this.handleResponse)
            .catch((e) => ({ error: e }));
        if (!response.error) this.userCache.push(response);
        return response;
    }
    /**
     *
     * @param pageNumber the page number to fetch users for
     * @param pageSize the page size
     * @param orderBy the field to order by
     * @param orderByDirection the direction to order by, asc | desc
     * @param first_name a partial first name to search by
     * @param last_name a partial last name to search by
     * @param email a partial email to search by
     * @returns {Promise<Response>} a paged response of users or an error
     */
    getUsers(
        pageNumber = 1,
        pageSize = 20,
        orderBy = "date_created",
        orderByDirection = "desc",
        first_name = null,
        last_name = null,
        email = null
    ) {
        let queryString =
            this.baseUrl +
            `/user?pageNumber=${pageNumber}&` +
            `pageSize=${pageSize}&` +
            `orderBy=${orderBy}&` +
            `orderDirection=${orderByDirection}`;
        if (first_name) queryString += `&first_name=${first_name}`;
        if (last_name) queryString += `&last_name=${last_name}`;
        if (email) queryString += `&email=${email}`;
        return rtFetch(queryString, {
            method: "GET",
            headers: this.getHeaders(),
        }).then(this.handleResponse);
    }

    updateGroup(groupId, updateGroup) {
        this.removeFromCache(this.groupCache, groupId);
        return rtFetch(this.baseUrl + `/user/group/${groupId}`, {
            method: "POST",
            body: JSON.stringify(updateGroup),
            headers: this.getHeaders(),
        }).then(this.handleResponse);
    }
}
