import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import { Box, Typography, Button } from "@mui/material";
import HelperExternalContainerAndBinSelectionForm from "./HelperExternalContainerAndBinSelectionForm";
import { connect } from "react-redux";
import { v1 as uuidv1 } from "uuid";
import ShellfishInventoryAPIClient from "../../models/ShellfishInventoryAPIClient";

const styles = (theme) => ({
    section: {
        marginTop: theme.spacing(7),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    containerProperty: {
        fontWeight: "bold",
        textTransform: "capitalize",
    },
});

let NGVTOLGV = WithGoogleAuth(
    class NgvToLgvReallocForm extends Component {
        constructor(props) {
            super(props);
            this.key = uuidv1();

            let binSelectErrors = {
                externalContainers: false,
                reef: false,
                bin: false,
                farm: false,
            };
            let errors = { binSelection: binSelectErrors };

            this.initialState = {
                farm: "4cabc6a0-ca25-11eb-b8bc-0242ac130003", //default wilson cove id
                reef: "",
                bins: "",
                errors: errors,
            };
            this.state = this.initialState;
            this.handleReefSelected = this.handleReefSelected.bind(this);
            this.handleFarmSelected = this.handleFarmSelected.bind(this);
            this.handleBinSelected = this.handleBinSelected.bind(this);
            this.handleShellfishHeldContainersSelected =
                this.handleShellfishHeldContainersSelected.bind(this);
        }

        componentDidMount() {}

        handleFarmSelected = (value) => {
            this.setState({ farm: value });
        };

        handleReefSelected = (value) => {
            this.setState({ reef: value });
        };

        handleBinSelected = (value) => {
            this.setState({ bins: value });
        };

        handleShellfishHeldContainersSelected = (value) => {
            this.setState({ externalShellfishHeldContainers: value });
        };

        submitForm(event) {
            event.preventDefault();

            if (!this.validate()) {
                console.log("cant validate");
                return;
            }

            let apiClient = new ShellfishInventoryAPIClient(
                this.props.authState
            );
            apiClient
                .createOysterCountsWithExternalShellfishContainerHeldData(
                    this.state.externalShellfishHeldContainers,
                    this.state.bins,
                    this.props.loggedInUser.id
                )
                .then(() => {
                    this.key = uuidv1();
                    this.setState(this.initialState);
                    this.props.showSubmittedMessage();
                })
                .catch((e) => {
                    console.log(e);
                    try {
                        e.json().then((body) => {
                            console.log("No body");
                            this.props.showErrorMessage(body.error);
                        });
                    } catch (e) {
                        console.log("In catch");
                        this.props.showErrorMessage(
                            "An unknown error occurred"
                        );
                    }
                });
        }

        validate() {
            let errors = this.state.errors;
            errors.binSelection.farm =
                this.state.farm == null || this.state.farm === ""
                    ? true
                    : false;
            errors.binSelection.externalContainers =
                this.state.externalShellfishHeldContainers == null ||
                this.state.externalShellfishHeldContainers.length === 0
                    ? true
                    : false;
            errors.binSelection.reef =
                this.state.reef == null || this.state.reef === ""
                    ? true
                    : false;
            errors.binSelection.bin =
                this.state.bins == null || this.state.bins === ""
                    ? true
                    : false;

            this.setState({ errors: errors });
            return (
                Object.values(errors).filter((val) => val === true).length ===
                    0 &&
                Object.values(errors.binSelection).filter((val) => val === true)
                    .length === 0
            );
        }

        render() {
            const { classes } = this.props;
            return (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" color="textPrimary">
                        This form is used to reallocate shellfish from the NGV
                        to the LGV
                    </Typography>
                    <form
                        className={classes.container}
                        noValidate
                        onSubmit={(e) => {
                            this.submitForm(e);
                        }}
                    >
                        <HelperExternalContainerAndBinSelectionForm
                            key={this.key + "bsf"}
                            onBinSelected={this.handleBinSelected}
                            onReefSelected={this.handleReefSelected}
                            onFarmSelected={this.handleFarmSelected}
                            updateShellfishHeldExternalContainers={
                                this.handleShellfishHeldContainersSelected
                            }
                            multiple={true}
                            errors={this.state.errors.binSelection}
                        ></HelperExternalContainerAndBinSelectionForm>
                        <Box className={classes.section} />
                        <Button variant="secondary" type="submit">
                            Submit
                        </Button>
                    </form>
                </Box>
            );
        }
    }
);

const mapStateToProps = (state) => ({
    loggedInUser: state.data.loggedInUser,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(NGVTOLGV));
