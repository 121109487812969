import React, { Component } from "react";
import {
    Autocomplete,
    IconButton,
    Button,
    TextField,
    Typography,
    Stack,
    Tooltip,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ClearIcon from "@mui/icons-material/Clear";

import { errorMessage } from "../helper/MessageMethodHelper";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import MessageHelper from "../helper/MessageHelper";

const styles = (theme) => ({
    propertiesFields: {
        display: "flex",
        flexDirection: "row",
    },
    propertyField: {
        minWidth: 170,
        marginRight: theme.spacing(2),
    },
    clearButton: {
        marginTop: "1.5rem",
        marginBottom: "0.5rem",
    },
    createButton: {
        marginTop: theme.spacing(1),
    },
});

class SubstratePropertyCreator extends Component {
    state = {
        propertyTypes: [],
        properties: [],
        isCreating: false,
    };

    async componentDidMount() {
        // Fetch a list of substrate properties to populate the property name
        // dropdowns.
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        const propertyTypes = await apiClient.getSubstratePropertyTypes();
        this.setState({ propertyTypes });
    }

    async newSubstrateProperties() {
        this.setState({
            isCreating: true,
            successMessage: undefined,
            successOpen: false,
            errorMessage: undefined,
            errorOpen: false,
        });
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        let newProperties = [];
        let failedProperties = [];

        await Promise.all(
            this.state.properties.map(async ({ name, type }) => {
                try {
                    const resName = await apiClient.createSubstrateProperty(
                        name,
                        type
                    );
                    newProperties.push(resName);
                } catch (error) {
                    failedProperties.push({
                        name,
                        error: errorMessage(error).errorMessage,
                    });
                }
            })
        );

        if (newProperties.length) {
            const successMessage =
                newProperties.length > 1
                    ? `Product Properties ${newProperties.join(", ")} created!`
                    : `Product Property ${newProperties[0]} created!`;
            // Clear all successfully created properties & leave the ones that error
            const trimmedProperties = this.state.properties.filter(
                (prop) => !newProperties.includes(prop.name)
            );
            this.setState({
                successMessage: successMessage,
                successOpen: true,
                properties: trimmedProperties,
                isCreating: false,
            });
        }
        if (failedProperties.length) {
            let errorMessage = "";
            failedProperties.forEach(({ name, error }) => {
                errorMessage += `Error adding ${name}: ${error}. `;
            });
            this.setState({
                errorMessage: errorMessage,
                errorOpen: true,
                isCreating: false,
            });
        }
    }

    onAddProperty = () => {
        this.setState({
            properties: [...this.state.properties, { name: "", type: null }],
        });
    };

    onRemoveProperty = (index) => {
        const properties = this.state.properties.filter((_, i) => i !== index);
        this.setState({
            properties,
        });
    };

    onChangeProperty = (name, type, index) => {
        const properties = this.state.properties;
        properties[index] = { name, type };
        this.setState({ properties });
    };

    render() {
        return (
            <Stack
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={this.props.style}
            >
                <Stack direction="row" alignItems="center">
                    <Typography variant="h3">Product Properties</Typography>
                    {!this.state.properties.length && (
                        <Tooltip title="Add Property" placement="right">
                            <IconButton
                                color="primary"
                                onClick={() => this.onAddProperty()}
                            >
                                <AddBoxIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
                {/* Display a dropdown and text field for each property (for 
                    the name and type, respectively). */}
                {this.state.properties.map(({ name, type }, i) => (
                    <Stack key={i} direction="row" alignItems="center">
                        <TextField
                            label="Name"
                            value={name}
                            onChange={({ target }) =>
                                this.onChangeProperty(target.value, type, i)
                            }
                            className={this.props.classes.propertyField}
                        />
                        <Autocomplete
                            value={type}
                            onChange={(_, newType) => {
                                this.onChangeProperty(name, newType, i);
                            }}
                            options={this.state.propertyTypes}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Type"
                                    className={this.props.classes.propertyField}
                                />
                            )}
                        />
                        <Tooltip title="Delete Property" placement="right">
                            <IconButton
                                color="primary"
                                onClick={() => this.onRemoveProperty(i)}
                                className={this.props.classes.clearButton}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                ))}
                {this.state.properties.length > 0 && (
                    <Tooltip title="Add Property" placement="right">
                        <IconButton
                            color="primary"
                            onClick={() => this.onAddProperty()}
                        >
                            <AddBoxIcon />
                        </IconButton>
                    </Tooltip>
                )}

                <Button
                    variant="secondary"
                    onClick={() => this.newSubstrateProperties()}
                    disabled={
                        this.state.isCreating || !this.state.properties.length
                    }
                    className={this.props.classes.createButton}
                >
                    Create
                </Button>

                {/** TODO(hannah): Fix styling. */}
                <MessageHelper // Success
                    message={this.state.successMessage}
                    open={this.state.successOpen}
                    setState={(a) =>
                        this.setState({ successOpen: a.openMessageOpen })
                    }
                />
                <MessageHelper // Fails
                    errorMessage={this.state.errorMessage}
                    open={this.state.errorOpen}
                    setState={(a) =>
                        this.setState({ errorOpen: a.openMessageOpen })
                    }
                    raiseSnackbar={{ transform: "translateY(-4rem)" }}
                />
            </Stack>
        );
    }
}

export default withStyles(styles)(WithGoogleAuth(SubstratePropertyCreator));
