import React, { Component } from "react";
import { WithGoogleAuth } from "../../../config/WithGoogleAuth";
import AdministrationAPIClient from "../../../models/AdministrationAPIClient";
import { Grid, Stack } from "@mui/material";
import RTable from "../../components/RTable";
import Typography from "@mui/material/Typography";
import RTDisplayUtils from "@running-tide/rt-frontend-helpers/helpers";
import {
    Action,
    AUTH_ADMINISTRATION,
} from "@running-tide/rt-api-access-control/build/PermissionValidator/constants";
import { EditGroups } from "./EditGroups";
import { GroupPill } from "./GroupPill";

class Users extends Component {
    state = { pagedUsers: [], pageNumber: 1 };
    constructor(props) {
        super(props);
        this.client = new AdministrationAPIClient(props.authState);
    }

    onRowUpdate = (newData, oldData) => {
        const addedGroups = newData.groups.filter(
            (newGroup) =>
                !oldData.groups.some((oldGroup) => oldGroup === newGroup)
        );
        const removedGroups = oldData.groups.filter(
            (oldGroup) =>
                !newData.groups.some((newGroup) => oldGroup === newGroup)
        );
        const addingPromises = addedGroups.map((g) =>
            this.client.addUserToGroup(newData.id, g)
        );
        const removePromises = removedGroups.map((g) =>
            this.client.removeUserFromGroup(newData.id, g)
        );
        return Promise.all(addingPromises.concat(removePromises));
    };
    getUserTableData(q) {
        const orderBy =
            q.orderBy != undefined ? q.orderBy.field : "date_created";

        const firstNameFilter = q.filters.find(
            (f) => f.column.field === "first_name"
        );
        const lastNameFilter = q.filters.find(
            (f) => f.column.field === "last_name"
        );
        const emailFilter = q.filters.find((f) => f.column.field === "email");
        return new Promise((resolve) => {
            this.client
                .getUsers(
                    parseInt(q.page) + 1,
                    q.pageSize,
                    orderBy,
                    q.orderDirection,
                    firstNameFilter?.value,
                    lastNameFilter?.value,
                    emailFilter?.value
                )
                .then((r) =>
                    resolve({
                        data: r.data,
                        page: r.pageNumber - 1,
                        totalCount: r.totalCount,
                    })
                );
        });
    }
    render() {
        return (
            <Stack>
                <RTable
                    columns={[
                        {
                            title: "First name",
                            field: "first_name",
                            editable: "never",
                        },
                        {
                            title: "Last name",
                            field: "last_name",
                            editable: "never",
                        },
                        {
                            title: "Email",
                            field: "email",
                            editable: "never",
                        },
                        {
                            title: "Groups",
                            field: "groups",
                            editable: "always",
                            filtering: false,
                            sorting: false,
                            render: (rowData) => (
                                <Grid container spacing={1}>
                                    {rowData.groups.map((groupId) => (
                                        <GroupPill
                                            key={groupId}
                                            groupId={groupId}
                                            client={this.client}
                                        />
                                    ))}
                                </Grid>
                            ),
                            editComponent: (props) => {
                                return (
                                    <EditGroups
                                        client={this.client}
                                        groups={props.value}
                                        onChange={props.onChange}
                                    ></EditGroups>
                                );
                            },
                        },
                        {
                            title: "Date created",
                            field: "date_created",
                            filtering: false,
                            editable: "never",
                            render: (rowData) => (
                                <Typography>
                                    {RTDisplayUtils.printDate(
                                        rowData.date_created
                                    )}
                                </Typography>
                            ),
                        },
                    ]}
                    data={(q) => this.getUserTableData(q)}
                    options={{
                        search: false,
                        filtering: true,
                        pageSize: 20,
                        pageSizeOptions: [],
                    }}
                    title="Users"
                    editable={{
                        isEditable: () => true,
                        onRowUpdate: (newData, oldData) =>
                            this.onRowUpdate(newData, oldData),
                    }}
                ></RTable>
            </Stack>
        );
    }
}

export default WithGoogleAuth(Users, [
    `${AUTH_ADMINISTRATION}:${Action.CREATE}`,
    `${AUTH_ADMINISTRATION}:${Action.READ}`,
    `${AUTH_ADMINISTRATION}:${Action.DELETE}`,
    `${AUTH_ADMINISTRATION}:${Action.UPDATE}`,
]);
