import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import React, { Component } from "react";
import { Button } from "@mui/material";
import {
    Action,
    AUTH_ADMINISTRATION,
} from "@running-tide/rt-api-access-control/build/PermissionValidator/constants";

class Administration extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div>
                <Button
                    variant="secondary"
                    type="submit"
                    href="/administration/users"
                >
                    Users
                </Button>
                <Button
                    variant="secondary"
                    type="submit"
                    href="/administration/groups"
                >
                    Groups
                </Button>
            </div>
        );
    }
}

export default WithGoogleAuth(Administration, [
    `${AUTH_ADMINISTRATION}:${Action.CREATE}`,
    `${AUTH_ADMINISTRATION}:${Action.READ}`,
    `${AUTH_ADMINISTRATION}:${Action.DELETE}`,
    `${AUTH_ADMINISTRATION}:${Action.UPDATE}`,
]);
