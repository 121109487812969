/**
 * @typedef User
 * @property {String} id.required - uuid, RunningTide ID. Could only be null if the user hasn't been created yet - eg:ece040a1-b30b-442a-9398-7515713f1fea
 * @property {String} googleId.required - google identifier - eg:00uid4BxXw6I6TV4m0g3
 * @property {String} firstName.required - first name of user - eg:John
 * @property {String} lastName.required - User Last name - eg:Doe
 * @property {String} email.required - The user email address - eg: phil@runningtide.com
 * @property {String} timezone.required - String of the timezone they are in - eg: "America/Los_Angeles"
 * @class User
 */
export default class User {
    /**
     *Creates an instance of User.
     * @param {String} id
     * @param {String} googleId
     * @param {String} firstName
     * @param {String} lastName
     * @param {String} email
     * @param {String} timezone
     * @memberof User
     */
    constructor(id, googleId, firstName, lastName, email, timezone) {
        this.id = id;
        this.googleId = googleId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.timezone = timezone;
    }
}
