/**
 * A component that allows the user to copy datastreams from one thing to others.
 */

import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import AutocompleteEditComponent from "../sections/AutocompleteEditComponent";

const styles = (theme) => ({
    container: {
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    select: {
        marginBottom: theme.spacing(2),
        width: "100%",
    },
});

class DatastreamCopier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thingToCopyFrom: null,
            thingsToCopyTo: [],
            isCreating: false,
        };
    }

    onCopy = async () => {
        this.setState({ isCreating: true });

        // Get the datastreams from the thing we're copying from.
        let thing;
        try {
            thing = (
                await this.props.apiClient.getThingAll(
                    this.state.thingToCopyFrom,
                    1
                )
            )[0];
        } catch (error) {
            console.error(error);
            this.props.onCopyComplete([], `An unexpected error occurred`);
            this.setState({ isCreating: false });
            return;
        }

        // Create the new datastreams for each thing we're copying to.
        const newDatastreams = await Promise.all(
            this.state.thingsToCopyTo
                .map((thingId) =>
                    thing.datastreams.map(async (datastream) => {
                        const newDatastream = {
                            ...datastream,
                            thingId: thingId,
                        };
                        try {
                            const result =
                                await this.props.apiClient.createDatastream(
                                    newDatastream
                                );
                            if (result.status !== 200) {
                                return null;
                            }
                            const json = await result.json();
                            return { ...newDatastream, id: json.id };
                        } catch (error) {
                            console.error(error);
                            return null;
                        }
                    })
                )
                .flat()
        );

        const successfullyAdded = newDatastreams.filter(
            (datastream) => datastream != null
        );
        if (successfullyAdded.length === newDatastreams.length) {
            this.props.onCopyComplete(successfullyAdded);
            // If all of the datastreams were successfully created, clear the
            // state.
            this.setState({
                thingToCopyFrom: null,
                thingsToCopyTo: [],
                isCreating: false,
            });
        } else {
            const failureCount =
                newDatastreams.length - successfullyAdded.length;
            this.props.onCopyComplete(
                successfullyAdded,
                `Failed to add ${failureCount} of ${newDatastreams.length} datastreams`
            );
            this.setState({ isCreating: false });
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <Paper className={classes.container}>
                <Typography className={classes.title} variant="h6">
                    Datastream Copier
                </Typography>

                <div className={classes.select}>
                    <Typography className={classes.title}>
                        Thing to copy datastreams from:
                    </Typography>
                    <AutocompleteEditComponent
                        value={this.state.thingToCopyFrom}
                        onChange={(value) => {
                            this.setState({ thingToCopyFrom: value });
                        }}
                        idsToLabels={this.props.things}
                    />

                    <Typography className={classes.title}>
                        Thing(s) to create datastreams for:
                    </Typography>
                    <AutocompleteEditComponent
                        value={this.state.thingsToCopyTo}
                        onChange={(values) => {
                            this.setState({ thingsToCopyTo: values });
                        }}
                        idsToLabels={this.props.things}
                        multiple={true}
                    />
                </div>

                <Button
                    variant="secondary"
                    disabled={
                        this.state.thingToCopyFrom == null ||
                        this.state.thingsToCopyTo.length === 0 ||
                        this.state.isCreating
                    }
                    onClick={() => this.onCopy()}
                >
                    Copy
                </Button>
            </Paper>
        );
    }
}

DatastreamCopier.propTypes = {
    apiClient: PropTypes.any.isRequired,
    // things: { [string]: string }
    things: PropTypes.object.isRequired,
    // onCopyComplete: (successfullyAdded: Object[], errorMessage: ?string)
    onCopyComplete: PropTypes.func.isRequired,
};

export default withStyles(styles)(DatastreamCopier);
