import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { Grid, Box, Typography } from "@mui/material";
import RTable from "../components/RTable";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import MessageHelper from "../helper/MessageHelper";
import APIClient from "../../models/APIClient";

const styles = (theme) => ({
    table: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
});

const DropDown = ({ value, onChange }) => (
    <select onChange={onChange} value={value}>
        <option value="">Choose One</option>
        <option value="runningtide">runningtide</option>
        <option value="geoforce">geoforce</option>
        <option value="maker">maker</option>
    </select>
);

const SelectorIdAllowlistManager = WithGoogleAuth(
    class SelectorIdAllowlistManager extends Component {
        constructor(props) {
            super(props);
            this.state = {
                allowlist: [],
                isLoading: true,
            };
        }

        async componentDidMount() {
            this.getAllowlist();
        }

        async getAllowlist() {
            const apiClient = new APIClient(this.props.authState);
            try {
                const result = await apiClient.fetchSelectorIdAllowlist();
                const selectorIdAllowlist = await result.json();
                this.setState({
                    allowlist: selectorIdAllowlist,
                    isLoading: false,
                });
            } catch (e) {
                console.error(e);
            }
        }

        onRowUpdate = (newData) =>
            new Promise((resolve, reject) => {
                const newDataUpdate = { ...newData };

                function isSelectorId(selectorId) {
                    return selectorId.id === newData.id;
                }
                new APIClient(this.props.authState)
                    .updateSelectorIdInAllowlist({
                        id: newDataUpdate.id,
                        selectorId: newDataUpdate.selector_id,
                        selectorIdType: newDataUpdate.selector_id_type,
                    })
                    .then(async (res) => {
                        if (res.status !== 200) {
                            res = await res.json();
                            this.setState(errorMessage(res));
                            reject();
                            return;
                        }
                        //Update State
                        const allowlist = [...this.state.allowlist],
                            i = allowlist.findIndex(isSelectorId);
                        allowlist[i] = newData;
                        this.setState({ allowlist });
                        this.setState(successMessage("Successfully updated"));
                        resolve();
                    })
                    .catch((e) => {
                        this.setState(errorMessage(e));
                        reject();
                    });
            });

        onRowAdd = (newData) =>
            new Promise((resolve, reject) => {
                const newDataUpdate = { ...newData };

                new APIClient(this.props.authState)
                    .createSelectorIdInAllowlistTable({
                        selectorId: newDataUpdate.selector_id,
                        selectorIdType: newDataUpdate.selector_id_type,
                    })
                    .then(async (res) => {
                        if (res.status !== 200) {
                            res = await res.json();
                            this.setState(errorMessage(res));
                            reject();
                            return;
                        }
                        res = await res.json();
                        newData.id = res.id;
                        const allowlist = [...this.state.allowlist];
                        allowlist.unshift(newData);
                        this.setState({ allowlist });
                        this.setState(successMessage("Successfully added"));
                        resolve();
                    })
                    .catch((e) => {
                        this.setState(errorMessage(e));
                        reject();
                    });
            });

        onRowDelete = (oldData) =>
            new Promise((resolve) => {
                new APIClient(this.props.authState)
                    .deleteSelectorIdInAllowlist(oldData)
                    .then(async (res) => {
                        if (res.status !== 200) {
                            res = await res.json();
                            this.setState(errorMessage(res));
                            resolve();
                            return;
                        }
                        //Update State
                        res = await res.json();
                        let allowlist = [...this.state.allowlist];
                        allowlist = allowlist.filter(
                            (selectorId) => selectorId.id !== oldData.id
                        );
                        this.setState({ allowlist });
                        this.setState(successMessage("Successfully deleted"));
                        resolve();
                    })
                    .catch((e) => {
                        this.setState(errorMessage(e));
                        resolve();
                    });
            });

        render() {
            const { classes } = this.props;

            return (
                <Grid>
                    <Grid item lg={1} xs={12}>
                        <MessageHelper
                            message={this.state.message}
                            errorMessage={this.state.errorMessage}
                            open={this.state.messageOpen}
                            setState={(a) => this.setState(a)}
                        />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Box>
                            <RTable
                                className={classes.table}
                                title={
                                    <Typography variant="h3">
                                        Allowlist Manager
                                    </Typography>
                                }
                                columns={[
                                    {
                                        title: "Selector ID (Unique)",
                                        field: "selector_id",
                                    },
                                    {
                                        title: "Selector ID Type",
                                        field: "selector_id_type",
                                        editComponent: (props) => (
                                            <DropDown
                                                value={props.value}
                                                onChange={(e) => {
                                                    props.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        ),
                                    },
                                ]}
                                data={this.state.allowlist}
                                editable={{
                                    isEditable: () => true,
                                    onRowAddCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowUpdateCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowAdd: (newData) =>
                                        this.onRowAdd(newData),
                                    onRowUpdate: (newData, oldData) =>
                                        this.onRowUpdate(newData, oldData),
                                    // edit all rows at once
                                    onBulkUpdate: (changes) =>
                                        Promise.all(
                                            Object.values(changes).map(
                                                ({ newData, oldData }) =>
                                                    this.onRowUpdate(
                                                        newData,
                                                        oldData
                                                    )
                                            )
                                        ),
                                    onRowDelete: (oldData) =>
                                        this.onRowDelete(oldData),
                                }}
                                isLoading={this.state.isLoading}
                                options={{
                                    search: true,
                                    maxColumnSort: 1,
                                    filtering: true,
                                    paging: true,
                                    // always display the newly-added row at the beginning of the table
                                    addRowPosition: "first",
                                }}
                            ></RTable>
                        </Box>
                    </Grid>
                </Grid>
            );
        }
    }
);

export default withStyles(styles)(SelectorIdAllowlistManager);
