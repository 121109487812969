function hasValidPermissions(user, requiredPermissions) {
    if (!requiredPermissions) return false;
    if (user === null || user === undefined || user.groups === undefined)
        return false;

    const permissions = user.groups
        .map((g) => g.permissions.map((p) => `${p.resource}:${p.access_level}`))
        .flat();
    for (const rp in requiredPermissions) {
        if (!permissions.includes(requiredPermissions[rp])) {
            return false;
        }
    }
    return true;
}
/* eslint-disable */
exports.hasValidPermissions = hasValidPermissions;
