import React, { Component } from "react";
import {
    Chip,
    Button,
    Paper,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    FormControl,
} from "@mui/material";
import {
    ExpandMore,
    Start,
    Check,
    Close,
    HourglassTop,
    Sync,
    Quiz,
    Bedtime,
    QuestionMark,
    PowerSettingsNew,
    Send,
    UploadFile,
} from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";
import {
    MCUManager,
    MGMT_GROUP_ID_IMAGE,
    IMG_MGMT_ID_STATE,
} from "../helper/mcumgr";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import APIClient from "../../models/APIClient";
import AutocompleteEditComponent from "../sections/AutocompleteEditComponent";
import { Parser } from "binary-parser";

const styles = () => ({
    accordion_container: {
        width: "100%",
        padding: "2px 0",
    },
    accordion: {
        width: "100%",
        background: "#4f4f4f",
    },
    space_between: {
        justifyContent: "space-between",
    },
    table_row: {
        borderBottom: "1px dotted white",
    },
    flex_div100: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    flex_div: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "5px",
    },
    file_upload_div: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "5px",
        background: "#777777",
        padding: "10px",
        borderRadius: "5px",
        margin: "10px 0",
    },
    characteristic_subheader: {
        margin: "25px 0px 10px 0px",
    },
    fill_table: {
        width: "100%",
    },
});

class BluetoothFirmwareImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            version: props.version,
            bootable: props.bootable,
            confirmed: props.confirmed,
            pending: props.pending,
            hash: props.hash,
            active: props.active,
            slot: props.slot,
            status: props.pending
                ? "pending"
                : props.confirmed
                ? "confirmed"
                : props.active
                ? "unconfirmed"
                : "inactive",
        };
    }

    componentDidUpdate() {}

    render() {
        return (
            <TableRow
                key={this.state.slot}
                style={{
                    border: this.state.active ? "2px solid #BBFF00" : "none",
                }}
            >
                <TableCell align="left">{this.state.slot}</TableCell>
                <TableCell align="left">{this.state.version}</TableCell>
                <TableCell align="center">
                    <Chip
                        icon={this.state.bootable ? <Start /> : <Close />}
                        color={this.state.bootable ? "success" : "error"}
                    />
                </TableCell>
                <TableCell align="center">
                    <Chip
                        icon={
                            this.state.status === "pending" ? (
                                <HourglassTop />
                            ) : this.state.status === "confirmed" ? (
                                <Check />
                            ) : this.state.status === "unconfirmed" ? (
                                <QuestionMark />
                            ) : (
                                <Bedtime />
                            )
                        }
                        color={
                            this.state.status === "pending"
                                ? "warning"
                                : this.state.status === "confirmed"
                                ? "success"
                                : this.state.status === "unconfirmed"
                                ? "error"
                                : "warning"
                        }
                        label={this.state.status}
                    />
                </TableCell>
                <TableCell align="left" style={{ overflowWrap: "break-word" }}>
                    {this.state.hash}
                </TableCell>
            </TableRow>
        );
    }
}

// Slot 0 is the active/running firmware image
const IMAGE_SLOT_0 = 0;

// Slot 1 is the inactive/loaded firmware image
const IMAGE_SLOT_1 = 1;

const BluetoothMCUmgrCharacteristic = WithGoogleAuth(
    class BluetoothMCUmgrCharacteristic extends Component {
        constructor(props) {
            super(props);
            this.state = {
                expanded: false,
                name: props.name,
                connected: false,
                connection_failed: false,
                upload_image_disabled: false,
                uploaded_image_file: undefined,
                uploaded_image_file_data: undefined,
                uploaded_image_file_size: 0,
                uploaded_image_file_hash: "",
                uploaded_image_listing: null,
                uploaded_image_status_percent: 0,
                uploaded_image_button_disabled: false,
                image_file_reader: new FileReader(),
                mcumgr: null,
                firmware_image_components: [],
                firmware_images: [],
                available_versions: [],
                selected_firmware_binary_filename: null,
                buoy_selector_labels: [],
            };

            this.apiClient = new APIClient(this.props.authState);
            this.accordionToggle = this.accordionToggle.bind(this);
            this.connectMCUmgr = this.connectMCUmgr.bind(this);
            this.onFileChange = this.onFileChange.bind(this);
            this.flashOTA = this.flashOTA.bind(this);
            this.resetMCU = this.resetMCU.bind(this);
            this.refreshImageList = this.refreshImageList.bind(this);
            this.testInactiveSlot = this.testInactiveSlot.bind(this);
            this.confirmActiveSlot = this.confirmActiveSlot.bind(this);
            this.getKey = this.getKey.bind(this);

            this.onFileLoad = this.onFileLoad.bind(this);
            this.state.image_file_reader.onload = this.onFileLoad;
            this.selectFirmwareHandler = this.selectFirmwareHandler.bind(this);
            this.downloadFirmware = this.downloadFirmware.bind(this);

            this.onLoad();
        }

        componentDidUpdate() {
            //Autoconnect to MCUmrg
            if (
                this.props.device &&
                !this.state.mcumgr &&
                !this.state.connected &&
                !this.state.connection_failed
            ) {
                this.connectMCUmgr();
            }
            //Update selectable firmware based on buoy type
            if (
                this.props.buoy_data &&
                this.props.buoy_data.selectorLabels &&
                this.props.buoy_data.selectorLabels.length &&
                !this.state.buoy_selector_labels.length
            ) {
                this.setState({
                    buoy_selector_labels: this.props.buoy_data.selectorLabels,
                });
            }
        }

        async onLoad() {
            const firmwareVersions =
                (await this.apiClient.getFirmwareVersions()) || [];
            const available_versions = firmwareVersions.filter(
                (version) => version.binary_path && version.binary_path.length
            );
            this.props.setAvailableFirmwareVersions(available_versions);
            this.setState({ available_versions });
        }

        async selectFirmwareHandler(binary_filename) {
            this.setState({
                selected_firmware_binary_filename: binary_filename,
            });
        }

        async connectMCUmgr() {
            try {
                await this.setState({
                    mcumgr: new MCUManager(this.props.device),
                });

                if (!this.state.mcumgr) {
                    this.setState({
                        connection_failed: true,
                    });
                    return;
                }

                this.state.mcumgr.onConnect(() => {
                    this.setState({
                        connected: true,
                        firmware_image_components: [],
                    });

                    this.state.mcumgr.cmdImageState();
                });

                this.state.mcumgr.onDisconnect(() => {
                    this.setState({
                        connected: false,
                        mcumgr: null,
                        connection_failed: false,
                        buoy_selector_labels: [],
                    });
                });

                this.setState({
                    mcumgr: this.state.mcumgr.onMessage(
                        ({ group, id, data }) => {
                            switch (group) {
                                case MGMT_GROUP_ID_IMAGE:
                                    switch (id) {
                                        case IMG_MGMT_ID_STATE: {
                                            let firmware_image_components = [];
                                            let firmware_images = [];
                                            if (data.images === undefined) {
                                                break;
                                            }
                                            data.images.forEach((mcu_image) => {
                                                let hash_string = Array.from(
                                                    mcu_image.hash
                                                )
                                                    .map((byte) =>
                                                        byte
                                                            .toString(16)
                                                            .padStart(2, "0")
                                                    )
                                                    .join("");
                                                //notify parent component of active version
                                                if (mcu_image.active) {
                                                    this.props.setActiveFirmwareHash(
                                                        hash_string
                                                    );
                                                }
                                                firmware_image_components.push(
                                                    <BluetoothFirmwareImage
                                                        version={
                                                            mcu_image.version
                                                        }
                                                        bootable={
                                                            mcu_image.bootable
                                                        }
                                                        pending={
                                                            mcu_image.pending
                                                        }
                                                        confirmed={
                                                            mcu_image.confirmed
                                                        }
                                                        hash={hash_string}
                                                        active={
                                                            mcu_image.active
                                                        }
                                                        slot={
                                                            firmware_images.length
                                                        }
                                                        key={
                                                            "slot_" +
                                                            firmware_images.length.toString()
                                                        }
                                                    />
                                                );
                                                firmware_images.push(mcu_image);
                                            });
                                            this.setState({
                                                firmware_image_components:
                                                    firmware_image_components,
                                                firmware_images:
                                                    firmware_images,
                                            });
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }
                    ),
                });

                await this.state.mcumgr.connect([
                    { name: "Accelerometer buoy" },
                ]);
            } catch (error) {
                this.setState({
                    mcumgr: null,
                    connection_failed: true,
                });
                window.alert("MCUmgr failed to connect! Error: " + error);
            }
        }

        /**
         * Callback when image file upload field changes
         * @param {*} event
         */
        async onFileChange(event) {
            let image_file = event.target.files[0];

            if (image_file !== undefined) {
                this.state.image_file_reader.readAsArrayBuffer(image_file);
                this.setState({
                    uploaded_image_file: image_file,
                });
            } else {
                this.setState({
                    uploaded_image_file: undefined,
                    uploaded_image_file_data: undefined,
                    uploaded_image_file_size: 0,
                    uploaded_image_file_hash: "",
                    uploaded_image_listing: null,
                });
            }
        }

        computeHash(contents) {
            const TLV_INFO_SIZE = 4;
            const TLV_HDR_SIZE = 4;
            class ImageHeader {
                constructor(
                    magic,
                    load_addr,
                    hdr_size,
                    img_size,
                    flags,
                    ver_major,
                    ver_minor,
                    ver_revision,
                    ver_build_num
                ) {
                    this.magic = magic;
                    this.load_addr = load_addr;
                    this.hdr_size = hdr_size;
                    this.img_size = img_size;
                    this.flags = flags;
                    this.ver_major = ver_major;
                    this.ver_minor = ver_minor;
                    this.ver_revision = ver_revision;
                    this.ver_build_num = ver_build_num;
                }
            }
            class TLVInfo {
                constructor(magic, tlv_size) {
                    this.magic = magic;
                    this.tlv_size = tlv_size;
                }
            }
            const img_header_parser = new Parser()
                .endianess("little")
                .uint32("magic")
                .uint32("load_addr")
                .uint16("hdr_size")
                .skip(2)
                .uint32("img_size")
                .uint32("flags")
                .int8("ver_major")
                .int8("ver_minor")
                .int16("ver_revision")
                .uint32("ver_build_num");
            const img_header_data = img_header_parser.parse(contents);
            const img_header = new ImageHeader(
                ...Object.values(img_header_data)
            );

            const tlv_info_offset = img_header.hdr_size + img_header.img_size;
            const tlv_info_parser = new Parser()
                .endianess("little")
                .uint16("magic")
                .uint16("tlv_size");
            const tlv_info_data = tlv_info_parser.parse(
                contents.slice(tlv_info_offset)
            );
            const tlv_info = new TLVInfo(...Object.values(tlv_info_data));

            const tlv_end = tlv_info_offset + tlv_info.tlv_size;
            let tlv_off = tlv_info_offset + TLV_INFO_SIZE;
            while (tlv_off < tlv_end) {
                const tlv_hdr_parser = new Parser()
                    .endianess("little")
                    .uint16("type")
                    .uint16("len");
                const tlv_hdr_data = tlv_hdr_parser.parse(
                    contents.slice(tlv_off)
                );

                if (tlv_hdr_data.type == 0x10) {
                    const start = tlv_off + TLV_HDR_SIZE;
                    const end = start + tlv_hdr_data.len;
                    return Array.from(contents.slice(start, end), (b) =>
                        b.toString(16).padStart(2, "0")
                    ).join("");
                }
                tlv_off += TLV_HDR_SIZE + tlv_hdr_data.len;
            }
        }

        async onFileLoad() {
            let image_file_data = this.state.image_file_reader.result;
            try {
                const info = await this.state.mcumgr.imageInfo(image_file_data);
                const hash = this.computeHash(new Uint8Array(image_file_data));

                this.setState({
                    uploaded_image_listing: {
                        version: info.version,
                        bootable: info.bootable,
                        hash,
                        size: image_file_data.byteLength,
                    },
                    uploaded_image_file_data: image_file_data,
                    uploaded_image_file_size: image_file_data.byteLength,
                    uploaded_image_file_hash: info.hash,
                });
            } catch (e) {
                window.alert(`Error loading file: ${e.message}`);
            }
        }

        async downloadFirmware() {
            if (!this.state.connected) return;
            const image_file = await this.apiClient.getFirmwareFile(
                this.state.selected_firmware_binary_filename
            );

            if (image_file !== undefined) {
                this.state.image_file_reader.readAsArrayBuffer(image_file);
                this.setState({
                    uploaded_image_file: image_file,
                });
            } else {
                this.setState({
                    uploaded_image_file: undefined,
                    uploaded_image_file_data: undefined,
                    uploaded_image_file_size: 0,
                    uploaded_image_file_hash: "",
                    uploaded_image_listing: null,
                });
            }
        }

        flashOTA() {
            if (this.state.connected) {
                this.setState({
                    uploaded_image_button_disabled: true,
                });

                this.state.mcumgr.onImageUploadProgress(({ percentage }) => {
                    if (percentage) {
                        this.setState({
                            uploaded_image_status_percent: percentage,
                        });
                    }
                });

                this.state.mcumgr.onImageUploadFinished(() => {
                    this.setState({
                        uploaded_image_file: undefined,
                        uploaded_image_file_data: undefined,
                        uploaded_image_file_size: 0,
                        uploaded_image_file_hash: "",
                        uploaded_image_listing: null,
                        uploaded_image_status_percent: 0,
                        uploaded_image_button_disabled: false,
                    });

                    this.state.mcumgr.cmdImageState();
                });

                this.state.mcumgr.cmdUpload(
                    this.state.uploaded_image_file_data
                );
            }
        }

        testInactiveSlot() {
            if (
                this.state.firmware_images.length > IMAGE_SLOT_1 && // Slot 1 must not be not empty
                this.state.firmware_images[IMAGE_SLOT_1].pending === false
            ) {
                this.state.mcumgr.cmdImageTest(
                    this.state.firmware_images[IMAGE_SLOT_1].hash
                );
            }
        }

        confirmActiveSlot() {
            if (
                this.state.firmware_images.length > IMAGE_SLOT_0 && // Slot 0 must not be not empty
                this.state.firmware_images[IMAGE_SLOT_0].confirmed === false
            ) {
                this.state.mcumgr.cmdImageConfirm(
                    this.state.firmware_images[IMAGE_SLOT_0].hash
                );
            }
        }

        resetMCU() {
            if (
                this.state.connected &&
                window.confirm(
                    "Resetting MCU will reboot the buoy, which may cause it to disconnect from Bluetooth. Have a magnet ready to restart the buoy's Bluetooth if this happens. Proceed?"
                )
            ) {
                this.state.mcumgr.cmdReset();
            }
        }

        refreshImageList() {
            if (this.state.connected) {
                this.state.mcumgr.cmdImageState();
            }
        }

        /**
         * returns unique key for react elements
         */
        getKey() {
            return this.state.uuid + this.keyCount++;
        }

        /**
         * @param {*} event
         * @param {bool} isExpanded state to set to
         */
        accordionToggle(event, isExpanded) {
            this.stopProp(event);
            this.setState({ expanded: isExpanded });
        }

        stopProp(event) {
            event.stopPropagation();
        }

        render() {
            const { classes } = this.props;
            let binary_options = {};
            this.state.available_versions.map((version) => {
                const targets = version.metadata?.targets;
                const targetMatch = Boolean(
                    targets?.filter((target) =>
                        this.state.buoy_selector_labels.includes(target)
                    ).length
                );
                //Adding extra condition to render it if the firmware does not have targets
                if (
                    this.state.buoy_selector_labels.length &&
                    !targetMatch &&
                    targets
                ) {
                    return;
                }
                const type = version.metadata?.firmware_type;
                const name = version.version;
                const description = version.description;
                const binary_path = version.binary_path;
                const binary_filename = binary_path.split("/").pop();
                binary_options[binary_filename] = `${type} - ${targets?.join(
                    ","
                )} - ${name} - ${description}`;
            });
            return (
                <Paper
                    key={this.state.uuid}
                    className={classes.accordion_container}
                >
                    <div className={classes.accordion_container}>
                        <Accordion
                            className={classes.accordion}
                            expanded={this.state.expanded}
                            onChange={this.accordionToggle}
                        >
                            <AccordionSummary
                                className={classes.space_between}
                                expandIcon={<ExpandMore />}
                                id={this.state.uuid}
                            >
                                <div className={classes.flex_div100}>
                                    <Typography
                                        sx={{ width: "30%", flexShrink: 0 }}
                                    >
                                        {this.state.name}
                                    </Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography
                                    className={classes.characteristic_subheader}
                                >
                                    Over the Air (OTA) Programming Instructions
                                </Typography>
                                <div className={classes.flex_div}>
                                    <ol>
                                        <li>
                                            Upload the compiled firmware binary
                                            from your local file system with the
                                            IMPORT IMAGE FILE button. Or
                                            Alternatively Select an available
                                            version from the drop down and press
                                            Download Firmware to Browser button
                                        </li>
                                        <li>
                                            Once the Dashboard indicates the
                                            uploaded file is valid, send it to
                                            the buoy with the UPLOAD IMAGE TO
                                            BUOY button. Uploading the same
                                            image twice will fail the test, so
                                            use a different Image than the one
                                            that is alreacy active.
                                        </li>
                                        <li>
                                            Once upload to buoy is complete
                                            after progress reaches 100 percent,
                                            the new firmware should appear in
                                            the Firmware Images list in Slot 1.
                                        </li>
                                        <li>
                                            The TEST button should now be
                                            enabled. Click it to test and
                                            activate the new firmware in Slot 1.
                                        </li>
                                        <li>
                                            If successful, the TEST button
                                            should now be disabled. Click the
                                            RESET button. This may disconnect
                                            the buoy from the Dashboard.
                                        </li>
                                        <li>
                                            Use the magnet to trigger test mode
                                            in the buoy. The buoy may not
                                            respond to the magnet for a few
                                            seconds while loading new firmware
                                            for the first time. Once the buoy is
                                            in test mode, reconnect MCUmgr with
                                            the Connect button.
                                        </li>
                                        <li>
                                            Slot 0 should be the only image
                                            listed, this should be the newly
                                            programmed firmware.
                                        </li>
                                        <li>
                                            The Status of Slot 0 might not be
                                            confirmed. If this is the case, the
                                            CONFIRM button will be enabled and
                                            should be clicked.
                                        </li>
                                    </ol>
                                </div>
                                {this.state.firmware_image_components.length ? (
                                    <Typography
                                        className={
                                            classes.characteristic_subheader
                                        }
                                    >
                                        Firmware Images
                                    </Typography>
                                ) : (
                                    ""
                                )}
                                {this.state.firmware_image_components.length ? (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">
                                                    Slot
                                                </TableCell>
                                                <TableCell align="left">
                                                    Version
                                                </TableCell>
                                                <TableCell align="center">
                                                    Bootable
                                                </TableCell>
                                                <TableCell align="center">
                                                    Status
                                                </TableCell>
                                                <TableCell align="left">
                                                    Hash
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state
                                                    .firmware_image_components
                                            }
                                        </TableBody>
                                    </Table>
                                ) : (
                                    ""
                                )}

                                <div
                                    className={classes.flex_div}
                                    style={{ marginTop: "10px" }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={this.testInactiveSlot}
                                        startIcon={<Quiz />}
                                        disabled={
                                            !this.state.connected ||
                                            !(
                                                this.state.firmware_images
                                                    .length > IMAGE_SLOT_1 &&
                                                this.state.firmware_images[
                                                    IMAGE_SLOT_1
                                                ].pending === false
                                            )
                                        }
                                    >
                                        Test
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={this.refreshImageList}
                                        disabled={!this.state.connected}
                                        startIcon={<Sync />}
                                    >
                                        Refresh
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={this.resetMCU}
                                        disabled={!this.state.connected}
                                        startIcon={<PowerSettingsNew />}
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={this.confirmActiveSlot}
                                        startIcon={<Check />}
                                        disabled={
                                            !this.state.connected ||
                                            !(
                                                this.state.firmware_images
                                                    .length > IMAGE_SLOT_0 &&
                                                this.state.firmware_images[
                                                    IMAGE_SLOT_0
                                                ].confirmed === false
                                            )
                                        }
                                    >
                                        Confirm
                                    </Button>
                                </div>

                                <div>
                                    <FormControl fullWidth>
                                        <AutocompleteEditComponent
                                            value={
                                                this.state
                                                    .selected_firmware_binary_filename
                                            }
                                            onChange={
                                                this.selectFirmwareHandler
                                            }
                                            multiple={false}
                                            idsToLabels={binary_options}
                                            label={"Select Firmware"}
                                        />

                                        <Button
                                            variant="contained"
                                            onClick={this.downloadFirmware}
                                            startIcon={<Check />}
                                            disabled={
                                                !this.state.connected ||
                                                !this.state
                                                    .selected_firmware_binary_filename ||
                                                this
                                                    .uploaded_image_button_disabled
                                            }
                                        >
                                            Download Firmware to Browser
                                        </Button>
                                    </FormControl>
                                </div>

                                <Typography
                                    className={classes.characteristic_subheader}
                                >
                                    Load New Firmware{" "}
                                    {this.state.uploaded_image_status_percent >
                                    0
                                        ? "(Upload Progress: " +
                                          this.state.uploaded_image_status_percent.toString() +
                                          "%)"
                                        : ""}
                                </Typography>
                                <div className={classes.file_upload_div}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        disabled={
                                            this.state.upload_image_disabled ||
                                            !this.state.connected
                                        }
                                        startIcon={<UploadFile />}
                                    >
                                        Import Image File
                                        <input
                                            hidden
                                            accept=".img,.bin"
                                            type="file"
                                            onChange={this.onFileChange}
                                        ></input>
                                    </Button>
                                    <div>
                                        {this.state.uploaded_image_file ===
                                        undefined
                                            ? "(No Firmware Image Currently Uploaded)"
                                            : this.state.uploaded_image_file
                                                  .name +
                                              " (" +
                                              this.state.uploaded_image_file.size.toString() +
                                              "b)"}{" "}
                                    </div>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        disabled={
                                            this.state
                                                .uploaded_image_file_data ===
                                                undefined ||
                                            !this.state.connected ||
                                            this.state
                                                .uploaded_image_button_disabled
                                        }
                                        onClick={this.flashOTA}
                                        startIcon={<Send />}
                                    >
                                        Upload Image to Buoy
                                    </Button>
                                </div>
                                {this.state.uploaded_image_listing != null ? (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">
                                                    Version
                                                </TableCell>
                                                <TableCell align="left">
                                                    Size (bytes)
                                                </TableCell>
                                                <TableCell align="left">
                                                    Hash
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="left">
                                                    {
                                                        this.state
                                                            .uploaded_image_listing
                                                            .version
                                                    }
                                                </TableCell>
                                                <TableCell align="left">
                                                    {
                                                        this.state
                                                            .uploaded_image_listing
                                                            .size
                                                    }
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    style={{
                                                        overflowWrap:
                                                            "break-word",
                                                    }}
                                                >
                                                    {
                                                        this.state
                                                            .uploaded_image_listing
                                                            .hash
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                ) : (
                                    ""
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Paper>
            );
        }
    }
);
export default withStyles(styles)(BluetoothMCUmgrCharacteristic);
