import React, { useState, useEffect } from "react";
import {
    Grid,
    Stack,
    Typography,
    Divider,
    Link,
    TextField,
    Select,
    MenuItem,
    Button,
    Autocomplete,
} from "@mui/material";

import DeploymentStatus from "./DeploymentStatus";
import CarbonAccountingAPIClient from "../../../models/CarbonAccountingAPIClient";
import APIClient from "../../../models/APIClient";
import { errorMessage } from "../../helper/MessageMethodHelper";
import MessageHelper from "../../helper/MessageHelper";

function DeploymentInfo(props) {
    const {
        authState,
        deploymentInfo,
        editing,
        editedDeploymentInfo,
        editDeploymentField,
        saveEdits,
        setFly,
    } = props;

    const [fois, setFois] = useState([]);
    const [things, setThings] = useState([]);
    const [deploymentPorts, setDeploymentPorts] = useState([]);
    const [deploymentVessels, setDeploymentVessels] = useState([]);
    const [message, setMessage] = useState({});

    useEffect(() => {
        const fetchDeploymentOptions = async () => {
            const apiClient = new APIClient(authState);
            const caApiClient = new CarbonAccountingAPIClient(authState);
            const [fois, deploymentPorts, deploymentVessels, things] =
                await Promise.all([
                    (await apiClient.getAllFois()).json(),
                    caApiClient.getDeploymentPorts(),
                    caApiClient.getDeploymentVessels(),
                    (await apiClient.getAllThings()).json(),
                ]);
            setFois(fois);
            setThings(things);
            setDeploymentPorts(deploymentPorts.data);
            setDeploymentVessels(deploymentVessels.data);
        };
        fetchDeploymentOptions();
    }, []); // Do not include auth state here. It's a warning but it causes this to be called twice.

    return (
        <Grid item lg={57} xs={100}>
            <Stack variant="tablelike">
                <Typography variant="body1_bold">Vessel Name</Typography>
                {editing ? (
                    <Select
                        sx={{ minWidth: 200 }}
                        value={editedDeploymentInfo.vesselId}
                        onChange={({ target }) =>
                            editDeploymentField("vesselId", target.value)
                        }
                    >
                        {deploymentVessels.map((vessel) => (
                            <MenuItem key={vessel.id} value={vessel.id}>
                                {vessel.title}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    <Typography color="secondaryUtility.main">
                        {deploymentInfo.vessel?.name}
                    </Typography>
                )}
            </Stack>
            <Divider />
            <Stack variant="tablelike">
                <Typography variant="body1_bold">Status</Typography>
                <DeploymentStatus
                    arrivalDate={deploymentInfo.arrivalDate}
                    departureDate={deploymentInfo.departureDate}
                />
            </Stack>
            <Divider />
            <Stack variant="tablelike">
                <Typography variant="body1_bold">Deployment FOI</Typography>
                {editing ? (
                    <Autocomplete
                        value={editedDeploymentInfo.foiId}
                        onChange={(_, value) => {
                            editDeploymentField("foiId", value);
                        }}
                        options={fois.map((foi) => foi.id)}
                        renderInput={(params) => (
                            <TextField
                                sx={{ minWidth: 300 }}
                                {...params}
                                margin="dense"
                            />
                        )}
                        getOptionLabel={(option) =>
                            fois.find((val) => val.id === option)?.name ?? ""
                        }
                    />
                ) : (
                    <Typography color="secondaryUtility.main">
                        {deploymentInfo.foiId}
                    </Typography>
                )}
            </Stack>
            <Divider />
            <Stack variant="tablelike">
                <Typography variant="body1_bold">
                    {editing
                        ? "Deployment GPS Thing"
                        : "Vessel Current Location"}
                </Typography>
                {editing ? (
                    <Autocomplete
                        value={editedDeploymentInfo.gpsTracker}
                        onChange={(_, value) => {
                            editDeploymentField("gpsTracker", value);
                        }}
                        options={things.map((thing) => thing.id)}
                        renderInput={(params) => (
                            <TextField
                                sx={{ minWidth: 300 }}
                                {...params}
                                margin="dense"
                            />
                        )}
                        getOptionLabel={(option) =>
                            things.find((thing) => thing.id === option)?.name ??
                            ""
                        }
                    />
                ) : deploymentInfo.locatable ? (
                    <Link
                        component="button"
                        variant="body1"
                        underline="hover"
                        onClick={setFly}
                    >
                        Locate
                    </Link>
                ) : (
                    <Typography>N/A</Typography>
                )}
            </Stack>
            <Divider />
            <Stack variant="tablelike">
                <Typography variant="body1_bold">Departure Port</Typography>
                {editing ? (
                    <Select
                        sx={{ minWidth: 200 }}
                        onChange={({ target }) =>
                            editDeploymentField("departurePortId", target.value)
                        }
                        value={editedDeploymentInfo.departurePortId}
                    >
                        {deploymentPorts.map((port) => (
                            <MenuItem key={port.id} value={port.id}>
                                {port.title}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    <Typography>
                        {deploymentInfo.departurePort?.name}
                    </Typography>
                )}
            </Stack>
            <Divider />
            {editing && (
                <Stack variant="tablelike">
                    <Typography variant="body1_bold">Arrival Port</Typography>
                    <Select
                        sx={{ minWidth: 200 }}
                        onChange={({ target }) =>
                            editDeploymentField("arrivalPortId", target.value)
                        }
                        value={editedDeploymentInfo.arrivalPortId}
                    >
                        {deploymentPorts.map((port) => (
                            <MenuItem key={port.id} value={port.id}>
                                {port.title}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>
            )}
            {editing && <Divider />}
            <Stack variant="tablelike">
                <Typography variant="body1_bold">
                    Vessel Target Departure
                </Typography>
                {editing ? (
                    <TextField
                        type="date"
                        value={editedDeploymentInfo.targetDepartureDate}
                        onChange={({ target }) =>
                            editDeploymentField(
                                "targetDepartureDate",
                                target.value
                            )
                        }
                    />
                ) : (
                    <Typography>
                        {deploymentInfo.targetDepartureDate
                            ? deploymentInfo.targetDepartureDate
                            : "Pending"}
                    </Typography>
                )}
            </Stack>
            <Divider />
            <Stack variant="tablelike">
                <Typography variant="body1_bold">
                    Vessel Actual Departure
                </Typography>
                {editing ? (
                    <TextField
                        type="date"
                        value={editedDeploymentInfo.departureDate}
                        onChange={({ target }) =>
                            editDeploymentField("departureDate", target.value)
                        }
                    />
                ) : (
                    <Typography>
                        {deploymentInfo.departureDate
                            ? deploymentInfo.departureDate
                            : "Pending"}
                    </Typography>
                )}
            </Stack>
            <Divider />
            <Stack variant="tablelike">
                <Typography variant="body1_bold">Vessel Return </Typography>
                {editing ? (
                    <TextField
                        type="date"
                        value={editedDeploymentInfo.arrivalDate}
                        onChange={({ target }) =>
                            editDeploymentField("arrivalDate", target.value)
                        }
                    />
                ) : (
                    <Typography>
                        {deploymentInfo.arrivalDate
                            ? deploymentInfo.arrivalDate
                            : "Pending"}
                    </Typography>
                )}
            </Stack>
            <Divider />
            <Stack variant="tablelike">
                <Typography variant="body1_bold">Deployment UID</Typography>
                <Typography>{deploymentInfo.id}</Typography>
            </Stack>
            <Divider />
            <Stack variant="tablelike">
                <Typography variant="body1_bold">
                    {editing
                        ? "Buoy Selector Label"
                        : "Port Weight of Carbon Buoys"}
                </Typography>
                {editing ? (
                    <TextField
                        value={editedDeploymentInfo.buoySelectorLabel}
                        onChange={({ target }) =>
                            editDeploymentField(
                                "buoySelectorLabel",
                                target.value
                            )
                        }
                    />
                ) : (
                    <Typography>
                        {deploymentInfo.weight
                            ? `${deploymentInfo.weight} t`
                            : "TBD"}
                    </Typography>
                )}
            </Stack>
            <Divider />
            {editing && (
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={3}
                    mt={2}
                >
                    <Button
                        variant="string"
                        onClick={() => {
                            setMessage({ ...message, messageOpen: false });
                            saveEdits(deploymentInfo, false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={async () => {
                            const apiClient = new CarbonAccountingAPIClient(
                                authState
                            );
                            try {
                                // Prevent empty strings (required by edit text fields)
                                // from causing type errors in backend update
                                for (const field in editedDeploymentInfo) {
                                    if (editedDeploymentInfo[field] === "") {
                                        editedDeploymentInfo[field] = undefined;
                                    }
                                }
                                const updatedDeploymentInfo =
                                    await apiClient.updateDeployment(
                                        deploymentInfo.id,
                                        editedDeploymentInfo.title,
                                        editedDeploymentInfo.departurePortId,
                                        editedDeploymentInfo.targetDepartureDate,
                                        editedDeploymentInfo.arrivalPortId,
                                        editedDeploymentInfo.vesselId,
                                        editedDeploymentInfo.arrivalDate,
                                        editedDeploymentInfo.gpsTracker,
                                        editedDeploymentInfo.foiId,
                                        editedDeploymentInfo.buoySelectorLabel,
                                        editedDeploymentInfo.departureDate
                                    );
                                setMessage({ ...message, messageOpen: false });
                                saveEdits(updatedDeploymentInfo, true);
                            } catch (error) {
                                setMessage(errorMessage(error));
                            }
                        }}
                    >
                        Save
                    </Button>
                </Stack>
            )}
            <MessageHelper
                message={message.message}
                errorMessage={message.errorMessage}
                open={message.messageOpen}
                setState={(a) => setMessage(a)}
            />
        </Grid>
    );
}

export default DeploymentInfo;
