import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import { Grid, Paper, Typography } from "@mui/material";

const styles = (theme) => ({
    root: {},
    paper: {
        padding: 50,
    },
    section: {
        marginTop: theme.spacing(5),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    paragraph: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    box: {
        padding: 10,
    },
});

let ThingManager = WithGoogleAuth(
    class ThingManagerions extends Component {
        render() {
            const { classes } = this.props;
            return (
                <Paper variant="outlined" className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item lg={7} xs={12}>
                            <Typography className={classes.title} variant="h4">
                                SensorThings API
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                                variant="body1"
                            >
                                Our database structure is based on SensorThings
                                API
                                (https://en.wikipedia.org/wiki/SensorThings_API).
                                However, understanding it is not necessary when
                                using this tool. This SensorThings Manager lets
                                you set up new sensors, read in the sensor data
                                automatically, and generate data graphs. Here is
                                a short explanation of each tab:
                            </Typography>
                            <Typography className={classes.title} variant="h6">
                                Thing
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                                variant="body1"
                            >
                                A Thing is an object or device that can be
                                represented by a collection of sensors, for
                                example, a buoy with multiple sensors attached
                                to it. Each Thing also has a specific location.
                                With this dashboard, you can create new Things,
                                update existing Things, and delete Things.
                            </Typography>
                            <Typography className={classes.title} variant="h6">
                                SFTP
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                                variant="body1"
                            >
                                In this tab, you can create an SFTP login where
                                a thing(collection of sensors) can drop their
                                data on a regular interval
                            </Typography>
                            <Typography className={classes.title} variant="h6">
                                Location
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                                variant="body1"
                            >
                                A Location represents a physical place on Earth,
                                identified by its latitude and longitude
                                coordinates. You can use Locations to keep track
                                of where Things are located. By assigning a
                                Location to a Thing, you can know where the
                                Thing is physically located.
                            </Typography>
                            <Typography className={classes.title} variant="h6">
                                Sensor
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                                variant="body1"
                            >
                                A Sensor in OSG Sensor Things represents a
                                device or equipment that can detect or measure a
                                physical property and convert it into a signal
                                which can be recorded, transmitted or processed.
                                It is one of the core entities in the OSG Sensor
                                Things API, and it&apos;s used to represent the
                                physical sensors that are deployed in the field.
                                It&apos;s used in this dashboard to create a
                                Datastream, which is a sequence of observations
                                made by a sensor at a certain time and location.
                                By creating a Datastream, you can track and
                                store data from the sensor over time.
                            </Typography>
                            <Typography className={classes.title} variant="h6">
                                Observed Property
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                                variant="body1"
                            >
                                An Observed Property represents what is being
                                measured or detected by a sensor. It is a
                                combination of a name and a description that
                                describes the physical phenomena that the sensor
                                is observing. For example,
                                &quot;Temperature&quot; is an Observed Property.
                                Observed Properties are used to create
                                Datastreams. It&apos;s important to note that
                                within a group of Datastreams that belong to a
                                Thing, the Observed Property must be unique for
                                each Datastream. For example, if a Thing has two
                                temperature sensors, then there needs to be two
                                different Observed Properties for temperature,
                                such as &quot;Water Temperature&quot; and
                                &quot;Air Temperature&quot; to ensure that the
                                data can be properly differentiated.
                            </Typography>
                            <Typography className={classes.title} variant="h6">
                                Datastream
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                                variant="body1"
                            >
                                A Datastream is a combination of a Thing, a
                                Sensor, and an Observed Property, representing
                                the information that will be attached to the
                                actual data coming in from that Thing/Sensor.
                                There can only be one Datastream per unique
                                combination of Thing, Sensor, and Observed
                                Property. Once it is created, a Datastream will
                                provide an optional URL to which a sensor can
                                send data directly (instead of dropping files in
                                the SFTP folder). An example of a Datastream
                                would be the collection of water temperatures
                                observed on the water sensor on a buoy at
                                Cousin&apos;s Island.
                            </Typography>
                            <Typography className={classes.title} variant="h6">
                                Feature Of Interest
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                                variant="body1"
                            >
                                A Feature of Interest represents the object or
                                phenomenon that a Sensor is collecting data on.
                                It can change over time, for example, a
                                fluorometer could be placed into Tank A for two
                                months and then moved to Tank B. In this case,
                                two Features of Interest will have to be
                                created, and they should be assigned the correct
                                date/time and to the correct Datastream (in the
                                next tab). Once it&apos;s all set up, you will
                                be able to view graphs and analyze the data of
                                your Feature of Interest.
                            </Typography>
                            <Typography className={classes.title} variant="h6">
                                Datastream to FOI(Feature Of Interest)
                                connections
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                                variant="body1"
                            >
                                Here we keep track of which Datastream (or
                                sensor) was recording data for which Feature of
                                Interest at a certain time. For example, if a
                                reef gets plugged into a different outlet or a
                                fluorometer gets put into a different tank, a
                                new Datastream to Feature of Interest (FOI)
                                connection needs to be made so that we know that
                                the data pertains to that specific Feature of
                                Interest. This allows us to keep track of which
                                sensor was measuring which Feature of Interest
                                and when, which is important for accurate data
                                analysis.
                            </Typography>
                            <Typography className={classes.title} variant="h6">
                                Data Dashboard
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                                variant="body1"
                            >
                                The Data Dashboard lets you create dashboards
                                with all graphs from chosen Features of
                                Interest.
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            );
        }
    }
);

export default withStyles(styles)(ThingManager);
