import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import MessageHelper from "../helper/MessageHelper";
import BuoyConfigForm from "./BuoyConfigForm";

const styles = (theme) => ({
    container: {
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    button: {
        marginTop: theme.spacing(2),
    },
});

const BuoyConfigSender = class BuoyConfigSender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scheduleConfigText: {
                name: "",
                contents: "",
            },
            sensorsConfigText: {
                name: "",
                contents: "",
            },
            logsConfigText: {
                name: "",
                contents: "",
            },
            buoyConfigText: {
                name: "",
                contents: "",
            },
            buoyProtocolVersionText: "",
            sendToBuoy: false,
        };
    }

    handleCheck = (checked) => this.setState({ sendToBuoy: checked });

    getConfigsObject() {
        const configsText = `{
                "schedule_config": ${this.state.scheduleConfigText.contents},
                "sensors_config": ${this.state.sensorsConfigText.contents},
                "logs_config": ${this.state.logsConfigText.contents},
                "buoy_config": ${this.state.buoyConfigText.contents},
                "buoy_protocol_version": ${this.state.buoyProtocolVersionText}
            }`;

        try {
            const configs = JSON.parse(configsText);
            return configs;
        } catch (e) {
            return {};
        }
    }

    handleScheduleConfigTextChange = (scheduleConfigText) =>
        this.setState({ scheduleConfigText: scheduleConfigText });
    handleSensorConfigTextChange = (sensorsConfigText) =>
        this.setState({ sensorsConfigText: sensorsConfigText });
    handleLogConfigTextChange = (logsConfigText) =>
        this.setState({ logsConfigText: logsConfigText });
    handleBuoyConfigTextChange = (buoyConfigText) =>
        this.setState({ buoyConfigText: buoyConfigText });
    handleBuoyProtocolVersionChange = (buoyProtocolVersionText) =>
        this.setState({ buoyProtocolVersionText: buoyProtocolVersionText });

    setScheduleConfigText = (config) => {
        this.setState({
            scheduleConfigText: {
                name: this.state.scheduleConfigText.name,
                contents: config,
            },
        });
    };
    setLogConfigText = (config) => {
        this.setState({
            logsConfigText: {
                name: this.state.scheduleConfigText.name,
                contents: config,
            },
        });
    };
    setSensorConfigText = (config) => {
        this.setState({
            sensorsConfigText: {
                name: this.state.scheduleConfigText.name,
                contents: config,
            },
        });
    };
    setBuoyConfigText = (config) => {
        this.setState({
            buoyConfigText: {
                name: this.state.scheduleConfigText.name,
                contents: config,
            },
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <Paper>
                <BuoyConfigForm
                    scheduleConfigText={this.state.scheduleConfigText}
                    handleScheduleConfigTextChange={
                        this.handleScheduleConfigTextChange
                    }
                    sensorsConfigText={this.state.sensorsConfigText}
                    handleSensorConfigTextChange={
                        this.handleSensorConfigTextChange
                    }
                    logsConfigText={this.state.logsConfigText}
                    handleLogConfigTextChange={this.handleLogConfigTextChange}
                    buoyConfigText={this.state.buoyConfigText}
                    handleBuoyConfigTextChange={this.handleBuoyConfigTextChange}
                    buoyProtocolVersionText={this.state.buoyProtocolVersionText}
                    handleBuoyProtocolVersionChange={
                        this.handleBuoyProtocolVersionChange
                    }
                    sendToBuoy={this.state.sendToBuoy}
                    handleCheck={this.handleCheck}
                    setScheduleConfigText={this.setScheduleConfigText}
                    setLogConfigText={this.setLogConfigText}
                    setSensorConfigText={this.setSensorConfigText}
                    setBuoyConfigText={this.setBuoyConfigText}
                />
                <div className={classes.container}>
                    <Button
                        className={classes.button}
                        disabled={
                            Object.entries(this.getConfigsObject()).length === 0
                        }
                        variant="secondary"
                        onClick={() =>
                            this.props.pushConfigs(
                                this.getConfigsObject(),
                                this.state.sendToBuoy
                            )
                        }
                    >
                        Send
                    </Button>
                </div>

                {/*Show an error if the push request is not valid.*/}
                <MessageHelper
                    message={this.state.message}
                    errorMessage={this.state.errorMessage}
                    open={this.state.messageOpen}
                    setState={(a) => this.setState(a)}
                />
            </Paper>
        );
    }
};

export default withStyles(styles)(BuoyConfigSender);
