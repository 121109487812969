import React, { Component, Fragment } from "react";
import { WithGoogleAuth } from "../config/WithGoogleAuth";
import withStyles from "@mui/styles/withStyles";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Collapse,
    Typography,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import cronstrue from "cronstrue";
import RTable from "./components/RTable";
import moment from "moment-timezone";

import APIClient from "../models/APIClient";
import BuoyLogExplorer from "./components/BuoyLogExplorer";
import BuoyTest from "./components/BuoyTestComponent";
import GraphComponent from "./components/GraphComponent";
import KelpMap from "./components/KelpMapComponent.js";
import MessageHelper from "./helper/MessageHelper";
import ImageExplorer from "./components/ImageExplorer";
import BuoyMessageStatus from "./helper/BuoyMessageStatus";
import { FirmwareBuoyProtocols } from "@running-tide/firmware-buoy-protocols";

const styles = () => ({
    button: {
        "margin-right": "8px",
    },
});

const MAX_CONFIG_QUEUE = 5;
// The unique ids we want to separate listed config objects by
const CONFIG_ROW_PRIMARY_KEYS = ["sensor", "job_name", "name"];

let History = WithGoogleAuth(
    class KelpHistory extends Component {
        constructor(props) {
            super(props);
            this.state = {
                buoyData: null,
                selectorLabels: [],
                installedConfigs: [],
                sentConfigs: [],
                collapseConfigs: {},
                downloadingData: false,
            };
            this.downloadData = this.downloadData.bind(this);
            this.fetchBuoyData = this.fetchBuoyData.bind(this);
        }

        async componentDidMount() {
            this.fetchBuoyData();

            const apiClient = new APIClient(this.props.authState);
            const [installedConfigs, sentConfigs] = await Promise.all([
                apiClient.getBuoyInstalledConfigs(this.props.params.thing_id),
                apiClient.getBuoySentConfigs(
                    this.props.params.thing_id,
                    MAX_CONFIG_QUEUE
                ),
            ]);
            this.initConfigsCollapseState(sentConfigs);
            this.setState({ installedConfigs, sentConfigs });
        }

        async fetchBuoyData(startDate, endDate) {
            // TODO(hannah): Avoid refetching data we already have.
            const apiClient = new APIClient(this.props.authState);
            const rawBuoyData = await apiClient.getThingAll(
                this.props.params.thing_id,
                null,
                startDate,
                endDate
            );

            // Since this component displays data for a single buoy, we expect
            // exactly one thing to be returned.
            if (rawBuoyData.length === 1) {
                const buoyData = mergeBuoyData(
                    this.state.buoyData,
                    rawBuoyData[0]
                );
                // Don't return until state is updated.
                await new Promise((resolve) =>
                    this.setState(
                        { buoyData, selectorLabels: buoyData.selectorLabels },
                        resolve
                    )
                );
            } else {
                console.error(
                    `Unexpected number of things: ${rawBuoyData.length}`
                );
                return;
            }
        }

        async downloadData(dataType) {
            this.setState({ downloadingData: true });
            const apiClient = new APIClient(this.props.authState);
            const thingId = this.props.params.thing_id;
            switch (dataType) {
                case "sensor-data":
                    await apiClient.downloadSensorData(thingId);
                    break;
                case "images":
                    await apiClient.downloadImages(thingId);
                    break;
                case "logs":
                    await apiClient.downloadLogs(thingId);
                    break;
                case "config":
                    await apiClient.downloadBuoyConfig(thingId);
                    break;
                case "psd":
                    await apiClient.downloadBuoyPSDs(thingId);
                    break;
                default:
            }
            this.setState({ downloadingData: false });
        }

        getScheduleData(configs) {
            if (
                configs.length === 0 ||
                !configs[0].config ||
                ((!configs[0].config.schedule_config ||
                    !configs[0].config.schedule_config.tasks) &&
                    !configs[0].config.length)
            ) {
                return [];
            }
            if (
                configs[0].config.schedule_config &&
                configs[0].config.schedule_config.tasks
            ) {
                // Only map over the most recent config update.
                return configs[0].config.schedule_config.tasks.map((data) => {
                    let schedule;
                    try {
                        schedule = cronstrue.toString(data.cron_schedule, {
                            verbose: true,
                        });
                    } catch {
                        schedule = `Failed to parse: ${data.cron_schedule}`;
                    }

                    return {
                        schedule,
                        cron_schedule: data.cron_schedule,
                        file: data.file,
                        name: data.name,
                    };
                });
            } else {
                return configs[0].config.map((config) => {
                    let schedule;
                    try {
                        schedule = cronstrue.toString(config.cron, {
                            verbose: true,
                        });
                    } catch {
                        schedule = `Failed to parse: ${config.cron}`;
                    }

                    return {
                        schedule,
                        cron_schedule: config.cron,
                        flags: config.flags
                            .map((flag) => {
                                const flagName = Object.entries(
                                    FirmwareBuoyProtocols.JobFlags
                                ).filter(
                                    (arr) => arr[1] == flag.enum
                                )?.[0]?.[0];
                                return `${flagName}${
                                    flag.arg.length ? "=" : ""
                                }${flag.arg}`;
                            })
                            .join(","),
                        name: Object.entries(FirmwareBuoyProtocols.Jobs).filter(
                            (arr) => arr[1] == config.enum
                        )?.[0]?.[0],
                    };
                });
            }
        }

        getAllBuoyUpdates(buoyData) {
            if (buoyData == null) {
                return [];
            }

            const buoyUpdates = buoyData.datastreams
                .map((datastream) =>
                    datastream.sensorObservations.map((so) => ({
                        description: datastream.name,
                        buoyName: buoyData.name,
                        timeSent: moment(
                            so.phenomenonTime,
                            "YYYY-MM-DDTHH:mm:ss.SSSZ"
                        ).format("YYYY-MM-DD HH:mm:ss Z"),
                        timeRecieved: moment(
                            so.resultTime,
                            "YYYY-MM-DDTHH:mm:ss.SSSZ"
                        ).format("YYYY-MM-DD HH:mm:ss Z"),
                        data:
                            datastream.name === "Config Update"
                                ? "See Buoy Schedule"
                                : JSON.stringify(so, null, " "),
                    }))
                )
                .flat();

            buoyUpdates.sort((a, b) => a.timeRecieved - b.timeRecieved);
            return buoyUpdates;
        }
        initConfigsCollapseState(configs) {
            for (const update of configs) {
                this.initCollapseConfigs(update.config, update.id);
            }
        }
        handleCollapse(key) {
            const collapseKey = this.state.collapseConfigs[key];
            this.setState((state) => ({
                collapseConfigs: {
                    ...state.collapseConfigs,
                    [key]: !collapseKey,
                },
            }));
        }
        initCollapseConfigs(config, configId) {
            const collapseConfigs = {};
            Object.keys(config).forEach((key) => {
                collapseConfigs[`${configId}-${key}`] = false;
            });
            this.setState((state) => ({
                collapseConfigs: {
                    ...state.collapseConfigs,
                    ...collapseConfigs,
                },
            }));
            for (const [key, value] of Object.entries(config)) {
                this.initObjectCollapse(key, value, `config-${configId}`);
            }
        }
        initObjectCollapse(key, value, index) {
            if (typeof value === "object" && value !== null) {
                this.setState((state) => ({
                    collapseConfigs: {
                        ...state.collapseConfigs,
                        [`${index}-${key}`]: false,
                    },
                }));
                if (Array.isArray(value)) {
                    value.forEach((obj, i) => {
                        for (const [objKey, val] of Object.entries(obj)) {
                            this.initObjectCollapse(
                                objKey,
                                val,
                                `${index}-${key}-${objKey}-${i}`
                            );
                        }
                    });
                } else {
                    Object.entries(value).map(([objKey, val], i) =>
                        this.initObjectCollapse(
                            objKey,
                            val,
                            `${index}-${key}-${objKey}-${i}`
                        )
                    );
                }
            }
        }
        configToJsx(config, configId) {
            return (
                <Grid container key={configId}>
                    {Object.entries(config).map(([key, value]) => {
                        return (
                            <Grid
                                key={`${configId}-grid-${key}`}
                                item
                                xs={12}
                                md={5}
                                lg={3}
                            >
                                {this.objectToJsx(
                                    key,
                                    value,
                                    `config-${configId}`,
                                    0
                                )}
                            </Grid>
                        );
                    })}
                </Grid>
            );
        }
        objectToJsx(key, value, index, indent) {
            if (typeof value === "object" && value !== null) {
                return (
                    <Fragment key={`${index}-${key}`}>
                        <ListItemButton
                            key={`${index}-${key}-header`}
                            onClick={() =>
                                this.handleCollapse(`${index}-${key}`)
                            }
                            sx={{ pl: indent }}
                        >
                            <ListItemText
                                key={`${index}-${key}-text`}
                                primary={key}
                            />
                            {this.state.collapseConfigs[`${index}-${key}`] ? (
                                <ExpandLess />
                            ) : (
                                <ExpandMore />
                            )}
                        </ListItemButton>
                        <Collapse
                            key={`${index}-${key}-collapse`}
                            in={this.state.collapseConfigs[`${index}-${key}`]}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List key={`${index}-${key}-list`} disablePadding>
                                {Array.isArray(value)
                                    ? value.map((obj, i) => (
                                          <ListItem
                                              key={`${index}-${key}-li-${i}`}
                                              sx={{ pl: indent + 4 }}
                                          >
                                              {this.objToListItemText(obj)}
                                          </ListItem>
                                      ))
                                    : Object.entries(value).map(
                                          ([objKey, val], i) =>
                                              this.objectToJsx(
                                                  objKey,
                                                  val,
                                                  `${index}-${key}-${objKey}-${i}`,
                                                  indent + 4
                                              )
                                      )}
                            </List>
                        </Collapse>
                    </Fragment>
                );
            } else {
                return (
                    <ListItemText
                        primary={`${key}: ${value}`}
                        sx={{ pl: indent }}
                    />
                );
            }
        }
        objToListItemText(obj) {
            let primaryText = "";
            let secondaryText = "{ ";
            for (const [key, value] of Object.entries(obj)) {
                if (CONFIG_ROW_PRIMARY_KEYS.includes(key)) {
                    primaryText += `${key}: ${value}`;
                } else {
                    secondaryText !== "{ " && (secondaryText += ", ");
                    secondaryText += `${key}: ${value}`;
                }
            }
            secondaryText += " }";
            return (
                <ListItemText primary={primaryText} secondary={secondaryText} />
            );
        }
        render() {
            const scheduleData = this.getScheduleData(
                this.state.installedConfigs
            );
            let columns = [
                {
                    title: "Schedule",
                    field: "schedule",
                },
                {
                    title: "Event Description",
                    field: "name",
                },
                {
                    title: "File",
                    field: "file",
                },
            ];
            if (
                scheduleData.length &&
                scheduleData.filter((job) => job.flags).length
            ) {
                columns.pop();
                columns.push({
                    title: "Flags",
                    field: "flags",
                });
            }
            const allBuoyUpdates = this.getAllBuoyUpdates(this.state.buoyData);

            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <KelpMap
                            buoyData={this.state.buoyData}
                            fetchBuoyData={this.fetchBuoyData}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {this.state.selectorLabels.includes("gs-buoy") ? (
                            <Box display="flex" alignItems="center">
                                <Button
                                    variant="secondary"
                                    disabled={this.state.downloadingData}
                                    onClick={() =>
                                        this.downloadData("sensor-data")
                                    }
                                    className={this.props.classes.button}
                                >
                                    Download sensor data
                                </Button>
                                {this.state.downloadingData && (
                                    <CircularProgress size={24} />
                                )}
                            </Box>
                        ) : this.state.selectorLabels.includes("accel-buoy") ? (
                            <Box display="flex" alignItems="center">
                                <Button
                                    variant="secondary"
                                    disabled={this.state.downloadingData}
                                    onClick={() =>
                                        this.downloadData("sensor-data")
                                    }
                                    className={this.props.classes.button}
                                >
                                    Download sensor data
                                </Button>
                                <Button
                                    variant="secondary"
                                    disabled={this.state.downloadingData}
                                    onClick={() => this.downloadData("psd")}
                                    className={this.props.classes.button}
                                >
                                    Download PSDs
                                </Button>
                                {this.state.downloadingData && (
                                    <CircularProgress size={24} />
                                )}
                            </Box>
                        ) : (
                            <Box display="flex" alignItems="center">
                                <Button
                                    variant="secondary"
                                    disabled={this.state.downloadingData}
                                    onClick={() =>
                                        this.downloadData("sensor-data")
                                    }
                                    className={this.props.classes.button}
                                >
                                    Download sensor data
                                </Button>
                                <Button
                                    variant="secondary"
                                    disabled={this.state.downloadingData}
                                    onClick={() => this.downloadData("images")}
                                    className={this.props.classes.button}
                                >
                                    Download images
                                </Button>
                                <Button
                                    variant="secondary"
                                    disabled={this.state.downloadingData}
                                    onClick={() => this.downloadData("logs")}
                                    className={this.props.classes.button}
                                >
                                    Download logs
                                </Button>
                                <Button
                                    variant="secondary"
                                    disabled={this.state.downloadingData}
                                    onClick={() => this.downloadData("psd")}
                                    className={this.props.classes.button}
                                >
                                    Download PSDs
                                </Button>
                                <Button
                                    variant="secondary"
                                    disabled={this.state.downloadingData}
                                    onClick={() => this.downloadData("config")}
                                    className={this.props.classes.button}
                                >
                                    Download Config
                                </Button>
                                {this.state.downloadingData && (
                                    <CircularProgress size={24} />
                                )}
                            </Box>
                        )}
                    </Grid>

                    {!this.state.selectorLabels.includes("gs-buoy") &&
                        !this.state.selectorLabels.includes("accel-buoy") && (
                            <Grid item xs={12}>
                                <ImageExplorer
                                    buoyId={this.props.params.thing_id}
                                />
                            </Grid>
                        )}

                    {!this.state.selectorLabels.includes("gs-buoy") && (
                        <Grid item xs={12}>
                            <RTable
                                title={
                                    <Typography variant="h3">
                                        Buoy Schedule
                                    </Typography>
                                }
                                columns={columns}
                                data={scheduleData}
                                options={{
                                    search: false,
                                    filtering: true,
                                    paging: false,
                                    idSynonym: "schedule",
                                }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <GraphComponent
                            picker={true}
                            thingSelector={this.props.params.thing_id}
                            size={"large"}
                            timelineAtBottom={true}
                            legendPos={"right"}
                            addToggleToLegend={true}
                            cappedGraphHeight={true}
                        />
                    </Grid>

                    {!this.state.selectorLabels.includes("gs-buoy") && (
                        <Grid item xs={12}>
                            <BuoyTest thingId={this.props.params.thing_id} />
                        </Grid>
                    )}

                    {!this.state.selectorLabels.includes("gs-buoy") &&
                        !this.state.selectorLabels.includes("accel-buoy") && (
                            <Grid item xs={12}>
                                <BuoyLogExplorer
                                    buoyId={this.props.params.thing_id}
                                />
                            </Grid>
                        )}

                    <Grid item xs={12}>
                        <RTable
                            title={
                                <Typography variant="h3">
                                    Buoy Message Queue
                                </Typography>
                            }
                            columns={[
                                {
                                    title: "Config send time",
                                    field: "dateSent",
                                },
                                {
                                    title: "Status",
                                    render: (rowData) => (
                                        <BuoyMessageStatus
                                            dateSent={rowData.dateSent}
                                            dateInstalled={
                                                rowData.dateInstalled
                                            }
                                            dateFailed={rowData.dateFailed}
                                        />
                                    ),
                                },
                                {
                                    title: "Time Installed",
                                    field: "dateInstalled",
                                    render: (rowData) =>
                                        rowData.dateInstalled ?? "N/A",
                                },
                                {
                                    title: "Install Failed",
                                    field: "dateFailed",
                                    render: (rowData) =>
                                        rowData.dateFailed ?? "N/A",
                                },
                            ]}
                            detailPanel={({ rowData }) => {
                                return this.configToJsx(
                                    rowData.config,
                                    rowData.id
                                );
                            }}
                            onRowClick={(_, __, togglePanel) => togglePanel()}
                            data={this.state.sentConfigs}
                            options={{
                                search: false,
                                filtering: false,
                                paging: false,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <RTable
                            title={
                                <Typography variant="h3">
                                    Individual Buoy History
                                </Typography>
                            }
                            columns={[
                                {
                                    title: "Name",
                                    field: "buoyName",
                                },
                                {
                                    title: "Event Description",
                                    field: "description",
                                },
                                {
                                    title: "Time Sent",
                                    field: "timeSent",
                                },
                                {
                                    title: "Time Recieved",
                                    field: "timeRecieved",
                                },
                                {
                                    title: "Full Data",
                                    field: "data",
                                },
                            ]}
                            data={allBuoyUpdates}
                            options={{
                                search: false,
                                filtering: true,
                                paging: true,
                                pageSizeOptions: [5],
                                idSynonym: "data.id",
                            }}
                        />
                    </Grid>

                    {/* Show an error if the buoy does not have a selector ID
                    as this will cause issues when the buoy starts sending
                    messages. */}
                    <MessageHelper
                        errorMessage={`WARNING: This buoy does not have a
                            selector ID. This will prevent us from receiving
                            messages from the buoy. Please set the buoy's
                            selector ID to its modem's IMEI.`}
                        open={
                            this.state.buoyData &&
                            this.state.buoyData.selectorId == null
                        }
                        setState={() => {
                            // Don't allow MessageHelper to change state; we
                            // want this message to stay visible.
                        }}
                    />
                </Grid>
            );
        }
    }
);

/**
 * Returns the new buoy data with the sensor observations from the existing
 * buoy data.
 */
const mergeBuoyData = (existingBuoy, newBuoy) => {
    if (existingBuoy == null) {
        return newBuoy;
    }

    const datastreams = {};
    existingBuoy.datastreams.forEach((datastream) => {
        datastreams[datastream.id] = datastream;
    });

    newBuoy.datastreams.forEach((datastream) => {
        if (datastreams[datastream.id] != null) {
            // Add the existing and new sensor observations to a map to avoid
            // duplicates.
            const observationsById = {};
            [
                ...datastreams[datastream.id].sensorObservations,
                ...datastream.sensorObservations,
            ].forEach((sensorObservation) => {
                observationsById[sensorObservation.id] = sensorObservation;
            });

            const sensorObservations = Object.values(observationsById);
            sensorObservations.sort(
                (a, b) => a.phenomenonTime - b.phenomenonTime
            );

            datastreams[datastream.id] = { ...datastream, sensorObservations };
        } else {
            datastreams[datastream.id] = datastream;
        }
    });

    return {
        ...newBuoy,
        datastreams: Object.values(datastreams),
    };
};

export default withStyles(styles)(History);
