import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import ShellfishInventoryAPIClient from "../../models/ShellfishInventoryAPIClient";
import RTDisplayUtils from "@running-tide/rt-frontend-helpers/helpers";
import { Paper, Typography } from "@mui/material";
import RTable from "../../pages/components/RTable";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { hexToCSSFilter } from "hex-to-css-filter";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";

const styles = (theme) => ({
    root: {
        padding: 0,
        margin: 0,
        backgroundColor: theme.palette.background.default,
    },
    paper: {
        padding: theme.spacing(3),
        margin: theme.spacing(2),
        textAlign: "left",
    },
    header: {
        color: theme.palette.text.secondary,
    },
});

let vs = WithGoogleAuth(
    class VesselStatus extends Component {
        constructor(props) {
            super(props);
            this.state = { vessels: [] };
        }

        componentDidMount() {
            this.updateVesselStatus();

            //Update every 5 mins
            this.vesselUpdater = setInterval(() => {
                this.updateVesselStatus();
            }, 300000);
        }

        componentWillUnmount() {
            clearInterval(this.vesselUpdater);
        }

        updateVesselStatus() {
            new ShellfishInventoryAPIClient(this.props.authState)
                .getVesselStatus()
                .then((res) => res.json())
                .then((res) => {
                    this.setState({ data: res });
                });
        }

        getVesselColorKey(name) {
            let color = this.props.colorMap[name];
            if (color === undefined || color == null)
                return this.props.colorMap["Other"];
            else return color;
        }

        render() {
            const { classes } = this.props;
            return (
                <Paper variant="outlined" className={classes.root}>
                    <RTable
                        disableAltRowColor
                        title={
                            <Typography variant="h3">Vessel Status</Typography>
                        }
                        columns={[
                            {
                                title: "Vessel",
                                field: "name",
                                render: (rowData) => {
                                    let filter = hexToCSSFilter(
                                        this.getVesselColorKey(rowData.name)
                                    ).filter.replace(";", "");
                                    return (
                                        <div>
                                            <div style={{ float: "left" }}>
                                                <FiberManualRecordIcon
                                                    style={{ filter: filter }}
                                                ></FiberManualRecordIcon>
                                            </div>
                                            <div>{rowData.name}</div>
                                        </div>
                                    );
                                },
                            },
                            {
                                title: "Location",
                                field: "location",
                                render: (rowData) => {
                                    return (
                                        <div>
                                            {RTDisplayUtils.printLatLong(
                                                rowData.location.lat,
                                                rowData.location.long
                                            )}
                                        </div>
                                    );
                                },
                            },
                            {
                                title: "Last Update",
                                field: "systemStatus.date",
                                render: (rowData) => {
                                    return (
                                        <div>
                                            {RTDisplayUtils.printUTCDateInLocal(
                                                rowData.location.time
                                            )}
                                        </div>
                                    );
                                },
                            },
                        ]}
                        data={this.state.data}
                        options={{
                            search: false,
                            maxColumnSort: 0,
                            filtering: false,
                            paging: false,
                            idSynonym: "vesselId",
                        }}
                    ></RTable>
                </Paper>
            );
        }
    }
);
export default withStyles(styles)(vs);
