import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import BinInformation from "./BinInformation";

class CurrentAllocationsGradingHistoryDetails extends Component {
    render() {
        return (
            <Grid container>
                <BinInformation
                    binId={this.props.binId}
                    binOrder={this.props.binOrder}
                />
            </Grid>
        );
    }
}

export default CurrentAllocationsGradingHistoryDetails;
