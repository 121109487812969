import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import {
    Button,
    Box,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import BinSelectionForm from "./BinSelectionForm";
import ShellfishInventoryAPIClient from "../../models/ShellfishInventoryAPIClient";
import { connect } from "react-redux";
import { v1 as uuidv1 } from "uuid";
import RTDisplayUtils from "@running-tide/rt-frontend-helpers/helpers";
import OysterStreamUtils from "../../models/OysterStreamUtils";

const styles = (theme) => ({
    section: {
        marginTop: theme.spacing(3),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
});

let FEntry = WithGoogleAuth(
    class OysterBinMoveForm extends Component {
        constructor(props) {
            super(props);

            let targetBinErrors = { farm: false, reef: false, bin: false };
            let sourceBinErrors = { farm: false, reef: false, bin: false };
            let errors = {
                grade: false,
                count: false,
                targetBinErrors,
                sourceBinErrors,
                stream: false,
            };

            this.initialState = {
                selectedStreamBins: [],
                selectedStream: null,
                sourceFarm: "",
                sourceReef: "",
                sourceBin: [],
                targetFarm: "",
                targetReef: "",
                targetBin: [],
                occuranceDate: new Date(),
                errors: errors,
                newCounts: false,
            };
            this.state = this.initialState;

            this.handleSourceBinSelected =
                this.handleSourceBinSelected.bind(this);
            this.handleSourceReefSelected =
                this.handleSourceReefSelected.bind(this);
            this.handleSourceFarmSelected =
                this.handleSourceFarmSelected.bind(this);

            this.handleTargetBinSelected =
                this.handleTargetBinSelected.bind(this);
            this.handleTargetReefSelected =
                this.handleTargetReefSelected.bind(this);
            this.handleTargetFarmSelected =
                this.handleTargetFarmSelected.bind(this);

            this.onFarmSystemReceived = this.onFarmSystemReceived.bind(this);

            this.handleNewCountsChanged =
                this.handleNewCountsChanged.bind(this);
            this.key = uuidv1();
        }

        async componentDidMount() {
            await this.getData();
        }

        /**
         * Gets Data from cloud
         */
        async getData() {
            let apiClient = new ShellfishInventoryAPIClient(
                this.props.authState
            );
            apiClient
                .getActiveStreams()
                .then((res) => res.json())
                .then((res) => {
                    this.setState({ streams: res });
                });

            apiClient
                .getBinAllocations()
                .then((res) => res.json())
                .then((res) => {
                    this.setState({ allocations: res });
                });
        }

        handleSourceFarmSelected = (value) => {
            this.setState({ sourceFarm: value });
        };

        handleSourceReefSelected = (value) => {
            this.setState({ sourceReef: value });
        };

        handleSourceBinSelected = (value) => {
            let stream = OysterStreamUtils.getStreamForBinId(
                this.state.streams,
                value
            );
            let selectedStreamBins = OysterStreamUtils.getBinsForBinIds(
                this.state.farms,
                value
            );
            this.setState({
                sourceBin: value,
                selectedStream: stream,
                selectedStreamBins: selectedStreamBins,
            });
        };

        handleTargetFarmSelected = (value) => {
            this.setState({ targetFarm: value });
        };

        handleTargetReefSelected = (value) => {
            this.setState({ targetReef: value });
        };

        handleTargetBinSelected = (value) => {
            this.setState({ targetBin: value });
        };

        handleOccuranceDateChanged = (event) => {
            this.setState({ occuranceDate: new Date(event.target.value) });
        };

        handleNewCountsChanged = (event) => {
            this.setState({ newCounts: event.target.checked });
        };

        /**
         * Text to be displayed after source bin is selected
         * @returns {String}
         */
        otherBins() {
            if (
                this.state.selectedStreamBins == null ||
                this.state.allocations === undefined
            ) {
                return "";
            }

            if (this.isSourceBinEmpty()) {
                return "There are currently no shellfish in that bin";
            }

            let shellfishType = RTDisplayUtils.printShellfishType(
                this.state.selectedStream.shellfishType
            ).toLowerCase();
            return (
                "We are moving " +
                shellfishType +
                "s from the following bins " +
                this.state.selectedStreamBins.map((bin) => bin.name).join(",")
            );
        }

        /**
         *
         * @returns {Boolean} True if empty, False if not
         */
        isSourceBinEmpty() {
            if (this.state.allocations === undefined) {
                return -1;
            }
            let currentAllocations = this.state.allocations.filter(
                (allocation) => this.state.sourceBin === allocation.binId
            );
            let count = currentAllocations
                .map((allocation) => allocation.count)
                .reduce(function (a, b) {
                    return a + b;
                }, 0);
            return count === 0;
        }

        onFarmSystemReceived(farms) {
            this.setState({ farms: farms });
        }

        submitForm(event) {
            event.preventDefault();

            if (!this.validate()) {
                return;
            }
            let t = this;
            let apiClient = new ShellfishInventoryAPIClient(
                this.props.authState
            );
            apiClient
                .createOysterMove(
                    this.state.selectedStream.id,
                    this.state.targetBin,
                    this.state.sourceBin,
                    this.state.occuranceDate,
                    this.props.loggedInUser.id,
                    new Date(),
                    this.state.newCounts
                )
                .then(() => {
                    t.key = uuidv1();
                    t.setState({
                        ...t.initialState,
                        occuranceDate: this.state.occuranceDate,
                    });
                    t.props.showSubmittedMessage();
                })
                .then(() => {
                    //Update data from db upon submit
                    this.getData();
                })
                .catch((e) => {
                    try {
                        e.json().then((body) => {
                            t.props.showErrorMessage(body.error);
                        });
                    } catch (e) {
                        t.props.showErrorMessage("An unknown error occurred");
                    }
                });
        }

        validate() {
            let errors = this.state.errors;
            errors.stream =
                this.state.selectedStream == null ||
                Object.keys(this.state.selectedStream).length === 0
                    ? true
                    : false;
            errors.sourceBinErrors.farm =
                this.state.sourceFarm == null || this.state.sourceFarm === ""
                    ? true
                    : false;
            errors.sourceBinErrors.reef =
                this.state.sourceReef == null || this.state.sourceReef === ""
                    ? true
                    : false;
            errors.sourceBinErrors.bin =
                this.state.sourceBin == null ||
                this.state.sourceBin === 0 ||
                this.isSourceBinEmpty()
                    ? true
                    : false;

            errors.targetBinErrors.farm =
                this.state.targetFarm == null || this.state.targetFarm === ""
                    ? true
                    : false;
            errors.targetBinErrors.reef =
                this.state.targetReef == null || this.state.targetReef === ""
                    ? true
                    : false;
            errors.targetBinErrors.bin =
                this.state.targetBin == null ||
                this.state.targetBin.length === 0 ||
                this.isTargetSameAsSource()
                    ? true
                    : false;

            if (
                this.state.selectedStreamBins == null ||
                this.state.selectedStreamBins.length === 0
            ) {
                errors.sourceBinErrors.bin = true;
            }
            if (!errors.sourceBinErrors.bin && errors.stream) {
                this.props.showErrorMessage(
                    "Oyster stream for source bin not available. Contact dev."
                );
            }
            this.setState({ errors: errors });
            return (
                Object.values(errors).filter((val) => val === true).length ===
                    0 &&
                Object.values(errors.sourceBinErrors).filter(
                    (val) => val === true
                ).length === 0 &&
                Object.values(errors.targetBinErrors).filter(
                    (val) => val === true
                ).length === 0
            );
        }

        sourceDescription() {
            return "Select one of the bins you moved and we will go ahead and take apply this movement to all of the bins that were part of this stream.";
        }

        /**
         * Check if target bin is same as source bin
         * @returns {Boolean} True is yes, False if not
         */
        isTargetSameAsSource() {
            if (
                this.state.targetBin.length === 1 &&
                this.state.targetBin[0] === this.state.sourceBin
            ) {
                return true;
            }
        }

        /**
         * Check whether 1 or more target bins are occupied
         * @returns {Boolean} True if yes, False if not
         */
        isTargetBinsOccupied() {
            if (this.state.allocations === undefined) return true;
            let currentAllocations = this.state.allocations.filter(
                (allocation) =>
                    this.state.targetBin.includes(allocation.binId) &&
                    allocation.binId !== this.state.sourceBin
            );
            let count = currentAllocations
                .map((allocation) => allocation.count)
                .reduce(function (a, b) {
                    return a + b;
                }, 0);

            return count > 0;
        }

        /**
         * Text to be displayed above submit button
         * Displays summary of moving shellfish
         * @returns {String}
         */
        oysterMovementInfo() {
            if (this.isTargetBinsOccupied()) {
                return "The target bins are already occupied.";
            } else if (this.isTargetSameAsSource()) {
                return "Target bin same as source bin";
            } else if (this.isSourceBinEmpty()) {
                return "Source bin is empty";
            } else if (this.state.selectedStreamBins.length > 0) {
                let existingBinNames = this.state.selectedStreamBins
                    .map((bin) => bin.name)
                    .join(",");
                let selectedBinIds = this.state.selectedStreamBins.map(
                    (bin) => bin.id
                );
                let existingAllocations = this.state.allocations.filter(
                    (allocation) => selectedBinIds.includes(allocation.binId)
                );
                let count = existingAllocations
                    .map((allocation) => allocation.count)
                    .reduce(function (a, b) {
                        return a + b;
                    }, 0);

                let shellfishType = RTDisplayUtils.printShellfishType(
                    this.state.selectedStream.shellfishType
                ).toLowerCase();

                let targetBinNames = OysterStreamUtils.getBinsForBinIds(
                    this.state.farms,
                    this.state.targetBin
                )
                    .map((bin) => bin.name)
                    .join(", ");

                return `This will move ${count.toLocaleString(
                    "en-US"
                )} ${shellfishType}s from bins ${existingBinNames} into bins ${targetBinNames} `;
            } else {
                return "";
            }
        }

        render() {
            const { classes } = this.props;
            return (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" color="textPrimary">
                        This form is used if you moved shellfish in a stream
                        from one set of bins to another
                    </Typography>
                    <form
                        className={classes.container}
                        noValidate
                        onSubmit={(e) => {
                            this.submitForm(e);
                        }}
                    >
                        <BinSelectionForm
                            key={this.key + "bsf"}
                            title="SOURCE BINS"
                            description={this.sourceDescription()}
                            onBinSelected={this.handleSourceBinSelected}
                            onReefSelected={this.handleSourceReefSelected}
                            onFarmSelected={this.handleSourceFarmSelected}
                            errors={this.state.errors.sourceBinErrors}
                            onFarmSystemReceived={this.onFarmSystemReceived}
                        ></BinSelectionForm>
                        <div>
                            <Typography sx={{ mt: 1 }}>
                                {this.otherBins()}
                            </Typography>
                        </div>
                        {this.isTargetBinsOccupied() ? (
                            <Box className={classes.section}>
                                <Typography
                                    className={classes.header}
                                    variant="h3"
                                    color="tertiaryUtility"
                                >
                                    OVERWRITE PREVIOUS COUNTS
                                </Typography>
                                <div>
                                    <Typography
                                        className={classes.header}
                                        variant="body1"
                                        color="textPrimary"
                                        fontWeight={300}
                                    >
                                        One or more of target bins are occupied.
                                        Check this box if you want to overwrite
                                        the current counts. If left unchecked,
                                        the moved shellfish will be added to the
                                        already existing counts.
                                    </Typography>
                                </div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.newCounts}
                                            onChange={
                                                this.handleNewCountsChanged
                                            }
                                            inputProps={{
                                                "aria-label":
                                                    "primary checkbox",
                                            }}
                                            name="newCounts"
                                        />
                                    }
                                    label="Brand new counts"
                                />
                            </Box>
                        ) : null}
                        <BinSelectionForm
                            key={this.key + "bsf2"}
                            title="TARGET BINS"
                            onBinSelected={this.handleTargetBinSelected}
                            onReefSelected={this.handleTargetReefSelected}
                            onFarmSelected={this.handleTargetFarmSelected}
                            errors={this.state.errors.targetBinErrors}
                            multiple={true}
                        ></BinSelectionForm>
                        <Box className={classes.section}>
                            <TextField
                                sx={{ mt: 3 }}
                                id="date"
                                label="Occurrence Date"
                                type="date"
                                onChange={this.handleOccuranceDateChanged}
                                defaultValue={RTDisplayUtils.printDatePickerDate(
                                    this.state.occuranceDate
                                )}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: false,
                                    variant: "overhead",
                                }}
                            />
                        </Box>
                        <div>
                            <Typography color={"primary"}>
                                {this.oysterMovementInfo()}
                            </Typography>
                        </div>
                        <Button variant="secondary" type="submit">
                            Submit
                        </Button>
                    </form>
                </Box>
            );
        }
    }
);

const mapStateToProps = (state) => ({
    loggedInUser: state.data.loggedInUser,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(FEntry));
