import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WithGoogleAuth } from "../config/WithGoogleAuth";
import CarbonAccountingAPIClient from "../models/CarbonAccountingAPIClient";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import APIClient from "../models/APIClient";
import { Stack, Typography } from "@mui/material";

function SwaggerComponent(props) {
    const { authState } = props;

    const [apiSpec, setAPISpec] = useState(null);
    const { api } = useParams();
    const interventionApiClient = new CarbonAccountingAPIClient(authState);
    const cloudAPI = new APIClient(authState);

    const getInterventionAPISpecs = async function () {
        return interventionApiClient.getAPISpecs();
    };

    const getCloudAPISpecs = async function () {
        return cloudAPI.getAPISpecs();
    };

    const complete = function (swaggerUi) {
        if (api == "intervention") {
            swaggerUi.preauthorizeApiKey(
                "carbon-sequestration",
                `Bearer ${interventionApiClient.accessToken}`
            );
            swaggerUi.preauthorizeApiKey(
                "verification-buoys",
                `Bearer ${interventionApiClient.accessToken}`
            );
            swaggerUi.preauthorizeApiKey(
                "authorization-administration",
                `Bearer ${interventionApiClient.accessToken}`
            );
        } else {
            swaggerUi.preauthorizeApiKey(
                "ApiKeyAuth",
                `Bearer ${cloudAPI.accessToken}`
            );
        }
    };

    useEffect(() => {
        async function getAPISpecs() {
            if (api == "intervention") {
                return getInterventionAPISpecs();
            } else {
                return getCloudAPISpecs();
            }
        }
        getAPISpecs()
            .then((result) => setAPISpec(result))
            .catch((e) => console.log(e));
    }, [api]);

    if (apiSpec == null) {
        return <div>Loading...</div>;
    } else {
        return (
            <Stack spacing={2}>
                <Typography variant="h2" component="div" gutterBottom>
                    {api == "intervention" ? "Intervention" : "Cloud"} API
                    Documentation
                </Typography>
                <Typography variant="h3">
                    The following is live API documentation. If you are in the
                    production enviroment then any changes you enter below will
                    also take place in the prod enviroment. Be safe.
                </Typography>
                <div style={{ backgroundColor: "white" }}>
                    <SwaggerUI spec={apiSpec} onComplete={complete}></SwaggerUI>
                </div>
            </Stack>
        );
    }
}

export default WithGoogleAuth((props) => (
    <SwaggerComponent {...props} params={useParams()} />
));
