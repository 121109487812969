import React from "react";
import { auth } from "../firebase";
import { hasValidPermissions } from "../../pages/helper/PermissionChecker";
import { Typography } from "@mui/material";

export const WithGoogleAuth = (WrappedComponent, requiredPermissions = []) => {
    /* eslint-disable react/display-name */
    return class extends React.Component {
        render() {
            if (!auth.currentUser) {
                return <div>Unauthorized</div>;
            }
            if (
                this.props.loggedInUser &&
                !hasValidPermissions(
                    this.props.loggedInUser,
                    requiredPermissions
                )
            ) {
                return (
                    <Typography variant="h3">
                        Unauthorized account: {this.props.loggedInUser.email}
                    </Typography>
                );
            }
            return (
                <WrappedComponent
                    {...this.props}
                    authState={{ accessToken: auth?.currentUser?.accessToken }}
                />
            );
        }
    };
};
