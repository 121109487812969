// src/AppWithRouterAccess.jsx

import React, { useCallback, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import { updateUser } from "../../actions";
import User from "../../models/User";

import Dashboard from "../../Dashboard";
import { connect } from "react-redux";
import Login from "../Login";

import FormEntry from "../../pages/forms/FormEntry";
import SensorThingsApiManager from "../../pages/SensorThingsApiManager";
import HistoricalGradings from "../../pages/HistoricalGradings.js";
import CurrentAllocations from "../../pages/CurrentAllocations.js";
import CurrentAllocationView from "../../pages/CurrentAllocationView";
import BuoyPageManager from "../../pages/buoyPageManager";
import HatcheryGraphs from "../../pages/HatcheryGraphs";
import LiveMap from "../../components/LiveMap";
import DataDashboard from "../../pages/DataDashboard";
import DownloadableGraphComponent from "../../pages/components/DownloadableGraphComponent";
import DownloadableHatcheryGraphComponent from "../../pages/components/DownloadableHatcheryGraphComponent";
import GradingSummaryDetails from "../../pages/GradingSummaryDetails";
import BuoysHistoryAndControlManager from "../../pages/BuoysHistoryAndControlManager";
import ProductInstance from "../../pages/productTesting/ProductInstance";
import TestConceptDetails from "../../pages/productTesting/TestConceptDetails";
import TestInstanceDetails from "../../pages/productTesting/TestInstanceDetails";
import SubstratePropertyCreator from "../../pages/productTesting/SubstratePropertyCreator";
import ProductInstanceCreator from "../../pages/productTesting/ProductInstanceCreator";
import ProductGroupCreator from "../../pages/productTesting/ProductGroupCreator";
import TestInstanceCreator from "../../pages/productTesting/TestInstanceCreator";
import TestConceptCreator from "../../pages/productTesting/TestConceptCreator";
import ProductInstanceList from "../../pages/productTesting/ProductInstanceList";
import TestConceptList from "../../pages/productTesting/TestConceptList";
import TestDataEntry from "../../pages/productTesting/TestDataEntry";
import UnderConstruction from "../../pages/UnderConstruction";
import Deployments from "../../pages/deployments/Deployments";
import DeploymentDetails from "../../pages/deployments/DeploymentDetails";
import DeploymentCreator from "../../pages/deployments/DeploymentCreator";
import ProductGroupList from "../../pages/productTesting/ProductGroupList";
import ProductGroupDetails from "../../pages/productTesting/ProductGroupDetails";
import ConfigTemplateManager from "../../pages/sections/ConfigTemplateManager";
import ThingGroupingsManager from "../../pages/sections/ThingGroupingsManager";
import ConfigUpdatesManager from "../../pages/sections/ConfigUpdatesManager";
import BuoyCreatorManager from "../../pages/sections/BuoyCreatorManager";
import BuoysInfoManager from "../../pages/sections/BuoysInfoManager";
import SelectorIdAllowlistManager from "../../pages/sections/SelectorIdAllowlistManager";
import BluetoothBuoySetup from "../../pages/components/BluetoothBuoySetup";
import Administration from "../../pages/Administration/Administration";
import Users from "../../pages/Administration/users/Users";
import DeploymentEventCreator from "../../pages/deployments/DeploymentEventCreator";
import Groups from "../../pages/Administration/groups/Groups";
import FirmwareVersionManager from "../../pages/sections/FirmwareVersionManager";
import BuoyFirmwareVersionManager from "../../pages/sections/BuoyFirmwareVersionManager";
import DeploymentEstimateCreator from "../../pages/deployments/DeploymentEstimateCreator";
import DeploymentEstimate from "../../pages/deployments/DeploymentEstimate";
import SwaggerComponent from "../../pages/SwaggerComponent";

function AppWithRouter(props) {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    auth.onIdTokenChanged((user) => {
        if (user) {
            updateUserInfo();
        }
    });

    const updateUserInfo = useCallback(async () => {
        if (user) {
            const nameSplit = user.displayName.split(" ");
            const firstNames = nameSplit
                .slice(0, nameSplit.length - 1)
                .join(" ");
            const lastName = nameSplit.at(-1);
            let rtUser = new User(
                null,
                user.uid,
                firstNames,
                lastName,
                user.email
            );
            if (props.loggedInUser?.id === null) {
                props.updateUser(user.accessToken, rtUser);
            }
        }
    }, [props, user]);

    useEffect(() => {
        if (loading) return;
        if (!user) {
            return navigate("/login");
        }
    }, [user, loading, navigate, updateUserInfo]);

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Dashboard user={user} />}>
                {/* TODO(hannah): Replace LiveMap with the overview page:
                 https://app.clickup.com/8412952/v/dc/80qrr-2547/80qrr-9744*/}
                <Route exact path="/" element={<Deployments />} />
                <Route exact path="/shellfish-overview" element={<LiveMap />} />
                <Route exact path="/forms" element={<FormEntry />} />
                <Route path="/history" element={<HistoricalGradings />} />
                <Route path="/allocations" element={<CurrentAllocations />} />
                <Route
                    path="/current-allocation-view"
                    element={<CurrentAllocationView />}
                />
                <Route
                    path="/sensor-things-api-manager"
                    element={<SensorThingsApiManager />}
                />
                <Route
                    path="/photo-session/:session_id"
                    element={<GradingSummaryDetails />}
                />
                <Route path="/kelp-buoys" element={<BuoyPageManager />} />
                <Route
                    path="/kelp-buoys/:selector_label"
                    element={<BuoyPageManager />}
                />
                <Route
                    path="/feature_of_interest/:foi_id"
                    element={<DownloadableGraphComponent />}
                />

                <Route
                    path="/hatchery/:foi_id"
                    element={<DownloadableHatcheryGraphComponent />}
                />

                <Route
                    path="/buoy-control/config-templates"
                    element={<ConfigTemplateManager />}
                />
                <Route
                    path="/buoy-control/thing-groupings"
                    element={<ThingGroupingsManager />}
                />
                <Route
                    path="/buoy-control/config-updates"
                    element={<ConfigUpdatesManager />}
                />
                <Route
                    path="/buoy-control/buoy-creator"
                    element={<BuoyCreatorManager />}
                />
                <Route
                    path="/buoy-control/buoys-manager"
                    element={<BuoysInfoManager />}
                />
                <Route
                    path="/buoy-control/allowlist-manager"
                    element={<SelectorIdAllowlistManager />}
                />
                <Route
                    path="/buoy-control/firmware-version-manager"
                    element={<FirmwareVersionManager />}
                />
                <Route
                    path="/buoy-control/buoy-metadata-manager"
                    element={<BuoyFirmwareVersionManager />}
                />
                <Route
                    path="/buoy-control/bluetooth-setup"
                    element={<BluetoothBuoySetup />}
                />

                <Route path="/hatchery" element={<HatcheryGraphs />} />

                <Route
                    path="/kelp_buoy/:thing_id"
                    element={<BuoysHistoryAndControlManager />}
                />
                <Route
                    path="/data/:dash_id"
                    element={<DataDashboard {...props} />}
                />
                <Route
                    path="/test/concept/list"
                    element={<TestConceptList />}
                />
                <Route path="/product/list" element={<ProductInstanceList />} />
                <Route
                    path="/product/group/create"
                    element={<ProductGroupCreator />}
                />
                <Route
                    path="/product/group/list"
                    element={<ProductGroupList />}
                />
                <Route
                    path="/product/group/:product_group_id"
                    element={<ProductGroupDetails />}
                />
                <Route
                    path="/product/create"
                    element={<ProductInstanceCreator />}
                />
                <Route
                    path="/test/create-concept"
                    element={<TestConceptCreator />}
                />
                <Route
                    path="/test/create-instance"
                    element={<TestInstanceCreator />}
                />
                <Route
                    path="/product/properties"
                    element={<SubstratePropertyCreator />}
                />
                <Route
                    path="/product/:product_instance_id"
                    element={<ProductInstance {...props} />}
                />
                <Route
                    path="/test/concept/:test_concept_id"
                    element={<TestConceptDetails {...props} />}
                />
                <Route
                    path="/test/instance/:test_instance_id"
                    element={<TestInstanceDetails {...props} />}
                />
                <Route
                    path="/test/data-entry"
                    element={<TestDataEntry {...props} />}
                />
                <Route path="/cdr/deployments" element={<Deployments />} />
                <Route
                    path="/cdr/deployments/:deployment_id"
                    element={<DeploymentDetails {...props} />}
                />
                <Route
                    path="/cdr/deployments/:deployment_id/events/create"
                    element={<DeploymentEventCreator {...props} />}
                />
                <Route
                    path="/cdr/deployments/:deployment_id/estimates/create"
                    element={<DeploymentEstimateCreator {...props} />}
                />
                <Route
                    path="/swagger-docs/:api"
                    element={<SwaggerComponent {...props} />}
                />
                <Route
                    path="/cdr/deployments/estimates/:deployment_id/:estimate_id"
                    element={<DeploymentEstimate {...props} />}
                />
                <Route
                    path="/cdr/deployments/create"
                    element={<DeploymentCreator />}
                />
                <Route path="/cdr/emissions" element={<UnderConstruction />} />
                <Route
                    path="/administration"
                    element={<Administration {...props} />}
                />
                <Route
                    path="/administration/users"
                    element={<Users {...props} />}
                />
                <Route
                    path="/administration/groups"
                    element={<Groups {...props} />}
                />
            </Route>
        </Routes>
    );
}

const mapStateToProps = (state) => ({
    loggedInUser: state.data.loggedInUser,
});

const mapDispatchToProps = (dispatch) => ({
    updateUser: (token, user) => dispatch(updateUser(token, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppWithRouter);
