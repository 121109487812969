import React, { Component } from "react";
import { Grid, Typography, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import { WithGoogleAuth } from "../config/WithGoogleAuth";
import ShellfishInventoryAPIClient from "../models/ShellfishInventoryAPIClient";
import RTDisplayUtils from "@running-tide/rt-frontend-helpers/helpers";
import NumberFormat from "react-number-format";
import RTable from "./components/RTable";

const styles = (theme) => ({
    root: {
        padding: 0,
        margin: 0,
        backgroundColor: theme.palette.background.component,
    },
    paper: {
        padding: theme.spacing(3),
        margin: theme.spacing(2),
        textAlign: "left",
        backgroundColor: theme.palette.background.default,
    },
});

const tableOptions = {
    paging: false,
    search: false,
    maxColumnSort: 0,
};

let GradingSummaryDetailsWithAuth = WithGoogleAuth(
    class GradingSummaryDetails extends Component {
        constructor(props) {
            super(props);
            let photoSessionId = props.session_id || props.params.session_id;
            let photoSession = props.photoSession || {
                inputBinIds: [],
                loaded: false,
            };
            this.state = {
                encoderReports: [],
                photoSessionId: photoSessionId,
                photoSession: photoSession,
                summary: { photoCount: 0, allocations: [] },
                farmSystem: props.farmSystem,
            };
        }

        componentDidMount() {
            let apiClient = new ShellfishInventoryAPIClient(
                this.props.authState
            );
            apiClient
                .getPhotoSessionSummary(this.state.photoSessionId)
                .then((res) => {
                    this.setState({ summary: res });
                });

            apiClient
                .getEncoderReport(this.state.photoSessionId)
                .then((res) => res.json())
                .then((res) => {
                    this.setState({ encoderReports: res });
                });

            if (this.state.farmSystem === undefined) {
                apiClient.getFarmSystem().then((farmSystem) => {
                    this.setState({ farmSystem: farmSystem });
                });
            }

            if (this.state.photoSession.loaded === false) {
                apiClient
                    .getPhotoSession(this.state.photoSessionId)
                    .then((res) => res.json())
                    .then((photoSession) => {
                        this.setState({ photoSession: photoSession });
                    });
            }
        }

        displayCountsSection() {
            const { classes } = this.props;

            return (
                <Grid item md={4} xs={12}>
                    <Paper variant="outlined" className={classes.paper}>
                        <Typography variant="body1" component="body">
                            Computed Count{" "}
                            <NumberFormat
                                thousandSeparator={true}
                                displayType={"text"}
                                value={this.state.summary.oysterCount}
                            />
                        </Typography>
                        <RTable
                            title={<Typography variant="h3">Counts</Typography>}
                            columns={[
                                {
                                    title: "Grade",
                                    render: (rowData) =>
                                        RTDisplayUtils.prettyPrintGrade(
                                            rowData.grade
                                        ),
                                },
                                {
                                    title: "Count",
                                    render: (rowData) => (
                                        <NumberFormat
                                            thousandSeparator={true}
                                            displayType={"text"}
                                            value={rowData.count}
                                        />
                                    ),
                                },
                                {
                                    title: "Boundary Count",
                                    render: (rowData) => (
                                        <NumberFormat
                                            thousandSeparator={true}
                                            displayType={"text"}
                                            value={rowData.boundary}
                                        />
                                    ),
                                },
                                {
                                    title: "Total Count",
                                    render: (rowData) => (
                                        <NumberFormat
                                            thousandSeparator={true}
                                            displayType={"text"}
                                            value={
                                                rowData.count +
                                                rowData.boundary / 2
                                            }
                                        />
                                    ),
                                },
                            ]}
                            data={[0, 1, 2, 3, 4, 5, 6, 7, 8].map((grade) => ({
                                grade,
                                count: this.state.summary[`w${grade}Count`],
                                boundary:
                                    this.state.summary[`w${grade}Boundary`],
                            }))}
                            options={tableOptions}
                        />
                    </Paper>
                </Grid>
            );
        }

        displayBinSection(binMap) {
            const { classes } = this.props;
            if (Object.keys(binMap).length === 0) {
                return null;
            }
            return (
                <Grid item md={4} xs={12}>
                    <Paper variant="outlined" className={classes.paper}>
                        <RTable
                            title={
                                <Typography variant="h3">
                                    Bins Photographed
                                </Typography>
                            }
                            columns={[
                                {
                                    title: "Reef",
                                    field: "reef",
                                },
                                {
                                    title: "Bin",
                                    field: "name",
                                },
                            ]}
                            data={this.state.photoSession.inputBinIds.map(
                                (binId) => ({
                                    reef: binMap[binId].reef,
                                    name: binMap[binId].name,
                                })
                            )}
                            options={tableOptions}
                        />
                    </Paper>
                </Grid>
            );
        }

        displayReallocationSection(binMap) {
            const { classes } = this.props;

            if (Object.keys(binMap).length === 0) {
                return null;
            }

            return (
                <Grid item md={4} xs={12}>
                    <Paper variant="outlined" className={classes.paper}>
                        <RTable
                            title={
                                <Typography variant="h3">
                                    Reallocation
                                </Typography>
                            }
                            columns={[
                                {
                                    title: "Reef",
                                    render: (rowData) =>
                                        binMap[rowData.binId].reef,
                                },
                                {
                                    title: "Bin",
                                    render: (rowData) =>
                                        binMap[rowData.binId].name,
                                },
                                {
                                    title: "Grade",
                                    render: (rowData) =>
                                        RTDisplayUtils.prettyPrintGrade(
                                            rowData.grade
                                        ),
                                },
                                {
                                    title: "Count",
                                    render: (rowData) => (
                                        <NumberFormat
                                            thousandSeparator={true}
                                            displayType={"text"}
                                            value={rowData.count}
                                        />
                                    ),
                                },
                            ]}
                            data={this.state.summary.allocations}
                            options={tableOptions}
                        />
                    </Paper>
                </Grid>
            );
        }

        render() {
            const { classes } = this.props;

            if (this.state.farmSystem === undefined) {
                return null;
            }

            let binMap = {};
            this.state.farmSystem.map((farm) => {
                return farm.reefs.map((reef) => {
                    return reef.bins.map((bin) => {
                        let b = bin;
                        binMap[bin.id] = { reef: reef.name, name: b.name };
                        return b;
                    });
                });
            });

            return (
                <div>
                    <Grid container className={classes.root}>
                        <Grid item xs={12}>
                            <Paper variant="outlined" className={classes.paper}>
                                <Typography variant="h5">
                                    Photos Processed:{" "}
                                    <NumberFormat
                                        thousandSeparator={true}
                                        displayType={"text"}
                                        value={this.state.summary.photoCount}
                                    />
                                </Typography>
                            </Paper>
                        </Grid>
                        {this.displayCountsSection()}
                        {this.displayBinSection(binMap)}
                        {this.displayReallocationSection(binMap)}
                        <Grid item xs={12}>
                            <Paper variant="outlined" className={classes.paper}>
                                <RTable
                                    title={
                                        <Typography variant="h3">
                                            Encoder Results
                                        </Typography>
                                    }
                                    columns={[
                                        {
                                            title: "Camera Id",
                                            field: "cameraId",
                                        },
                                        {
                                            title: "Grade",
                                            render: (rowData) =>
                                                RTDisplayUtils.prettyPrintGrade(
                                                    rowData.grade
                                                ),
                                        },
                                        {
                                            title: "Photo Count",
                                            render: (rowData) => (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.photoCount}
                                                />
                                            ),
                                        },
                                        {
                                            title: "Average Fire Time",
                                            render: (rowData) => (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    fixedDecimalScale={3}
                                                    decimalScale={2}
                                                    displayType={"text"}
                                                    value={
                                                        rowData.averageFireTime /
                                                        1000000000
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            title: "Encoder Error Percent",
                                            render: (rowData) => (
                                                <NumberFormat
                                                    allowLeadingZeros={true}
                                                    fixedDecimalScale={2}
                                                    decimalScale={2}
                                                    suffix="%"
                                                    displayType={"text"}
                                                    value={
                                                        ((rowData.lowerBoundErrors +
                                                            rowData.upperBoundErrors) /
                                                            rowData.photoCount) *
                                                        100
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            title: "Lower Bound Error Percent",
                                            render: (rowData) => (
                                                <NumberFormat
                                                    allowLeadingZeros={true}
                                                    fixedDecimalScale={2}
                                                    decimalScale={2}
                                                    suffix="%"
                                                    displayType={"text"}
                                                    value={
                                                        (rowData.lowerBoundErrors /
                                                            rowData.photoCount) *
                                                        100
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            title: "Upper Bound Error Percent",
                                            render: (rowData) => (
                                                <NumberFormat
                                                    allowLeadingZeros={true}
                                                    fixedDecimalScale={2}
                                                    decimalScale={2}
                                                    suffix="%"
                                                    displayType={"text"}
                                                    value={
                                                        (rowData.upperBoundErrors /
                                                            rowData.photoCount) *
                                                        100
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            title: "Lower Bound Errors",
                                            render: (rowData) => (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={
                                                        rowData.lowerBoundErrors
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            title: "Upper Bound Errors",
                                            render: (rowData) => (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={
                                                        rowData.upperBoundErrors
                                                    }
                                                />
                                            ),
                                        },
                                    ]}
                                    data={this.state.encoderReports}
                                    options={tableOptions}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }
);

export default withStyles(styles)((props) => (
    <GradingSummaryDetailsWithAuth {...props} params={useParams()} />
));
