import React, { Component } from "react";
import {
    Chip,
    TextField,
    Paper,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Switch,
    LinearProgress,
    FormControlLabel,
} from "@mui/material";
import { ExpandMore, Sync, CloudDownload } from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";
import Chart from "chart.js";
import moment from "moment-timezone";

// Constant for converting fraction of collection time elapsed to percentage of collection time elapsed
const ONE_HUNDRED_PERCENT = 100;

const styles = () => ({
    accordion_container: {
        width: "100%",
        padding: "2px 0",
    },
    accordion: {
        width: "100%",
        background: "#4f4f4f",
    },
    space_between: {
        justifyContent: "space-between",
    },
    tabel_row: {
        borderBottom: "1px dotted white",
    },
    flex_div100: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    flex_div: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "5px",
    },
    canvas: {
        // height: "auto",
        // width: "100%",
    },
});

class BluetoothWaveRawSensorCharacteristic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            uuid: props.uuid,
            name: props.name,
            characteristic: props.characteristic,
            raw_accelerometer_x_characteristic:
                props.raw_accelerometer_x_characteristic,
            raw_accelerometer_y_characteristic:
                props.raw_accelerometer_y_characteristic,
            raw_accelerometer_z_characteristic:
                props.raw_accelerometer_z_characteristic,
            raw_gyroscope_x_characteristic:
                props.raw_gyroscope_x_characteristic,
            raw_gyroscope_y_characteristic:
                props.raw_gyroscope_y_characteristic,
            raw_gyroscope_z_characteristic:
                props.raw_gyroscope_z_characteristic,
            typeHandler: props.typeHandler,
            runtimeSeconds: 60,
            runtimeProgress: 0,
            sampleFrequencyHz: 200,
            accelerometerOdrHz: 833,
            gyroscopeOdrHz: 833,
            packets: {
                raw_accelerometer_x: [],
                raw_accelerometer_y: [],
                raw_accelerometer_z: [],
                raw_gyroscope_x: [],
                raw_gyroscope_y: [],
                raw_gyroscope_z: [],
            },
            channels: {
                raw_accelerometer_x: [],
                raw_accelerometer_y: [],
                raw_accelerometer_z: [],
                raw_gyroscope_x: [],
                raw_gyroscope_y: [],
                raw_gyroscope_z: [],
            },
            graph_raw_data: false,
        };
        this.accordianToggle = this.accordianToggle.bind(this);
        this.kickOffJob = this.kickOffJob.bind(this);

        this.runtimeUpdate = this.runtimeUpdate.bind(this);
        this.samplingFrequencyHzUpdate =
            this.samplingFrequencyHzUpdate.bind(this);
        this.accelerometerOdrHzUpdate =
            this.accelerometerOdrHzUpdate.bind(this);
        this.gyroscopeOdrHzUpdate = this.gyroscopeOdrHzUpdate.bind(this);

        this.handleNotificationsRawAccelerometerX =
            this.handleNotificationsRawAccelerometerX.bind(this);
        this.handleNotificationsRawAccelerometerY =
            this.handleNotificationsRawAccelerometerY.bind(this);
        this.handleNotificationsRawAccelerometerZ =
            this.handleNotificationsRawAccelerometerZ.bind(this);
        this.handleNotificationsRawGyroscopeX =
            this.handleNotificationsRawGyroscopeX.bind(this);
        this.handleNotificationsRawGyroscopeY =
            this.handleNotificationsRawGyroscopeY.bind(this);
        this.handleNotificationsRawGyroscopeZ =
            this.handleNotificationsRawGyroscopeZ.bind(this);
        this.downloadCallback = this.downloadCallback.bind(this);
        this.loadChart3DOF = this.loadChart3DOF.bind(this);
        this.enable_notify_ble_characteristic();
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
        this.chartRawAccelerometerRef = React.createRef();
        this.chartRawGyroscopeRef = React.createRef();
        this.chartRawAccelerometer = undefined;
        this.chartRawGyroscope = undefined;
    }
    /**
     * Updates Sensor graphs
     */
    componentDidUpdate() {
        this.chartRawAccelerometer = undefined;
        this.chartRawGyroscope = undefined;

        if (this.state.graph_raw_data === true) {
            this.chartRawAccelerometer = this.loadChart3DOF(
                this.chartRawAccelerometerRef.current.getContext("2d"),
                this.state.channels.raw_accelerometer_x,
                this.state.channels.raw_accelerometer_y,
                this.state.channels.raw_accelerometer_z,
                "Raw Accelerometer (m/s^2)"
            );
            this.chartRawGyroscope = this.loadChart3DOF(
                this.chartRawGyroscopeRef.current.getContext("2d"),
                this.state.channels.raw_gyroscope_x,
                this.state.channels.raw_gyroscope_y,
                this.state.channels.raw_gyroscope_z,
                "Raw Gyroscope (rad./s)"
            );
        }
    }
    download(content, filename) {
        let encodedUri = encodeURI(content);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    }
    downloadCallback(event) {
        event.preventDefault();
        event.stopPropagation();

        let header_row = [
            "timestamp",
            "ax",
            "ay",
            "az",
            "wx",
            "wy",
            "wz",
            "mx",
            "my",
            "mz",
            "t",
        ];
        let csv_string = header_row.join(",");
        csv_string = csv_string.concat("\n");
        for (
            let sample = 0;
            sample < this.state.runtimeSeconds * this.state.sampleFrequencyHz;
            sample++
        ) {
            let data_row = [
                "",
                this.state.channels.raw_accelerometer_x[sample].toString(),
                this.state.channels.raw_accelerometer_y[sample].toString(),
                this.state.channels.raw_accelerometer_z[sample].toString(),
                this.state.channels.raw_gyroscope_x[sample].toString(),
                this.state.channels.raw_gyroscope_y[sample].toString(),
                this.state.channels.raw_gyroscope_z[sample].toString(),
                "",
                "",
                "",
                (sample / this.state.sampleFrequencyHz).toString(),
            ];
            csv_string = csv_string.concat(data_row.join(","));
            csv_string = csv_string.concat("\n");
        }
        let content = `data:text/csv;charset=utf-8,${csv_string.trim()}`;
        let filename = `BUOY_NAME_RAW_WAVE_${moment
            .utc()
            .format("YYYY-MM-DD_HH:mm:ss_z")}.csv`;
        this.download(content, filename);
    }
    /**
     * returns unique key for react elements
     */
    getKey() {
        return this.state.uuid + this.keyCount++;
    }
    /**
     * @param {*} event
     * @param {bool} isExpanded state to set to
     */
    accordianToggle(event, isExpanded) {
        this.setState({ expanded: isExpanded });
    }
    loadChart3DOF(chartRef, data_x, data_y, data_z, label) {
        let sampleFrequencyHz = this.state.sampleFrequencyHz;
        return new Chart(chartRef, {
            type: "line",
            data: {
                labels: [...Array(data_x.length).keys()].map(function (_, i) {
                    return i / sampleFrequencyHz;
                }),
                datasets: [
                    {
                        label: "X",
                        data: data_x,
                        borderColor: "#f00",
                    },
                    {
                        label: "Y",
                        data: data_y,
                        borderColor: "#0f0",
                    },
                    {
                        label: "Z",
                        data: data_z,
                        borderColor: "#00f",
                    },
                ],
            },
            options: {
                legend: { display: true },
                title: {
                    display: true,
                    text: label,
                },
            },
        });
    }
    /**
     * BLE characteristic hander
     * receives new first part of X raw accelerometer
     * over bluetooth when broadcasted by buoy
     * @param {*} event
     */
    handleNotificationsRawAccelerometerX(event) {
        let value = event.target.value;
        let raw_accelerometer_x = [...this.state.packets.raw_accelerometer_x];
        raw_accelerometer_x.push(value);
        let acc_x = [
            ...this.state.channels.raw_accelerometer_x,
            ...this.state.typeHandler(value, raw_accelerometer_x),
        ];
        this.setState({
            packets: {
                ...this.state.packets,
                raw_accelerometer_x: raw_accelerometer_x,
            },
            channels: {
                ...this.state.channels,
                raw_accelerometer_x: acc_x,
            },
        });
    }
    /**
     * BLE characteristic hander
     * receives new first part of Y raw accelerometer
     * over bluetooth when broadcasted by buoy
     * @param {*} event
     */
    handleNotificationsRawAccelerometerY(event) {
        let value = event.target.value;
        let raw_accelerometer_y = [...this.state.packets.raw_accelerometer_y];
        raw_accelerometer_y.push(value);
        let acc_y = [
            ...this.state.channels.raw_accelerometer_y,
            ...this.state.typeHandler(value, raw_accelerometer_y),
        ];
        this.setState({
            packets: {
                ...this.state.packets,
                raw_accelerometer_y: raw_accelerometer_y,
            },
            channels: {
                ...this.state.channels,
                raw_accelerometer_y: acc_y,
            },
        });
    }
    /**
     * BLE characteristic hander
     * receives new first part of Z raw accelerometer
     * over bluetooth when broadcasted by buoy
     * @param {*} event
     */
    handleNotificationsRawAccelerometerZ(event) {
        let value = event.target.value;
        let raw_accelerometer_z = [...this.state.packets.raw_accelerometer_z];
        let acc_z = [
            ...this.state.channels.raw_accelerometer_z,
            ...this.state.typeHandler(value, raw_accelerometer_z),
        ];
        this.setState({
            packets: {
                ...this.state.packets,
                raw_accelerometer_z: raw_accelerometer_z,
            },
            channels: {
                ...this.state.channels,
                raw_accelerometer_z: acc_z,
            },
        });
    }
    /**
     * BLE characteristic hander
     * receives new first part of X raw gyroscope
     * over bluetooth when broadcasted by buoy
     * @param {*} event
     */
    handleNotificationsRawGyroscopeX(event) {
        let value = event.target.value;
        let raw_gyroscope_x = [...this.state.packets.raw_gyroscope_x];
        raw_gyroscope_x.push(value);
        let gyr_x = [
            ...this.state.channels.raw_gyroscope_x,
            ...this.state.typeHandler(value, raw_gyroscope_x),
        ];
        this.setState({
            packets: {
                ...this.state.packets,
                raw_gyroscope_x: raw_gyroscope_x,
            },
            channels: {
                ...this.state.channels,
                raw_gyroscope_x: gyr_x,
            },
        });
    }
    /**
     * BLE characteristic hander
     * receives new first part of Y raw gyroscope
     * over bluetooth when broadcasted by buoy
     * @param {*} event
     */
    handleNotificationsRawGyroscopeY(event) {
        let value = event.target.value;
        let raw_gyroscope_y = [...this.state.packets.raw_gyroscope_y];
        raw_gyroscope_y.push(value);
        let gyr_y = [
            ...this.state.channels.raw_gyroscope_y,
            ...this.state.typeHandler(value, raw_gyroscope_y),
        ];
        this.setState({
            packets: {
                ...this.state.packets,
                raw_gyroscope_y: raw_gyroscope_y,
            },
            channels: {
                ...this.state.channels,
                raw_gyroscope_y: gyr_y,
            },
        });
    }
    /**
     * BLE characteristic hander
     * receives new first part of Z raw gyroscope
     * over bluetooth when broadcasted by buoy
     * @param {*} event
     */
    handleNotificationsRawGyroscopeZ(event) {
        let value = event.target.value;
        let raw_gyroscope_z = [...this.state.packets.raw_gyroscope_z];
        raw_gyroscope_z.push(value);
        let gyr_z = [
            ...this.state.channels.raw_gyroscope_z,
            ...this.state.typeHandler(value, raw_gyroscope_z),
        ];

        // Z Gyroscope is last to be sent, so use length of time-series to compute run time progress in percent
        let current_progress =
            (gyr_z.length /
                (this.state.runtimeSeconds * this.state.sampleFrequencyHz)) *
            ONE_HUNDRED_PERCENT;
        // Constrain progress to 100 percent or less
        current_progress =
            current_progress > ONE_HUNDRED_PERCENT
                ? ONE_HUNDRED_PERCENT
                : current_progress;

        this.setState({
            packets: {
                ...this.state.packets,
                raw_gyroscope_z: raw_gyroscope_z,
            },
            channels: {
                ...this.state.channels,
                raw_gyroscope_z: gyr_z,
            },
            runtimeProgress: current_progress,
            graph_raw_data:
                this.state.graph_raw_data ||
                current_progress === ONE_HUNDRED_PERCENT, // Automatically graph all raw data, but only when full run/collection time has been reached
        });
    }
    /**
     * Start notification on raw accelerometer and gyroscope data channels. to start listening for compressed
     * IMU data to be sent over BLE from buoy once ready
     */
    async enable_notify_ble_characteristic() {
        try {
            await this.state.raw_accelerometer_x_characteristic.startNotifications();
            await this.state.raw_accelerometer_y_characteristic.startNotifications();
            await this.state.raw_accelerometer_z_characteristic.startNotifications();
            await this.state.raw_gyroscope_x_characteristic.startNotifications();
            await this.state.raw_gyroscope_y_characteristic.startNotifications();
            await this.state.raw_gyroscope_z_characteristic.startNotifications();
            this.state.raw_accelerometer_x_characteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleNotificationsRawAccelerometerX
            );
            this.state.raw_accelerometer_y_characteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleNotificationsRawAccelerometerY
            );
            this.state.raw_accelerometer_z_characteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleNotificationsRawAccelerometerZ
            );
            this.state.raw_gyroscope_x_characteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleNotificationsRawGyroscopeX
            );
            this.state.raw_gyroscope_y_characteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleNotificationsRawGyroscopeY
            );
            this.state.raw_gyroscope_z_characteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleNotificationsRawGyroscopeZ
            );
        } catch (error) {
            //TODO Philipp Add proper error handling
            console.log("Argh! " + error);
        }
    }

    async kickOffJob(event) {
        event.preventDefault();
        event.stopPropagation();
        let runtimeSeconds = parseInt(this.state.runtimeSeconds);
        let sampleFrequencyHz = parseFloat(this.state.sampleFrequencyHz);
        let accelerometerOdrHz = parseInt(this.state.accelerometerOdrHz);
        let gyroscopeOdrHz = parseInt(this.state.gyroscopeOdrHz);

        if (
            sampleFrequencyHz > accelerometerOdrHz ||
            sampleFrequencyHz > gyroscopeOdrHz
        ) {
            alert(
                "NOT starting job! Sampling frequency must be less than BOTH Accelerometer Output Data Rate (ODR) and Gyroscope ODR!"
            );
            return;
        }

        this.setState({
            packets: {
                raw_accelerometer_x: [],
                raw_accelerometer_y: [],
                raw_accelerometer_z: [],
                raw_gyroscope_x: [],
                raw_gyroscope_y: [],
                raw_gyroscope_z: [],
            },
            channels: {
                raw_accelerometer_x: [],
                raw_accelerometer_y: [],
                raw_accelerometer_z: [],
                raw_gyroscope_x: [],
                raw_gyroscope_y: [],
                raw_gyroscope_z: [],
            },
            runtimeProgress: 0,
            graph_raw_data: false,
        });

        let buffer = new ArrayBuffer(12);
        let dataview = new DataView(buffer, 0);
        dataview.setUint32(0, runtimeSeconds, true);
        dataview.setFloat32(4, sampleFrequencyHz, true);
        dataview.setUint16(8, accelerometerOdrHz, true);
        dataview.setUint16(10, gyroscopeOdrHz, true);
        await this.state.characteristic.writeValueWithResponse(buffer);
    }

    runtimeUpdate(event) {
        this.setState({
            runtimeSeconds: parseInt(event.target.value),
        });
    }

    samplingFrequencyHzUpdate(event) {
        this.setState({
            sampleFrequencyHz: parseFloat(event.target.value),
        });
    }

    accelerometerOdrHzUpdate(event) {
        this.setState({
            accelerometerOdrHz: parseFloat(event.target.value),
        });
    }

    gyroscopeOdrHzUpdate(event) {
        this.setState({
            gyroscopeOdrHz: parseFloat(event.target.value),
        });
    }

    stopProp(event) {
        event.stopPropagation();
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper
                key={this.state.uuid}
                className={classes.accordion_container}
            >
                <div className={classes.accordion_container}>
                    <Accordion
                        className={classes.accordion}
                        expanded={this.state.expanded}
                        onChange={this.accordianToggle}
                    >
                        <AccordionSummary
                            className={classes.space_between}
                            expandIcon={<ExpandMore />}
                            id={this.state.uuid}
                        >
                            <div className={classes.flex_div100}>
                                <Typography
                                    sx={{ width: "30%", flexShrink: 0 }}
                                >
                                    {this.state.name}
                                </Typography>
                                <div className={classes.flex_div}>
                                    <TextField
                                        required
                                        id="runtime-seconds"
                                        value={this.state.runtimeSeconds}
                                        label="Runtime (s)"
                                        variant="filled"
                                        type="number"
                                        onChange={this.runtimeUpdate}
                                        onClick={this.stopProp}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: 100 }}
                                    />
                                    <TextField
                                        required
                                        id="sampling-frequency-hz"
                                        value={this.state.sampleFrequencyHz}
                                        label="Samp. Freq. (Hz)"
                                        variant="filled"
                                        type="number"
                                        onChange={
                                            this.samplingFrequencyHzUpdate
                                        }
                                        onClick={this.stopProp}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: 125 }}
                                    />
                                    <FormControl>
                                        <InputLabel id="accelerometer-odr-hz-label">
                                            Acc. ODR
                                        </InputLabel>
                                        <Select
                                            labelId="accelerometer-odr-hz-label"
                                            id="accelerometer-odr-hz"
                                            value={
                                                this.state.accelerometerOdrHz
                                            }
                                            label="Acc. ODR"
                                            onChange={
                                                this.accelerometerOdrHzUpdate
                                            }
                                            sx={{ width: 150 }}
                                        >
                                            <MenuItem value={26}>
                                                26 Hz
                                            </MenuItem>
                                            <MenuItem value={52}>
                                                52 Hz
                                            </MenuItem>
                                            <MenuItem value={104}>
                                                104 Hz
                                            </MenuItem>
                                            <MenuItem value={208}>
                                                208 Hz
                                            </MenuItem>
                                            <MenuItem value={417}>
                                                417 Hz
                                            </MenuItem>
                                            <MenuItem value={833}>
                                                833 Hz
                                            </MenuItem>
                                            <MenuItem value={1667}>
                                                1667 Hz
                                            </MenuItem>
                                            <MenuItem value={3333}>
                                                3333 Hz
                                            </MenuItem>
                                            <MenuItem value={6667}>
                                                6667 Hz
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel id="gyroscope-odr-hz-label">
                                            Gyr. ODR
                                        </InputLabel>
                                        <Select
                                            labelId="gyroscope-odr-hz-label"
                                            id="gyroscope-odr-hz"
                                            value={this.state.gyroscopeOdrHz}
                                            label="Gyr. ODR"
                                            onChange={this.gyroscopeOdrHzUpdate}
                                            sx={{ width: 150 }}
                                        >
                                            <MenuItem value={26}>
                                                26 Hz
                                            </MenuItem>
                                            <MenuItem value={52}>
                                                52 Hz
                                            </MenuItem>
                                            <MenuItem value={104}>
                                                104 Hz
                                            </MenuItem>
                                            <MenuItem value={208}>
                                                208 Hz
                                            </MenuItem>
                                            <MenuItem value={417}>
                                                417 Hz
                                            </MenuItem>
                                            <MenuItem value={833}>
                                                833 Hz
                                            </MenuItem>
                                            <MenuItem value={1667}>
                                                1667 Hz
                                            </MenuItem>
                                            <MenuItem value={3333}>
                                                3333 Hz
                                            </MenuItem>
                                            <MenuItem value={6667}>
                                                6667 Hz
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControlLabel
                                        label="Graph"
                                        labelPlacement="top"
                                        control={
                                            <Switch
                                                labelId="toggle-raw-data-graph-switch-label"
                                                id="toggle-raw-data-graph-switch"
                                                color="success"
                                                checked={
                                                    this.state.graph_raw_data
                                                }
                                                onChange={(event) => {
                                                    this.setState({
                                                        graph_raw_data:
                                                            event.target
                                                                .checked,
                                                    });
                                                }}
                                            />
                                        }
                                    />
                                </div>
                                <div className={classes.flex_div}>
                                    <Chip
                                        icon={<Sync />}
                                        label="Run Job"
                                        disabled={!this.props.connected}
                                        onClick={this.kickOffJob}
                                        color="success"
                                    />
                                    <Chip
                                        icon={<CloudDownload />}
                                        label="Download"
                                        onClick={this.downloadCallback}
                                        color="success"
                                    />
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <LinearProgress
                                    variant="determinate"
                                    value={this.state.runtimeProgress}
                                />
                                <canvas
                                    id="chartRawAccelerometerRef"
                                    ref={this.chartRawAccelerometerRef}
                                    className={classes.canvas}
                                />
                                <canvas
                                    id="chartRawGyroscopeRef"
                                    ref={this.chartRawGyroscopeRef}
                                    className={classes.canvas}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(BluetoothWaveRawSensorCharacteristic);
