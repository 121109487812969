import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
    Grid,
    Stack,
    Typography,
    Table,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
    Button,
} from "@mui/material";

import CarbonAccountingAPIClient from "../../../models/CarbonAccountingAPIClient";
import {
    ApprovalStatus,
    EstimateContext,
    QuantFormulaVar,
} from "../../../models/enums/InterventionEnums";

const BorderedTableCell = styled(TableCell)(({ theme }) => ({
    border: "1px solid " + theme.palette.tableBorder,
}));

const BorderedTableContainer = styled(TableContainer)(({ theme }) => ({
    border: "1px solid " + theme.palette.tableBorder,
}));

// columnHeaders: Array, each item corresponds to a header
// rows: Array[Array()], content of 2d array maps percisely to table body
const renderTable = (title, columnHeaders, rows, addButton) => (
    <Stack pt={2.5} spacing={1}>
        {addButton ? (
            <Stack variant="splitHeader">
                <Typography variant="body1_bold" display="block" pl={1}>
                    {title}
                </Typography>
                {addButton}
            </Stack>
        ) : (
            <Typography variant="body1_bold" display="block" pl={1}>
                {title}
            </Typography>
        )}
        <BorderedTableContainer component={Paper} variant="outlined">
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {columnHeaders.map((header) => (
                            <BorderedTableCell
                                align="left"
                                key={title + "-" + header}
                                style={{
                                    width: `${100 / columnHeaders.length}%`,
                                }}
                            >
                                {header}
                            </BorderedTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={title + index}>
                            {row.map((cell, col) =>
                                index === 0 ? (
                                    <BorderedTableCell
                                        component="th"
                                        scope="row"
                                        key={title + "-" + index + "x" + col}
                                    >
                                        {cell}
                                    </BorderedTableCell>
                                ) : (
                                    <BorderedTableCell
                                        align="left"
                                        key={title + "-" + index + "x" + col}
                                    >
                                        {cell}
                                    </BorderedTableCell>
                                )
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </BorderedTableContainer>
    </Stack>
);

function DeploymentManifest(props) {
    const {
        substrates,
        buoyData,
        updateEventRecipes,
        deploymentId,
        authState,
    } = props;

    const [recipeBatches, setRecipeBatches] = useState([]);
    const [estimates, setEstimates] = useState([]);

    useEffect(() => {
        let substratesRecipes = new Map();
        const fetchProductGroups = async () => {
            const apiClient = new CarbonAccountingAPIClient(authState);
            const [recipeBatch, estimateObjs] = await Promise.all([
                Promise.all(
                    substrates.map(async (substrate, index) => {
                        const { productGroup } =
                            await apiClient.getProductGroupInfo(
                                substrate.substrateProductGroupId
                            );
                        substratesRecipes.set(substrate.id, productGroup?.name);
                        return [
                            <Link
                                key={productGroup?.name + "-" + index}
                                underline="hover"
                                href={`/product/group/${substrate.substrateProductGroupId}`}
                            >
                                {productGroup?.name}
                            </Link>,
                            substrate.quantity + " t",
                            "",
                            substrate.status,
                        ];
                    })
                ),
                apiClient.getDeploymentEstimates(deploymentId),
            ]);
            const estimates = estimateObjs.map((estimate) => [
                <Link
                    key={estimate.id + "-title"}
                    underline="hover"
                    href={`/cdr/deployments/estimates/${deploymentId}/${estimate.id}`}
                >
                    {estimate.title}
                </Link>,
                Object.keys(EstimateContext).find(
                    (key) => EstimateContext[key] === estimate.context
                ),
                Object.keys(ApprovalStatus).find(
                    (key) => ApprovalStatus[key] === estimate.approvalStatus
                ),
                Object.keys(QuantFormulaVar).find(
                    (key) => QuantFormulaVar[key] === estimate.quantFormulaVar
                ),
                estimate.co2e,
                substrates.find((sub) => sub.id === estimate.carbonBuoyBatchId)
                    ?.title || "N/A",
            ]);
            setEstimates(estimates);
            setRecipeBatches(recipeBatch);
            updateEventRecipes(substratesRecipes);
        };

        fetchProductGroups();
    }, [substrates, authState, updateEventRecipes]);

    const addEstimateButton = (
        <Button
            variant="tertiary"
            href={`/cdr/deployments/${deploymentId}/estimates/create`}
        >
            Add
        </Button>
    );

    return (
        <Grid
            item
            bgcolor="#131313"
            display="flex"
            justifyContent="center"
            xs={120}
        >
            <Grid item xs={114} pb={4.5}>
                <Stack>
                    <Typography variant="h4" fontWeight={700} pl={1}>
                        MANIFEST
                    </Typography>
                </Stack>
                <Stack spacing={2.5}>
                    {renderTable(
                        "CARBON BUOY BATCHES",
                        ["Recipe Name", "Port Weight", "", "Status"],
                        recipeBatches
                    )}
                    {renderTable(
                        "INTERVENTION ESTIMATES",
                        [
                            "Estimate Name",
                            "Context",
                            "Approval Status",
                            "Impact Source",
                            "CO2e",
                            "Buoy Batch",
                        ],
                        estimates,
                        addEstimateButton
                    )}
                    {renderTable(
                        "OCEAN OBSERVATION PLATFORMS",
                        ["Buoy ID", "Locate on Map", "Last Contact", "Status"],
                        buoyData.verification
                    )}
                    {renderTable(
                        "TRAJECTORY SENSORS",
                        [
                            "Buoy Batch ID",
                            "Locate on Map",
                            "Last Contact",
                            "Status",
                        ],
                        buoyData.trajectory
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
}

export default DeploymentManifest;
