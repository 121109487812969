import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import APIClient from "../../models/APIClient";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import { Box, Grid, Typography } from "@mui/material";
import RTable from "../components/RTable";
import MessageHelper from "../helper/MessageHelper";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";

const styles = (theme) => ({
    root: {},
    paper: {
        padding: 10,
    },
    section: {
        marginTop: theme.spacing(5),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paragraph: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    description: {
        paddingLeft: 30,
        paddingRight: 30,
    },
    box: {
        padding: 10,
    },
});

let ThingManager = WithGoogleAuth(
    class ThingManagerions extends Component {
        constructor(props) {
            super(props);
            this.state = {
                observedProperties: [],
                isLoading: true,
            };
        }

        componentDidMount() {
            this.loadState();
        }
        async loadState() {
            let result = await new APIClient(
                    this.props.authState
                ).getAllObservedProperties(),
                observedProperties = await result.json();

            this.setState({ observedProperties: observedProperties });
            this.setState({ isLoading: false });
        }

        render() {
            const { classes } = this.props;
            return (
                <Grid container spacing={3}>
                    <Grid item lg={1} xs={12}>
                        <MessageHelper
                            message={this.state.message}
                            errorMessage={this.state.errorMessage}
                            open={this.state.messageOpen}
                            setState={(a) => this.setState(a)}
                        />
                    </Grid>
                    <Grid item lg={12} xs={12} className={classes.description}>
                        <div className={classes.description}>
                            <Typography className={classes.title} variant="h6">
                                Observed Property
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                                variant="body1"
                            >
                                An Observed Property is a name and description
                                of what is being observed. For example
                                Temperature. Observed Properties are used to
                                create Datastreams. Note that in a collection of
                                Datastreams belonging to a thing the observed
                                property must be unique for each Datastream. For
                                example, if a Thing has two temperature sensors
                                then there needs to be two different Observed
                                Properties for temperature, like &quot;water
                                temperature&quot; and &quot;air
                                temperature&quot;.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Box>
                            <RTable
                                className={classes.table}
                                title={
                                    <Typography variant="h3">
                                        Observed Property Manager
                                    </Typography>
                                }
                                columns={[
                                    {
                                        title: "Name (UNIQUE)*",
                                        field: "name",
                                    },
                                    {
                                        title: "Description *",
                                        field: "description",
                                    },
                                    {
                                        title: "Definition",
                                        field: "definition",
                                    },
                                ]}
                                data={this.state.observedProperties}
                                editable={{
                                    isEditable: () => true,
                                    onRowAddCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowUpdateCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowAdd: (newData) =>
                                        new Promise((resolve, reject) => {
                                            //add observed property
                                            new APIClient(this.props.authState)
                                                .createObservedProperty(newData)
                                                .then(async (res) => {
                                                    if (res.status !== 200) {
                                                        res = await res.json();
                                                        this.setState(
                                                            errorMessage(res)
                                                        );
                                                        reject();
                                                        return;
                                                    }
                                                    //Update State
                                                    res = await res.json();
                                                    newData.id = res.id;
                                                    let observedProperties = [
                                                        ...this.state
                                                            .observedProperties,
                                                    ];
                                                    observedProperties.unshift(
                                                        newData
                                                    );
                                                    this.setState({
                                                        observedProperties,
                                                    });
                                                    this.setState(
                                                        successMessage(
                                                            "Successfully added"
                                                        )
                                                    );
                                                    resolve();
                                                })
                                                .catch((e) => {
                                                    this.setState(
                                                        errorMessage(e)
                                                    );
                                                    reject();
                                                });
                                        }),
                                    onRowUpdate: (newData) =>
                                        new Promise((resolve, reject) => {
                                            //Update everything
                                            function isObservedProperty(
                                                observedProperty
                                            ) {
                                                return (
                                                    observedProperty.id ===
                                                    newData.id
                                                );
                                            }
                                            new APIClient(this.props.authState)
                                                .updateObservedProperty(newData)
                                                .then(async (res) => {
                                                    if (res.status !== 200) {
                                                        res = await res.json();
                                                        this.setState(
                                                            errorMessage(res)
                                                        );
                                                        reject();
                                                        return;
                                                    }
                                                    //Update State
                                                    let observedProperties = [
                                                            ...this.state
                                                                .observedProperties,
                                                        ],
                                                        i =
                                                            observedProperties.findIndex(
                                                                isObservedProperty
                                                            );
                                                    observedProperties[i] =
                                                        newData;
                                                    this.setState({
                                                        observedProperties,
                                                    });
                                                    this.setState(
                                                        successMessage(
                                                            "Successfully updated"
                                                        )
                                                    );
                                                    resolve();
                                                })
                                                .catch((e) => {
                                                    this.setState(
                                                        errorMessage(e)
                                                    );
                                                    reject();
                                                });
                                        }),
                                    onRowDelete: (oldData) =>
                                        new Promise((resolve) => {
                                            new APIClient(this.props.authState)
                                                .removeObservedProperty(oldData)
                                                .then(async (res) => {
                                                    if (res.status !== 200) {
                                                        res = await res.json();
                                                        this.setState(
                                                            errorMessage(res)
                                                        );
                                                        resolve();
                                                        return;
                                                    }
                                                    //Update State
                                                    let observedProperties = [
                                                        ...this.state
                                                            .observedProperties,
                                                    ];
                                                    observedProperties =
                                                        observedProperties.filter(
                                                            (
                                                                observedProperty
                                                            ) =>
                                                                observedProperty.id !==
                                                                oldData.id
                                                        );
                                                    this.setState({
                                                        observedProperties,
                                                    });
                                                    this.setState(
                                                        successMessage(
                                                            "Successfully deleted"
                                                        )
                                                    );
                                                    resolve();
                                                })
                                                .catch((e) => {
                                                    this.setState(
                                                        errorMessage(e)
                                                    );
                                                    resolve();
                                                });
                                        }),
                                }}
                                isLoading={this.state.isLoading}
                                options={{
                                    search: true,
                                    maxColumnSort: 1,
                                    filtering: true,
                                    paging: true,
                                    addRowPosition: "first",
                                }}
                            ></RTable>
                        </Box>
                    </Grid>
                </Grid>
            );
        }
    }
);

export default withStyles(styles)(ThingManager);
