import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import {
    Grid,
    Box,
    Typography,
    FormControl,
    Select,
    MenuItem,
} from "@mui/material";
import RTable from "../components/RTable";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import MessageHelper from "../helper/MessageHelper";
import APIClient from "../../models/APIClient";

const styles = (theme) => ({
    table: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
});

const BuoyFirmwareVersionManager = WithGoogleAuth(
    class BuoyFirmwareVersionManager extends Component {
        constructor(props) {
            super(props);
            this.state = {
                buoyFirmwareVersions: [],
                firmwareVersions: [],
                buoys: [],
                isLoading: true,
            };
        }

        async componentDidMount() {
            let promises = [
                this.getBuoyFirmwareVersions(),
                this.getFirmwareVersions(),
                this.getBuoys(),
            ];
            await Promise.all(promises);
            this.setState({
                isLoading: false,
            });
        }

        async getBuoys() {
            const apiClient = new APIClient(this.props.authState);
            try {
                const result = await apiClient.fetchThingsByThingSelector(
                    "buoy"
                );
                let things = await result.json();
                this.setState({ buoys: things });
            } catch (e) {
                console.error(e);
            }
        }

        async getFirmwareVersions() {
            const apiClient = new APIClient(this.props.authState);
            try {
                const firmwareVersions =
                    (await apiClient.getFirmwareVersions()) || [];
                //convert json to text
                const sanitizedFirmwareVersions = firmwareVersions.map(
                    (firmwareVersion) => {
                        return {
                            ...firmwareVersion,
                            config: JSON.stringify(firmwareVersion.config),
                            metadata: JSON.stringify(firmwareVersion.metadata),
                        };
                    }
                );
                this.setState({
                    firmwareVersions: sanitizedFirmwareVersions,
                });
            } catch (e) {
                console.error(e);
            }
        }

        async getBuoyFirmwareVersions() {
            const apiClient = new APIClient(this.props.authState);
            try {
                const result = await apiClient.getBuoyFirmwareVersions();
                const buoyFirmwareVersions = await result.json();
                this.setState({
                    buoyFirmwareVersions: buoyFirmwareVersions,
                });
            } catch (e) {
                console.error(e);
            }
        }

        onRowUpdate = (newData) =>
            new Promise((resolve, reject) => {
                const newDataUpdate = { ...newData };

                function isSelectorId(selectorId) {
                    return selectorId.id === newData.id;
                }
                new APIClient(this.props.authState)
                    .updateBuoyFirmwareVersion({
                        id: newDataUpdate.id,
                        version_id: newDataUpdate.firmware_version,
                        thing_id: newDataUpdate.buoy,
                    })
                    .then(async (res) => {
                        if (res.status !== 200) {
                            res = await res.json();
                            this.setState(errorMessage(res));
                            reject();
                            return;
                        }
                        //Update State
                        const buoyFirmwareVersions = [
                                ...this.state.buoyFirmwareVersions,
                            ],
                            i = buoyFirmwareVersions.findIndex(isSelectorId);
                        buoyFirmwareVersions[i] = newData;
                        this.setState({ buoyFirmwareVersions });
                        this.setState(successMessage("Successfully updated"));
                        resolve();
                    })
                    .catch((e) => {
                        this.setState(errorMessage(e));
                        reject();
                    });
            });

        onRowAdd = (newData) =>
            new Promise((resolve, reject) => {
                const newDataUpdate = { ...newData };

                new APIClient(this.props.authState)
                    .createBuoyFirmwareVersion({
                        version_id: newDataUpdate.firmware_version,
                        thing_id: newDataUpdate.buoy,
                    })
                    .then(async (res) => {
                        if (res.status !== 200) {
                            res = await res.json();
                            this.setState(errorMessage(res));
                            reject();
                            return;
                        }
                        res = await res.json();
                        newData.id = res.id;
                        const buoyFirmwareVersions = [
                            ...this.state.buoyFirmwareVersions,
                        ];
                        buoyFirmwareVersions.unshift(newData);
                        this.setState({ buoyFirmwareVersions });
                        this.setState(successMessage("Successfully added"));
                        resolve();
                    })
                    .catch((e) => {
                        this.setState(errorMessage(e));
                        reject();
                    });
            });

        onRowDelete = (oldData) =>
            new Promise((resolve) => {
                new APIClient(this.props.authState)
                    .deleteBuoyFirmwareVersion(oldData)
                    .then(async (res) => {
                        if (res.status !== 200) {
                            res = await res.json();
                            this.setState(errorMessage(res));
                            resolve();
                            return;
                        }
                        //Update State
                        res = await res.json();
                        let buoyFirmwareVersions = [
                            ...this.state.buoyFirmwareVersions,
                        ];
                        buoyFirmwareVersions = buoyFirmwareVersions.filter(
                            (version) => version.id !== oldData.id
                        );
                        this.setState({ buoyFirmwareVersions });
                        this.setState(successMessage("Successfully deleted"));
                        resolve();
                    })
                    .catch((e) => {
                        this.setState(errorMessage(e));
                        resolve();
                    });
            });

        render() {
            const { classes } = this.props;

            return (
                <Grid>
                    <Grid item lg={1} xs={12}>
                        <MessageHelper
                            message={this.state.message}
                            errorMessage={this.state.errorMessage}
                            open={this.state.messageOpen}
                            setState={(a) => this.setState(a)}
                        />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Box>
                            <RTable
                                className={classes.table}
                                title={
                                    <Typography variant="h3">
                                        Buoy Metadata Manager
                                    </Typography>
                                }
                                columns={[
                                    {
                                        title: "Firmware Version",
                                        field: "firmware_version",
                                        editComponent: (props) => (
                                            <FormControl fullWidth>
                                                <Select
                                                    value={props.value}
                                                    defaultValue={props.value}
                                                    onChange={(e) => {
                                                        props.onChange(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    {this.state.firmwareVersions.map(
                                                        (firmwareVersion) => {
                                                            return (
                                                                <MenuItem
                                                                    key={
                                                                        firmwareVersion.id
                                                                    }
                                                                    value={
                                                                        firmwareVersion.id
                                                                    }
                                                                >
                                                                    {
                                                                        firmwareVersion.version
                                                                    }{" "}
                                                                    -{" "}
                                                                    {
                                                                        firmwareVersion.description
                                                                    }
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </FormControl>
                                        ),
                                        render: (rowData) => {
                                            return (
                                                <div>
                                                    {(() => {
                                                        let version =
                                                            this.state.firmwareVersions.filter(
                                                                (
                                                                    firmwareVersion
                                                                ) =>
                                                                    firmwareVersion.id ==
                                                                    rowData.firmware_version
                                                            )?.[0];
                                                        return `${version?.version} - ${version?.description}`;
                                                    })()}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        title: "Buoy",
                                        field: "buoy",
                                        editComponent: (props) => (
                                            <FormControl fullWidth>
                                                <Select
                                                    value={props.value}
                                                    defaultValue={props.value}
                                                    onChange={(e) => {
                                                        props.onChange(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    {this.state.buoys.map(
                                                        (buoy) => {
                                                            return (
                                                                <MenuItem
                                                                    key={
                                                                        buoy.id
                                                                    }
                                                                    value={
                                                                        buoy.id
                                                                    }
                                                                >
                                                                    {buoy.name}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </FormControl>
                                        ),
                                        render: (rowData) => {
                                            return (
                                                <div>
                                                    {
                                                        this.state.buoys.filter(
                                                            (buoy) =>
                                                                buoy.id ==
                                                                rowData.buoy
                                                        )?.[0]?.name
                                                    }
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                data={this.state.buoyFirmwareVersions}
                                editable={{
                                    isEditable: () => true,
                                    onRowAddCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowUpdateCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowAdd: (newData) =>
                                        this.onRowAdd(newData),
                                    onRowUpdate: (newData, oldData) =>
                                        this.onRowUpdate(newData, oldData),
                                    // edit all rows at once
                                    onBulkUpdate: (changes) =>
                                        Promise.all(
                                            Object.values(changes).map(
                                                ({ newData, oldData }) =>
                                                    this.onRowUpdate(
                                                        newData,
                                                        oldData
                                                    )
                                            )
                                        ),
                                    onRowDelete: (oldData) =>
                                        this.onRowDelete(oldData),
                                }}
                                isLoading={this.state.isLoading}
                                options={{
                                    search: true,
                                    maxColumnSort: 1,
                                    filtering: true,
                                    paging: true,
                                    // always display the newly-added row at the beginning of the table
                                    addRowPosition: "first",
                                }}
                            ></RTable>
                        </Box>
                    </Grid>
                </Grid>
            );
        }
    }
);

export default withStyles(styles)(BuoyFirmwareVersionManager);
