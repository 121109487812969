import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormGroup,
    Typography,
    Box,
    Divider,
} from "@mui/material";
import { Fragment } from "react";
import ShellfishInventoryAPIClient from "../../models/ShellfishInventoryAPIClient";
import RTDisplayUtils from "@running-tide/rt-frontend-helpers/helpers";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";

const styles = (theme) => ({
    section: {
        marginTop: theme.spacing(3),
    },
    formControl: {
        minWidth: 120,
        marginRight: theme.spacing(2),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: -theme.spacing(2),
    },
    dividerContainers: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        background: theme.palette.divider,
    },
    containerProperty: {
        fontWeight: 550,
        textTransform: "capitalize",
    },
    containerName: {
        fontWeight: "bold",
        textTransform: "capitalize",
    },
});

let ECBSF = WithGoogleAuth(
    class ExternalContainerAndBinSelectionForm extends Component {
        constructor(props) {
            super(props);
            this.initialState = {
                data: [],
                selectedExternalContainerIds: [],
                selectedFarmId: "4cabc6a0-ca25-11eb-b8bc-0242ac130003",
                selectedReefId: "",
                selectedBinId: "",
                errors: props.errors,
                selectedShellfishHeldExternalContainers: [],
            };
            this.state = this.initialState;
            this.onFarmSelected = this.onFarmSelected.bind(this);
            this.onReefSelected = this.onReefSelected.bind(this);
            this.onBinSelected = this.onBinSelected.bind(this);
            this.onExternalContainerSelected =
                this.onExternalContainerSelected.bind(this);
        }

        componentDidMount() {
            // get farms
            new ShellfishInventoryAPIClient(this.props.authState)
                .getFarmSystem()
                .then((farms) => {
                    this.setState({ farms: farms });
                    return farms;
                });
            // get all containers that contain graduate oysters and make it a map
            new ShellfishInventoryAPIClient(this.props.authState)
                .getShellfishHeldExternalContainersWithNoReallocation()
                .then((shellfishHeldExternalContainers) => {
                    let mapShellfishHeldExternalContainers =
                        shellfishHeldExternalContainers.reduce(function (
                            map,
                            obj
                        ) {
                            map[obj.id] = obj;
                            return map;
                        },
                        {});
                    return [
                        mapShellfishHeldExternalContainers,
                        shellfishHeldExternalContainers,
                    ];
                })
                .then((data) => {
                    let mapShellfishHeldExternalContainers = data[0];
                    let shellfishHeldExternalContainers = data[1];
                    // get all containers (id, name)
                    new ShellfishInventoryAPIClient(this.props.authState)
                        .getAllExternalContainers()
                        .then((externalContainers) => {
                            let mapContainers = externalContainers.reduce(
                                function (map, obj) {
                                    map[obj.externalContainerId] = obj;
                                    return map;
                                },
                                {}
                            );
                            for (const [key] of Object.entries(
                                mapShellfishHeldExternalContainers
                            )) {
                                if (
                                    mapShellfishHeldExternalContainers[key][
                                        "externalContainerId"
                                    ] in mapContainers
                                ) {
                                    mapShellfishHeldExternalContainers[key][
                                        "externalContainerName"
                                    ] =
                                        mapContainers[
                                            mapShellfishHeldExternalContainers[
                                                key
                                            ]["externalContainerId"]
                                        ].externalContainerName;
                                }
                            }
                            this.setState({
                                shellfishHeldExternalContainers:
                                    shellfishHeldExternalContainers,
                                mapShellfishHeldExternalContainers:
                                    mapShellfishHeldExternalContainers,
                            });
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        /**
         * Add graduate held containers to the array in state based on user selection in drop valuee
         * @param {String} value
         */
        updateShellfishHeldExternalContainers(value) {
            let temp_arr = [];
            value.forEach((item) => {
                if (item in this.state.mapShellfishHeldExternalContainers) {
                    temp_arr.push(
                        this.state.mapShellfishHeldExternalContainers[item]
                    );
                }
            });
            this.setState({
                selectedShellfishHeldExternalContainers: temp_arr,
            });
            if (
                this.props.updateShellfishHeldExternalContainers !== undefined
            ) {
                this.props.updateShellfishHeldExternalContainers(temp_arr);
            }
        }

        getExternalContainerForm() {
            return (
                <Select
                    labelId="demo-simple-select-label"
                    id="external-container"
                    multiple
                    value={this.state.selectedExternalContainerIds}
                    onChange={this.onExternalContainerSelected}
                    error={this.state.errors.externalContainer}
                >
                    {this.externalContainerOptions()}
                </Select>
            );
        }

        clear() {
            this.setState(this.initialState);
        }

        onFarmSelected = (event) => {
            this.setState({ selectedFarmId: event.target.value });
            if (this.props.onFarmSelected !== undefined) {
                this.props.onFarmSelected(event.target.value);
            }
            this.onReefSelected({ target: { value: "" } });
        };

        onExternalContainerSelected = (event) => {
            this.setState({ selectedExternalContainerIds: event.target.value });
            this.updateShellfishHeldExternalContainers(event.target.value);
        };

        onReefSelected = (event) => {
            this.setState({ selectedReefId: event.target.value });
            this.onBinSelected({ target: { value: "" } });
            if (this.props.onReefSelected !== undefined) {
                this.props.onReefSelected(event.target.value);
            }
        };

        onBinSelected = (event) => {
            this.setState({ selectedBinId: event.target.value });
            if (this.props.onBinSelected !== undefined) {
                this.props.onBinSelected(event.target.value);
            }
        };

        getSelectedFarm() {
            if (this.state.farms == null) return null;

            for (var i = 0; i < this.state.farms.length; i++) {
                if (this.state.selectedFarmId === this.state.farms[i].id)
                    return this.state.farms[i];
            }
            return null;
        }

        farmOptions() {
            if (this.state.farms == null) return;
            return this.state.farms.map((farm) => (
                <MenuItem key={farm.id} value={farm.id}>
                    {farm.name}
                </MenuItem>
            ));
        }

        getSelectedReef() {
            let farm = this.getSelectedFarm();
            if (farm == null) return null;

            for (var i = 0; i < farm.reefs.length; i++) {
                if (this.state.selectedReefId === farm.reefs[i].id)
                    return farm.reefs[i];
            }
            return null;
        }

        externalContainerOptions() {
            if (this.state.shellfishHeldExternalContainers == null) return;
            return this.state.shellfishHeldExternalContainers.map(
                (container) => (
                    <MenuItem key={container.id} value={container.id}>
                        {container.externalContainerName}
                    </MenuItem>
                )
            );
        }

        reefOptions() {
            //Check if it's a new row
            let farm = this.getSelectedFarm();
            if (farm == null) return;
            return farm.reefs.map((reef) => (
                <MenuItem key={reef.id} value={reef.id}>
                    {reef.name}
                </MenuItem>
            ));
        }

        binOptions() {
            let reef = this.getSelectedReef();
            if (reef == null) return;
            return reef.bins.map((bin) => (
                <MenuItem key={bin.id} value={bin.id}>
                    {bin.name}
                </MenuItem>
            ));
        }

        render() {
            const { classes } = this.props;
            return (
                <Box className={classes.section}>
                    <Typography variant="overline" color="textPrimary">
                        {this.props.title != null
                            ? this.props.title
                            : "Select Bag, Reef and Bin"}
                    </Typography>
                    <Divider className={classes.divider}></Divider>
                    <Typography variant="body" color="textPrimary">
                        {this.props.description ?? ""}
                    </Typography>
                    <FormGroup row={true}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                Bag
                            </InputLabel>
                            {this.getExternalContainerForm()}
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                Farm
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="farm"
                                value={this.state.selectedFarmId}
                                onChange={this.onFarmSelected}
                                error={this.state.errors.farm}
                            >
                                {this.farmOptions()}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                Reef
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="reef"
                                value={this.state.selectedReefId}
                                onChange={this.onReefSelected}
                                error={this.state.errors.reef}
                            >
                                {this.reefOptions()}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                Bin
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="bin"
                                value={this.state.selectedBinId}
                                onChange={this.onBinSelected}
                                error={this.state.errors.bin}
                            >
                                {this.binOptions()}
                            </Select>
                        </FormControl>
                    </FormGroup>
                    <Box className={classes.section} />
                    <Fragment>
                        <table key="table">
                            <tbody key="table-body">
                                {this.state.selectedShellfishHeldExternalContainers.map(
                                    (item, i) => {
                                        return (
                                            <tr key={"row" + i}>
                                                <td>
                                                    <Typography
                                                        className={
                                                            classes.containerName
                                                        }
                                                        variant="h6"
                                                    >
                                                        {
                                                            item.externalContainerName
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.containerProperty
                                                        }
                                                        display="inline"
                                                    >
                                                        {"Date Bagged: "}{" "}
                                                    </Typography>
                                                    <Typography display="inline">
                                                        {" "}
                                                        {RTDisplayUtils.printDate(
                                                            item.dateBagged
                                                        )}{" "}
                                                    </Typography>
                                                    <Typography />
                                                    <Typography
                                                        className={
                                                            classes.containerProperty
                                                        }
                                                        display="inline"
                                                    >
                                                        {"Grade: "}{" "}
                                                    </Typography>
                                                    <Typography display="inline">
                                                        {" "}
                                                        {item.grade}{" "}
                                                    </Typography>
                                                    <Typography />
                                                    <Typography
                                                        className={
                                                            classes.containerProperty
                                                        }
                                                        display="inline"
                                                    >
                                                        {
                                                            "Graduate Oyster Count: "
                                                        }{" "}
                                                    </Typography>
                                                    <Typography display="inline">
                                                        {" "}
                                                        {
                                                            item.shellfishCount
                                                        }{" "}
                                                    </Typography>
                                                    <Typography />
                                                    <Typography
                                                        className={
                                                            classes.containerProperty
                                                        }
                                                        display="inline"
                                                    >
                                                        {"Container ID: "}{" "}
                                                    </Typography>
                                                    <Typography display="inline">
                                                        {" "}
                                                        {
                                                            item.externalContainerId
                                                        }{" "}
                                                    </Typography>
                                                    <Typography />
                                                    <Typography
                                                        className={
                                                            classes.containerProperty
                                                        }
                                                        display="inline"
                                                    >
                                                        {"Photo Session ID: "}{" "}
                                                    </Typography>
                                                    <Typography display="inline">
                                                        {" "}
                                                        {item.photoSessionId}
                                                    </Typography>
                                                    <Typography />
                                                    <Typography
                                                        className={
                                                            classes.containerProperty
                                                        }
                                                        display="inline"
                                                    >
                                                        {"Oyster Count ID: "}{" "}
                                                    </Typography>
                                                    <Typography display="inline">
                                                        {" "}
                                                        {item.oysterCountId}
                                                    </Typography>
                                                    <Divider
                                                        className={
                                                            classes.dividerContainers
                                                        }
                                                        sx={{
                                                            borderBottomWidth: 5,
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </Fragment>
                </Box>
            );
        }
    }
);

export default withStyles(styles)(ECBSF);
