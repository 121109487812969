import { React, Component } from "react";
import { SyncAlt } from "@mui/icons-material";
import { Chip } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import moment from "moment-timezone";

const styles = (theme) => ({
    disabledButton: {
        color: theme.palette.darkUtility.main,
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});

class BluetoothRtcSyncButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: props.uuid,
            name: props.name,
            characteristic: props.characteristic,
        };
    }

    async syncDate(event, characteristic) {
        event.preventDefault();
        event.stopPropagation();
        // eslint does not recognize BigInts so we must disable to use
        /* eslint-disable */
        let dateInMs = BigInt(moment.now());
        let buffer = new ArrayBuffer(8);
        let dataview = new DataView(buffer, 0);
        dataview.setBigInt64(0, dateInMs, true);
        if (characteristic !== undefined) {
            await characteristic.writeValueWithResponse(buffer);
        }
        console.log("synced RTC");
    }

    render() {
        return (
            <Chip
                icon={<SyncAlt />}
                label="Sync RTC"
                disabled={!this.props.connected}
                onClick={async (event) => {
                    await this.syncDate(event, this.state.characteristic);
                }}
                color="success"
            />
        );
    }
}

export default withStyles(styles)(BluetoothRtcSyncButton);
