import React, { Component } from "react";
import { useParams } from "react-router-dom";
import { Grid, Stack, Link } from "@mui/material";
import RTable from "../components/RTable";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";

import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";

const styles = (theme) => ({
    title: {
        marginBottom: theme.spacing(2),
    },
});

class ProductGroupDetails extends Component {
    state = {
        id: "",
        name: "",
        description: "",
        products: [],
        loading: true,
    };

    async componentDidMount() {
        // Fetch information associated with this product group
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);

        const {
            productGroup: { id, name, description },
            productInstances,
        } = await apiClient.getProductGroup(
            this.props.params.product_group_id,
            true
        );

        this.setState({
            id,
            name,
            description,
            products: productInstances,
            loading: false,
        });
    }

    render() {
        return (
            <Grid container rowSpacing={2}>
                <Grid item xs={9}>
                    <Stack>
                        {this.state.loading && (
                            <Typography
                                variant="h3"
                                className={this.props.classes.title}
                            >
                                Loading
                            </Typography>
                        )}
                        <Typography
                            variant="h3"
                            className={this.props.classes.title}
                        >
                            {`${this.state.name}`}
                        </Typography>
                        <Typography variant="body1">
                            {this.state.description}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <RTable
                        title={
                            <Typography variant="h3">
                                Product Instances
                            </Typography>
                        }
                        columns={[
                            {
                                render: (rowData) => (
                                    <Link href={`/product/${rowData.id}`}>
                                        Product Instance
                                    </Link>
                                ),
                            },
                            { title: "Creation Date", field: "creationDate" },
                            { title: "Batch Index", field: "batchIndex" },
                            { title: "Index", field: "index" },
                        ]}
                        data={this.state.products}
                        options={{
                            paging: this.state.products.length > 10,
                            search: true,
                            actionsColumnIndex: -1,
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(
    WithGoogleAuth((props) => (
        <ProductGroupDetails {...props} params={useParams()} />
    ))
);
