// in src/theme/muiTheme.js
import { createTheme, alpha } from "@mui/material/styles";

// import { Link } from '@mui/material';

const Theme = (mode) =>
    createTheme({
        palette: {
            mode,
            ...(mode === "light"
                ? {
                      text: {
                          primary: "#222222",
                      },
                      input: {
                          primary: "#000000",
                          background: "#FFFFFF",
                      },
                      primaryBackground: "#E5E5E5",
                      navListBorder: "#FFFFFF",
                      navListSelected: "#BBFF00",
                      iconBackground: "#999999",
                      altRow: "#DDDDDD",
                      tableBorder: "#000000",
                  }
                : {
                      primary: {
                          // light: will be calculated from palette.primary.main,
                          main: "#BBFF00",

                          // dark: will be calculated from palette.primary.main,
                          // contrastText: will be calculated to contrast with palette.primary.main
                      },
                      secondary: {
                          main: "#F37E7E",
                      },
                      background: {
                          paper: "#000000",
                          component: "#000000",
                          mapOverlay: "rgba(0, 0, 0, 0.5)",
                          default: "#000000",
                      },
                      text: {
                          primary: "#FFFFFF",
                      },
                      action: {
                          active: "#FFFFFF",
                      },
                      error: {
                          main: "#e57373",
                          backgroundColor: "#E57373",
                          borderColor: "#d93232",
                      },
                      success: {
                          main: "#73e573",
                          backgroundColor: "#73e573",
                          borderColor: "#32d932",
                      },
                      warning: {
                          main: "#e9e989",
                          backgroundColor: "#e9e989",
                          borderColor: "#e1e15e",
                      },
                      darkUtility: {
                          main: "#222222",
                          contrastText: "#FFFFFF",
                      },
                      primaryUtility: {
                          main: "#444444",
                          contrastText: "#FFFFFF",
                      },
                      secondaryUtility: {
                          main: "#999999",
                          contrastText: "#FFFFFF",
                      },
                      tertiaryUtility: {
                          main: "#DDDDDD",
                          contrastText: "#000000",
                      },
                      quaternaryUtility: {
                          main: "#EEEEEE",
                          contrastText: "#000000",
                      },
                      progressChip: {
                          main: "#F178B6",
                          contrastText: "#000000",
                      },
                      completedChip: {
                          main: "#2776ED",
                          contrastText: "#FFFFFF",
                      },

                      // Used by `getContrastText()` to maximize the contrast between
                      // the background and the text.
                      contrastThreshold: 3,
                      // Used by the functions below to shift a color's luminance by approximately
                      // two indexes within its tonal palette.
                      // E.g., shift from Red 500 to Red 300 or Red 700.
                      tonalOffset: 0.2,
                      input: {
                          primary: "#000000",
                          background: "#FFFFFF",
                      },
                      divider: "#444444",
                      header: "#DDDDDD",
                      primaryBackground: "#000000",
                      secondaryBackground: "#FFFFFF",
                      navListBorder: "#333333",
                      navListSelected: "#222222",
                      iconBackground: "#000000",
                      altRow: "#0D0D0D",
                      tableBorder: "#999999",
                  }),
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                mdsm: 750,
                md: 900,
                lg: 1200,
                lgr: 1350,
                xl: 1536,
            },
        },
        typography: {
            fontFamily: "Helvetica Now Display",
            h1: {
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "4rem",
                lineHeight: 1,
            },
            h2: {
                fontStyle: "normal",
                fontWeight: 325,
                fontSize: "2.5rem",
                lineHeight: 1,
            },
            h3: {
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "1.5rem",
            },
            h4: {
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "1.25rem",
                lineHeight: 1.2,
            },
            h4_bold: {
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "1.25rem",
                lineHeight: 1.2,
            },
            body1: {
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "1rem",
                lineHeight: 1.5,
            },
            body1_bold: {
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "1rem",
                lineHeight: 1.5,
            },
            body2: {
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "0.875rem",
                lineHeight: 1.57,
            },
            body2_bold: {
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "0.875rem",
                lineHeight: 1.57,
            },
            microtext: {
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "0.625rem",
                lineHeight: 2.4,
            },
            microtext_bold: {
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "0.625rem",
                lineHeight: 2.4,
            },
        },
        components: {
            MuiAutocomplete: {
                styleOverrides: {
                    paper: ({ theme }) => ({
                        backgroundColor: theme.palette.input.background,
                    }),
                    option: ({ theme }) => ({
                        color: theme.palette.input.primary,
                        "&.Mui-focused": {
                            backgroundColor:
                                "rgba(187, 255, 0, 0.3) !important",
                        },
                        "&.Mui-selected": {
                            backgroundColor: "rgba(0, 0, 0, 0.4) !important",
                        },
                    }),
                    tag: ({ theme }) => ({
                        color: theme.palette.input.primary,
                        backgroundColor: alpha(theme.palette.primary.main, 0.8),
                    }),
                    clearIndicator: ({ theme }) => ({
                        color: theme.palette.secondaryUtility.main,
                    }),
                    popupIndicator: ({ theme }) => ({
                        color: theme.palette.secondaryUtility.main,
                    }),
                },
            },
            MuiBackdrop: {
                variants: [
                    // Note: In order for proper behavior when using this variant, be sure to wrap your
                    // backdrop & desired component to cover in a container with style position: "relative"
                    {
                        props: { variant: "limited" },
                        style: () => ({
                            position: "absolute",
                            zIndex: 1,
                        }),
                    },
                ],
            },
            MuiButton: {
                variants: [
                    {
                        props: { variant: "primary" },
                        style: ({ theme }) => ({
                            padding: "8px 22px",
                            fontWeight: 700,
                            fontSize: "1.5rem",
                            lineHeight: 1,
                            height: "4rem",
                            borderRadius: "1rem",
                            color: theme.palette.primaryBackground,
                            backgroundColor: theme.palette.primary.main,
                            "&:hover": {
                                backgroundColor: theme.palette.primary.dark,
                                // Reset on touch devices, it doesn't add specificity
                                "@media (hover: none)": {
                                    backgroundColor: theme.palette.primary.main,
                                },
                                "&.Mui-disabled": {
                                    backgroundColor:
                                        theme.palette.action.disabledBackground,
                                },
                            },
                            "&.Mui-focusVisible": {
                                boxShadow: theme.shadows[6],
                            },
                            "&.Mui-active": {
                                boxShadow: theme.shadows[8],
                            },
                            "&.Mui-disabled": {
                                color: theme.palette.action.disabled,
                                boxShadow: theme.shadows[0],
                                backgroundColor:
                                    theme.palette.action.disabledBackground,
                            },
                            "&:before": {
                                fontFamily: "Material Icons",
                                content: '"\\e5c8"',
                                display: "inherit",
                                marginRight: 8,
                                marginLeft: -4,
                                fontWeight: 100,
                                fontSize: "1.5rem",
                            },
                        }),
                    },
                    {
                        props: { variant: "secondary" },
                        style: ({ theme }) => ({
                            color: theme.palette.primaryBackground,
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: ".75rem",
                            height: "2.5rem",
                            fontWeight: 400,
                            fontSize: "1rem",
                            lineHeight: 1.5,
                            "&:hover": {
                                backgroundColor: theme.palette.primary.dark,
                                // Reset on touch devices, it doesn't add specificity
                                "@media (hover: none)": {
                                    backgroundColor: theme.palette.primary.main,
                                },
                                "&.Mui-disabled": {
                                    backgroundColor:
                                        theme.palette.action.disabledBackground,
                                },
                            },
                            "&.Mui-focusVisible": {
                                boxShadow: theme.shadows[6],
                            },
                            "&.Mui-active": {
                                boxShadow: theme.shadows[8],
                            },
                            "&.Mui-disabled": {
                                color: theme.palette.action.disabled,
                                boxShadow: theme.shadows[0],
                                backgroundColor:
                                    theme.palette.action.disabledBackground,
                            },
                        }),
                    },
                    {
                        props: { variant: "tertiary" },
                        style: ({ theme }) => ({
                            color:
                                theme.palette.mode === "light"
                                    ? theme.palette.primaryUtility.main
                                    : theme.palette.secondaryBackground,
                            padding: "5px 15px",
                            border: `1px solid ${
                                theme.palette.mode === "light"
                                    ? alpha(
                                          theme.palette.primaryUtility.main,
                                          0.5
                                      )
                                    : alpha(
                                          theme.palette.secondaryBackground,
                                          0.5
                                      )
                            }`,
                            borderRadius: ".75rem",
                            height: "2.5rem",
                            fontWeight: 400,
                            fontSize: ".875rem",
                            lineHeight: 1.57,
                            "&.Mui-disabled": {
                                border: `1px solid ${theme.palette.action.disabledBackground}`,
                            },
                            "&:hover": {
                                border: `1px solid ${
                                    theme.palette.mode === "light"
                                        ? theme.palette.primaryUtility.main
                                        : theme.palette.secondaryBackground
                                }`,
                                backgroundColor: alpha(
                                    theme.palette.mode === "light"
                                        ? theme.palette.primaryUtility.main
                                        : theme.palette.secondaryBackground,
                                    theme.palette.action.hoverOpacity
                                ),
                                // Reset on touch devices, it doesn't add specificity
                                "@media (hover: none)": {
                                    backgroundColor: "transparent",
                                },
                            },
                            "&:before": {
                                fontFamily: "Material Icons",
                                content: '"\\e5c8"',
                                display: "inherit",
                                marginRight: 8,
                                marginLeft: -4,
                                fontSize: "1rem",
                            },
                        }),
                    },
                ],
            },
            MuiChip: {
                styleOverrides: {
                    deleteIcon: {
                        color: "rgba(0, 0, 0, 0.6) !important",
                    },
                },
            },
            MuiFormControl: {
                defaultProps: {
                    margin: "normal",
                },
                styleOverrides: {
                    marginNormal: {
                        marginTop: "1.5rem",
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        "&.Mui-selected": {
                            backgroundColor: theme.palette.primary.main,
                            fontWeight: "bold",
                            color: theme.palette.primaryUtility.main,
                        },
                        backgroundColor: theme.palette.tertiaryUtility.main,
                        color: theme.palette.primaryUtility.main,
                        borderLeft: `1px solid ${theme.palette.primaryBackground}`,
                        borderRight: `1px solid ${theme.palette.primaryBackground}`,
                        height: "2.5rem",
                        minHeight: "2.5rem",
                        padding: ".6875rem 3rem",
                    }),
                },
                variants: [
                    {
                        props: { variant: "leftmost" },
                        style: () => ({
                            borderRadius: "0.5rem 0rem 0rem 0.5rem",
                        }),
                    },
                    {
                        props: { variant: "rightmost" },
                        style: () => ({
                            borderRadius: "0rem 0.5rem 0.5rem 0rem",
                        }),
                    },
                    {
                        props: { variant: "single" },
                        style: () => ({
                            borderRadius: "0.5rem 0.5rem 0.5rem 0.5rem",
                        }),
                    },
                ],
            },
            MuiTabs: {
                defaultProps: {
                    TabIndicatorProps: {
                        style: {
                            display: "none",
                        },
                    },
                    variant: "scrollable",
                    scrollButtons: "auto",
                },
                styleOverrides: {
                    root: {
                        height: "2.5rem",
                        minHeight: "2.5rem",
                        marginBottom: "1rem",
                    },
                },
            },
            MuiTablePagination: {
                styleOverrides: {
                    select: {
                        color: "#000000",
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    head: {
                        textTransform: "uppercase",
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderStyle: "none",
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        ...(theme.palette.mode === "dark" && {
                            backgroundImage: "none",
                        }),
                    }),
                },
            },
            MuiMenuItem: {
                defaultProps: {
                    divider: true,
                },
                styleOverrides: {
                    root: {
                        "&.Mui-selected": {
                            backgroundColor: "rgba(0, 0, 0, 0.4)",
                        },
                        "&:hover": {
                            backgroundColor: "rgba(187, 255, 0, 0.3)",
                        },
                    },
                    divider: {
                        color: "#000000",
                        borderColor: "transparent",
                        "&:after": {
                            content: '""',
                            position: "absolute",
                            top: 0,
                            left: "5%",
                            borderBottom: "1px solid #DDDDDD",
                            width: "95%",
                        },
                    },
                },
            },
            MuiListItemButton: {
                styleOverrides: {
                    divider: ({ theme }) => ({
                        borderBottom: `1px solid ${theme.palette.navListBorder}`,
                    }),
                    root: ({ theme }) => ({
                        "&.Mui-selected": {
                            backgroundColor: theme.palette.navListSelected,
                        },
                    }),
                },
            },
            MuiInputBase: {
                defaultProps: {
                    size: "small",
                },
                styleOverrides: {
                    root: ({ theme }) => ({
                        color: theme.palette.input.primary,
                        background: theme.palette.input.background,
                    }),
                    sizeSmall: {
                        height: "2.5rem",
                        minHeight: "2.5rem",
                    },
                    multiline: {
                        height: "auto",
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        "&:after": {
                            borderBottomStyle: "none",
                        },
                        // To keep our MT search clean on hover
                        "&:hover, &:not(&.Mui-disabled)": {
                            "&:before": {
                                borderBottomStyle: "none",
                            },
                        },
                        border: `1px solid ${theme.palette.secondaryUtility.main}`,
                        borderRadius: ".5rem",
                    }),
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    input: ({ theme }) => ({
                        "&:-webkit-autofill": {
                            WebkitBoxShadow:
                                "0 0 0 100px rgba(187, 255, 0, 0.2) inset",
                            WebkitTextFillColor: theme.palette.input.primary,
                        },
                    }),
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        color: theme.palette.input.primary,
                        lineHeight: "1.5rem",
                    }),
                    formControl: {
                        // Manual reposition of the label required after downsizing
                        // the InputBase
                        transform: "translate(.75rem, .5rem) scale(1)",
                        transformOrigin: "top left",
                    },
                    // The input labels are hard to read when they're on the
                    // border of the input box. This keeps the label inside
                    // to start but styles it as an overhead after the input
                    // has been filled out
                    shrink: ({ theme }) => ({
                        "&.Mui-focused": {
                            transform: "translate(0, -2.5rem) scale(0.75)",
                            transformOrigin: "top left",
                            color: theme.palette.header,
                            textTransform: "uppercase",
                            marginTop: "1.25rem",
                        },
                        transform: "translate(0, -2.5rem) scale(0.75)",
                        transformOrigin: "top left",
                        color: theme.palette.header,
                        textTransform: "uppercase",
                        marginTop: "1.25rem",
                    }),
                },
                variants: [
                    {
                        props: { variant: "overhead" },
                        style: ({ theme }) => ({
                            transform: "translate(0, -3rem) scale(1)",
                            transformOrigin: "top left",
                            color: theme.palette.header,
                            textTransform: "uppercase",
                            marginTop: "1.25rem",
                        }),
                    },
                ],
            },
            MuiInputAdornment: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        color: theme.palette.secondaryUtility.main,
                        "& .MuiTypography-body1": {
                            color: theme.palette.secondaryUtility.main,
                        },
                    }),
                },
            },
            MuiSelect: {
                styleOverrides: {
                    icon: ({ theme }) => ({
                        color: theme.palette.input.primary,
                    }),
                },
            },
            MuiMenu: {
                styleOverrides: {
                    list: ({ theme }) => ({
                        background: theme.palette.input.background,
                        color: theme.palette.input.primary,
                    }),
                    icon: ({ theme }) => ({
                        color: theme.palette.input.primary,
                    }),
                },
            },
            MuiPopover: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        // TODO(hannah): Find a better way to keep checkboxes
                        // visible.
                        "& .MuiCheckbox-root": {
                            color: theme.palette.input.primary,
                        },
                    }),
                },
            },
            MuiStack: {
                variants: [
                    {
                        props: { variant: "tablelike" },
                        style: () => ({
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingLeft: "1.5rem",
                            paddingRight: "1.5rem",
                            paddingTop: ".75rem",
                            paddingBottom: ".75rem",
                            alignItems: "center",
                        }),
                    },
                    {
                        props: { variant: "splitHeader" },
                        style: () => ({
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingLeft: "0rem",
                            paddingRight: "0rem",
                            alignItems: "center",
                        }),
                    },
                ],
            },
            MuiToolbar: {
                defaultProps: {
                    disableGutters: true,
                },
            },
        },
    });

/*
//   "#1D2A2C"
//   "#197190"
//   "#9AA8B6"
//   "#FFFDFB"
//     "#756C60"
*/

export default Theme;
