/**
 * Exports 'titleCase' function
 * Takes a camelCase string & returns title text
 * Note: Double replacement w/ assertions classifies
 * sequential capitalized letters as acronyms
 *
 * Params
 *
 * Name     | Type      | Desc
 *
 * text     | string    | camelCase string
 *
 */

export default function titleCase(text) {
    const camelSpaced = text.replace(/([A-Z])/g, " $1");
    // Manually replacing known acronyms which, if initially capitalized sequentially, would get split apart into individual characters by above replacement
    // Could be done with following lookbehind but these are not yet supported in safari
    // Ref: https://caniuse.com/js-regexp-lookbehind
    // const result = firstPass.replace(/((?<![A-Z])[A-Z])/g, " $1");
    const acronymsJoined = camelSpaced.replace(/R T/g, "RT");
    return acronymsJoined.charAt(0).toUpperCase() + acronymsJoined.slice(1);
}
