import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import {
    FormControl,
    Box,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Typography,
    FormGroup,
    Button,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import BinSelectionForm from "./BinSelectionForm";
import { connect } from "react-redux";
import { v1 as uuidv1 } from "uuid";
import ShellfishInventoryAPIClient from "../../models/ShellfishInventoryAPIClient";
import RTDisplayUtils from "@running-tide/rt-frontend-helpers/helpers";

const styles = (theme) => ({
    section: {
        marginTop: theme.spacing(3),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    formControl: {
        minWidth: 120,
        marginRight: theme.spacing(2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
});

let GTF = WithGoogleAuth(
    class SeedAdditionForm extends Component {
        constructor(props) {
            super(props);

            let binSelectErrors = { farm: false, reef: false, bin: false };
            let errors = {
                shellfishType: false,
                seedError: false,
                spawnError: false,
                lowerSeedSizeError: false,
                upperSeedSizeError: false,
                volume: false,
                countPerML: false,
                count: false,
                binSelection: binSelectErrors,
            };

            this.initialState = {
                shellfishType: 0,
                species: "",
                countType: "count",
                volume: "",
                countPerML: "",
                count: "",
                seedSource: "",
                spawnId: "",
                startingSeedSizeLower: "",
                startingSeedSizeUpper: "",
                occuranceDate: new Date(),
                isNewStream: false,
                isBroodStock: false,
                errors: errors,
            };
            this.state = this.initialState;
            this.handleBinSelected = this.handleBinSelected.bind(this);
            this.handleReefSelected = this.handleReefSelected.bind(this);
            this.handleFarmSelected = this.handleFarmSelected.bind(this);
            this.handleSeedSourceChanged =
                this.handleSeedSourceChanged.bind(this);
            this.handleSpawnIdChanged = this.handleSpawnIdChanged.bind(this);
            this.handleStartingSeedSizeLowerChanged =
                this.handleStartingSeedSizeLowerChanged.bind(this);
            this.handleStartingSeedSizeUpperChanged =
                this.handleStartingSeedSizeUpperChanged.bind(this);
            this.handleCountPerMLValueChange =
                this.handleCountPerMLValueChange.bind(this);
            this.handleVolumeValueChange =
                this.handleVolumeValueChange.bind(this);
            this.handleCountPerMLValueChange =
                this.handleCountPerMLValueChange.bind(this);
            this.handleShellfishTypeValueChange =
                this.handleShellfishTypeValueChange.bind(this);
            this.handleSpeciesChanged = this.handleSpeciesChanged.bind(this);
            this.key = uuidv1();
        }

        componentDidMount() {}

        handleFarmSelected = (value) => {
            this.setState({ farm: value });
        };

        handleReefSelected = (value) => {
            this.setState({ reef: value });
        };

        handleBinSelected = (value) => {
            this.setState({ bin: value });
        };

        onCountTypeChange = (event) => {
            this.setState({ countType: event.target.value });
        };

        handleSeedSourceChanged = (event) => {
            this.setState({ seedSource: event.target.value });
        };

        handleSpawnIdChanged = (event) => {
            this.setState({ spawnId: event.target.value });
        };

        handleOccuranceDateChanged = (event) => {
            this.setState({ occuranceDate: new Date(event.target.value) });
        };

        handleIsNewStreamChanged = (event) => {
            this.setState({ isNewStream: event.target.checked });
        };

        handleCountValueChange = (event) => {
            this.setState({
                count: event.target.value,
                countPerML: "",
                volume: "",
            });
        };

        handleStartingSeedSizeLowerChanged = (event) => {
            this.setState({ startingSeedSizeLower: event.target.value });
        };

        handleStartingSeedSizeUpperChanged = (event) => {
            this.setState({ startingSeedSizeUpper: event.target.value });
        };

        handleVolumeValueChange = (event) => {
            if (this.state.countPerML != null) {
                this.setState({
                    volume: event.target.value,
                    count: event.target.value * this.state.countPerML,
                });
            } else {
                this.setState({ volume: event.target.value });
            }
        };

        handleCountPerMLValueChange = (event) => {
            if (this.state.volume != null) {
                this.setState({
                    countPerML: event.target.value,
                    count: event.target.value * this.state.volume,
                });
            } else {
                this.setState({ countPerML: event.target.value });
            }
        };

        handleShellfishTypeValueChange = (event) => {
            this.setState({ shellfishType: event.target.value });
        };

        handleSpeciesChanged = (event) => {
            this.setState({ species: event.target.value });
        };

        submitForm(event) {
            event.preventDefault();

            if (!this.validate()) {
                return;
            }

            let apiClient = new ShellfishInventoryAPIClient(
                this.props.authState
            );

            apiClient
                .createOysterGrowOutTransfer(
                    this.state.farm,
                    this.state.reef,
                    this.state.bin,
                    this.state.seedSource,
                    this.state.spawnId,
                    this.state.startingSeedSizeLower,
                    this.state.startingSeedSizeUpper,
                    this.state.isNewStream,
                    false,
                    this.state.volume,
                    this.state.countPerML,
                    this.state.count,
                    this.state.occuranceDate,
                    this.props.loggedInUser.id,
                    new Date(),
                    this.state.shellfishType,
                    this.state.species !== "" ? this.state.species : undefined
                )
                .then(() => {
                    this.key = uuidv1();
                    this.setState({
                        ...this.initialState,
                        occuranceDate: this.state.occuranceDate,
                    });
                    this.props.showSubmittedMessage();
                })
                .catch((e) => {
                    try {
                        e.json().then((body) => {
                            this.props.showErrorMessage(body.error);
                        });
                    } catch (e) {
                        this.props.showErrorMessage(
                            "An unknown error occurred"
                        );
                    }
                });
        }

        validate() {
            let errors = this.state.errors;
            errors.binSelection.farm =
                this.state.farm == null || this.state.farm === "";
            errors.binSelection.reef =
                this.state.reef == null || this.state.reef === "";
            errors.binSelection.bin =
                this.state.bin == null || this.state.bin === "";
            errors.seedError =
                this.state.seedSource == null || this.state.seedSource === "";
            errors.spawnError =
                this.state.spawnId == null || this.state.spawnId === "";
            errors.lowerSeedSizeError =
                this.state.startingSeedSizeLower == null ||
                this.state.startingSeedSizeLower === "";
            errors.upperSeedSizeError =
                this.state.startingSeedSizeUpper == null ||
                this.state.startingSeedSizeUpper === "";
            errors.volume =
                this.state.volume == null || this.state.volume === "";
            errors.countPerML =
                this.state.countPerML == null || this.state.countPerML === "";
            errors.count = this.state.count == null || this.state.count === "";
            //We can leave volume and countPerML blank
            if (errors.volume && errors.countPerML && !errors.count) {
                errors.volume = false;
                errors.countPerML = false;
            }
            errors.shellfishType =
                this.state.shellfishType == null ||
                this.state.shellfishType === "";

            this.setState({ errors: errors });
            return (
                Object.values(errors).filter((val) => val === true).length ===
                    0 &&
                Object.values(errors.binSelection).filter((val) => val === true)
                    .length === 0
            );
        }

        render() {
            const { classes } = this.props;
            return (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" color="textPrimary">
                        This form is used when you are inititially populating
                        reefs with shellfish from the nursery
                    </Typography>
                    <form
                        className={classes.container}
                        noValidate
                        onSubmit={(e) => {
                            this.submitForm(e);
                        }}
                    >
                        <BinSelectionForm
                            key={this.key + "bsf"}
                            onBinSelected={this.handleBinSelected}
                            onReefSelected={this.handleReefSelected}
                            onFarmSelected={this.handleFarmSelected}
                            errors={this.state.errors.binSelection}
                        ></BinSelectionForm>
                        <Box className={classes.section}>
                            <TextField
                                sx={{ mt: 3 }}
                                id="date"
                                label="Occurrence Date"
                                type="date"
                                onChange={this.handleOccuranceDateChanged}
                                defaultValue={RTDisplayUtils.printDatePickerDate(
                                    this.state.occuranceDate
                                )}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: false,
                                    variant: "overhead",
                                }}
                            />
                        </Box>
                        <Box className={classes.section}>
                            <Typography
                                className={classes.header}
                                variant="h3"
                                color="tertiaryUtility"
                            >
                                BIN DATA
                            </Typography>
                            <FormControl margin="dense">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.isNewStream}
                                            onChange={
                                                this.handleIsNewStreamChanged
                                            }
                                            inputProps={{
                                                "aria-label":
                                                    "primary checkbox",
                                            }}
                                            name="Adding to bin with NO shellfish in it?"
                                        />
                                    }
                                    label="Adding to bin with NO shellfish in it?"
                                />
                            </FormControl>
                        </Box>
                        <Box className={classes.section}>
                            <Typography
                                className={classes.header}
                                variant="h3"
                                color="tertiaryUtility"
                            >
                                SHELLFISH INFO
                            </Typography>
                            <FormGroup row={true}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel
                                        variant="overhead"
                                        id="label-select-shellfish-type"
                                        shrink={false}
                                    >
                                        SHELLFISH TYPE
                                    </InputLabel>
                                    <Select
                                        id="shellfishType"
                                        label="label-select-shellfish-type"
                                        value={this.state.shellfishType}
                                        onChange={
                                            this.handleShellfishTypeValueChange
                                        }
                                        error={this.state.errors.shellfishType}
                                    >
                                        <MenuItem value={0}>Oyster</MenuItem>
                                        <MenuItem value={1}>Surf Clam</MenuItem>
                                        <MenuItem value={2}>Scallop</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    id="species"
                                    label="Species"
                                    placeholder={
                                        "American " +
                                        RTDisplayUtils.printShellfishType(
                                            this.state.shellfishType
                                        )
                                    }
                                    value={this.state.species}
                                    onChange={this.handleSpeciesChanged}
                                    InputLabelProps={{
                                        shrink: false,
                                        variant: "overhead",
                                    }}
                                    className={classes.formControl}
                                />
                                <TextField
                                    id="seed-source"
                                    label="Seed Source"
                                    placeholder="RTT"
                                    value={this.state.seedSource}
                                    onChange={this.handleSeedSourceChanged}
                                    error={this.state.errors.seedError}
                                    InputLabelProps={{
                                        shrink: false,
                                        variant: "overhead",
                                    }}
                                    className={classes.formControl}
                                >
                                    RTT
                                </TextField>
                                <TextField
                                    id="spawn-id"
                                    label="Spawn ID"
                                    placeholder="IN0330"
                                    value={this.state.spawnId}
                                    onChange={this.handleSpawnIdChanged}
                                    error={this.state.errors.spawnError}
                                    InputLabelProps={{
                                        shrink: false,
                                        variant: "overhead",
                                    }}
                                    className={classes.formControl}
                                />
                                <TextField
                                    id="starting-seed-size"
                                    label="Lower starting Seed Size (mm)"
                                    type="number"
                                    onChange={
                                        this.handleStartingSeedSizeLowerChanged
                                    }
                                    value={this.state.startingSeedSizeLower}
                                    error={this.state.errors.lowerSeedSizeError}
                                    InputLabelProps={{
                                        shrink: false,
                                        variant: "overhead",
                                    }}
                                    className={classes.formControl}
                                />
                                <TextField
                                    id="starting-seed-size"
                                    label="Upper starting Seed Size (mm)"
                                    type="number"
                                    onChange={
                                        this.handleStartingSeedSizeUpperChanged
                                    }
                                    value={this.state.startingSeedSizeUpper}
                                    error={this.state.errors.upperSeedSizeError}
                                    InputLabelProps={{
                                        shrink: false,
                                        variant: "overhead",
                                    }}
                                    className={classes.formControl}
                                />
                            </FormGroup>
                        </Box>
                        <Box className={classes.section}>
                            <Typography
                                className={classes.header}
                                sx={{ mt: 3 }}
                                variant="h3"
                                color="tertiaryUtility"
                            >
                                {RTDisplayUtils.printShellfishType(
                                    this.state.shellfishType
                                )}{" "}
                                Count (leave mL and countPerML blank to enter
                                raw count)
                            </Typography>
                            <FormGroup row={true}>
                                <TextField
                                    id="volume"
                                    label="ML"
                                    type="number"
                                    value={this.state.volume}
                                    onChange={this.handleVolumeValueChange}
                                    error={this.state.errors.volume}
                                    InputLabelProps={{
                                        shrink: false,
                                        variant: "overhead",
                                    }}
                                    className={classes.formControl}
                                />
                                <TextField
                                    id="countPerML"
                                    label="Count per mL"
                                    type="number"
                                    value={this.state.countPerML}
                                    onChange={this.handleCountPerMLValueChange}
                                    error={this.state.errors.countPerML}
                                    InputLabelProps={{
                                        shrink: false,
                                        variant: "overhead",
                                    }}
                                    className={classes.formControl}
                                />
                                <TextField
                                    id="count"
                                    label="Count"
                                    type="number"
                                    value={this.state.count}
                                    onChange={this.handleCountValueChange}
                                    error={this.state.errors.count}
                                    InputLabelProps={{
                                        shrink: false,
                                        variant: "overhead",
                                    }}
                                    className={classes.formControl}
                                />
                            </FormGroup>
                        </Box>
                        <Button variant="secondary" type="submit">
                            Submit
                        </Button>
                    </form>
                </Box>
            );
        }
    }
);

const mapStateToProps = (state) => ({
    loggedInUser: state.data.loggedInUser,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(GTF));
