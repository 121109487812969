/**
 * A component that allows the user to create multiple Things at once.
 */

import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const styles = (theme) => ({
    container: {
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: "100%",
    },
});

class BulkThingCreator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            names: "",
            descriptions: "",
            isCreating: false,
        };
    }

    onCreate = async () => {
        const names = this.state.names
            .split(",")
            .filter((name) => name.length > 0);
        let descriptions = this.state.descriptions
            .split(",")
            .filter((description) => description.length > 0);

        // If only one description was entered, use it for all
        // of the things.
        if (descriptions.length === 1) {
            descriptions = names.map(() => descriptions[0]);
        }

        // Ensure we end up with the same number of names and
        // descriptions.
        if (names.length !== descriptions.length) {
            this.props.onCopyComplete(
                [],
                "Enter the same number of names and descriptions or a single description"
            );
            return;
        }

        this.setState({ isCreating: true });

        const newThings = await Promise.all(
            names.map(async (name, i) => {
                const thing = { name, description: descriptions[i] };
                try {
                    const response = await this.props.apiClient.createThing(
                        thing
                    );
                    const json = await response.json();
                    if (response.status !== 200) {
                        console.error(json);
                        return null;
                    }
                    return { ...thing, id: json.id };
                } catch (e) {
                    console.error(e);
                    return null;
                }
            })
        );

        const successfullyAdded = newThings.filter((thing) => thing != null);
        if (newThings.length === successfullyAdded.length) {
            this.props.onCreateComplete(successfullyAdded);
            // Clear the names and descriptions if the things were successfully
            // created.
            this.setState({ names: "", descriptions: "", isCreating: false });
        } else {
            const failureCount = newThings.length - successfullyAdded.length;
            this.props.onCreateComplete(
                successfullyAdded,
                `Failed to add ${failureCount} of ${newThings.length} things`
            );
            this.setState({ isCreating: false });
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.container}>
                <Typography className={classes.title} variant="h6">
                    Bulk Thing Creator
                </Typography>
                <TextField
                    className={classes.textField}
                    label="Thing names"
                    helperText="Enter names separated by commas (no quotes)"
                    multiline
                    value={this.state.names}
                    onChange={(event) => {
                        this.setState({ names: event.target.value });
                    }}
                />
                <TextField
                    className={classes.textField}
                    label="Thing description(s)"
                    helperText="Enter descriptions separated by commas OR a single, shared description (no quotes)"
                    multiline
                    value={this.state.descriptions}
                    onChange={(event) => {
                        this.setState({ descriptions: event.target.value });
                    }}
                />
                <Button
                    variant="secondary"
                    disabled={
                        this.state.names.length === 0 ||
                        this.state.descriptions.length === 0 ||
                        this.state.isCreating
                    }
                    onClick={() => this.onCreate()}
                >
                    Create
                </Button>
            </Paper>
        );
    }
}

BulkThingCreator.propTypes = {
    apiClient: PropTypes.any.isRequired,
    // onCreateComplete: (successfullyAdded: Object[], errorMessage: ?string)
    onCreateComplete: PropTypes.func.isRequired,
};

export default withStyles(styles)(BulkThingCreator);
