import React, { Component } from "react";
import { Chip, Grid } from "@mui/material";

export class GroupPill extends Component {
    constructor(props) {
        super(props);
        this.client = props.client;
        this.state = { group: undefined };
        this.groupId = props.groupId;
    }

    async componentDidMount() {
        if (!this.groupId) return;
        const group = await this.client.getGroup(this.groupId);
        this.setState({ group: group });
    }

    render() {
        const group = this.state.group;
        return group ? (
            <Grid item key={`key-${group.id}`} xs={12}>
                <Chip label={group.name} key={group.id} color="success" />
            </Grid>
        ) : (
            <div />
        );
    }
}
