import React, { Component } from "react";
import { Chip, Grid } from "@mui/material";

export class PermissionPill extends Component {
    constructor(props) {
        super(props);
        this.client = props.client;
        this.onDelete = props.onDelete;
    }

    render() {
        const permission = this.props.permission;
        return permission ? (
            <Grid item key={`key-${permission.id}`}>
                <Chip
                    label={`${permission.resource} - ${permission.access_level}`}
                    key={permission.id}
                    color="success"
                    onDelete={
                        this.onDelete
                            ? () => this.onDelete(permission.id)
                            : undefined
                    }
                />
            </Grid>
        ) : (
            <div />
        );
    }
}
