import React, { Component } from "react";
import { WithGoogleAuth } from "../config/WithGoogleAuth";
import withStyles from "@mui/styles/withStyles";
import { Tabs, Tab, AppBar, Grid } from "@mui/material";
import FeatureOfInterestManager from "./sections/FeatureOfInterestManager";
import LocationManager from "./sections/LocationManager";
import ThingManager from "./sections/ThingManager";
import SensorManager from "./sections/SensorManager";
import ObservedPropertyManager from "./sections/ObservedPropertyManager";
import DatastreamManager from "./sections/DatastreamManager";
import DatastreamToFOIConnectionManager from "./sections/DatastreamToFOIConnectionManager";
import SFTPManager from "./sections/SFTPManager";
import DataDashboardManager from "./sections/DataDashboardManager";
import SensorThingsDocs from "./sections/SensorThingsDocs";

const styles = (theme) => ({
    root: {},
    paper: {
        padding: 10,
    },
    section: {
        marginTop: theme.spacing(5),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    box: {
        padding: 10,
    },
});

let SensorThingsAPIManager = WithGoogleAuth(
    class SensorThingsAPIManagers extends Component {
        constructor(props) {
            super(props);
            this.state = {
                selectedTab: 0,
            };
        }

        render() {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <AppBar position="static">
                            <Tabs
                                value={this.state.selectedTab}
                                onChange={(event, newValue) =>
                                    this.setState({ selectedTab: newValue })
                                }
                                aria-label="Sensor Things tabs"
                            >
                                <Tab label="Docs" variant="leftmost" />
                                <Tab label="Thing" />
                                <Tab label="SFTP" />
                                <Tab label="Location" />
                                <Tab label="Sensor" />
                                <Tab label="Observed Property" />
                                <Tab label="Datastream" />
                                <Tab label="Feature Of Interest" />
                                <Tab label="Datastream - FOI" />
                                <Tab
                                    label="Data Dashboard"
                                    variant="rightmost"
                                />
                            </Tabs>
                        </AppBar>
                        {this.state.selectedTab === 0 && <SensorThingsDocs />}
                        {this.state.selectedTab === 1 && <ThingManager />}
                        {this.state.selectedTab === 2 && <SFTPManager />}
                        {this.state.selectedTab === 3 && <LocationManager />}
                        {this.state.selectedTab === 4 && <SensorManager />}
                        {this.state.selectedTab === 5 && (
                            <ObservedPropertyManager />
                        )}
                        {this.state.selectedTab === 6 && <DatastreamManager />}
                        {this.state.selectedTab === 7 && (
                            <FeatureOfInterestManager />
                        )}
                        {this.state.selectedTab === 8 && (
                            <DatastreamToFOIConnectionManager />
                        )}
                        {this.state.selectedTab === 9 && (
                            <DataDashboardManager />
                        )}
                    </Grid>
                </Grid>
            );
        }
    }
);

export default withStyles(styles)(SensorThingsAPIManager);
