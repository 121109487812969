import React, { Component } from "react";
import { useParams } from "react-router-dom";
import {
    IconButton,
    Grid,
    Stack,
    Link,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Button,
    Autocomplete,
    TextField,
    Tooltip,
    CircularProgress,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloseIcon from "@mui/icons-material/Close";
import AddBoxIcon from "@mui/icons-material/AddBox";
import UploadFileRounded from "@mui/icons-material/UploadFileRounded";
import { ArrowCircleUpRounded } from "@mui/icons-material";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import Dialog from "@mui/material/Dialog";

import { errorMessage, successMessage } from "../helper/MessageMethodHelper";
import RTable from "../components/RTable";
import MessageHelper from "../helper/MessageHelper";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";

const styles = (theme) => ({
    inputWidth: {
        minWidth: 210,
    },
    inputWidthMargin: {
        minWidth: 180,
        marginRight: theme.spacing(2),
    },
    inputSetWidth: {
        width: 450,
    },
    accordionSummary: {
        minHeight: "1.5rem !important",
        flexDirection: "row-reverse",
    },
});

class TestConceptDetails extends Component {
    state = {
        name: "",
        description: "",
        data: [],
        constants: [],
        formulae: [],
        tests: [],
        downloadOpen: false,
        downloadIds: [],
        downloadData: [],
        productInstanceOptions: [],
        allProductsOnPage: [],
        activeFilters: [],
        selectedFile: undefined,
        successfulCreations: [],
        failedCreations: [],
        helpDialogueOpen: false,
        infoDialogueOpen: false,
        loading: true,
    };

    async componentDidMount() {
        // Fetch information associated with this test concept
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);

        const { testConcept, testInstances, productInstances } =
            await apiClient.getTestConcept(
                this.props.params.test_concept_id,
                true
            );

        const data = Object.keys(testConcept.data).map((name) => {
            return { name, type: testConcept.data[name] };
        });
        data.sort();
        const constants = Object.keys(testConcept.constants).map((name) => {
            return { name, type: testConcept.constants[name] };
        });
        constants.sort();
        const formulae = testConcept.formulae;

        const tests = testInstances.map((testInstance) => {
            const { id, productInstanceId, startDate, endDate } = testInstance;
            const assocProdInstance = productInstances.find(
                (element) => element.id === productInstanceId
            );
            const creationDate = assocProdInstance.creationDate;
            const batchIndex = assocProdInstance.batchIndex;
            const index = assocProdInstance.index;
            return {
                id,
                productInstanceId,
                creationDate,
                batchIndex,
                index,
                startDate,
                endDate,
            };
        });
        this.setState({
            name: testConcept.name,
            description: testConcept.description,
            data,
            constants,
            formulae,
            tests,
            allProductsOnPage: productInstances,
            downloadIds: productInstances.map((product) => product.id),
            loading: false,
        });
    }

    downloadCSV() {
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);

        apiClient.downloadTestResultsCSV(
            this.state.name,
            this.props.params.test_concept_id,
            this.state.downloadIds,
            this.state.downloadData
        );
    }

    onSelectProducts(downloadIds) {
        this.setState({
            downloadIds,
        });
    }
    onSelectData(downloadData) {
        this.setState({
            downloadData,
        });
    }

    onSelectFilter(filters, type) {
        // filters comes in as the set of all filters selected of the type
        // we want to make sure there's no duplicates in active filters,
        // so we need to remove all filters of the passed type, and
        // then add back those values from the input
        const activeFilters = [
            ...this.state.activeFilters.filter(
                (filter) => filter.type !== type
            ),
            ...filters.map((filter) => ({ type: type, value: filter })),
        ];
        this.setState(
            {
                activeFilters,
            },
            () => {
                this.onRunFilters();
            }
        );
    }

    grabFilteredProducts(newFilters, type, someCriteria) {
        const filters = newFilters.filter((filter) => filter.type === type);
        return this.state.allProductsOnPage.filter((productInstance) =>
            filters.some((filter) => {
                return someCriteria(filter, productInstance);
            })
        );
    }

    grabFilterSets() {
        return [
            this.grabFilteredProducts(
                this.state.activeFilters,
                "date",
                (filter, productInstance) => {
                    return productInstance.creationDate.includes(filter.value);
                }
            ),
            this.grabFilteredProducts(
                this.state.activeFilters,
                "batch",
                (filter, productInstance) => {
                    return productInstance.batchIndex === Number(filter.value);
                }
            ),
            this.grabFilteredProducts(
                this.state.activeFilters,
                "index",
                (filter, productInstance) => {
                    return productInstance.index === Number(filter.value);
                }
            ),
        ];
    }

    onRunFilters() {
        const filterSets = this.grabFilterSets();
        const nonEmptyLists = filterSets.filter(
            (filterList) => filterList.length > 0
        );

        const intersection = nonEmptyLists.reduce(
            (filterList, curIntersect) =>
                filterList.filter((product) => curIntersect.includes(product)),
            this.state.allProductsOnPage
        );

        const downloadIds = intersection.map((product) => product.id);
        this.setState({ downloadIds });
    }

    onFileChange(file) {
        this.setState({ selectedFile: file });
    }

    async createInstancesFromFile(testConceptId) {
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);

        try {
            const { successes, failures } =
                await apiClient.createTestInstancesFromCsv(
                    this.state.selectedFile,
                    testConceptId
                );
            this.setState({
                ...successMessage(`${successes.length} test instances created`),
                successfulCreations: successes,
                failedCreations: failures,
                infoDialogueOpen: true,
            });
        } catch (err) {
            this.setState({
                ...errorMessage(err),
            });
        }
    }

    render() {
        return (
            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        {this.state.loading && (
                            <Stack direction="row" spacing={2}>
                                <Typography variant="h3">Loading</Typography>
                                <CircularProgress size="1.5rem" />
                            </Stack>
                        )}
                        <Stack
                            spacing={2}
                            direction={
                                this.state.downloadOpen ? "column" : "row"
                            }
                            justifyContent="flex-start"
                            alignItems={
                                this.state.downloadOpen
                                    ? "flex-start"
                                    : "center"
                            }
                        >
                            <Typography variant="h3">
                                {this.state.name}
                            </Typography>

                            {/* TODO (Nikhil) Place this to be displayed w/ Backdrop */}
                            <Accordion
                                onChange={(_, expanded) => {
                                    this.setState({
                                        downloadOpen: expanded,
                                    });
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        this.state.downloadOpen ? (
                                            <CloseIcon color="primary" />
                                        ) : this.state.loading ? (
                                            <div></div>
                                        ) : (
                                            <Tooltip
                                                title="Download CSV"
                                                placement="right"
                                            >
                                                <CloudDownloadIcon color="primary" />
                                            </Tooltip>
                                        )
                                    }
                                    className={
                                        this.props.classes.accordionSummary
                                    }
                                />
                                <AccordionDetails sx={{ width: "100%" }}>
                                    <Stack direction="column" spacing={2}>
                                        <Autocomplete
                                            multiple
                                            value={[
                                                ...this.state.activeFilters
                                                    .filter(
                                                        (filter) =>
                                                            filter.type ===
                                                            "date"
                                                    )
                                                    .map(
                                                        (filter) => filter.value
                                                    ),
                                            ]}
                                            onChange={(_, dates) =>
                                                this.onSelectFilter(
                                                    dates,
                                                    "date"
                                                )
                                            }
                                            options={[
                                                ...new Set(
                                                    this.state.allProductsOnPage.map(
                                                        (product) =>
                                                            // grabs the date value of an ISO string
                                                            // yyyy-mm-ddThh:mm:ss:mmmR -> yyyy-mm-dd
                                                            product.creationDate.substring(
                                                                0,
                                                                10
                                                            )
                                                    )
                                                ),
                                            ]}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Product Creation Dates"
                                                    multiline
                                                    margin="dense"
                                                />
                                            )}
                                            getOptionLabel={(option) => option}
                                            className={
                                                this.props.classes.inputSetWidth
                                            }
                                        />
                                        <Autocomplete
                                            multiple
                                            value={[
                                                ...this.state.activeFilters
                                                    .filter(
                                                        (filter) =>
                                                            filter.type ===
                                                            "batch"
                                                    )
                                                    .map(
                                                        (filter) => filter.value
                                                    ),
                                            ]}
                                            onChange={(_, batchNums) =>
                                                this.onSelectFilter(
                                                    batchNums,
                                                    "batch"
                                                )
                                            }
                                            options={[
                                                ...new Set(
                                                    this.state.allProductsOnPage.map(
                                                        (product) =>
                                                            String(
                                                                product.batchIndex
                                                            )
                                                    )
                                                ),
                                            ]}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Batches"
                                                    multiline
                                                    margin="dense"
                                                />
                                            )}
                                            getOptionLabel={(option) => option}
                                            className={
                                                this.props.classes.inputSetWidth
                                            }
                                        />
                                        <Autocomplete
                                            multiple
                                            value={[
                                                ...this.state.activeFilters
                                                    .filter(
                                                        (filter) =>
                                                            filter.type ===
                                                            "index"
                                                    )
                                                    .map(
                                                        (filter) => filter.value
                                                    ),
                                            ]}
                                            onChange={(_, indices) =>
                                                this.onSelectFilter(
                                                    indices,
                                                    "index"
                                                )
                                            }
                                            options={[
                                                ...new Set(
                                                    this.state.allProductsOnPage.map(
                                                        (product) =>
                                                            String(
                                                                product.index
                                                            )
                                                    )
                                                ),
                                            ]}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Indices"
                                                    multiline
                                                    margin="dense"
                                                />
                                            )}
                                            getOptionLabel={(option) => option}
                                            className={
                                                this.props.classes.inputSetWidth
                                            }
                                        />
                                        <Autocomplete
                                            multiple
                                            value={this.state.downloadData}
                                            onChange={(_, downloadData) => {
                                                this.onSelectData(downloadData);
                                            }}
                                            label="Data"
                                            options={[
                                                ...this.state.data,
                                                ...this.state.formulae,
                                            ].map((option) =>
                                                String(option.name)
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Data"
                                                    multiline
                                                    margin="dense"
                                                />
                                            )}
                                            getOptionLabel={(option) => option}
                                            className={
                                                this.props.classes.inputSetWidth
                                            }
                                        />
                                    </Stack>
                                    <Typography>
                                        {`${
                                            this.state.downloadIds.length
                                        } product${
                                            this.state.downloadIds.length === 1
                                                ? ""
                                                : "s"
                                        } selected`}
                                    </Typography>
                                </AccordionDetails>
                                <AccordionActions>
                                    <Button
                                        variant="secondary"
                                        disabled={
                                            this.state.downloadIds.length === 0
                                        }
                                        onClick={() => this.downloadCSV()}
                                    >
                                        Download
                                    </Button>
                                </AccordionActions>
                            </Accordion>
                        </Stack>
                        <Typography variant="body1">
                            {this.state.description}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item lg={4}>
                    <RTable
                        disableAltRowColor
                        title={<Typography variant="h3">Data</Typography>}
                        columns={[{ field: "name" }, { field: "type" }]}
                        data={this.state.data}
                        options={{
                            header: false,
                            paging: false,
                            search: false,
                            maxColumnSort: 0,
                        }}
                    />
                </Grid>
                <Grid item lg={4}>
                    <RTable
                        disableAltRowColor
                        title={<Typography variant="h3">Constants</Typography>}
                        columns={[{ field: "name" }, { field: "type" }]}
                        data={this.state.constants}
                        options={{
                            header: false,
                            paging: false,
                            search: false,
                            maxColumnSort: 0,
                        }}
                    />
                </Grid>
                <Grid item lg={4}>
                    <RTable
                        disableAltRowColor
                        title={<Typography variant="h3">Formulae</Typography>}
                        columns={[{ field: "name" }, { field: "logic" }]}
                        data={this.state.formulae}
                        options={{
                            header: false,
                            paging: false,
                            search: false,
                            maxColumnSort: 0,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RTable
                        title={
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                <Typography variant="h3">
                                    Test Instances
                                </Typography>
                                {this.state.loading && (
                                    <CircularProgress size="1rem" />
                                )}
                                <IconButton
                                    color="primary"
                                    href={`/test/create-instance?testConcept=${this.props.params.test_concept_id}`}
                                >
                                    <AddBoxIcon />
                                </IconButton>

                                {!this.state.selectedFile && (
                                    <Stack
                                        spacing={2}
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Tooltip
                                            title="Create from CSV"
                                            placement="right"
                                        >
                                            <IconButton
                                                color="primary"
                                                component="label"
                                                onChange={(event) => {
                                                    this.onFileChange(
                                                        event.target.files[0]
                                                    );
                                                }}
                                            >
                                                <input
                                                    hidden
                                                    accept=".csv"
                                                    type="file"
                                                />
                                                <UploadFileRounded />
                                            </IconButton>
                                        </Tooltip>
                                        <IconButton
                                            color="primary"
                                            onClick={() => {
                                                this.setState({
                                                    helpDialogueOpen: true,
                                                });
                                            }}
                                        >
                                            <HelpOutlineRoundedIcon />
                                        </IconButton>
                                        <Dialog
                                            open={this.state.helpDialogueOpen}
                                            onClose={() => {
                                                this.setState({
                                                    helpDialogueOpen: false,
                                                });
                                            }}
                                        >
                                            <Typography>
                                                This allows you to upload test
                                                instances to be created by CSV.
                                                The uploaded CSV should include
                                                columns for creationDate,
                                                batchIndex, productIndex,
                                                startDate, and any constants. To
                                                specify data, include a time
                                                column followed by columns for
                                                datapoints that are paired with
                                                that time.
                                            </Typography>
                                        </Dialog>
                                    </Stack>
                                )}
                                {this.state.selectedFile && (
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Typography>
                                            {this.state.selectedFile.name}
                                        </Typography>
                                        <IconButton
                                            color="primary"
                                            onClick={() => {
                                                this.setState({
                                                    selectedFile: undefined,
                                                });
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <Tooltip
                                            title="Create Instances from CSV"
                                            placement="right"
                                        >
                                            <IconButton
                                                color="primary"
                                                onClick={() => {
                                                    this.createInstancesFromFile(
                                                        this.props.params
                                                            .test_concept_id
                                                    );
                                                }}
                                            >
                                                <ArrowCircleUpRounded />
                                            </IconButton>
                                        </Tooltip>
                                        <IconButton
                                            color="primary"
                                            onClick={() => {
                                                this.setState({
                                                    infoDialogueOpen: true,
                                                });
                                            }}
                                        >
                                            <HelpOutlineRoundedIcon />
                                        </IconButton>
                                        <Dialog
                                            maxWidth={"lg"}
                                            open={this.state.infoDialogueOpen}
                                            onClose={() => {
                                                this.setState({
                                                    infoDialogueOpen: false,
                                                });
                                            }}
                                        >
                                            <Stack
                                                direction={"column"}
                                                spacing={2}
                                            >
                                                <Stack
                                                    direction="row"
                                                    paddingTop={2}
                                                    alignItems="start"
                                                    justifyContent="space-between"
                                                    spacing={5}
                                                >
                                                    <RTable
                                                        disableAltRowColor
                                                        padding={5}
                                                        title={
                                                            <Typography variant="h3">
                                                                Successful
                                                                Creations
                                                            </Typography>
                                                        }
                                                        columns={[
                                                            {
                                                                title: "Test Instance ID",
                                                                field: "testInstanceId",
                                                            },
                                                            {
                                                                title: "Product Creation Date",
                                                                field: "creationDate",
                                                            },
                                                            {
                                                                title: "Product Batch Index",
                                                                field: "batchIndex",
                                                            },
                                                            {
                                                                title: "Product Index",
                                                                field: "index",
                                                            },
                                                        ]}
                                                        data={
                                                            this.state
                                                                .successfulCreations
                                                        }
                                                        options={{
                                                            search: false,
                                                            filtering: true,
                                                            paging:
                                                                this.state
                                                                    .successfulCreations
                                                                    .length >
                                                                10,
                                                            maxColumnSort: 0,
                                                        }}
                                                    />

                                                    <RTable
                                                        disableAltRowColor
                                                        padding={5}
                                                        title={
                                                            <Typography variant="h3">
                                                                Failed Creations
                                                            </Typography>
                                                        }
                                                        columns={[
                                                            {
                                                                title: "Product Creation Date",
                                                                field: "creationDate",
                                                            },
                                                            {
                                                                title: "Product Batch Index",
                                                                field: "batchIndex",
                                                            },
                                                            {
                                                                title: "Product Index",
                                                                field: "index",
                                                            },
                                                        ]}
                                                        data={
                                                            this.state
                                                                .failedCreations
                                                        }
                                                        options={{
                                                            search: false,
                                                            filtering: true,
                                                            paging:
                                                                this.state
                                                                    .failedCreations
                                                                    .length >
                                                                10,
                                                            maxColumnSort: 0,
                                                        }}
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Dialog>
                                    </Stack>
                                )}
                            </Stack>
                        }
                        columns={[
                            {
                                render: (rowData) => (
                                    <Link
                                        href={`/product/${rowData.productInstanceId}`}
                                    >
                                        Product Instance
                                    </Link>
                                ),
                            },
                            { title: "Creation Date", field: "creationDate" },
                            { title: "Batch Index", field: "batchIndex" },
                            { title: "Index", field: "index" },
                            { title: "Start Date", field: "startDate" },
                            { title: "End Date", field: "endDate" },
                            {
                                render: (rowData) => (
                                    <Link href={`/test/instance/${rowData.id}`}>
                                        Test Results
                                    </Link>
                                ),
                            },
                        ]}
                        data={this.state.tests}
                    />
                    <MessageHelper
                        message={this.state.message}
                        errorMessage={this.state.errorMessage}
                        open={this.state.messageOpen}
                        setState={(a) => this.setState(a)}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(
    WithGoogleAuth((props) => (
        <TestConceptDetails {...props} params={useParams()} />
    ))
);
