import { FirmwareBuoyProtocols } from "@running-tide/firmware-buoy-protocols";
const FirmwareConfigUpdate = FirmwareBuoyProtocols.FirmwareBuoyBound;

export class FirmwareBuoyConfig {
    constructor(binary = null, json = null) {
        this.binary = binary?.buffer || binary;
        this.json = json;
        if (this.binary) {
            this.computeJson();
        } else if (this.json) {
            this.computeBinary();
        }
    }

    computeJson() {
        const firmwareBuoyBound = FirmwareConfigUpdate.deserializeBinary(
            this.binary
        );
        if (firmwareBuoyBound.hasConfigUpdate()) {
            this.json = this._getJobs(firmwareBuoyBound);
        }
    }

    _getJobs(firmwareBuoyBound) {
        const _this = this;
        let jobs = firmwareBuoyBound.getConfigUpdate().getJobsList();
        return jobs.map((job) => {
            return {
                cron: atob(job.getCronSchedule_asB64()),
                enum: job.getJob(),
                flags: _this._getFlags(job.getFlagsList()),
            };
        });
    }

    _getFlags(flags) {
        return flags.map((flag) => {
            let flag_data = {
                enum: flag.getFlags(),
            };
            if (flag.hasArgument()) {
                flag_data.arg = Number(flag.getArgument());
            }
            return flag_data;
        });
    }

    computeBinary() {
        const _this = this;
        const configJson = this.json;
        const configProto = new FirmwareConfigUpdate.ConfigUpdate();
        configProto.setJobsList(
            configJson.map((job) => _this._getJobConfig(job))
        );
        const buoyBound = new FirmwareBuoyProtocols.FirmwareBuoyBound();
        buoyBound.setConfigUpdate(configProto);
        const serialMessage = buoyBound.serializeBinary();
        this.binary = serialMessage;
    }

    _getJobConfig(jobJson) {
        const _this = this;
        const utf8Encode = new TextEncoder();
        const jobConfig = new FirmwareConfigUpdate.JobConfig();
        jobConfig.setCronSchedule(utf8Encode.encode(jobJson.cron));
        jobConfig.setJob(FirmwareBuoyProtocols.Jobs[jobJson.enum]);
        jobConfig.setFlagsList(
            jobJson.flags.map((flag) => _this._getFlagConfig(flag))
        );
        return jobConfig;
    }

    _getFlagConfig(flagJson) {
        const flagConfig = new FirmwareConfigUpdate.Flag();
        flagConfig.setFlags(FirmwareBuoyProtocols.JobFlags[flagJson.enum]);
        if (!isNaN(flagJson.arg)) {
            const value = this._castToUint32(flagJson.arg);
            flagConfig.setArgument(value);
        }
        return flagConfig;
    }

    _castToUint32(num) {
        return Number(num) >>> 0;
    }
}

export default FirmwareBuoyConfig;
