import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { CircularProgress, Link, Stack, Typography } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { connect } from "react-redux";

import RTable from "../components/RTable";
import IconButtonWithTooltip from "../helper/IconButtonWithTooltip";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import DeploymentStatus from "./helpers/DeploymentStatus";
import { hasValidPermissions } from "../helper/PermissionChecker";
import {
    CARBON_SEQUESTRATION,
    Action,
} from "@running-tide/rt-api-access-control/build/PermissionValidator/constants";

const styles = () => ({
    mapContainer: {
        position: "relative",
    },
});

const limit = 25;
class Deployments extends Component {
    state = {
        deployments: [],
    };

    async componentDidMount() {
        // Fetch a list of all of deployments that exist
        const fetchDeployments = async () => {
            if (data.length === limit) {
                data = await apiClient.getDeployments(
                    limit,
                    this.state.deployments.length
                ).data;
                this.setState(
                    {
                        deployments: this.state.deployments.concat(data),
                    },
                    fetchDeployments
                );
            } else {
                this.setState({ loading: false }, this.fetchSecondary);
            }
        };
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        let { data } = await apiClient.getDeployments(limit, 1);
        this.setState({ deployments: data }, fetchDeployments);
    }

    async fetchSecondary() {
        this.fetchSubstrates();
    }

    async fetchSubstrates() {
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        const deployments = Array.isArray(this.state.deployments)
            ? await Promise.all(
                  this.state.deployments.map(async (deployment) => {
                      const substrates =
                          await apiClient.getDeploymentSubstrates(
                              deployment.id
                          );
                      deployment.weight = substrates.reduce(
                          (sum, sub) => sum + sub.quantity,
                          0
                      );
                      return deployment;
                  })
              )
            : [];
        this.setState({ deployments });
    }

    render() {
        const csaUser = hasValidPermissions(this.props.loggedInUser, [
            `${CARBON_SEQUESTRATION}:${Action.CREATE}`,
            `${CARBON_SEQUESTRATION}:${Action.UPDATE}`,
        ]);
        return (
            <Stack>
                <RTable
                    title={
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="h3">
                                Deployment tracker
                            </Typography>
                            {this.state.loading && (
                                <CircularProgress size="1.5rem" />
                            )}
                            <IconButtonWithTooltip
                                tooltipText={
                                    csaUser
                                        ? "Create Deployment"
                                        : "User not authorized"
                                }
                                color="primary"
                                href="/cdr/deployments/create"
                                disabled={!csaUser}
                            >
                                <AddBoxIcon />
                            </IconButtonWithTooltip>
                        </Stack>
                    }
                    columns={[
                        {
                            title: "Deployment Name",
                            field: "title",
                            render: (rowData) => (
                                <Link href={`/cdr/deployments/${rowData.id}`}>
                                    {rowData.title}
                                </Link>
                            ),
                        },
                        {
                            title: "Weight of Cargo",
                            field: "weight",
                            render: (rowData) => (
                                <Typography>
                                    {rowData.weight
                                        ? `${rowData.weight} t`
                                        : rowData.weight === 0
                                        ? "TBD"
                                        : "..."}
                                </Typography>
                            ),
                        },
                        {
                            title: "Departure Port",
                            field: "carbonBuoyResponse.departurePortResponse.title",
                        },
                        {
                            title: "Status",
                            render: (rowData) => (
                                <DeploymentStatus
                                    arrivalDate={
                                        rowData.carbonBuoyResponse?.arrivalDate
                                    }
                                    departureDate={
                                        rowData.carbonBuoyResponse
                                            ?.departureDate
                                    }
                                />
                            ),
                        },
                    ]}
                    data={this.state.deployments}
                    options={{ pageSize: 10 }}
                />
            </Stack>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedInUser: state.data.loggedInUser,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(WithGoogleAuth(Deployments)));
