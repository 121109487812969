import React, { Component } from "react";
import {
    Box,
    Paper,
    Fab,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Container,
    IconButton,
    ListItem,
    List,
    TextField,
} from "@mui/material";
import {
    Add as AddIcon,
    DeleteForever as DeleteForeverIcon,
} from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import { Cron } from "react-js-cron";
import "react-js-cron/dist/styles.css";
import { FirmwareBuoyProtocols } from "@running-tide/firmware-buoy-protocols";

const styles = (theme) => ({
    container: {
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: "30%",
    },
    titleBar: {
        display: "flex",
        width: "30%",
        flexFlow: "row",
        justifyContent: "space-between",
    },
    box: {
        padding: "2px",
        border: "1px dashed grey",
        width: "100%",
    },
    empty: {
        textAlign: "center",
        padding: "100px",
        fontSize: "44px",
        color: "grey",
    },
    addButton: {
        margin: "10px;",
    },
    jobBox: {
        padding: "2px",
        border: "1px solid grey",
        width: "100%",
        display: "flex",
        alignItems: "stretch",
    },
    cronContainer: {
        flexGrow: "4",
    },
    jobContainer: {
        flexShrink: "1",
    },
    flagsContainer: {
        flexGrow: "4",
    },
    deleteContainer: {
        width: "75px",
        padding: "15px 25px 0px 0px",
    },
    argListItem: {
        padding: "0px",
    },
});

const BuoyFirmwareConfigForm = WithGoogleAuth(
    class BuoyFirmwareConfigForm extends Component {
        constructor(props) {
            super(props);

            this.displaySchedule = this.displaySchedule.bind(this);
            this.setCronValue = this.setCronValue.bind(this);
        }

        setCronValue(value, index) {
            let selectedTemplate = this.props.selectedTemplate;
            selectedTemplate.contents.jobs[index].cron = value;
            this.props.selectSchedule(selectedTemplate.contents.jobs);
            this.setState({ selectedTemplate });
        }

        setJob(value, index) {
            let selectedTemplate = this.props.selectedTemplate;
            selectedTemplate.contents.jobs[index].enum = value;
            this.props.selectSchedule(selectedTemplate.contents.jobs);
            this.setState({ selectedTemplate });
        }

        deleteJob(index) {
            let selectedTemplate = this.props.selectedTemplate;
            selectedTemplate.contents.jobs.splice(index, 1);
            this.props.selectSchedule(selectedTemplate.contents.jobs);
            this.setState({ selectedTemplate });
        }

        addJob() {
            let selectedTemplate = this.props.selectedTemplate;
            selectedTemplate.contents.jobs.push({
                enum: Object.keys(this.props.selectableJobs)[0],
                cron: "* * * * *",
                flags: [],
            });
            this.props.selectSchedule(selectedTemplate.contents.jobs);
            this.setState({ selectedTemplate });
        }

        setFlags(value, index) {
            let selectedTemplate = this.props.selectedTemplate;
            //filter out removed flags
            selectedTemplate.contents.jobs[index].flags =
                selectedTemplate.contents.jobs[index].flags.filter((flag) =>
                    value.includes(flag.enum)
                );
            //Add selected flags
            value.map((selectedFlag) => {
                if (
                    !selectedTemplate.contents.jobs[index].flags
                        .filter((flag) => flag.enum)
                        .includes(selectedFlag)
                ) {
                    selectedTemplate.contents.jobs[index].flags.push({
                        enum: selectedFlag,
                        arg: "",
                    });
                }
            });
            this.props.selectSchedule(selectedTemplate.contents.jobs);
            this.setState({ selectedTemplate });
        }

        updateFlagArg(value, jobIndex, flagIndex) {
            if (value == "" || isNaN(value)) {
                value = "";
            } else {
                // convert Number to valid uint32
                value = Number(value) >>> 0;
            }
            let selectedTemplate = { ...this.props.selectedTemplate };
            //filter out removed flags
            selectedTemplate.contents.jobs[jobIndex].flags[flagIndex].arg =
                value;
            this.props.selectSchedule(selectedTemplate.contents.jobs);
            this.setState({ selectedTemplate });
        }

        displaySchedule() {
            const { classes } = this.props;
            let result = this.props.selectedTemplate?.contents?.jobs?.map(
                (job, index) => {
                    return (
                        <Box className={classes.jobBox} key={index}>
                            <Container className={classes.cronContainer}>
                                <div>Cron: {job.cron}</div>
                                <Cron
                                    value={job.cron}
                                    setValue={(value) => {
                                        this.setCronValue(value, index);
                                    }}
                                />
                            </Container>
                            <Container className={classes.jobContainer}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        id={"job-select-select-label-" + index}
                                    >
                                        Job
                                    </InputLabel>
                                    <Select
                                        id={
                                            "job-select-select-standard-" +
                                            index
                                        }
                                        labelId={
                                            "job-select-select-label-" + index
                                        }
                                        label="Job"
                                        defaultValue={job.enum}
                                        onChange={(event) => {
                                            this.setJob(
                                                event.target.value,
                                                index
                                            );
                                        }}
                                    >
                                        {Object.keys(
                                            this.props.selectableJobs
                                        ).map((key) => {
                                            return (
                                                <MenuItem
                                                    key={index + key}
                                                    value={key}
                                                >
                                                    {/* Get Job name */}
                                                    {
                                                        Object.entries(
                                                            FirmwareBuoyProtocols.Jobs
                                                        ).filter(
                                                            (arr) =>
                                                                arr[1] == key
                                                        )?.[0]?.[0]
                                                    }
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Container>
                            <Container className={classes.flagContainer}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        id={"flag-select-select-label-" + index}
                                    >
                                        Flags
                                    </InputLabel>
                                    <Select
                                        id={
                                            "flag-select-select-standard-" +
                                            index
                                        }
                                        labelId={
                                            "flag-select-select-label-" + index
                                        }
                                        label="Flags"
                                        multiple
                                        value={job.flags.map(
                                            (flag) => flag.enum
                                        )}
                                        onChange={(event) => {
                                            this.setFlags(
                                                event.target.value,
                                                index
                                            );
                                        }}
                                    >
                                        {Object.entries(
                                            FirmwareBuoyProtocols.JobFlags
                                        )
                                            .filter(
                                                (value) =>
                                                    this.props.selectableJobs[
                                                        job.enum
                                                    ]?.[value[1]] != undefined
                                            )
                                            .map(([key, value]) => {
                                                return (
                                                    <MenuItem
                                                        key={index + key}
                                                        value={value}
                                                        selected={job.flags
                                                            .map(
                                                                (flag) =>
                                                                    flag.enum
                                                            )
                                                            .includes(value)}
                                                    >
                                                        {key}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                    <List>
                                        {job.flags
                                            .filter((value) => {
                                                //In the settings only flags that have arg=0 have an arg. undefined don't
                                                return this.props
                                                    .selectableJobs[job.enum]?.[
                                                    value.enum
                                                ];
                                            })
                                            .map((flag, flagIndex) => {
                                                const flagName = Object.entries(
                                                    FirmwareBuoyProtocols.JobFlags
                                                ).filter(
                                                    (val) => val[1] == flag.enum
                                                )?.[0]?.[0];
                                                return (
                                                    <ListItem
                                                        key={
                                                            job.enum +
                                                            "flag-arg" +
                                                            flagIndex +
                                                            index
                                                        }
                                                        className={
                                                            classes.argListItem
                                                        }
                                                    >
                                                        <TextField
                                                            id="outlined-basic"
                                                            label={flagName}
                                                            defaultValue={
                                                                flag.arg
                                                            }
                                                            value={flag.arg}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                this.updateFlagArg(
                                                                    event.target
                                                                        .value,
                                                                    index,
                                                                    flagIndex
                                                                );
                                                            }}
                                                        />
                                                    </ListItem>
                                                );
                                            })}
                                    </List>
                                </FormControl>
                            </Container>
                            <Container className={classes.deleteContainer}>
                                <Fab
                                    className={classes.addButton}
                                    size="small"
                                    color="primary"
                                    aria-label="add"
                                >
                                    <IconButton aria-label="delete">
                                        <DeleteForeverIcon
                                            sx={{ color: "black" }}
                                            onClick={() => {
                                                this.deleteJob(index);
                                            }}
                                        />
                                    </IconButton>
                                </Fab>
                            </Container>
                        </Box>
                    );
                }
            );
            if (result && result.length > 0) {
                return result;
            }
            //Default return if schedule is empty
            return (
                <p className={classes.empty}>
                    Click
                    <Fab
                        className={classes.addButton}
                        size="small"
                        color="primary"
                        aria-label="add"
                    >
                        <IconButton aria-label="delete">
                            <AddIcon
                                sx={{ color: "black" }}
                                onClick={() => {
                                    this.addJob();
                                }}
                            />
                        </IconButton>
                    </Fab>{" "}
                    to add schedule
                </p>
            );
        }

        render() {
            const { classes } = this.props;

            return (
                <Paper>
                    <div className={classes.container}>
                        <Box component="span" className={classes.box}>
                            {this.displaySchedule()}
                            <Fab
                                className={classes.addButton}
                                size="small"
                                color="primary"
                                aria-label="add"
                            >
                                <IconButton aria-label="add">
                                    <AddIcon
                                        sx={{ color: "black" }}
                                        onClick={() => {
                                            this.addJob();
                                        }}
                                    />
                                </IconButton>
                            </Fab>
                        </Box>
                    </div>
                </Paper>
            );
        }
    }
);

// display checkbox for sendToBuoy by default
BuoyFirmwareConfigForm.defaultProps = {
    displayCheckbox: true,
};

export default withStyles(styles)(BuoyFirmwareConfigForm);
