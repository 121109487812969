import React, { Component } from "react";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import withStyles from "@mui/styles/withStyles";
import ShellfishInventoryAPIClient from "../../models/ShellfishInventoryAPIClient";
import { Paper, Grid, Box } from "@mui/material";
import RTable from "../../pages/components/RTable";
import Typography from "@mui/material/Typography";
import RTDisplayUtils from "@running-tide/rt-frontend-helpers/helpers";
import NumberFormat from "react-number-format";
import { TextField, Autocomplete } from "@mui/material";
import { ShellfishTypeToJson } from "@running-tide/farm-system/models/enums";
const styles = (theme) => ({
    paper: {
        padding: theme.spacing(3),
        margin: theme.spacing(2),
        textAlign: "left",
    },
});

let vs = WithGoogleAuth(
    class VesselStatus extends Component {
        constructor(props) {
            super(props);
            this.state = {
                vessels: [],
                shellfishType: -1,
                shellfishName: "",
                shellfishTypes: [],
            };
        }

        componentDidMount() {
            new ShellfishInventoryAPIClient(this.props.authState)
                .getOysterSizeData()
                .then((res) => res.json())
                .then((res) => {
                    const shellfishMenuItems = Object.keys(
                        res.gradeResults
                    ).map((key) => ShellfishTypeToJson(parseInt(key)));
                    //Add total to each gradeResult. (sorted by shellfish_type)
                    if (res.growOutData.length > 0) {
                        res.gradeResults.forEach((gradeResult) => {
                            let count = gradeResult.reduce((a, b) => {
                                return a + b.count;
                            }, 0);
                            gradeResult.push({
                                grade: "total",
                                count: count,
                                shellfishType: -1,
                            });
                        });
                    }

                    this.setState({
                        reefResults: res.reefResults,
                        gradeResults: res.gradeResults,
                        farmResults: res.farmResults,
                        growOutData: res.growOutData,
                        lastUpdated: res.lastUpdated,
                        shellfishTypes: shellfishMenuItems,
                        shellfishType: shellfishMenuItems[0]?.val,
                        shellfishName: RTDisplayUtils.printShellfishType(
                            shellfishMenuItems[0]?.val
                        ),
                    });
                });
        }

        /**
         * Change the data displayed on page based on user selection in dropdown menu
         */
        handleShellfishTypeChange = (event, value) => {
            if (value && value.val !== null) {
                this.setState({
                    shellfishType: value.val,
                    shellfishName: RTDisplayUtils.printShellfishType(value.val),
                });
            }
        };

        render() {
            const { classes } = this.props;
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            {RTDisplayUtils.printShellfishType(
                                this.state.shellfishType
                            )}{" "}
                            Counts Last Updated:{" "}
                            {RTDisplayUtils.printDate(this.state.lastUpdated)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            <Autocomplete
                                disablePortal
                                id="shellfish-type"
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                value={{
                                    val: this.state.shellfishType,
                                    name: this.state.shellfishName,
                                }}
                                options={this.state.shellfishTypes}
                                sx={{ width: 300 }}
                                onChange={this.handleShellfishTypeChange}
                                renderOption={(props, option) => {
                                    const { val } = option;
                                    return (
                                        <span {...props}>
                                            {RTDisplayUtils.printShellfishType(
                                                val
                                            )}
                                        </span>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Shellfish Type"
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid item lg={2} xs={12}>
                        <Paper
                            variant="outlined"
                            className={classes.binLocation}
                        >
                            <RTable
                                title={
                                    <Typography variant="h3">
                                        Total{" "}
                                        {RTDisplayUtils.printShellfishType(
                                            this.state.shellfishType
                                        )}{" "}
                                        Counts
                                    </Typography>
                                }
                                columns={[
                                    {
                                        title: "Grade",
                                        field: "grade",
                                        render: (rowData) => {
                                            return (
                                                <div>
                                                    {RTDisplayUtils.prettyPrintGrade(
                                                        rowData.grade
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        title: "Count",
                                        field: "count",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.count}
                                                />
                                            );
                                        },
                                    },
                                ]}
                                data={
                                    this.state.gradeResults
                                        ? this.state.gradeResults[
                                              this.state.shellfishType
                                          ]
                                        : []
                                }
                                options={{
                                    search: false,
                                    maxColumnSort: 0,
                                    filtering: false,
                                    paging: false,
                                    idSynonym: "grade",
                                }}
                            ></RTable>
                        </Paper>
                    </Grid>
                    <Grid item lg={10} xs={12}>
                        <Paper
                            variant="outlined"
                            className={classes.binLocation}
                        >
                            <RTable
                                title={
                                    <Typography variant="h3">
                                        {RTDisplayUtils.printShellfishType(
                                            this.state.shellfishType
                                        )}{" "}
                                        Counts By Reef
                                    </Typography>
                                }
                                columns={[
                                    {
                                        title: "Farm Name",
                                        field: "farmName",
                                        render: (rowData) => {
                                            return (
                                                <div>{rowData.farmName}</div>
                                            );
                                        },
                                    },
                                    {
                                        title: "Reef Name",
                                        field: "reefName",
                                        render: (rowData) => {
                                            return (
                                                <div>{rowData.reefName}</div>
                                            );
                                        },
                                    },
                                    {
                                        title: "W0",
                                        field: "w0",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W0}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W1",
                                        field: "w1",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W1}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W2",
                                        field: "w2",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W2}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W3",
                                        field: "w3",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W3}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W4",
                                        field: "w4",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W4}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W5",
                                        field: "w5",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W5}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W6",
                                        field: "w6",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W6}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W7",
                                        field: "w7",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W7}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W8",
                                        field: "w8",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W8}
                                                />
                                            );
                                        },
                                    },
                                ]}
                                data={
                                    this.state.reefResults
                                        ? this.state.reefResults[
                                              this.state.shellfishType
                                          ]
                                        : []
                                }
                                s
                                options={{
                                    search: false,
                                    maxColumnSort: 0,
                                    filtering: false,
                                    paging: false,
                                    idSynonym: "reefName",
                                }}
                            ></RTable>
                        </Paper>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Paper
                            variant="outlined"
                            className={classes.binLocation}
                        >
                            <RTable
                                title={
                                    <Typography variant="h3">
                                        {RTDisplayUtils.printShellfishType(
                                            this.state.shellfishType
                                        )}{" "}
                                        Counts By Farm
                                    </Typography>
                                }
                                columns={[
                                    {
                                        title: "Farm Name",
                                        field: "farmName",
                                        render: (rowData) => {
                                            return (
                                                <div>{rowData.farmName}</div>
                                            );
                                        },
                                    },
                                    {
                                        title: "W0",
                                        field: "w0",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W0}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W1",
                                        field: "w1",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W1}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W2",
                                        field: "w2",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W2}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W3",
                                        field: "w3",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W3}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W4",
                                        field: "w4",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W4}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W5",
                                        field: "w5",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W5}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W6",
                                        field: "w6",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W6}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W7",
                                        field: "w7",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W7}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W8",
                                        field: "w8",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W8}
                                                />
                                            );
                                        },
                                    },
                                ]}
                                data={
                                    this.state.farmResults
                                        ? this.state.farmResults[
                                              this.state.shellfishType
                                          ]
                                        : []
                                }
                                options={{
                                    search: false,
                                    maxColumnSort: 0,
                                    filtering: false,
                                    paging: false,
                                    idSynonym: "farmName",
                                }}
                            ></RTable>
                        </Paper>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Paper
                            variant="outlined"
                            className={classes.binLocation}
                        >
                            <RTable
                                title={
                                    <Typography variant="h3">
                                        Bin Breakdown By Farm
                                    </Typography>
                                }
                                columns={[
                                    {
                                        title: "Farm Name",
                                        field: "farmName",
                                        render: (rowData) => {
                                            return (
                                                <div>{rowData.farmName}</div>
                                            );
                                        },
                                    },
                                    {
                                        title: "W0",
                                        field: "w0binCount",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W0binCount}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W1",
                                        field: "w1binCount",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W1binCount}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W2",
                                        field: "w2binCount",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W2binCount}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W3",
                                        field: "w3binCount",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W3binCount}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W4",
                                        field: "w4binCount",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W4binCount}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W5",
                                        field: "w5binCount",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W5binCount}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W6",
                                        field: "w6binCount",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W6binCount}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W7",
                                        field: "w7binCount",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W7binCount}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: "W8",
                                        field: "w8binCount",
                                        render: (rowData) => {
                                            return (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.W8binCount}
                                                />
                                            );
                                        },
                                    },
                                ]}
                                data={
                                    this.state.farmResults
                                        ? this.state.farmResults[
                                              this.state.shellfishType
                                          ]
                                        : []
                                }
                                options={{
                                    search: false,
                                    maxColumnSort: 0,
                                    filtering: false,
                                    paging: false,
                                    idSynonym: "farmName",
                                }}
                            ></RTable>
                        </Paper>
                    </Grid>
                </Grid>
            );
        }
    }
);
export default withStyles(styles)(vs);
