import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import RTable from "./components/RTable";
import APIClient from "../models/APIClient";
import { WithGoogleAuth } from "../config/WithGoogleAuth";
import moment from "moment-timezone";
import Link from "@mui/material/Link";
import FullKelpMap from "./components/FullKelpMapComponent";
import BuoyStatus from "./helper/BuoyStatus";

const strToMoment = (time) =>
    moment(time, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz(moment.tz.guess());

const formatTime = (time) => strToMoment(time).format("YYYY-MM-DD HH:mm:ss z");
const ignoreBatteryLabels = ["trajectory-buoy", "gf-buoy"];
const voltageObservedProperty = "Voltage";
const standardBatteryThreshold = 12.5;
const firmwareBatteryLabels = ["accel-buoy"];
const firmwareBatteryThreshold = 3.74;

let KelpBuoys = WithGoogleAuth(
    class KelpBuoys extends Component {
        constructor(props) {
            super(props);
            this.thingSelector = props.thingSelector;
            this.state = { buoys: null, buoysPowerStatus: null };
        }

        async componentDidMount() {
            const apiClient = new APIClient(this.props.authState);
            const [buoys, buoysPowerStatus, firmwareLatestPower] =
                await Promise.all([
                    apiClient.getThingAll(
                        this.thingSelector,
                        1,
                        null,
                        null,
                        null,
                        ["GPS", "latitude", "Buoy Update", "Image"]
                    ),
                    apiClient.getBuoysPowerStatus(this.thingSelector),
                    apiClient.getLatestPropertyObservationForThing(
                        this.thingSelector,
                        voltageObservedProperty
                    ),
                ]);
            for (const obs of firmwareLatestPower) {
                obs.id in buoysPowerStatus ||
                    (buoysPowerStatus[obs.id] = {
                        batteryVolts: obs.result_numeric,
                    });
            }
            this.setState({ buoys, buoysPowerStatus });
        }

        latestData = (buoys) => {
            if (buoys == null) {
                return [];
            }
            const buoyData = buoys.map((buoy) => {
                const allSensorObservations = buoy.datastreams
                    .map((datastream) => datastream.sensorObservations)
                    .flat();
                const gpsObservations = buoy.datastreams
                    .filter(
                        (datastream) =>
                            datastream.name === "GPS" ||
                            datastream.name === "latitude"
                    )
                    .map((datastream) => datastream.sensorObservations)
                    .flat();
                const buoyUpdates = buoy.datastreams
                    .filter((datastream) => datastream.name === "Buoy Update")
                    .map((datastream) => datastream.sensorObservations)
                    .flat();
                const images = buoy.datastreams
                    .filter((datastream) => datastream.name === "Image")
                    .map((datastream) => datastream.sensorObservations)
                    .flat();

                let ignoreBatteryHealth = false;
                let batteryThreshold = standardBatteryThreshold;
                for (const label of buoy.selectorLabels) {
                    if (
                        !ignoreBatteryHealth &&
                        ignoreBatteryLabels.includes(label)
                    ) {
                        ignoreBatteryHealth = true;
                    }
                    if (
                        batteryThreshold != firmwareBatteryThreshold &&
                        firmwareBatteryLabels.includes(label)
                    ) {
                        batteryThreshold = firmwareBatteryThreshold;
                    }
                }

                return {
                    name: buoy.name,
                    lastContact: allSensorObservations
                        .map((so) => strToMoment(so.resultTime))
                        .reduce(
                            (a, b) => moment.max(a, b),
                            moment().subtract(1, "years")
                        )
                        .format("YYYY-MM-DD HH:mm:ss z"),
                    gpsDisplay: gpsObservations.length
                        ? `${JSON.stringify(
                              gpsObservations[0].resultLocation
                          )} ${formatTime(gpsObservations[0].resultTime)}`
                        : "N/A",
                    lastUpdate: buoyUpdates.length
                        ? formatTime(buoyUpdates[0].resultTime)
                        : "N/A",
                    imageTime: images.length
                        ? formatTime(images[0].resultTime)
                        : "N/A",
                    thingID: buoy.id,
                    batteryReading:
                        this.state.buoysPowerStatus[buoy.id]?.batteryVolts,
                    ignoreBatteryHealth,
                    batteryThreshold,
                };
            });

            // Sort buoys by last contact.
            buoyData.sort((a, b) => a.lastContact - b.lastContact);
            return buoyData;
        };

        render() {
            const buoyData = this.latestData(this.state.buoys);
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FullKelpMap buoys={this.state.buoys} />
                    </Grid>
                    <Grid item xs={12}>
                        <RTable
                            columns={[
                                {
                                    title: "Status",
                                    field: "name",
                                    render: (rowData) => (
                                        <BuoyStatus
                                            lastContact={rowData.lastContact}
                                            batteryReading={
                                                rowData.batteryReading
                                            }
                                            ignoreBatteryHealth={
                                                rowData.ignoreBatteryHealth
                                            }
                                            batteryThreshold={
                                                rowData.batteryThreshold
                                            }
                                        />
                                    ),
                                },
                                {
                                    title: "Buoy ID",
                                    field: "name",
                                    render: (rowData) => (
                                        <Link
                                            href={`/kelp_buoy/${rowData.thingID}`}
                                        >
                                            {rowData.name}
                                        </Link>
                                    ),
                                },
                                {
                                    title: "Last Contact",
                                    field: "lastContact",
                                    render: (rowData) =>
                                        `${moment(
                                            rowData.lastContact,
                                            "YYYY-MM-DD HH:mm:ss z"
                                        ).fromNow()}`,
                                },
                                {
                                    title: "Last Contact",
                                    field: "lastContact",
                                },
                                {
                                    title: "Last Location",
                                    field: "gpsDisplay",
                                },
                                {
                                    title: "Last Schedule Update",
                                    field: "lastUpdate",
                                },
                                {
                                    title: "Last Photo Sent",
                                    field: "imageTime",
                                },
                            ]}
                            data={buoyData}
                            options={{
                                search: false,
                                filtering: true,
                                paging: false,
                                idSynonym: "name",
                                showTitle: false,
                                toolbar: false,
                            }}
                        ></RTable>
                    </Grid>
                </Grid>
            );
        }
    }
);
export default KelpBuoys;
