import React, { Component } from "react";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import {
    FormControl,
    Select,
    MenuItem,
    Box,
    Snackbar,
    Paper,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import OysterCountForm from "./OysterCountForm";
import SeedAdditionForm from "./SeedAdditionForm";
import { connect } from "react-redux";
import Alert from "@mui/material/Alert";
import OysterBinMoveForm from "./OysterBinMoveForm";
import withStyles from "@mui/styles/withStyles";
import NgvToLgvReallocForm from "./NgvToLgvReallocForm";

const styles = (theme) => ({
    root: {},
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        padding: 10,
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: theme.divider,
    },
});

let FEntry = WithGoogleAuth(
    class FormEntry extends Component {
        constructor(props) {
            super(props);
            this.state = {
                entryType: "shellfish-count",
                openMessageOpen: false,
            };
            this.onEntryTypeChange = this.onEntryTypeChange.bind(this);
            this.showSubmittedMessage = this.showSubmittedMessage.bind(this);
        }

        componentDidMount() {}

        onEntryTypeChange = (event) => {
            this.setState({ entryType: event.target.value });
        };

        renderSubForm() {
            if (this.state.entryType === "shellfish-count") {
                return (
                    <OysterCountForm
                        showSubmittedMessage={this.showSubmittedMessage}
                        showErrorMessage={this.showErrorMessage}
                    ></OysterCountForm>
                );
            } else if (this.state.entryType === "seed-addition") {
                return (
                    <SeedAdditionForm
                        showSubmittedMessage={this.showSubmittedMessage}
                        showErrorMessage={this.showErrorMessage}
                    ></SeedAdditionForm>
                );
            } else if (this.state.entryType === "move-shellfish") {
                return (
                    <OysterBinMoveForm
                        showSubmittedMessage={this.showSubmittedMessage}
                        showErrorMessage={this.showErrorMessage}
                    ></OysterBinMoveForm>
                );
            } else if (this.state.entryType === "reallocate-ngv-to-lgv") {
                return (
                    <NgvToLgvReallocForm
                        showSubmittedMessage={this.showSubmittedMessage}
                        showErrorMessage={this.showErrorMessage}
                    ></NgvToLgvReallocForm>
                );
            }
        }

        showSubmittedMessage = () => {
            this.setState({
                message: "Success",
                errorMessage: undefined,
                openMessageOpen: true,
            });
        };

        showErrorMessage = (message) => {
            this.setState({
                errorMessage: message,
                message: undefined,
                openMessageOpen: true,
            });
        };

        closeMessage = (event, reason) => {
            if (reason === "clickaway") {
                return;
            }
            this.setState({ openMessageOpen: false });
        };

        message() {
            if (this.state.message !== undefined) {
                return <Alert severity="success">{this.state.message}</Alert>;
            } else if (this.state.errorMessage !== undefined) {
                return (
                    <Alert severity="error">{this.state.errorMessage}</Alert>
                );
            } else {
                return <div></div>;
            }
        }

        render() {
            const { classes } = this.props;
            return (
                <Box className={classes.root} display="flex">
                    <Paper className={classes.paper}>
                        <FormControl className={classes.formControl}>
                            <Typography variant="h2" component="h2">
                                Forms
                            </Typography>
                            <Divider className={classes.divider} />
                            <Select
                                onChange={this.onEntryTypeChange}
                                id="demo-simple-select"
                                value={this.state.entryType}
                                defaultValue={"shellfish-count"}
                            >
                                <MenuItem value={"shellfish-count"}>
                                    Shellfish Count
                                </MenuItem>
                                <MenuItem value={"seed-addition"}>
                                    Seed Addition
                                </MenuItem>
                                <MenuItem value={"move-shellfish"}>
                                    Move Shellfish
                                </MenuItem>
                                <MenuItem value={"reallocate-ngv-to-lgv"}>
                                    Reallocate From NGV to LGV
                                </MenuItem>
                                {/* <MenuItem value={"condition"}>Equipment Condition</MenuItem> */}
                            </Select>
                            <Box>{this.renderSubForm()}</Box>
                        </FormControl>
                    </Paper>
                    <Snackbar
                        open={this.state.openMessageOpen}
                        autoHideDuration={6000}
                        onClose={this.closeMessage}
                    >
                        {this.message()}
                    </Snackbar>
                </Box>
            );
        }
    }
);

const mapStateToProps = (state) => ({
    loggedInUser: state.data.loggedInUser,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(FEntry));
