/**
 * A component that lazy loads a buoy's images.
 */

import React, { Component } from "react";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import APIClient from "../../models/APIClient";
import ImageList from "@mui/material/ImageList";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ImageListItem from "@mui/material/ImageListItem";
import moment from "moment-timezone";

class ImageExplorer extends Component {
    constructor(props) {
        super(props);
        this.fetchImages();
        this.state = {
            images: [],
            totalPixelsForAllImages: 100,
        };
    }

    async fetchImages() {
        const apiClient = new APIClient(this.props.authState);
        const images = await apiClient.fetchBuoyImages(this.props.buoyId);
        //find out the width of all of the photos together so container isnt too long
        const pxWidth = images.length * 300;
        //most recent photos first
        this.setState({
            images: images,
            totalPixelsForAllImages: pxWidth,
        });
        return images;
    }

    getImageTimestamp(url) {
        const timestamp = url.match(/\d{4}-\d{2}-\d{2}_\d{2}-\d{2}-\d{2}/);
        const formattedTimestamp = moment(
            timestamp,
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
        ).format("YYYY-MM-DD HH:mm:ss Z");

        return formattedTimestamp;
    }

    getImageNameSize(url, size) {
        const cameraName = url.match(/CAM_\d*/);
        return cameraName + " - [" + size + " bytes]";
    }

    render() {
        return (
            <Paper
                style={{
                    paddingTop: 24,
                    paddingLeft: 24,
                    paddingRight: 24,
                    maxHeight: 900,
                    overflowX: "scroll",
                }}
            >
                <Typography
                    variant="h6"
                    style={{ marginBottom: 24, position: "sticky", left: 0 }}
                >
                    Buoy Images
                </Typography>
                <ImageList
                    variant="masonry"
                    cols={this.state.images.length}
                    gap={8}
                    rowHeight={400}
                    style={{ width: this.state.totalPixelsForAllImages }}
                >
                    {this.state.images.map((image) => (
                        <ImageListItem key={image.url} rows={1}>
                            <img
                                src={image.url}
                                alt={"Kelp Buoy"}
                                loading="lazy"
                                style={{ objectFit: "contain" }}
                            />
                            <ImageListItemBar
                                position="bottom"
                                title={this.getImageTimestamp(image.url)}
                                subtitle={this.getImageNameSize(
                                    image.url,
                                    image.size
                                )}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Paper>
        );
    }
}

export default WithGoogleAuth(ImageExplorer);
