import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { Grid, Box, Typography } from "@mui/material";
import RTable from "../components/RTable";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import MessageHelper from "../helper/MessageHelper";
import APIClient from "../../models/APIClient";
import sortNumericNull from "../helper/SortNumericNull";

const styles = (theme) => ({
    table: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
});

const ThingGroupingsManager = WithGoogleAuth(
    class ThingGroupingsManager extends Component {
        constructor(props) {
            super(props);
            this.state = {
                thingGroupings: [],
                isLoading: true,
            };
        }

        componentDidMount() {
            this.getThingGroupings();
        }
        async getThingGroupings() {
            const apiClient = new APIClient(this.props.authState);
            try {
                const thingGroupings = await apiClient.fetchThingGroupings();
                thingGroupings.sort((a, b) =>
                    sortNumericNull(a.tab_order, b.tab_order)
                );
                thingGroupings.forEach(
                    (_, index) => (thingGroupings[index].tab_order = index)
                );
                this.setState({
                    thingGroupings,
                    isLoading: false,
                });
            } catch (e) {
                console.error(e);
            }
        }

        onRowUpdate = (newData) =>
            new Promise((resolve, reject) => {
                const newDataUpdate = { ...newData };
                function isGrouping(grouping) {
                    return grouping.id === newData.id;
                }
                new APIClient(this.props.authState)
                    .updateThingGrouping(newDataUpdate)
                    .then(async (res) => {
                        if (res.status !== 200) {
                            res = await res.json();
                            this.setState(errorMessage(res));
                            reject();
                            return;
                        }
                        //Update State
                        const thingGroupings = [...this.state.thingGroupings],
                            i = thingGroupings.findIndex(isGrouping);
                        thingGroupings[i] = newData;
                        this.setState({ thingGroupings });
                        this.setState(successMessage("Successfully updated"));
                        resolve();
                    })
                    .catch((e) => {
                        this.setState(errorMessage(e));
                        reject();
                    });
            });

        onRowAdd = (newData) =>
            new Promise((resolve, reject) => {
                const newDataUpdate = { ...newData };
                new APIClient(this.props.authState)
                    .createThingGrouping(newDataUpdate)
                    .then(async (res) => {
                        if (res.status !== 200) {
                            res = await res.json();
                            this.setState(errorMessage(res));
                            reject();
                            return;
                        }
                        //Update State
                        res = await res.json();
                        newData.id = res.id;
                        const thingGroupings = [...this.state.thingGroupings];
                        thingGroupings.unshift(newData);
                        this.setState({
                            thingGroupings,
                        });
                        this.setState(successMessage("Successfully added"));
                        resolve();
                    })
                    .catch((e) => {
                        this.setState(errorMessage(e));
                        reject();
                    });
            });

        onRowDelete = (oldData) =>
            new Promise((resolve) => {
                new APIClient(this.props.authState)
                    .deleteThingGrouping(oldData)
                    .then(async (res) => {
                        if (res.status !== 200) {
                            res = await res.json();
                            this.setState(errorMessage(res));
                            resolve();
                            return;
                        }
                        //Update State
                        res = await res.json();
                        let thingGroupings = [...this.state.thingGroupings];
                        thingGroupings = thingGroupings.filter(
                            (grouping) => grouping.id !== oldData.id
                        );
                        this.setState({
                            thingGroupings,
                        });
                        this.setState(successMessage("Successfully deleted"));
                        resolve();
                    })
                    .catch((e) => {
                        this.setState(errorMessage(e));
                        resolve();
                    });
            });

        async setDefaultTab(groupId) {
            let thingGroupings = [...this.state.thingGroupings];
            // Find oldDefault (if it exists) & update so it's no longer default_tab
            const oldDefault = thingGroupings.find((group, index, array) => {
                if (group.default_tab === true) {
                    array[index] = { ...group, default_tab: false };
                    return true;
                }
                return false;
            });
            const newDefault = thingGroupings.find((group, index, array) => {
                if (group.id === groupId) {
                    array[index] = { ...group, default_tab: true };
                    return true;
                }
                return false;
            });
            const apiClient = new APIClient(this.props.authState);
            await Promise.all([
                oldDefault &&
                    apiClient.updateThingGrouping({
                        ...oldDefault,
                        default_tab: false,
                    }),
                apiClient.updateThingGrouping({
                    ...newDefault,
                    default_tab: true,
                }),
            ]);
            this.setState({
                thingGroupings,
            });
        }

        async swapTabOrder(groupId, swapPosition) {
            let thingGroupings = [...this.state.thingGroupings];
            const groupIndex = thingGroupings.findIndex(
                (group) => group.id === groupId
            );
            const swapIndex = groupIndex + swapPosition;

            const swapGroup = {
                ...thingGroupings[swapIndex],
                tab_order: groupIndex,
            };
            thingGroupings[swapIndex] = {
                ...thingGroupings[groupIndex],
                tab_order: swapIndex,
            };
            thingGroupings[groupIndex] = swapGroup;

            const apiClient = new APIClient(this.props.authState);
            await Promise.all([
                apiClient.updateThingGrouping({
                    ...thingGroupings[swapIndex],
                }),
                apiClient.updateThingGrouping({
                    ...thingGroupings[groupIndex],
                }),
            ]);
            this.setState({
                thingGroupings,
            });
        }

        render() {
            const { classes } = this.props;
            return (
                <Grid>
                    <Grid item lg={1} xs={12}>
                        <MessageHelper
                            message={this.state.message}
                            errorMessage={this.state.errorMessage}
                            open={this.state.messageOpen}
                            setState={(a) => this.setState(a)}
                        />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Box>
                            <RTable
                                className={classes.table}
                                title={
                                    <Typography variant="h3">
                                        Thing Groupings Manager
                                    </Typography>
                                }
                                columns={[
                                    {
                                        title: "Selector Label",
                                        field: "selector_label",
                                    },
                                    {
                                        title: "Name",
                                        field: "name",
                                    },
                                ]}
                                data={this.state.thingGroupings}
                                editable={{
                                    isEditable: () => true,
                                    onRowAddCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowUpdateCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowAdd: (newData) =>
                                        this.onRowAdd(newData),
                                    onRowUpdate: (newData, oldData) =>
                                        this.onRowUpdate(newData, oldData),
                                    onRowDelete: (oldData) =>
                                        this.onRowDelete(oldData),
                                }}
                                isLoading={this.state.isLoading}
                                actions={[
                                    (rowData) => ({
                                        icon: rowData.default_tab
                                            ? "star"
                                            : "star_outline",
                                        tooltip: rowData.default_tab
                                            ? "Default Tab"
                                            : "Set Default Tab",
                                        onClick: (_, rowData) =>
                                            this.setDefaultTab(rowData.id),
                                        disabled: rowData.default_tab,
                                    }),
                                    (rowData) =>
                                        rowData.tab_order !== 0 && {
                                            icon: "arrow_circle_up",
                                            tooltip: "Move Tab Up",
                                            onClick: (_, rowData) =>
                                                this.swapTabOrder(
                                                    rowData.id,
                                                    -1
                                                ),
                                        },
                                    (rowData) =>
                                        rowData.tab_order !==
                                            this.state.thingGroupings.length -
                                                1 && {
                                            icon: "arrow_circle_down",
                                            tooltip: "Move Tab Down",
                                            onClick: (_, rowData) =>
                                                this.swapTabOrder(
                                                    rowData.id,
                                                    1
                                                ),
                                        },
                                ]}
                                options={{
                                    search: true,
                                    maxColumnSort: 1,
                                    filtering: true,
                                    paging: true,
                                    // always display the newly-added row at the beginning of the table
                                    addRowPosition: "first",
                                }}
                            ></RTable>
                        </Box>
                    </Grid>
                </Grid>
            );
        }
    }
);

export default withStyles(styles)(ThingGroupingsManager);
