import React, { Component } from "react";
import {
    Autocomplete,
    Stack,
    Button,
    TextField,
    Typography,
    IconButton,
    Tooltip,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";

import { errorMessage, successMessage } from "../helper/MessageMethodHelper";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import MessageHelper from "../helper/MessageHelper";
import RTable from "../components/RTable";

const styles = (theme) => ({
    overheadTextField: {
        marginTop: "2rem",
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    dataFields: {
        marginBottom: theme.spacing(1),
    },
    propertyField: {
        minWidth: 170,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    leftPropertyField: {
        minWidth: 170,
        marginRight: theme.spacing(1),
    },
    idPropertyField: {
        minWidth: 270,
        marginLeft: theme.spacing(1),
    },
    testConceptField: {
        minWidth: 240,
    },
    createButton: {
        marginTop: theme.spacing(2),
    },
    icon: {
        marginTop: "1.5rem",
        marginBottom: "0.5rem",
    },
});

class TestInstanceCreator extends Component {
    state = {
        testConcepts: [],
        testConcept: "",
        expectedData: [],
        expectedConstants: [],
        constants: [],
        isCreating: false,
        tableId: 0,
        toCreate: [],
        dataColumns: [],
        timeIndex: 0,
        dataIndex: 0,
        successfulCreations: [],
        failedCreations: [],
        activeTestId: "",
    };

    async componentDidMount() {
        // Fetch a list of test concepts & product instances for the user to choose
        // from
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        // TODO {Nikhil} if this query slows significantly when more instances are
        // added, create a spinner to convey that to the user
        const testConcepts = await apiClient.getTestConcepts();

        this.setState(
            {
                testConcepts,
            },
            () => {
                const autofilledTestConcept =
                    this.getTestConceptFromId(testConcepts);
                this.onConceptSelected(autofilledTestConcept);
            }
        );
    }

    /**
     * Returns the test concept name from the URL if it is valid and null otherwise.
     *
     */
    getTestConceptFromId = (testConcepts) => {
        const testConceptId = new URLSearchParams(
            this.props.location.search
        ).get("testConcept");

        const testConceptsWithId = testConcepts.filter(
            (testConcept) => testConcept.id === testConceptId
        );

        if (testConceptsWithId.length === 1) {
            return testConceptsWithId[0];
        } else {
            return null;
        }
    };

    async createTestInstances() {
        this.setState({ isCreating: true });
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        const constantHeaders = [
            "creationDate",
            "batchIndex",
            "productIndex",
            "startDate",
            ...this.state.constants.map((constant) => constant.name),
        ];
        //create the actual header
        const headerRow = [
            ...constantHeaders,
            ...this.state.dataColumns.map((column) => column.title),
        ];
        //create the header for indexing correctly
        const headerIndexRow = [
            ...constantHeaders,
            ...this.state.dataColumns.map((column) => column.field),
        ];

        // get data in str[][]
        const data = [
            headerRow,
            ...this.state.toCreate.map((rowObj) =>
                headerIndexRow.map((header) => rowObj[header])
            ),
        ];
        try {
            const { successes, failures } =
                await apiClient.createTestInstancesFromData(
                    data,
                    this.state.activeTestId
                );
            this.setState({
                ...successMessage(`Test instances created!`),
                successfulCreations: successes,
                failedCreations: failures,
                isCreating: false,
                toCreate: [],
            });
        } catch (error) {
            this.setState({
                ...errorMessage(error),
                isCreating: false,
            });
        }
    }

    onConceptSelected(conceptObj) {
        if (conceptObj) {
            const testConcept = conceptObj.name;
            const { id, data, constants } = this.state.testConcepts.find(
                (concept) => concept.name === testConcept
            );
            const expectedData = Object.keys(data).map((name) => {
                return { name, type: data[name] };
            });
            expectedData.sort();
            let requiredConstants = [];
            if (constants) {
                requiredConstants = Object.keys(constants).map((constant) => {
                    return { name: constant, value: "" };
                });
            }
            this.setState({
                activeTestId: id,
                testConcept,
                expectedData,
                constants: requiredConstants,
                expectedConstants: constants,
            });
        } else {
            this.setState({ testConcept: "", expectedData: [], constants: [] });
        }
    }

    render() {
        return (
            <Stack justifyContent="start" alignItems="start">
                <Typography variant="h3" className={this.props.classes.title}>
                    Create Test Instances
                </Typography>
                <Autocomplete
                    inputValue={this.state.testConcept}
                    onChange={(_, newConcept) =>
                        this.onConceptSelected(newConcept)
                    }
                    options={this.state.testConcepts}
                    renderInput={(params) => (
                        <TextField {...params} label="Test Concept" />
                    )}
                    getOptionLabel={(option) => option.name}
                    className={this.props.classes.testConceptField}
                />
                <RTable
                    style={{
                        width: "100%",
                    }}
                    title={
                        <Stack paddingTop={2} spacing={1}>
                            <Typography variant="h3">
                                Instances to Create
                            </Typography>
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography>Add/Remove Data Columns</Typography>
                                <Tooltip
                                    title="Add a data column set"
                                    placement="top-end"
                                >
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            const prevDataCol = [
                                                ...this.state.dataColumns,
                                            ];
                                            prevDataCol.push({
                                                title: "time",
                                                field: `time${this.state.timeIndex}`,
                                                editComponent: (props) => (
                                                    <TextField
                                                        type="datetime-local"
                                                        value={props.value}
                                                        onChange={(e) =>
                                                            props.onChange(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                ),
                                            });
                                            const newTimeIndex =
                                                this.state.timeIndex + 1;
                                            let dataInd = this.state.dataIndex;
                                            const newDataCol = [
                                                ...prevDataCol,
                                                ...this.state.expectedData.map(
                                                    (datapoint) => {
                                                        const col = {
                                                            title: datapoint.name,
                                                            field: `${datapoint.name}${dataInd}`,
                                                            editComponent: (
                                                                props
                                                            ) => (
                                                                <TextField
                                                                    type="text"
                                                                    value={
                                                                        props.value
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        props.onChange(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            ),
                                                        };
                                                        dataInd += 1;
                                                        return col;
                                                    }
                                                ),
                                            ];
                                            this.setState({
                                                dataColumns: newDataCol,
                                                timeIndex: newTimeIndex,
                                                dataIndex: dataInd,
                                            });
                                        }}
                                    >
                                        <AddRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title="Remove last data column set"
                                    placement="top-end"
                                >
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            const newDataCol = [
                                                ...this.state.dataColumns,
                                            ].slice(
                                                0,
                                                -1 *
                                                    (1 +
                                                        Object.keys(
                                                            this.state
                                                                .expectedData
                                                        ).length)
                                            );
                                            this.setState({
                                                dataColumns: newDataCol,
                                            });
                                        }}
                                    >
                                        <RemoveRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    }
                    columns={[
                        {
                            title: "Product Creation Date",
                            field: "creationDate",
                            editComponent: (props) => (
                                <TextField
                                    type="date"
                                    value={props.value}
                                    onChange={(e) =>
                                        props.onChange(e.target.value)
                                    }
                                />
                            ),
                        },
                        {
                            title: "Product Batch Index",
                            field: "batchIndex",
                            editComponent: (props) => {
                                return (
                                    <TextField
                                        value={props.value}
                                        onChange={(e) =>
                                            props.onChange(e.target.value)
                                        }
                                    />
                                );
                            },
                        },
                        {
                            title: "Product Index",
                            field: "productIndex",
                            editComponent: (props) => {
                                return (
                                    <TextField
                                        value={props.value}
                                        onChange={(e) =>
                                            props.onChange(e.target.value)
                                        }
                                    />
                                );
                            },
                        },
                        {
                            title: "Start Date",
                            field: "startDate",
                            editComponent: (props) => {
                                return (
                                    <TextField
                                        type="datetime-local"
                                        value={props.value}
                                        onChange={(e) =>
                                            props.onChange(e.target.value)
                                        }
                                    />
                                );
                            },
                        },
                        ...Object.keys(this.state.expectedConstants).map(
                            (constant) => {
                                return {
                                    title: constant,
                                    field: constant,
                                    editComponent: (props) => {
                                        return (
                                            <TextField
                                                value={props.value}
                                                onChange={(e) =>
                                                    props.onChange(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        );
                                    },
                                };
                            }
                        ),
                        ...this.state.dataColumns,
                    ]}
                    data={this.state.toCreate}
                    editable={{
                        onRowAdd: (newData) => {
                            return new Promise((resolve) => {
                                const dataWithId = newData;
                                dataWithId["id"] = this.state.tableId;
                                const newId = this.state.tableId + 1;
                                const newArr = this.state.toCreate;
                                newArr.push(dataWithId);
                                this.setState({
                                    toCreate: newArr,
                                    tableId: newId,
                                });
                                resolve();
                            });
                        },
                        onRowUpdate: (newData, oldData) => {
                            return new Promise((resolve) => {
                                const replacementIndex =
                                    this.state.toCreate.findIndex(
                                        (row) => row.id === oldData.id
                                    );
                                const newState = this.state.toCreate;
                                newState[replacementIndex] = newData;
                                this.setState({ toCreate: [...newState] });
                                resolve();
                            });
                        },
                        onRowDelete: (oldData) => {
                            return new Promise((resolve) => {
                                const prevData = [...this.state.toCreate];
                                const newArr = prevData.filter(
                                    (element) => element.id !== oldData.id
                                );
                                this.setState({ toCreate: newArr });
                                resolve();
                            });
                        },
                    }}
                />
                <Button
                    variant="secondary"
                    onClick={() => this.createTestInstances()}
                    disabled={
                        this.state.isCreating || !this.state.toCreate.length
                    }
                    className={this.props.classes.createButton}
                >
                    Create
                </Button>
                {(this.state.successfulCreations.length > 0 ||
                    this.state.failedCreations > 0) && (
                    <Stack
                        direction="row"
                        paddingTop={2}
                        alignItems="start"
                        justifyContent="space-between"
                        spacing={5}
                    >
                        <RTable
                            disableAltRowColor
                            padding={5}
                            title={
                                <Typography variant="h3">
                                    Successful Creations
                                </Typography>
                            }
                            columns={[
                                {
                                    title: "Test Instance ID",
                                    field: "testInstanceId",
                                },
                                {
                                    title: "Product Creation Date",
                                    field: "creationDate",
                                },
                                {
                                    title: "Product Batch Index",
                                    field: "batchIndex",
                                },
                                {
                                    title: "Product Index",
                                    field: "index",
                                },
                            ]}
                            data={this.state.successfulCreations}
                            options={{
                                search: false,
                                filtering: true,
                                paging:
                                    this.state.successfulCreations.length > 10,
                                maxColumnSort: 0,
                            }}
                        />

                        <RTable
                            disableAltRowColor
                            padding={5}
                            title={
                                <Typography variant="h3">
                                    Failed Creations
                                </Typography>
                            }
                            columns={[
                                {
                                    title: "Product Creation Date",
                                    field: "creationDate",
                                },
                                {
                                    title: "Product Batch Index",
                                    field: "batchIndex",
                                },
                                {
                                    title: "Product Index",
                                    field: "index",
                                },
                            ]}
                            data={this.state.failedCreations}
                            options={{
                                search: false,
                                filtering: true,
                                paging: this.state.failedCreations.length > 10,
                                maxColumnSort: 0,
                            }}
                        />
                    </Stack>
                )}

                <MessageHelper
                    message={this.state.message}
                    errorMessage={this.state.errorMessage}
                    open={this.state.messageOpen}
                    setState={(a) => this.setState(a)}
                />
            </Stack>
        );
    }
}

export default withStyles(styles)(
    WithGoogleAuth((props) => (
        <TestInstanceCreator {...props} location={useLocation()} />
    ))
);
