import React, { Component } from "react";
import { useParams } from "react-router-dom";
import {
    Grid,
    Backdrop,
    CircularProgress,
    Link,
    TextField,
    Stack,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import moment from "moment-timezone";
import { connect } from "react-redux";

import IconButtonWithTooltip from "../helper/IconButtonWithTooltip";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import MapComponent from "../components/MapComponent";
import DeploymentInfo from "./helpers/DeploymentInfo";
import processData from "./helpers/processData";
import {
    DeploymentEvents,
    updateEventsWithDetail,
} from "./helpers/DeploymentEvents";
import DeploymentManifest from "./helpers/DeploymentManifest";
import { DeploymentEventEnum } from "@running-tide/farm-system/models/enums";
import { hasValidPermissions } from "../helper/PermissionChecker";
import {
    CARBON_SEQUESTRATION,
    Action,
} from "@running-tide/rt-api-access-control/build/PermissionValidator/constants";
import cleanDeploymentInfo from "./helpers/cleanDeploymentInfo";

const styles = () => ({
    mapContainer: {
        position: "relative",
    },
});

const strToMoment = (time) =>
    moment(time, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz(moment.tz.guess());

class DeploymentDetails extends Component {
    state = {
        deploymentInfo: {},
        flyId: null,
        events: [],
        substrates: [],
        // buoyData populates the tables in Manifest
        buoyData: { verification: [], trajectory: [], unknown: [] },
        vesselGPSTrack: null,
        // processedBuoys holds all of the information needed to map the
        // buoys & is passed to MapComponent
        processedBuoys: null,
        initialStartDate: moment().subtract(3, "days"),
        initialEndDate: moment(),
        editedDeploymentInfo: {},
        focusedBuoyId: null,
    };

    constructor(props) {
        super(props);
        this.updateEventRecipes = this.updateEventRecipes.bind(this);
        this.flyTo = this.flyTo.bind(this);
    }

    async componentDidMount() {
        Promise.all([
            this.loadDeploymentDetails(),
            this.fetchEventsSubstateAndBuoys(null, null),
        ]).then(([deploymentInfo, data]) => {
            this.getVesselGPSTrack(deploymentInfo, null, null);

            deploymentInfo.weight = data.substrates.reduce((sum, sub) => {
                return sum + sub.quantity;
            }, 0);

            this.setState({ deploymentInfo });
        });
    }

    async loadDeploymentDetails() {
        // Fetch information associated with this deployment
        const caApiClient = new CarbonAccountingAPIClient(this.props.authState);
        const deploymentInfo = await caApiClient.getDeployment(
            this.props.params.deployment_id
        );
        cleanDeploymentInfo(deploymentInfo);
        deploymentInfo.locatable = false;
        // Compare target departure to determine the inital ranges
        let initialStartDate = moment().subtract(3, "days");
        let initialEndDate = moment();

        if (
            deploymentInfo.departureDate &&
            moment(deploymentInfo.departureDate).isBefore(moment())
        ) {
            initialStartDate = moment(deploymentInfo.departureDate);
            initialEndDate = moment.min(
                moment(),
                moment(deploymentInfo.departureDate).add(1, "quarters")
            );
        }

        const editedDeploymentInfo = {
            ...deploymentInfo,
            departurePortId:
                deploymentInfo.carbonBuoyResponse?.departurePortResponse?.id,
            arrivalPortId:
                deploymentInfo.carbonBuoyResponse?.arrivalPortResponse?.id,
            vesselId: deploymentInfo.carbonBuoyResponse?.vesselResponse?.id,
        };
        delete editedDeploymentInfo.carbonBuoyResponse;
        // To prevent warning from passing null values to input fields
        // values are reset to null on the updateDeployment call in Info
        for (const key in editedDeploymentInfo) {
            editedDeploymentInfo[key] ?? (editedDeploymentInfo[key] = "");
        }

        this.setState({
            deploymentInfo,
            editedDeploymentInfo,
            initialStartDate,
            initialEndDate,
        });
        return deploymentInfo;
    }

    async fetchEventsSubstateAndBuoys(startDate, endDate) {
        const caAPI = new CarbonAccountingAPIClient(this.props.authState);
        const substratesMap = new Map();
        // In order to load the page as fast as possible we want to get verification buoy data immediately, it takes the longest to load
        // However we don't want to hold everything else up
        // We handle this by requesting events, substrate and buoy data all at once.
        // We setState immediately as we get their results.
        // When all 3 have completed loading, we process the dependencies and update the events with the data.

        const eventsPromise = caAPI
            .getDeploymentEvents(this.props.params.deployment_id)
            .then((events) => {
                this.setState({ events });
                return events;
            });
        const substratesPromise = caAPI
            .getDeploymentSubstrates(this.props.params.deployment_id)
            .then((substrates) => {
                substrates.forEach((sub) => {
                    substratesMap.set(sub.id, {
                        id: sub.id,
                        quantity: sub.quantity,
                        productGroupId: sub.substrateProductGroupId,
                        status: "Pending",
                    });
                });
                this.setState({ substrates });
                return substrates;
            });

        const verificationBuoysPromise = this.getVerificationBuoyTrack(
            startDate,
            endDate
        );

        let [buoysMap, substrates, events] = await Promise.all([
            verificationBuoysPromise,
            substratesPromise,
            eventsPromise,
        ]);
        events = updateEventsWithDetail(events, buoysMap, substratesMap);

        this.setState({ events });
        return { events, substrates };
    }

    async getVerificationBuoyTrack(startDate, endDate) {
        const buoysMap = new Map();
        const caAPI = new CarbonAccountingAPIClient(this.props.authState);
        return caAPI
            .getAllVerificationBuoys(this.props.params.deployment_id)
            .then((buoyDatas) => {
                //id: buoy.gpsTracker.length > 0 ? buoy.gpsTracker[0].datastreamId : '',
                buoyDatas.forEach((buoy) => {
                    const gpsDatastream =
                        buoy.gpsTracker.length > 0
                            ? {
                                  id: buoy.gpsTracker[0].datastreamId,
                                  name: "GPS",
                                  sensorObservations: buoy.gpsTracker,
                              }
                            : {
                                  id: `${buoy.id}-GPS`,
                                  name: "GPS",
                                  sensorObservations: [],
                              };
                    buoysMap.set(buoy.id, {
                        type: buoy.type,
                        id: buoy.id,
                        name: buoy.name,
                        datastreams: [gpsDatastream],
                        status: buoy.status,
                        lastContact: strToMoment(buoy.lastContact),
                    });
                });
                this.processBuoys(
                    Array.from(buoysMap.values()),
                    startDate,
                    endDate
                );
                this.setState({
                    buoys: Array.from(buoysMap.values()),
                });
                return buoysMap;
            });
    }

    async getVesselGPSTrack(deploymentInfo, startDate, endDate) {
        if (!deploymentInfo.gpsTracker || !deploymentInfo.foiId) return;

        const caAPI = new CarbonAccountingAPIClient(this.props.authState);
        caAPI
            .getDeploymentTrack(this.props.params.deployment_id)
            .then((vesselGPSTrack) => {
                if (vesselGPSTrack.length > 0) {
                    vesselGPSTrack = vesselGPSTrack.map((obs) => {
                        obs.resultLocation = [
                            obs.resultLocation.long,
                            obs.resultLocation.lat,
                        ];
                        return obs;
                    });
                    const vesselData = {
                        id: deploymentInfo.id,
                        name: this.state.deploymentInfo.vessel?.name,
                        datastreams: [
                            {
                                name: "GPS",
                                sensorObservations: vesselGPSTrack,
                            },
                        ],
                    };
                    const departureDate = deploymentInfo.departureDate
                        ? moment(deploymentInfo.departureDate, "YYYY-MM-DD")
                        : new Date(0);
                    const arrivalDate = deploymentInfo.arrivalDate
                        ? moment(deploymentInfo.arrivalDate, "YYYY-MM-DD")
                        : new Date();

                    vesselGPSTrack = processData(
                        vesselData,
                        startDate ?? departureDate,
                        endDate ?? arrivalDate
                    );
                    deploymentInfo.locatable = true;
                    this.setState({ vesselGPSTrack });
                }
            })
            .catch((e) => {
                console.log(e);
                deploymentInfo.locatable = false;
            });
    }

    processBuoys(buoys, startDate, endDate) {
        const buoyData = { verification: [], trajectory: [], unknown: [] };
        const processedBuoys = [];
        buoys.forEach((buoy) => {
            const processedBuoy = processData(buoy, startDate, endDate);
            processedBuoys.push(processedBuoy);
            buoyData[buoy.type].push([
                <Link
                    key={buoy.id}
                    underline="hover"
                    href={`/kelp_buoy/${buoy.id}`}
                >
                    {buoy.name}
                </Link>,
                processedBuoy.coordinates.length ? (
                    <Link
                        key={buoy.id + "-locate"}
                        underline="hover"
                        component="button"
                        variant="body1"
                        onClick={() => {
                            this.setState({
                                focusedBuoyId: buoy.datastreams[0].id,
                            });
                            this.flyTo(buoy.id);
                        }}
                    >
                        Locate
                    </Link>
                ) : (
                    <Typography>N/A</Typography>
                ),
                buoy.lastContact.format("YYYY-MM-DD HH:mm:ss z"),
                buoy.status,
            ]);
        });
        this.setState({ processedBuoys, buoyData });
    }

    loadNewGeoDataset(startDate, endDate) {
        this.getVesselGPSTrack(this.state.deploymentInfo, startDate, endDate);
        this.getVerificationBuoyTrack(startDate, endDate);
    }
    updateEventRecipes(substratesRecipes) {
        const events = this.state.events.slice().map((event) => {
            (event.eventType ===
                DeploymentEventEnum.SUBSTRATE_DEPLOYMENT_START ||
                event.eventType ===
                    DeploymentEventEnum.SUBSTRATE_DEPLOYMENT_STOP) &&
                (event.recipeName = substratesRecipes.get(event.instrumentId));
            return event;
        });
        this.setState({ events });
    }

    flyTo(flyId) {
        document
            .getElementsByClassName("mapboxgl-canvas")[0]
            .scrollIntoView({ block: "center" });
        this.setState({
            flyId,
        });
    }

    render() {
        const csaUser = hasValidPermissions(this.props.loggedInUser, [
            `${CARBON_SEQUESTRATION}:${Action.CREATE}`,
            `${CARBON_SEQUESTRATION}:${Action.UPDATE}`,
        ]);

        let mapDatasets = [];
        if (this.state.processedBuoys != null) {
            mapDatasets = mapDatasets.concat(this.state.processedBuoys);
        }
        if (this.state.vesselGPSTrack != null) {
            mapDatasets = mapDatasets.concat(this.state.vesselGPSTrack);
        }
        return (
            <Grid
                container
                columns={120}
                rowSpacing={3}
                justifyContent="space-between"
            >
                <Grid item xs={120}>
                    <Grid item lg={57} xs={100}>
                        <Stack variant="tablelike">
                            {this.state.editing ? (
                                <TextField
                                    required
                                    InputProps={{
                                        sx: { height: 40, fontSize: 28 },
                                    }}
                                    value={
                                        this.state.editedDeploymentInfo.title
                                    }
                                    onChange={({ target }) =>
                                        this.setState((prevState) => ({
                                            editedDeploymentInfo: {
                                                ...prevState.editedDeploymentInfo,
                                                title: target.value,
                                            },
                                        }))
                                    }
                                />
                            ) : (
                                <Typography variant="h3">
                                    {this.state.deploymentInfo.title}
                                </Typography>
                            )}
                            {!this.state.editing && (
                                <IconButtonWithTooltip
                                    tooltipText={
                                        csaUser
                                            ? "Edit Deployment"
                                            : "User not authorized"
                                    }
                                    color="primary"
                                    onClick={() =>
                                        this.setState({ editing: true })
                                    }
                                    disabled={!csaUser}
                                >
                                    <EditIcon />
                                </IconButtonWithTooltip>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
                <DeploymentInfo
                    deploymentInfo={this.state.deploymentInfo}
                    saveEdits={(deploymentInfo, reload) =>
                        this.setState(
                            {
                                deploymentInfo:
                                    cleanDeploymentInfo(deploymentInfo),
                                editing: false,
                            },
                            () => reload && this.loadDeploymentDetails()
                        )
                    }
                    editDeploymentField={(field, value) =>
                        this.setState((prevState) => ({
                            editedDeploymentInfo: {
                                ...prevState.editedDeploymentInfo,
                                [field]: value,
                            },
                        }))
                    }
                    editing={this.state.editing}
                    editedDeploymentInfo={this.state.editedDeploymentInfo}
                    setFly={() =>
                        this.flyTo(this.state.deploymentInfo.gpsTracker)
                    }
                    {...this.props}
                />
                <DeploymentEvents
                    deploymentInfo={this.state.deploymentInfo}
                    events={this.state.events}
                />
                <Grid item xs={120} className={this.props.classes.mapContainer}>
                    <Backdrop
                        open={!this.state.processedBuoys}
                        variant="limited"
                    >
                        <CircularProgress />
                    </Backdrop>
                    <MapComponent
                        key={
                            // TODO(hannah/nikhil): Once the map component no
                            // longer relies on the update prop to update its
                            // data, remove the key.
                            this.state.processedBuoys != null
                                ? "loaded"
                                : "not-loaded"
                        }
                        picker
                        update={(startDate, endDate) =>
                            this.loadNewGeoDataset(startDate, endDate)
                        }
                        datasets={mapDatasets}
                        initialZoom={3}
                        addPoints={false}
                        flyId={this.state.flyId}
                        initialDateFilter={"all"}
                        focusedBuoyId={this.state.focusedBuoyId}
                        initialDateRange={{
                            from: this.state.initialStartDate,
                            to: this.state.initialEndDate,
                        }}
                    />
                </Grid>
                <DeploymentManifest
                    substrates={this.state.substrates}
                    buoyData={this.state.buoyData}
                    updateEventRecipes={this.updateEventRecipes}
                    deploymentId={this.props.params.deployment_id}
                    {...this.props}
                />
            </Grid>
        );
    }
}
const mapStateToProps = (state) => ({
    loggedInUser: state.data.loggedInUser,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withStyles(styles)(
        WithGoogleAuth((props) => (
            <DeploymentDetails {...props} params={useParams()} />
        ))
    )
);
