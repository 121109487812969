import React, { Component } from "react";
import { IconButton, Link, Stack, Typography } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

import RTable from "../components/RTable";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";

// number of groups per query limit. consistent with product instance list semantics
const limit = 20;
class ProductGroupList extends Component {
    state = {
        productGroups: [],
    };

    async componentDidMount() {
        const fetchGroups = async () => {
            // if we hit the limit, we could have more to query, start after the last ID we have
            if (productGroups.length === limit) {
                productGroups = await apiClient.getProductGroups(
                    limit,
                    productGroups.at(-1).id
                );
                this.setState(
                    {
                        productGroups:
                            this.state.productGroups.concat(productGroups),
                    },
                    fetchGroups
                );
            }
        };
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        let productGroups = await apiClient.getProductGroups(limit);
        this.setState({ productGroups }, fetchGroups);
    }

    render() {
        return (
            <RTable
                title={
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="h3">Product Groups</Typography>
                        <IconButton
                            color="primary"
                            href="/product/group/create"
                        >
                            <AddBoxIcon />
                        </IconButton>
                    </Stack>
                }
                columns={[
                    {
                        title: "Name",
                        render: (rowData) => (
                            <Link href={`/product/group/${rowData.id}`}>
                                {rowData.name}
                            </Link>
                        ),
                    },
                    { title: "Description", field: "description" },
                ]}
                data={this.state.productGroups}
            />
        );
    }
}

export default WithGoogleAuth(ProductGroupList);
