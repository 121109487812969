import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Paper, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import moment from "moment-timezone";
import randomColor from "randomcolor";
import SimpleNumericGraphComponent from "./SimpleNumericGraphComponent";

const styles = (theme) => ({
    text: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
});

class GraphComponent extends Component {
    constructor(props) {
        super(props);
        this.splitByUnit = props.splitByUnit || true;
        this.size = this.getSize(props.size);
        this.picker = props.picker;
    }

    getSize(size) {
        switch (size) {
            case "small":
                return 4;
            case "medium":
                return 6;
            case "large":
                return 12;
            default:
                return 6;
        }
    }

    processNumericGraphData(data) {
        const initialDatasets = data.map((dataset) => ({
            unit: dataset.op_unit,
            label: `${dataset.ds_name} (${dataset.op_unit})`,
            borderColor: randomColor({
                luminosity: "bright",
                format: "rgb",
                seed: dataset.ds_id + dataset.op_name,
            }),
            fill: false,
            data: dataset.date_time.map((time, i) => ({
                x: new Date(time),
                y: dataset.result_numeric[i],
            })),
            earliestStartDate: dataset.date_time.reduce(
                (acc, time) => moment.min(acc, moment(time)),
                moment()
            ),
        }));
        initialDatasets.forEach((element) => {
            if (element.label.includes("Current_Mode")) {
                element["yAxisID"] = "y1";
                element["borderColor"] = "red";
            }
        });
        if (this.splitByUnit) {
            let units = {};
            initialDatasets.forEach((dataset) => {
                units[dataset.unit] = [...(units[dataset.unit] || []), dataset];
            });
            return Object.values(units);
        } else {
            return [initialDatasets];
        }
    }

    async loadNewNumericDatasetByUnit(startDate, endDate, unit) {
        let { data } = await this.props.loadNewDataset(
            startDate,
            endDate,
            unit
        );
        let datasets = this.processNumericGraphData(data.result_numeric);
        return datasets;
    }

    getNumericGraphs() {
        const latestNumeric = this.processNumericGraphData(
            this.props.numericGraphData
        ).filter((d) => d.length);

        return latestNumeric.map((dataset, i) => (
            <Grid
                item
                xs={12}
                lg={
                    dataset.length && dataset[0].unit === "time"
                        ? 12
                        : this.size
                }
                key={`graph${i}`}
            >
                <SimpleNumericGraphComponent
                    picker={this.picker}
                    id={`graph${i}`}
                    datasets={dataset}
                    update={async (startDate, endDate, unit) =>
                        await this.loadNewNumericDatasetByUnit(
                            startDate,
                            endDate,
                            unit
                        )
                    }
                />
            </Grid>
        ));
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper variant="outlined">
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Typography className={classes.text} variant="h6">
                            {this.props.name}
                        </Typography>
                        <Typography className={classes.text} variant="body1">
                            {this.props.description}
                        </Typography>
                    </Grid>
                    {this.getNumericGraphs()}
                </Grid>
            </Paper>
        );
    }
}

GraphComponent.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    numericGraphData: PropTypes.array.isRequired,
    loadNewDataset: PropTypes.func.isRequired,
    splitByUnit: PropTypes.bool,
    size: PropTypes.string,
    picker: PropTypes.bool,
};

export default withStyles(styles)(GraphComponent);
