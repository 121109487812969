import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import withTheme from "@mui/styles/withTheme";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import Chart from "chart.js";
import moment from "moment-timezone";
import GraphDateRangePickerComponent from "./GraphDateRangePickerComponent";

const styles = () => ({
    graphContainer: {
        height: "100%",
        width: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
        minHeight: "100%",
        minWidth: "100%",
    },
    canvas: {
        height: "auto",
        width: "100%",
    },
    graphContainerSlim: {
        height: "500px",
        width: "100%",
        maxHeight: "500px",
        maxWidth: "100%",
        minHeight: "500px",
        minWidth: "100%",
    },
    canvasSlim: {
        maxHeight: "400px",
        height: "400px",
        width: "100%",
    },
    lastUpdated: {
        display: "inline-block",
        fontSize: ".7rem",
        padding: 10,
    },
    datePicker: {
        "padding-left": "10px",
    },
});

let Graph = WithGoogleAuth(
    class GraphComponent extends Component {
        constructor(props) {
            super(props);
            this.datasets = props.datasets;
            this.unit =
                this.datasets && this.datasets.length
                    ? this.datasets[0].unit
                    : null;
            this.update = props.update;
            this.dateRange = this.getDateRange(this.datasets);
            this.picker = props.picker;
            this.legendPos = props.legendPos || "top";
            this.chartRef = React.createRef();
            this.loadState = this.loadState.bind(this);
        }

        getDateRange(datasets) {
            return {
                from: datasets.reduce(
                    (acc, dataset) =>
                        moment.min(acc, moment(dataset.earliestStartDate)),
                    moment()
                ),
                to: moment(),
            };
        }

        componentDidMount() {
            this.loadState();
        }

        componentDidUpdate() {
            this.currentCart.data.datasets = this.props.datasets;
            this.currentCart.update();
        }

        componentWillUnmount() {
            this.update = () => {};
            this.loadState = () => {};
            clearInterval(this.liveInterval);
        }
        async loadState() {
            const myChartRef = this.chartRef.current.getContext("2d");
            if (this.currentCart) this.currentCart.destroy();
            Chart.defaults.global.defaultFontColor =
                this.props.theme.palette.tertiaryUtility.main;
            this.currentCart = new Chart(myChartRef, {
                type: "line",
                data: {
                    datasets: this.props.datasets,
                },
                options: {
                    animation: {
                        duration: 0,
                    },
                    maintainAspectRatio: !this.props.cappedGraphHeight,
                    legend: {
                        position: this.legendPos,
                        labels: {
                            boxWidth: 12,
                        },
                        onClick: (event, legendItem) => {
                            if (legendItem.text === "Toggle All") {
                                this.currentCart.data.datasets.forEach(
                                    function (ds) {
                                        ds.hidden = !ds.hidden;
                                    }
                                );
                                this.currentCart.update();
                            } else {
                                //Instead of calling the default callback we manually do it
                                //The default callback had a bug where it did not update
                                //The hidden property correctly
                                this.currentCart.data.datasets.forEach(
                                    function (ds) {
                                        if (ds.label === legendItem.text) {
                                            ds.hidden = !ds.hidden;
                                        }
                                    }
                                );
                                this.currentCart.update();
                            }
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                type: "time",
                                time: {
                                    displayFormats: {
                                        minute: "MMM D h:mm a",
                                        hour: "MMM D h a",
                                        day: "MMM D h a",
                                        week: "ll",
                                    },
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: "Date Time",
                                },
                                gridLines: {
                                    color: this.props.theme.palette
                                        .primaryUtility.main,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                gridLines: {
                                    color: this.props.theme.palette
                                        .primaryUtility.main,
                                },
                            },
                        ],
                    },
                },
            });
        }

        render() {
            const { classes } = this.props;
            return (
                <div
                    className={
                        !this.props.cappedGraphHeight
                            ? classes.graphContainer
                            : classes.graphContainerSlim
                    }
                >
                    <canvas
                        id="myChart"
                        ref={this.chartRef}
                        className={
                            !this.props.cappedGraphHeight
                                ? classes.canvas
                                : classes.canvasSlim
                        }
                    />
                    {(this.picker || this.picker === undefined) && (
                        <GraphDateRangePickerComponent
                            dateRange={this.dateRange}
                            update={async (startDate, endDate) =>
                                await this.update(startDate, endDate, this.unit)
                            }
                        />
                    )}
                </div>
            );
        }
    }
);

export default withTheme(withStyles(styles)(Graph));
