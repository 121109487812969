export default function cleanDeploymentInfo(deploymentInfo) {
    // Set frequented fields directly on deploymentInfo object
    deploymentInfo.departureDate =
        deploymentInfo.carbonBuoyResponse?.departureDate &&
        new Date(deploymentInfo.carbonBuoyResponse?.departureDate)
            .toISOString()
            .split(/[T ]/i, 1)[0];
    deploymentInfo.targetDepartureDate =
        deploymentInfo.carbonBuoyResponse?.targetDepartureDate &&
        new Date(deploymentInfo.carbonBuoyResponse?.targetDepartureDate)
            .toISOString()
            .split(/[T ]/i, 1)[0];
    deploymentInfo.arrivalDate =
        deploymentInfo.carbonBuoyResponse?.arrivalDate &&
        new Date(deploymentInfo.carbonBuoyResponse?.arrivalDate)
            .toISOString()
            .split(/[T ]/i, 1)[0];
    deploymentInfo.gpsTracker = deploymentInfo.carbonBuoyResponse?.gpsTracker;
    deploymentInfo.vessel = {
        name: deploymentInfo.carbonBuoyResponse?.vesselResponse?.name,
    };
    deploymentInfo.departurePort = {
        name: deploymentInfo.carbonBuoyResponse?.departurePortResponse?.title,
    };
    deploymentInfo.buoySelectorLabel =
        deploymentInfo.carbonBuoyResponse?.buoySelectorLabel;

    return deploymentInfo;
}
