import React, { Component } from "react";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import withStyles from "@mui/styles/withStyles";

import { errorMessage, successMessage } from "../helper/MessageMethodHelper";
import APIClient from "../../models/APIClient";
import BuoyConfigForm from "../helper/BuoyConfigForm";
import MessageHelper from "../helper/MessageHelper";
import { Container } from "@mui/system";
import FirmwareConfigSelector from "../components/FirmwareConfigSelector";

const styles = (theme) => ({
    container: {
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    formControl: {
        minWidth: "200px",
    },
    button: {
        marginTop: theme.spacing(6),
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    fullWidth: {
        maxWidth: "100%",
    },
});

const BuoyCreatorManager = WithGoogleAuth(
    class BuoyCreatorManager extends Component {
        constructor(props) {
            super(props);
            this.state = {
                thingsWithTemplateLabel: [],
                selectedThing: null,
                name: "",
                description: "",
                selectorId: "",
                selectorLabels: null,
                properties: null,
                scheduleConfigText: {
                    name: "",
                    contents: "",
                },
                sensorsConfigText: {
                    name: "",
                    contents: "",
                },
                logsConfigText: {
                    name: "",
                    contents: "",
                },
                buoyConfigText: {
                    name: "",
                    contents: "",
                },
                buoyProtocolVersionText: "",
                selectedSchedule: [],
                requiresConfig: true,
                selectedFirmwareVersion: {},
            };
            this.firmwareConfigForm = React.createRef();
            this.createThingAndDatastreamsAndSelectorID =
                this.createThingAndDatastreamsAndSelectorID.bind(this);
            this.setSelectedSchedule = this.setSelectedSchedule.bind(this);
            this.setRequiresConfig = this.setRequiresConfig.bind(this);
            this.setFirmwareVersion = this.setFirmwareVersion.bind(this);
        }

        async componentDidMount() {
            this.getThingsByTemplateLabel();
        }

        getSelectorIdTypeByTemplateBuoy() {
            // a list of strings
            const labelsOfTemplateBuoy =
                this.state.selectedThing.selectorLabels;
            const selectorIdType =
                this.mapSelectorLabelToSelectorIdType(labelsOfTemplateBuoy);
            return selectorIdType;
        }

        mapSelectorLabelToSelectorIdType(labels) {
            for (const label of labels) {
                if (label === "rt-buoy") {
                    return "runningtide";
                } else if (label === "gf-buoy") {
                    return "geoforce";
                } else if (label === "maker-buoy") {
                    return "maker";
                }
                // else: it will return undefined, an error message will display: `selectorIdType can not be empty`
            }
        }

        async getThingsByTemplateLabel() {
            const apiClient = new APIClient(this.props.authState);
            try {
                const result = await apiClient.fetchThingsByThingSelector(
                    "template-buoy"
                );
                const things = await result.json();
                this.setState({ thingsWithTemplateLabel: things });
            } catch (e) {
                console.error(e);
            }
        }

        validIMEI(str) {
            // imei should always be a number with 15 digits
            return /^([0-9]{15})$/.test(str);
        }

        // create thing, datastreams and selectorID for allowlist
        async createThingAndDatastreamsAndSelectorID(
            requiresConfig,
            requiresFirmwareConfig
        ) {
            const apiClient = new APIClient(this.props.authState);

            try {
                let selectorLabelsList;
                if (
                    this.state.selectorLabels.length === 0 ||
                    this.state.selectorLabels.trim() === ""
                ) {
                    selectorLabelsList = null;
                } else {
                    //this parameter for API should be a list
                    selectorLabelsList = this.state.selectorLabels.split(",");
                }

                let propertiesJSON = null;
                try {
                    propertiesJSON = JSON.parse(this.state.properties);
                    // for rt-buoy: check imei and rockblock
                    if (
                        selectorLabelsList &&
                        selectorLabelsList.includes("rt-buoy")
                    ) {
                        if (
                            "imei" in propertiesJSON &&
                            "rockblock" in propertiesJSON
                        ) {
                            if (!this.validIMEI(propertiesJSON.imei)) {
                                this.setState(
                                    errorMessage(
                                        "Invalid imei: imei should be a number with 15 digits"
                                    )
                                );
                                return;
                            }
                            if (typeof propertiesJSON.rockblock !== "boolean") {
                                this.setState(
                                    errorMessage(
                                        "Invalid rockblock: rockblock should be a boolean"
                                    )
                                );
                                return;
                            }
                        } else {
                            this.setState(
                                errorMessage(
                                    "Missing field of imei or rockblock for properties"
                                )
                            );
                            return;
                        }
                    }
                } catch (e) {
                    this.setState(errorMessage("Invalid JSON for properties"));
                    return;
                }

                const data = await apiClient.createThingAndDatastreams({
                    name: this.state.name,
                    description: this.state.description,
                    template_thing_id: this.state.selectedThing.id,
                    selector_id: this.state.selectorId,
                    selector_labels: selectorLabelsList,
                    properties: propertiesJSON,
                });

                const numberOfDatastreams = data.datastream_ids.length;

                // meanwhile, add the newly created selector_ID to the allowlist automatically
                // we put this try-catch block here because we need to put successMessage for datastreams and selectorId together,
                // even though adding selectorId to allowlist and adding datastreams are separate execution
                let selectorIdTypeOfTemplateBuoy = null;
                try {
                    selectorIdTypeOfTemplateBuoy =
                        this.getSelectorIdTypeByTemplateBuoy();
                    await apiClient.createSelectorIdInAllowlist({
                        selectorId: this.state.selectorId,
                        selectorIdType: selectorIdTypeOfTemplateBuoy,
                    });
                } catch (e) {
                    // this error will display only when the selectorIdType is null, but thing will be created successfully
                    this.setState(
                        errorMessage(
                            `Buoy ${this.state.name} (with id ${data.thing_id} and ${numberOfDatastreams} datastreams) were created, but selector ID was not added to the allowlist.`
                        )
                    );
                    return;
                }

                if (requiresFirmwareConfig && this.state.requiresConfig) {
                    const configsObject = this.state.selectedSchedule;
                    const newThingId = data.thing_id;

                    // if both thing and selectorID(in allowlist) can be created successfully, then push config
                    await apiClient.createFirmwareConfigRequest(
                        newThingId,
                        configsObject,
                        false
                    );
                    // if config was created succesfully add version entry
                    await apiClient.createBuoyFirmwareVersion({
                        version_id: this.state.selectedFirmwareVersion.id,
                        thing_id: newThingId,
                    });
                } else if (requiresConfig) {
                    const configsObject = this.getConfigsObject();
                    const newThingId = data.thing_id;

                    // if both thing and selectorID(in allowlist) can be created successfully, then push config
                    await apiClient.createConfigRequest(
                        newThingId,
                        configsObject,
                        false
                    );
                }

                this.setState(
                    successMessage(
                        `Buoy ${this.state.name} (with id ${
                            data.thing_id
                        }, and ${numberOfDatastreams} datastreams) successfully created!
                        Also, selectorId ${
                            this.state.selectorId
                        }, and selectorIdType ${selectorIdTypeOfTemplateBuoy}
                        successfully created in allowlist! And its config was successfully pushed!${
                            requiresFirmwareConfig
                                ? " And recorded firmware version."
                                : ""
                        }`
                    )
                );
            } catch (e) {
                console.error(e);
                this.setState(errorMessage(e));
            }
        }

        getConfigsObject() {
            const configsText = `{
                "schedule_config": ${this.state.scheduleConfigText.contents},
                "sensors_config": ${this.state.sensorsConfigText.contents},
                "logs_config": ${this.state.logsConfigText.contents},
                "buoy_config": ${this.state.buoyConfigText.contents},
                "buoy_protocol_version": ${this.state.buoyProtocolVersionText}
            }`;
            try {
                const configs = JSON.parse(configsText);
                return configs;
            } catch (e) {
                console.log(e);
                return {};
            }
        }

        handleScheduleConfigTextChange = (scheduleConfigText) =>
            this.setState({ scheduleConfigText: scheduleConfigText });
        handleSensorConfigTextChange = (sensorsConfigText) =>
            this.setState({ sensorsConfigText: sensorsConfigText });
        handleLogConfigTextChange = (logsConfigText) =>
            this.setState({ logsConfigText: logsConfigText });
        handleBuoyConfigTextChange = (buoyConfigText) =>
            this.setState({ buoyConfigText: buoyConfigText });
        handleBuoyProtocolVersionChange = (buoyProtocolVersionText) =>
            this.setState({ buoyProtocolVersionText: buoyProtocolVersionText });

        setScheduleConfigText = (config) => {
            this.setState({
                scheduleConfigText: {
                    name: this.state.scheduleConfigText.name,
                    contents: config,
                },
            });
        };
        setLogConfigText = (config) => {
            this.setState({
                logsConfigText: {
                    name: this.state.scheduleConfigText.name,
                    contents: config,
                },
            });
        };
        setSensorConfigText = (config) => {
            this.setState({
                sensorsConfigText: {
                    name: this.state.scheduleConfigText.name,
                    contents: config,
                },
            });
        };
        setBuoyConfigText = (config) => {
            this.setState({
                buoyConfigText: {
                    name: this.state.scheduleConfigText.name,
                    contents: config,
                },
            });
        };

        getHelperTextForSelectorId() {
            if (this.state.selectorLabels === null) {
                return "";
            }
            if (this.state.selectorLabels.includes("rt-buoy")) {
                return "Please enter the IMEI for RT buoy, e.g. 300434066664500";
            }
            if (this.state.selectorLabels.includes("maker-buoy")) {
                return "Please enter a valid Selector ID for maker buoy, e.g. RT-029";
            }
            if (this.state.selectorLabels.includes("gf-buoy")) {
                return "Please enter a valid Selector ID for geoforce buoy, e.g. 2-3262263";
            }
            if (this.state.selectorLabels.includes("gs-buoy")) {
                return "Please enter a valid Selector ID for globalstar buoy, e.g. 0-4378650";
            }
        }

        setSelectedSchedule(jobs) {
            this.setState({ selectedSchedule: jobs });
        }
        setRequiresConfig(requiresConfig) {
            this.setState({ requiresConfig });
        }
        setFirmwareVersion(version) {
            this.setState({ selectedFirmwareVersion: version });
        }

        render() {
            const { classes } = this.props;
            let labels =
                this.state.selectorLabels != null
                    ? this.state.selectorLabels.split(",")
                    : [];
            const isRTBuoy = labels.includes("rt-buoy");

            let requiresConfig =
                labels.includes("cage-buoy") || labels.includes("sonde-buoy");

            let requiresFirmwareConfig =
                labels.includes("accel-buoy") ||
                labels.includes("cam-lite-buoy");

            return (
                <Paper>
                    <div className={classes.container}>
                        <h1>Buoy Creator</h1>
                        <FormControl className={classes.formControl}>
                            <InputLabel>Template Buoy</InputLabel>
                            <Select
                                value={this.state.selectedThing}
                                onChange={(event) => {
                                    const selectedThing = event.target.value;
                                    this.setState({
                                        selectedThing,
                                        selectorLabels:
                                            selectedThing.selectorLabels
                                                .filter(
                                                    (e) => e !== "template-buoy"
                                                )
                                                .join(","),
                                    });
                                }}
                            >
                                {this.state.thingsWithTemplateLabel.map(
                                    (thing, i) => (
                                        <MenuItem value={thing} key={i}>
                                            {thing.name}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                        <TextField
                            required
                            id="Buoy Name"
                            label="Buoy Name"
                            variant="outlined"
                            placeholder="rt-kb-v1-01"
                            onChange={(event) => {
                                this.setState({
                                    name: event.target.value,
                                });
                            }}
                        />
                        <TextField
                            required
                            id="Buoy Description"
                            label="Buoy Description"
                            placeholder="KelpBuoy v1 Labrador Sea deployment 1"
                            variant="outlined"
                            multiline={true}
                            rows={3}
                            onChange={(event) => {
                                this.setState({
                                    description: event.target.value,
                                });
                            }}
                        />
                        <TextField
                            required
                            id="Buoy Selector ID"
                            label="Buoy Selector ID"
                            disabled={this.state.selectedThing === null}
                            variant="outlined"
                            onChange={(event) => {
                                this.setState({
                                    selectorId: event.target.value,
                                });
                            }}
                            helperText={this.getHelperTextForSelectorId()}
                        />
                        <TextField
                            id="Buoy Selector Labels"
                            label="Buoy Selector Labels"
                            disabled={this.state.selectedThing === null}
                            InputLabelProps={{ shrink: true }}
                            value={this.state.selectorLabels}
                            variant="outlined"
                            onChange={(event) => {
                                this.setState({
                                    selectorLabels: event.target.value,
                                });
                            }}
                        />
                        <TextField
                            required={isRTBuoy}
                            placeholder={
                                '{"imei":"123456789012345","rockblock":true}'
                            }
                            helperText='Please enter a valid JSON object, imei and rockblock are required for RT buoys, e.g. {"imei":"300125061272780","rockblock":false}'
                            id="Buoy Properties"
                            label="Buoy Properties"
                            multiline={true}
                            rows={3}
                            disabled={this.state.selectedThing === null}
                            variant="outlined"
                            onChange={(event) => {
                                this.setState({
                                    // if the properties is blank/empty, make it null
                                    properties:
                                        event.target.value.trim() === ""
                                            ? null
                                            : event.target.value,
                                });
                            }}
                        />
                    </div>
                    {requiresConfig && (
                        <BuoyConfigForm
                            displayCheckbox={false}
                            scheduleConfigText={this.state.scheduleConfigText}
                            handleScheduleConfigTextChange={
                                this.handleScheduleConfigTextChange
                            }
                            sensorsConfigText={this.state.sensorsConfigText}
                            handleSensorConfigTextChange={
                                this.handleSensorConfigTextChange
                            }
                            logsConfigText={this.state.logsConfigText}
                            handleLogConfigTextChange={
                                this.handleLogConfigTextChange
                            }
                            buoyConfigText={this.state.buoyConfigText}
                            handleBuoyConfigTextChange={
                                this.handleBuoyConfigTextChange
                            }
                            buoyProtocolVersionText={
                                this.state.buoyProtocolVersionText
                            }
                            handleBuoyProtocolVersionChange={
                                this.handleBuoyProtocolVersionChange
                            }
                            sendToBuoy={false}
                            handleCheck={() => {}}
                            setScheduleConfigText={this.setScheduleConfigText}
                            setLogConfigText={this.setLogConfigText}
                            setSensorConfigText={this.setSensorConfigText}
                            setBuoyConfigText={this.setBuoyConfigText}
                        />
                    )}

                    {requiresFirmwareConfig && (
                        <Container class={classes.fullWidth}>
                            <FirmwareConfigSelector
                                firmwareVersionSelectable={true}
                                setSelectedSchedule={this.setSelectedSchedule}
                                setRequiresConfig={this.setRequiresConfig}
                                setFirmwareVersion={this.setFirmwareVersion}
                                labels={labels}
                            />
                        </Container>
                    )}

                    <Button
                        className={classes.button}
                        disabled={
                            this.state.selectedThing === null ||
                            Object.keys(this.state.selectedThing).length ===
                                0 ||
                            this.state.name.trim() === "" ||
                            this.state.length === 0 ||
                            this.state.description.trim() === "" ||
                            this.state.description.length === 0 ||
                            this.state.selectorId.trim() === "" ||
                            this.state.selectorId.length === 0 ||
                            (requiresConfig &&
                                (this.state.properties === null ||
                                    Object.entries(this.getConfigsObject())
                                        .length === 0)) ||
                            (requiresFirmwareConfig &&
                                this.state.requiresConfig &&
                                !this.state.selectedSchedule.length)
                        }
                        variant="secondary"
                        onClick={() => {
                            this.createThingAndDatastreamsAndSelectorID(
                                requiresConfig,
                                requiresFirmwareConfig
                            );
                        }}
                    >
                        Create
                    </Button>
                    <MessageHelper
                        message={this.state.message}
                        errorMessage={this.state.errorMessage}
                        open={this.state.messageOpen}
                        setState={(a) => this.setState(a)}
                    />
                </Paper>
            );
        }
    }
);

export default withStyles(styles)(BuoyCreatorManager);
