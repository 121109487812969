import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { Grid, Box, Typography } from "@mui/material";
import RTable from "../components/RTable";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import MessageHelper from "../helper/MessageHelper";
import APIClient from "../../models/APIClient";

const styles = (theme) => ({
    table: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
});

const FirmwareVersionManager = WithGoogleAuth(
    class FirmwareVersionManager extends Component {
        constructor(props) {
            super(props);
            this.state = {
                firmwareVersions: [],
                isLoading: true,
            };
            this.downloadCallback = this.downloadCallback.bind(this);
            this.downloadFirmware = this.downloadFirmware.bind(this);
        }

        async componentDidMount() {
            this.getVersions();
        }

        async getVersions() {
            const apiClient = new APIClient(this.props.authState);
            try {
                const firmwareVersions =
                    (await apiClient.getFirmwareVersions()) || [];
                //convert json to text
                const sanitizedFirmwareVersions = firmwareVersions.map(
                    (firmwareVersion) => {
                        return {
                            ...firmwareVersion,
                            config: JSON.stringify(firmwareVersion.config),
                            metadata: JSON.stringify(firmwareVersion.metadata),
                        };
                    }
                );
                this.setState({
                    firmwareVersions: sanitizedFirmwareVersions,
                    isLoading: false,
                });
            } catch (e) {
                console.error(e);
            }
        }

        onRowUpdate = (newData) =>
            new Promise((resolve, reject) => {
                const newDataUpdate = { ...newData };

                function isSelectorId(selectorId) {
                    return selectorId.id === newData.id;
                }
                new APIClient(this.props.authState)
                    .updateFirmwareVersion({
                        id: newDataUpdate.id,
                        version: newDataUpdate.version,
                        description: newDataUpdate.description,
                        binary_path: newDataUpdate.binary_path,
                        config: newDataUpdate.config,
                        metadata: newDataUpdate.metadata,
                    })
                    .then(async (res) => {
                        if (res.status !== 200) {
                            res = await res.json();
                            this.setState(errorMessage(res));
                            reject();
                            return;
                        }
                        //Update State
                        const firmwareVersions = [
                                ...this.state.firmwareVersions,
                            ],
                            i = firmwareVersions.findIndex(isSelectorId);
                        firmwareVersions[i] = newData;
                        this.setState({ firmwareVersions });
                        this.setState(successMessage("Successfully updated"));
                        resolve();
                    })
                    .catch((e) => {
                        this.setState(errorMessage(e));
                        reject();
                    });
            });

        onRowAdd = (newData) =>
            new Promise((resolve, reject) => {
                const newDataUpdate = { ...newData };

                new APIClient(this.props.authState)
                    .createFirmwareVersion({
                        version: newDataUpdate.version,
                        description: newDataUpdate.description,
                        binary_path: newDataUpdate.binary_path,
                        config: newDataUpdate.config,
                        metadata: newDataUpdate.metadata,
                    })
                    .then(async (res) => {
                        if (res.status !== 200) {
                            res = await res.json();
                            this.setState(errorMessage(res));
                            reject();
                            return;
                        }
                        res = await res.json();
                        newData.id = res.id;
                        const firmwareVersions = [
                            ...this.state.firmwareVersions,
                        ];
                        firmwareVersions.unshift(newData);
                        this.setState({ firmwareVersions });
                        this.setState(successMessage("Successfully added"));
                        resolve();
                    })
                    .catch((e) => {
                        this.setState(errorMessage(e));
                        reject();
                    });
            });

        onRowDelete = (oldData) =>
            new Promise((resolve) => {
                new APIClient(this.props.authState)
                    .deleteFirmwareVersion(oldData)
                    .then(async (res) => {
                        if (res.status !== 200) {
                            res = await res.json();
                            this.setState(errorMessage(res));
                            resolve();
                            return;
                        }
                        //Update State
                        res = await res.json();
                        let firmwareVersions = [...this.state.firmwareVersions];
                        firmwareVersions = firmwareVersions.filter(
                            (version) => version.id !== oldData.id
                        );
                        this.setState({ firmwareVersions });
                        this.setState(successMessage("Successfully deleted"));
                        resolve();
                    })
                    .catch((e) => {
                        this.setState(errorMessage(e));
                        resolve();
                    });
            });

        downloadCallback(filename, contents) {
            const url = window.URL.createObjectURL(new Blob([contents]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }

        async downloadFirmware(binary_path) {
            const filename = binary_path.split("/").pop();
            const apiClient = new APIClient(this.props.authState);
            const image_file = await apiClient.getFirmwareFile(filename);

            if (image_file !== undefined) {
                this.downloadCallback(filename, image_file);
            } else {
                this.setState(errorMessage("Could not download binary."));
            }
        }

        render() {
            const { classes } = this.props;

            return (
                <Grid>
                    <Grid item lg={1} xs={12}>
                        <MessageHelper
                            message={this.state.message}
                            errorMessage={this.state.errorMessage}
                            open={this.state.messageOpen}
                            setState={(a) => this.setState(a)}
                        />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Box>
                            <RTable
                                className={classes.table}
                                title={
                                    <Typography variant="h3">
                                        Firmware Version Manager
                                    </Typography>
                                }
                                columns={[
                                    {
                                        title: "Version",
                                        field: "version",
                                    },
                                    {
                                        title: "Description",
                                        field: "description",
                                    },
                                    {
                                        title: "Binary Path",
                                        field: "binary_path",
                                    },
                                    {
                                        title: "Config",
                                        field: "config",
                                    },
                                    {
                                        title: "Metadata",
                                        field: "metadata",
                                    },
                                ]}
                                data={this.state.firmwareVersions}
                                editable={{
                                    isEditable: () => true,
                                    onRowAddCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowUpdateCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowAdd: (newData) =>
                                        this.onRowAdd(newData),
                                    onRowUpdate: (newData, oldData) =>
                                        this.onRowUpdate(newData, oldData),
                                    // edit all rows at once
                                    onBulkUpdate: (changes) =>
                                        Promise.all(
                                            Object.values(changes).map(
                                                ({ newData, oldData }) =>
                                                    this.onRowUpdate(
                                                        newData,
                                                        oldData
                                                    )
                                            )
                                        ),
                                    onRowDelete: (oldData) =>
                                        this.onRowDelete(oldData),
                                }}
                                isLoading={this.state.isLoading}
                                options={{
                                    search: true,
                                    maxColumnSort: 1,
                                    filtering: true,
                                    paging: true,
                                    // always display the newly-added row at the beginning of the table
                                    addRowPosition: "first",
                                }}
                                actions={[
                                    {
                                        icon: "download",
                                        tooltip: "Download",
                                        onClick: (event, rowData) =>
                                            this.downloadFirmware(
                                                rowData.binary_path
                                            ),
                                    },
                                ]}
                            ></RTable>
                        </Box>
                    </Grid>
                </Grid>
            );
        }
    }
);

export default withStyles(styles)(FirmwareVersionManager);
