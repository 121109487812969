import { initializeApp } from "firebase/app";
import {
    getAuth,
    getRedirectResult,
    signInWithRedirect,
    GoogleAuthProvider,
    signOut,
} from "firebase/auth";
import firebaseConfig from "../static/firebase.json";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
    try {
        await signInWithRedirect(auth, googleProvider);
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

console.log("Calling getRedirectResult");

// Handles getting the auth result. This is called automatically when the page loads.
// This function isn't necessary for the login flow, but can be used to get the auth result at page load.
getRedirectResult(auth)
    .then((result) => {
        console.log(result, "Redirect Result");
        const credential = GoogleAuthProvider.credentialFromResult(result);
        console.log(credential);
    })
    .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        console.log(errorCode, errorMessage);
    });

const logout = () => {
    signOut(auth);
};

export { auth, signInWithGoogle, logout };
