import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Paper, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import formatcoords from "formatcoords";
import moment from "moment-timezone";
import randomColor from "randomcolor";

import MapComponent from "../MapComponent";
import SimpleNumericGraphComponent from "../SimpleNumericGraphComponent";

const styles = (theme) => ({
    text: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
});

class GraphComponent extends Component {
    constructor(props) {
        super(props);
        this.splitByUnit = props.splitByUnit || true;
        this.size = this.getSize(props.size);
        this.picker = props.picker;
        this.processNumericGraphData = this.processNumericGraphData.bind(this);
        this.getNumericGraphs = this.getNumericGraphs.bind(this);
    }

    getSize(size) {
        switch (size) {
            case "small":
                return 4;
            case "medium":
                return 6;
            case "large":
                return 12;
            default:
                return 6;
        }
    }

    processNumericGraphData(data) {
        const initialDatasets = data.map((dataset) => ({
            unit: dataset.op_unit,
            label: `${dataset.thing_name} - ${dataset.ds_name} (${dataset.op_unit})`,
            borderColor: randomColor({
                luminosity: "bright",
                format: "rgb",
                seed: dataset.ds_id + dataset.op_name,
            }),
            fill: false,
            data: dataset.date_time.map((time, i) => ({
                x: new Date(time),
                y: dataset.result_numeric?.[i],
            })),
            earliestStartDate: dataset.date_time.reduce(
                (acc, time) => moment.min(acc, moment(time)),
                moment()
            ),
        }));

        if (this.splitByUnit) {
            let units = {};
            initialDatasets.forEach((dataset) => {
                units[dataset.unit] = [...(units[dataset.unit] || []), dataset];
            });
            // Adds an empty dataset to get a custom Toggle All legend item
            if (this.props.addToggleToLegend) {
                for (let key in units) {
                    units[key].unshift({
                        unit: key,
                        label: `Toggle All`,
                        fill: false,
                        data: [],
                        earliestStartDate: moment(),
                    });
                }
            }
            return Object.values(units);
        } else {
            return [initialDatasets];
        }
    }

    processGeoGraphData(data) {
        let datasets = data.map((dataset) => {
            dataset.coordinates = dataset.result_location.reduce(
                (acc, point) => {
                    point = JSON.parse(point);
                    acc.push(point.coordinates);
                    return acc;
                },
                []
            );
            dataset.unit = dataset.op_unit;
            delete dataset.op_unit;
            dataset.color = randomColor({
                luminosity: "dark",
                format: "hex",
                seed: dataset.ds_id + dataset.op_name,
            });
            dataset.geoJson = {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: dataset.result_location.map((point, i) => {
                        const coordinates = JSON.parse(point).coordinates;
                        const [lng, lat] = coordinates;
                        return {
                            type: "Feature",
                            properties: {
                                description: `${
                                    dataset.thing_name
                                } <br> ${formatcoords(
                                    lat,
                                    lng
                                ).format()}<br>${coordinates} <br>${
                                    dataset.date_time[i]
                                }`,
                            },
                            geometry: {
                                type: "Point",
                                coordinates: coordinates,
                            },
                        };
                    }),
                },
            };
            dataset.earliestStartDate = dataset.date_time.reduce(
                (acc, time) => moment.min(acc, moment(time)),
                moment()
            );
            return dataset;
        });

        return [datasets];
    }

    getNumericGraphs() {
        let latestNumeric = this.processNumericGraphData(
            this.props.numericGraphData
        ).filter((d) => d.length);

        // If we have the timeline graph from the buoy detail page
        // we want this prop to enable us to render it as the last
        // graph instead of the first graph
        if (this.props.timelineAtBottom) {
            let indexOfTimeline = -1;
            latestNumeric.map((dataset, index) => {
                if (dataset[0].unit === "time") {
                    indexOfTimeline = index;
                }
            });
            if (indexOfTimeline > -1) {
                latestNumeric.push(latestNumeric.splice(indexOfTimeline, 1)[0]);
            }
        }

        return latestNumeric.map((dataset, i) => {
            let isCapped = this.props.cappedGraphHeight;
            //Don't make timeline graph isCapped
            if (this.props.cappedGraphHeight && dataset[0].unit === "time") {
                isCapped = false;
            }
            return (
                <Grid
                    item
                    xs={12}
                    lg={
                        dataset.length && dataset[0].unit === "time"
                            ? 12
                            : this.size
                    }
                    key={`graph${i}`}
                >
                    <SimpleNumericGraphComponent
                        legendPos={this.props.legendPos}
                        picker={this.picker}
                        id={`graph${i}`}
                        datasets={dataset}
                        update={async (startDate, endDate, unit) =>
                            await this.props.loadNewDataset(
                                startDate,
                                endDate,
                                unit
                            )
                        }
                        cappedGraphHeight={isCapped}
                    />
                </Grid>
            );
        });
    }

    getMapGraphs() {
        const latestGeo = this.props.alwaysDisplayMap
            ? this.processGeoGraphData(this.props.geoGraphData)
            : this.processGeoGraphData(this.props.geoGraphData).filter(
                  (d) => d.length
              );
        return latestGeo.map((dataset, i) => (
            <Grid
                item
                lg={dataset.length > 10 ? 12 : this.size}
                xs={12}
                key={`map${i}`}
            >
                <MapComponent
                    picker={this.picker}
                    id={`map${i}`}
                    datasets={dataset}
                    update={async (startDate, endDate) =>
                        await this.props.loadNewDataset(startDate, endDate)
                    }
                />
            </Grid>
        ));
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper variant="outlined">
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Typography className={classes.text} variant="h6">
                            {this.props.name}
                        </Typography>
                        <Typography className={classes.text} variant="body1">
                            {this.props.description}
                        </Typography>
                    </Grid>
                    {this.getMapGraphs()}
                    {this.getNumericGraphs()}
                </Grid>
            </Paper>
        );
    }
}

GraphComponent.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    geoGraphData: PropTypes.array.isRequired,
    numericGraphData: PropTypes.array.isRequired,
    loadNewDataset: PropTypes.func.isRequired,
    splitByUnit: PropTypes.bool,
    size: PropTypes.string,
    picker: PropTypes.bool,
    timelineAtBottom: PropTypes.bool,
    legendPos: PropTypes.string,
    addToggleToLegend: PropTypes.bool,
    cappedGraphHeight: PropTypes.bool,
};

export default withStyles(styles)(GraphComponent);
