import React, { Component } from "react";
import { Switch } from "@mui/material";
import Button from "@mui/material/Button";
import formatcoords from "formatcoords";
import FormControlLabel from "@mui/material/FormControlLabel";
import mapboxgl from "mapbox-gl";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import config from "../../config";
import GeoUtils from "./GeoUtils.js";
import GraphDateRangePickerComponent from "./GraphDateRangePickerComponent";

const TIMEOUT_MS = 500;

const rt_green = "#00FF00";
const line_color = "#565656";
const pulsingDot = "-pulsing-dot";

const styles = (theme) => ({
    graphContainer: {
        height: "100%",
        width: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
        minHeight: "100%",
        minWidth: "100%",
    },
    map: {
        height: "600px",
        width: "100%",
    },
    lastUpdated: {
        display: "inline-block",
        fontSize: ".7rem",
        padding: 10,
    },
    popup: {
        color: theme.palette.darkUtility.main,
        "& .mapboxgl-popup-content": {
            background: "#CCC",
        },
        "& .mapboxgl-popup-tip": {
            "border-top-color": "#CCC",
        },
    },
});

const standardMap = "mapbox://styles/sscranton1/cldxstkvr002l01pawi6h21sy";
const depthMap = "mapbox://styles/sscranton1/cldxpkcav002r01ol9vlq57w7";

class MapComponent extends Component {
    updateMapTimeoutID = null;

    constructor(props) {
        super(props);
        this.picker = props.picker;
        this.update = props.update;
        mapboxgl.accessToken = config.mapBoxAccessToken;
        this.state = {
            lng: props.centerLng || -70.19238,
            lat: props.centerLat || 43.726751,
            zoom: props.initialZoom || 2,
            // set the status for rendering prediction button
            renderPrediction: false,
        };
        this.showPrediction = this.showPrediction.bind(this);
        this.switchHandler = this.switchHandler.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.flyTo = this.flyTo.bind(this);
        this.highlightLine = this.highlightLine.bind(this);
        this.openPopUpAndHighlightLine =
            this.openPopUpAndHighlightLine.bind(this);
        //mapbox loaded sources, layers and images
        this.activeLayers = [];
        this.activeSources = [];
        this.activeImages = [];
        this.activePopups = {};
        this.selectedLines = [];
        this.mapContainer = React.createRef();
        this.dateRange =
            props.initialDateRange ?? this.getDateRange(props.datasets);
    }

    showPrediction() {
        this.setState({ renderPrediction: !this.state.renderPrediction });
    }

    getPredictionDescription() {
        if (!this.props.datasets || this.props.datasets.length !== 1) {
            return null;
        }

        const [
            estimatedTime,
            [predicatedLon, predicatedLat],
            [predicatedLonToDMS, predicatedLatToDMS],
        ] = GeoUtils.getTimeCoordinatesIn15Minutes(this.props.datasets[0]);
        if (estimatedTime === 0 && predicatedLon === 0 && predicatedLat === 0) {
            return "";
        } else {
            return (
                <div>
                    <p>{estimatedTime}</p>
                    <p>
                        {predicatedLon}, {predicatedLat}
                    </p>
                    <p>
                        {predicatedLonToDMS}, {predicatedLatToDMS}
                    </p>
                </div>
            );
        }
    }

    getDateRange(datasets) {
        return {
            from: datasets
                .filter((dataset) => dataset.earliestStartDate != null)
                .reduce(
                    (acc, dataset) =>
                        moment.max(moment(dataset.earliestStartDate), acc),
                    moment("01/01/2010")
                ),
            to: moment(),
        };
    }

    componentDidMount() {
        this.loadState();
    }

    componentDidUpdate(prevProps) {
        this.map.resize();
        this.fitMap();
        this.updateMap();
        if (this.props.flyId !== prevProps.flyId) {
            this.props.flyId && this.flyTo();
        }

        // If the focused buoy ID has changed, highlight the line and open the popup.
        if (prevProps.focusedBuoyId !== this.props.focusedBuoyId) {
            this.openPopUpAndHighlightLine(null, this.props.focusedBuoyId);
        }
    }

    componentWillUnmount() {
        this.clearUpdateMapTimeout();
    }

    clearUpdateMapTimeout() {
        this.updateMapTimeoutID != null &&
            clearTimeout(this.updateMapTimeoutID);
    }

    fitMap() {
        const coordinates = this.props.datasets.flatMap(
            (dataset) => dataset.coordinates
        );
        if (!coordinates.length) {
            return;
        }

        // If there's only one point on the map, center it and zoom in (rather
        // than calculate bounds).
        if (coordinates.length === 1) {
            this.map.flyTo({
                center: coordinates[0],
                zoom: 10,
                essential: true, // this animation is considered essential with respect to prefers-reduced-motion
            });
            return;
        }

        var bounds = coordinates.reduce((bounds, coord) => {
            return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

        this.map.fitBounds(bounds, {
            padding: 20,
        });
    }

    addPulsePointClickEvent(id) {
        this.activePopups[id] = new mapboxgl.Popup({
            closeButton: true,
            closeOnClick: false,
            className: this.props.classes.popup,
        });

        const _this = this;
        // When the popup is manually closed by clicking the close button we need to unhighlight the line
        this.activePopups[id].on("close", function () {
            const lineId = id.replace(pulsingDot, "");
            _this.selectedLines.splice(_this.selectedLines.indexOf(lineId), 1);
            _this.highlightLine(lineId, false);
        });

        // When the pulse point is clicked we need to highlight the line and open the popup
        this.map.on("click", id, function (e) {
            const lineId = id.replace(pulsingDot, "");
            var feature = _this.map.getSource(id)._options.data.features[0];
            _this.openPopUpAndHighlightLine(e, lineId, feature);
        });
    }

    /**
     * Opens the popup and highlights the line
     * @param {mapboxgl.MapMouseEvent} e - The event coming from mapbox
     * @param {String} lineId - the id of the line to highlight
     */
    openPopUpAndHighlightLine(e, lineId) {
        var feature = this.map.getSource(lineId + pulsingDot)._options.data
            .features[0];

        if (e == null) {
            e = {
                lngLat: {
                    lng: feature.geometry.coordinates[0],
                    lat: feature.geometry.coordinates[1],
                },
            };
        }

        this.selectedLines.push(lineId);
        this.highlightLine(lineId, true);
        this.openPopup(e, lineId + pulsingDot, feature);
    }

    /**
     * Adds a hover event to the map for the give point id
     * @param {String} id
     */
    addPointHoverEvent(id) {
        this.activePopups[id] = new mapboxgl.Popup({
            closeButton: true,
            closeOnClick: false,
            className: this.props.classes.popup,
        });

        const _this = this;
        this.map.on("mouseenter", id, function (e) {
            _this.openPopup(e, e.features[0].layer.id, e.features[0]);
        });

        this.map.on("mouseleave", id, function () {
            _this.map.getCanvas().style.cursor = "";
            _this.activePopups[id].remove();
        });
    }

    removeHoverEvent(id) {
        this.map.off("mouseleave", id);
        delete this.activePopups[id];
    }

    /**
     * Opens a popup at the end point of the line
     * @param {Event} e
     * @param {string} id - id of the end point of the line
     * @param {Feature} feature - feature of the end point of the line
     */
    openPopup(e, id, feature) {
        // Change the cursor style as a UI indicator.
        this.map.getCanvas().style.cursor = "pointer";

        var coordinates = feature.geometry.coordinates.slice();
        var description = feature.properties.description;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        this.activePopups[id]
            .setLngLat(coordinates)
            .setHTML(description)
            .addTo(this.map);
    }

    /**
     * Adds a hover event to the map for the give line id
     * @param {String} id
     */
    addLineHoverEvent(id) {
        let _this = this;
        // Update the line color on hover
        this.map.on("mouseenter", id, function () {
            _this.map.getCanvas().style.cursor = "pointer";
            _this.highlightLine(id, true);
        });

        this.map.on("mouseleave", id, function () {
            // Don't reset the color if the line is selected
            _this.map.getCanvas().style.cursor = "";
            if (_this.selectedLines.includes(id)) return;
            _this.highlightLine(id, false);
        });
    }

    /**
     * Adds a click event for the given line id
     * @param {String} id
     */
    addLineClickEvent(id) {
        let _this = this;
        this.map.on("click", id, function (e) {
            if (!_this.selectedLines.includes(id)) {
                _this.openPopUpAndHighlightLine(e, id);
            } else {
                _this.selectedLines.splice(_this.selectedLines.indexOf(id), 1);
                _this.highlightLine(id, false);
                _this.activePopups[id + pulsingDot].remove();
            }
        });
    }

    /**
     * Highlights or unhighlights a line for the given id
     * @param {String} id
     * @param {String} highlight
     */
    highlightLine(id, highlight) {
        if (highlight) {
            this.map.setPaintProperty(id, "line-color", rt_green);
            this.map.setPaintProperty(id, "line-width", 7);
            this.map.moveLayer(id);
        } else {
            this.map.setPaintProperty(id, "line-color", line_color);
            this.map.setPaintProperty(id, "line-width", 3);
        }
    }

    /**
     * Adds a pulsing dot to the last location for a line
     * @param {Object} pulsingDotObj
     * @param {String} id
     * @param {Array} coordinates
     * @param {Object} dataset
     */
    markLastLocation(pulsingDotObj, id, coordinates, dataset) {
        this.map.addImage(`${id}${pulsingDot}`, pulsingDotObj, {
            pixelRatio: 2,
        });

        const speed = GeoUtils.getSpeedMPH(dataset, "nm");
        const directions = GeoUtils.getDirection(dataset);
        let speedDirectionDescription;
        if (speed === 0 && directions[1] === 0) {
            speedDirectionDescription = ``;
        } else {
            speedDirectionDescription = `</br><b>Speed:</b> ${speed} nm/hour</br>
                                         <b>Compass Direction:</b> ${directions[0]}</br>
                                         <b>Great Circle Bearing:</b> ${directions[1]}`;
        }

        this.map.addSource(`${id}${pulsingDot}`, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        properties: {
                            description: this.createPopUpDescription(
                                dataset,
                                speedDirectionDescription
                            ),
                        },
                        geometry: {
                            type: "Point",
                            coordinates: coordinates,
                        },
                    },
                ],
            },
        });
        this.map.addLayer({
            id: `${id}${pulsingDot}`,
            type: "symbol",
            source: `${id}${pulsingDot}`,
            layout: {
                "icon-image": `${id}${pulsingDot}`,
                //This allows the pulsing dots to be visible at all zoom levels
                "icon-allow-overlap": true,
                "icon-ignore-placement": true,
                "text-allow-overlap": true,
                "text-ignore-placement": true,
            },
        });
        if (!this.props.addPoints) {
            this.addPulsePointClickEvent(`${id}${pulsingDot}`);
        }

        this.addMapData(
            `${id}${pulsingDot}`,
            `${id}${pulsingDot}`,
            `${id}${pulsingDot}`
        );
    }

    createPopUpDescription(dataset, speedDirectionDescription) {
        return `<b>Name:</b> ${
            dataset.type === "buoy"
                ? `<a href="/kelp_buoy/${dataset.thing_id}" target="_blank" >${dataset.thing_name}</a>`
                : dataset.thing_name
        } <br> <b>Coordinates(DMS):</b> ${formatcoords(
            dataset.coordinates[0][1],
            dataset.coordinates[0][0]
        ).format()}<br><b>Coordinates(DD):</b> ${
            dataset.coordinates[0]
        } <br><b>Time:</b> ${moment(dataset.date_time[0])
            .local()
            .format("MM-DD-YY HH:mm:ss")} + ${speedDirectionDescription}`;
    }

    addPoints(dataset) {
        this.map.addSource(`${dataset.ds_id}-points`, dataset.geoJson);

        this.map.addLayer({
            id: `${dataset.ds_id}-points`,
            source: `${dataset.ds_id}-points`,
            type: "circle",
            paint: {
                "circle-radius": 5,
                "circle-color": rt_green,
            },
        });
        //This moves all the points to be on top of the line
        this.map.moveLayer(`${dataset.ds_id}`, `${dataset.ds_id}-points`);
        this.addPointHoverEvent(`${dataset.ds_id}-points`);
        this.addMapData(
            `${dataset.ds_id}-points`,
            `${dataset.ds_id}-points`,
            null
        );
    }

    // add a "bottom-left" scale bar
    addScale() {
        const scale = new mapboxgl.ScaleControl({
            // max length of the scale control in pixels
            maxWidth: 80,
            // unit of the distance
            unit: "nautical",
        });
        this.map.addControl(scale);
    }

    clearMap() {
        try {
            this.activeLayers.forEach((layer) => {
                this.map.removeLayer(layer);
                this.removeHoverEvent(layer);
            });
            this.activeLayers = [];
            this.activeSources.forEach((source) => {
                this.map.removeSource(source);
                this.removeHoverEvent(source);
            });
            this.activeSources = [];
            this.activeImages.forEach((image) => {
                this.map.removeImage(image);
                this.removeHoverEvent(image);
            });
            this.activeImages = [];
        } catch (e) {
            //mapbox sometimes says it does not exist
        }
    }

    addMapData(layer, source, image) {
        layer && this.activeLayers.push(layer);
        source && this.activeSources.push(source);
        image && this.activeImages.push(image);
    }

    updateMap(clearMap = true) {
        // If a dataset comes in before the map finishes loading, we can't
        // update the map. Theoretically, we should be able to rely on the
        // "styledata" callback set up in loadState to call updateMap when it's
        // done loading, but unfortunately this.map.isStyleLoaded can still
        // evaluate to false after the callback has already been triggered.
        // (See GitHub issue linked in switchHandler.) As a result, we add a timeout
        // to ensure we get the latest data.

        if (!this.map.isStyleLoaded()) {
            this.clearUpdateMapTimeout();
            this.updateMapTimeoutID = setTimeout(
                () => this.updateMap(clearMap),
                TIMEOUT_MS
            );
            return;
        }

        if (clearMap) {
            this.clearMap();
        }
        this.props.datasets.forEach((dataset) => {
            this.addMapData(dataset.ds_id, dataset.ds_id, null);
            this.map.addSource(dataset.ds_id, {
                type: "geojson",
                data: {
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "LineString",
                        coordinates: dataset.coordinates,
                    },
                },
            });
            this.map.addLayer({
                id: dataset.ds_id,
                type: "line",
                source: dataset.ds_id,
                layout: {
                    "line-join": "round",
                },
                paint: {
                    "line-color": dataset.color,
                    "line-width": 3,
                },
            });

            // We should only have a line click event if we are not adding points.
            if (!this.props.addPoints) {
                this.addLineHoverEvent(dataset.ds_id);
                this.addLineClickEvent(dataset.ds_id);
            }

            if (dataset.coordinates && dataset.coordinates.length) {
                //only show dots if using a kelp buoy dataset
                if (this.props.addPoints) {
                    this.addPoints(dataset);
                }
                this.markLastLocation(
                    this.pulsingDot,
                    dataset.ds_id,
                    dataset.coordinates[0],
                    dataset
                );
            }
        });
    }

    async loadState() {
        let size = 50,
            _this = this;
        this.pulsingDot = {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4),

            // get rendering context for the map canvas when layer is added to the map
            onAdd: function () {
                var canvas = document.createElement("canvas");
                canvas.width = this.width;
                canvas.height = this.height;
                this.context = canvas.getContext("2d");
            },

            // called once before every frame where the icon will be used
            render: function () {
                var duration = 1000;
                var t = (performance.now() % duration) / duration;

                var radius = (size / 2) * 0.3;
                var outerRadius = (size / 2) * 0.7 * t + radius;
                var context = this.context;

                // draw outer circle
                context.clearRect(0, 0, this.width, this.height);
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    outerRadius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = "rgba(187, 255, 0," + (1 - t) + ")";
                context.fill();

                // draw inner circle
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    radius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = rt_green;
                context.strokeStyle = "rgba(100, 100, 100, 0)";
                context.lineWidth = 2 + 4 * (1 - t);
                context.fill();
                context.stroke();

                // update this image's data with data from the canvas
                this.data = context.getImageData(
                    0,
                    0,
                    this.width,
                    this.height
                ).data;

                // continuously repaint the map, resulting in the smooth animation of the dot

                _this.map.triggerRepaint();
                // return `true` to let the map know that the image was updated
                return true;
            },
        };
        this.map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: depthMap,
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom,
        });

        this.map.addControl(new mapboxgl.FullscreenControl());
        this.addScale();
        this.map.once("styledata", () => {
            _this.map.resize();
            _this.fitMap();
            this.updateMap();
        });
    }

    switchHandler = (event, value) => {
        this.map.setStyle(value ? depthMap : standardMap);
        // Triggered when `setStyle` is called. `setStyle` clears all existing data from the map so we have to reload it.
        // Link to mapbox setStyle issue: https://github.com/mapbox/mapbox-gl-js/issues/4006
        this.map.once("styledata", () => {
            this.activeLayers = [];
            this.activeSources = [];
            this.activeImages = [];
            // prop so we dont try to clear data that is already cleared
            this.updateMap(false);
        });
    };

    async loadNew(startDate, endDate) {
        await this.update(startDate, endDate);
    }

    flyTo() {
        const [coords] = this.props.datasets.find(
            (dataset) =>
                dataset.foi_id === this.props.flyId ||
                dataset.thing_id === this.props.flyId
        ).coordinates;
        this.map.flyTo({
            center: coords,
            zoom: 10,
            essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
    }

    render() {
        const { classes } = this.props;

        let predictionButton;
        // if there exists valid single buoy on the map, then display the predict button, otherwise display nothing
        if (this.getPredictionDescription()) {
            predictionButton = (
                <Button onClick={this.showPrediction} variant="secondary">
                    Predict
                </Button>
            );
        } else {
            predictionButton = "";
        }

        return (
            <div className={classes.graphContainer}>
                <div ref={this.mapContainer} className={classes.map}></div>
                <FormControlLabel
                    sx={{ ml: 0 }}
                    control={
                        <Switch
                            defaultChecked
                            onChange={this.switchHandler}
                            color="primary"
                        />
                    }
                    label="Map Style"
                />
                {(this.picker || this.picker === undefined) && (
                    <GraphDateRangePickerComponent
                        dateRange={this.dateRange}
                        update={async (startDate, endDate) =>
                            await this.loadNew(startDate, endDate)
                        }
                        initialActiveButton={this.props.initialDateFilter}
                    />
                )}

                <div>
                    {predictionButton}
                    {this.state.renderPrediction &&
                        this.getPredictionDescription()}
                </div>
            </div>
        );
    }
}

// NOTE(hannah): These PropTypes aren't actually enforced and may not be
// completely accurate; they're just an attempt to retroactively document what
// data this component expects.
MapComponent.propTypes = {
    datasets: PropTypes.arrayOf(
        PropTypes.shape({
            color: PropTypes.string,
            // An array in which each entry is a two-element array of [lng, lat]
            coordinates: PropTypes.array,
            // An array of dates.
            date_time: PropTypes.arrayOf(PropTypes.any),
            ds_id: PropTypes.string,
            // Used to identify a feature of interest
            // e.g. - for a deployment, should map to a deployment's gpsTracker
            // to locate the dot on the map
            foi_id: PropTypes.string, // optional
            // Used to identify a thing
            // e.g. - for a kelp buoy, should map to a buoy's id
            // to locate its dot on the map
            thing_id: PropTypes.string, // optional
            // A date (anything that can be parsed to moment)
            earliestStartDate: PropTypes.any,
            // Passed directly to MapBox: https://docs.mapbox.com/help/glossary/geojson
            geoJson: PropTypes.any,
            thing_name: PropTypes.string,
        })
    ).isRequired,
    picker: PropTypes.bool.isRequired,
    // (startDate, endDate) => [datasets]
    update: PropTypes.func, // required if picker is true

    addPoints: PropTypes.bool,
    initialDateFilter: PropTypes.oneOf(["year", "month", "week", "day", "all"]),
};

export default withStyles(styles)(MapComponent);
