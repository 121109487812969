import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import APIClient from "../../models/APIClient";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import { Box, Grid, Link, Typography } from "@mui/material";
import RTable from "../components/RTable";
import MessageHelper from "../helper/MessageHelper";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";
import GraphComponent from "../components/GraphComponent";
import SelectorThingsLabelManager from "../components/SensorThingsLabelManager";

const styles = (theme) => ({
    root: {},
    paper: {
        padding: 10,
    },
    section: {
        marginTop: theme.spacing(5),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paragraph: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    description: {
        paddingLeft: 30,
        paddingRight: 30,
    },
    box: {
        padding: 10,
    },
});

let FeatureOfInterestManager = WithGoogleAuth(
    class FeatureOfInterestManagerions extends Component {
        constructor(props) {
            super(props);
            this.state = {
                fois: [],
                isLoading: true,
            };
        }

        componentDidMount() {
            this.loadState();
        }
        async loadState() {
            let result = await new APIClient(this.props.authState).getAllFois(),
                fois = await result.json();
            fois = fois.map((foi) => {
                if (foi.feature) foi.feature = JSON.stringify(foi.feature);
                return foi;
            });
            fois = this.generateFOIGraphURL(fois);
            this.setState({ fois: fois });
            this.setState({ isLoading: false });
        }

        generateFOIGraphURL(fois = [...this.state.fois]) {
            return fois.map((foi) => {
                foi.url = `${window.location.protocol}//${window.location.host}/feature_of_interest/${foi.id}`;
                return foi;
            });
        }

        render() {
            const { classes } = this.props;
            return (
                <Grid container spacing={3}>
                    <Grid item lg={1} xs={12}>
                        <MessageHelper
                            message={this.state.message}
                            errorMessage={this.state.errorMessage}
                            open={this.state.messageOpen}
                            setState={(a) => this.setState(a)}
                        />
                    </Grid>
                    <Grid item lg={12} xs={12} className={classes.description}>
                        <div className={classes.description}>
                            <Typography className={classes.title} variant="h6">
                                Feature Of Interest
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                                variant="body1"
                            >
                                A Feature of Interest is what the Sensor
                                collects data on. A Feature of Interest can
                                change over time. For example, a fluorometer
                                could be placed into Tank A for two months and
                                then swapped into Tank B. In this case, two
                                Features of Interest will have to be created and
                                assigned at the correct date/time and to the
                                correct Datastream(in the next tab). Once all
                                set up you will be able to view graphs for the
                                data of your Feature of Interest
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Box>
                            <RTable
                                className={classes.table}
                                title={
                                    <Typography variant="h3">
                                        Features of Interest Manager
                                    </Typography>
                                }
                                columns={[
                                    {
                                        title: "ID",
                                        field: "id",
                                        editable: "never",
                                    },
                                    {
                                        title: "Name *",
                                        field: "name",
                                    },
                                    {
                                        title: "Description *",
                                        field: "description",
                                    },
                                    {
                                        title: "Selector ID",
                                        field: "selectorid",
                                    },
                                    {
                                        title: "Seletor Labels (comma-separated)",
                                        field: "selectorlabel",
                                        render: (rowData) => (
                                            <SelectorThingsLabelManager
                                                value={rowData.selectorlabel}
                                                edit={false}
                                            ></SelectorThingsLabelManager>
                                        ),
                                        editComponent: (rowData) => {
                                            return (
                                                <SelectorThingsLabelManager
                                                    value={rowData.value}
                                                    onChange={rowData.onChange}
                                                    edit={true}
                                                ></SelectorThingsLabelManager>
                                            );
                                        },
                                    },
                                    {
                                        title: "Metadata (JSON)",
                                        field: "feature",
                                    },
                                    {
                                        title: "Graph URL",
                                        field: "url",
                                        editable: "never",
                                        render: (rowData) => (
                                            <Link
                                                href={
                                                    rowData ? rowData.url : ""
                                                }
                                                target="_blank"
                                            >
                                                {rowData ? rowData.url : ""}
                                            </Link>
                                        ),
                                    },
                                ]}
                                data={this.state.fois}
                                editable={{
                                    isEditable: () => true,
                                    onRowAddCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowUpdateCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowAdd: (newData) =>
                                        new Promise((resolve, reject) => {
                                            //add foi
                                            let newDataUpdate = { ...newData };
                                            try {
                                                if (newDataUpdate.feature)
                                                    newDataUpdate.feature =
                                                        JSON.parse(
                                                            newDataUpdate.feature
                                                        );
                                            } catch (e) {
                                                this.setState(
                                                    errorMessage(
                                                        "Feature - expecting valid JSON"
                                                    )
                                                );
                                                reject();
                                                return;
                                            }
                                            new APIClient(this.props.authState)
                                                .createFoi(newDataUpdate)
                                                .then(async (res) => {
                                                    if (res.status !== 200) {
                                                        res = await res.json();
                                                        this.setState(
                                                            errorMessage(res)
                                                        );
                                                        reject();
                                                        return;
                                                    }
                                                    //Update State
                                                    res = await res.json();
                                                    newData.id = res.id;
                                                    let fois = [
                                                        ...this.state.fois,
                                                    ];
                                                    fois.unshift(newData);
                                                    fois =
                                                        this.generateFOIGraphURL(
                                                            fois
                                                        );
                                                    this.setState({ fois });
                                                    this.setState(
                                                        successMessage(
                                                            "Successfully added"
                                                        )
                                                    );
                                                    resolve();
                                                })
                                                .catch((e) => {
                                                    this.setState(
                                                        errorMessage(e)
                                                    );
                                                    reject();
                                                });
                                        }),
                                    onRowUpdate: (newData) =>
                                        new Promise((resolve, reject) => {
                                            let newDataUpdate = { ...newData };
                                            try {
                                                if (newDataUpdate.feature)
                                                    newDataUpdate.feature =
                                                        JSON.parse(
                                                            newDataUpdate.feature
                                                        );
                                            } catch (e) {
                                                this.setState(
                                                    errorMessage(
                                                        "Feature - expecting valid JSON"
                                                    )
                                                );
                                                reject();
                                                return;
                                            }
                                            function isFoi(foi) {
                                                return foi.id === newData.id;
                                            }
                                            new APIClient(this.props.authState)
                                                .updateFoi(newDataUpdate)
                                                .then(async (res) => {
                                                    if (res.status !== 200) {
                                                        res = await res.json();
                                                        this.setState(
                                                            errorMessage(res)
                                                        );
                                                        reject();
                                                        return;
                                                    }
                                                    //Update State
                                                    let fois = [
                                                            ...this.state.fois,
                                                        ],
                                                        i =
                                                            fois.findIndex(
                                                                isFoi
                                                            );
                                                    fois[i] = newData;
                                                    fois =
                                                        this.generateFOIGraphURL(
                                                            fois
                                                        );
                                                    this.setState({ fois });
                                                    this.setState(
                                                        successMessage(
                                                            "Successfully updated"
                                                        )
                                                    );
                                                    resolve();
                                                })
                                                .catch((e) => {
                                                    this.setState(
                                                        errorMessage(e)
                                                    );
                                                    reject();
                                                });
                                        }),
                                    onRowDelete: (oldData) =>
                                        new Promise((resolve) => {
                                            //remove foi
                                            new APIClient(this.props.authState)
                                                .removeFoi(oldData)
                                                .then(async (res) => {
                                                    if (res.status !== 200) {
                                                        res = await res.json();
                                                        this.setState(
                                                            errorMessage(res)
                                                        );
                                                        resolve();
                                                        return;
                                                    }
                                                    //Update State
                                                    let fois = [
                                                        ...this.state.fois,
                                                    ];
                                                    fois = fois.filter(
                                                        (foi) =>
                                                            foi.id !==
                                                            oldData.id
                                                    );
                                                    this.setState({ fois });
                                                    this.setState(
                                                        successMessage(
                                                            "Successfully deleted"
                                                        )
                                                    );
                                                    resolve();
                                                })
                                                .catch((e) => {
                                                    this.setState(
                                                        errorMessage(e)
                                                    );
                                                    resolve();
                                                });
                                        }),
                                }}
                                isLoading={this.state.isLoading}
                                detailPanel={({ rowData }) => (
                                    <GraphComponent
                                        foi_id={rowData.id}
                                        size="medium"
                                    />
                                )}
                                options={{
                                    search: true,
                                    maxColumnSort: 1,
                                    filtering: true,
                                    paging: true,
                                    addRowPosition: "first",
                                }}
                            ></RTable>
                        </Box>
                    </Grid>
                </Grid>
            );
        }
    }
);

export default withStyles(styles)(FeatureOfInterestManager);
