import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ShellfishInventoryAPIClient from "../models/ShellfishInventoryAPIClient";
import { WithGoogleAuth } from "../config/WithGoogleAuth";
import withStyles from "@mui/styles/withStyles";
import NumberFormat from "react-number-format";
import Typography from "@mui/material/Typography";
import { groupBy } from "../models/GroupBy";
import RTDisplayUtils from "@running-tide/rt-frontend-helpers/helpers";
import moment from "moment-timezone";

const styles = (theme) => ({
    title: {
        marginBottom: theme.spacing(3),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    binContainer: {
        margin: 0,
    },
    binInnerContainer: {
        padding: 10,
        color: theme.palette.darkUtility.main,
    },
    binName: {
        marginLeft: 5,
        marginTop: "auto",
        marginBottom: "auto",
    },
    submitContainer: {
        marginTop: 10,
        float: "right",
    },
    recentGrade: {
        margin: 0,
        backgroundColor: theme.palette.secondaryUtility.main,
    },
    middleAgeGrade: {
        margin: 0,
        backgroundColor: theme.palette.warning.backgroundColor,
    },
    oldGrade: {
        margin: 0,
        backgroundColor: theme.palette.error.backgroundColor,
    },
});

let cAllocations = WithGoogleAuth(
    class CurrentAllocationView extends Component {
        constructor(props) {
            super(props);
            this.state = { reefs: [], streams: [] };
        }

        componentDidMount() {
            new ShellfishInventoryAPIClient(this.props.authState)
                .getBinAllocations()
                .then((res) => res.json())
                .then((res) =>
                    res.reduce((r, a) => {
                        //Groups allocation by reef
                        r[a.reefId] = [...(r[a.reefId] || []), a];
                        return r;
                    }, {})
                )
                .then((res) => {
                    //Group on binId by first mapping the reef
                    let obj = Object.entries(res).map((reef) => {
                        console.log(reef);
                        let bins = groupBy(reef[1], "binId");
                        // console.log(reef[1])
                        //The problem is that reef[1] is an object where the key is the bin id.
                        //I need to transform that into an array of bins sorted by binOrder
                        console.log(bins);
                        bins = Object.entries(bins).map((a) => a[1]);
                        bins = bins.sort(function (a, b) {
                            return a[0].bin.binOrder - b[0].bin.binOrder;
                        });

                        console.log(bins);
                        return {
                            reefId: reef[0],
                            reefName: bins[0][0].reefName,
                            bins: bins,
                            farmName: bins[0][0].farmName,
                        };
                    });
                    console.log(obj);
                    return obj;
                })
                .then((res) => {
                    //Sort by reefName
                    return res.sort((a, b) =>
                        a.reefName.localeCompare(b.reefName)
                    );
                })
                .then((res) => {
                    console.log(res);
                    this.setState({ reefs: res });
                });
        }

        renderReef(reef) {
            let pairs = [];
            let halfReef = reef.bins.length / 2;
            for (let i = 0; i < halfReef; i++) {
                pairs.push(i);
            }
            return (
                <Grid item md={4} xs={12} key={reef.reefId + "reef"}>
                    <Box>
                        <div key={reef.reefId + "reefName"}>
                            <Typography variant="h2" component="h2">
                                {reef.reefName}
                            </Typography>
                            <Typography variant="h5" component="h5">
                                {reef.farmName}
                            </Typography>
                        </div>
                        {pairs.map((pair) => {
                            return (
                                <Grid
                                    container
                                    key={
                                        reef.bins[pair][0].id +
                                        "-" +
                                        reef.bins[pair + halfReef][0].id
                                    }
                                >
                                    {this.renderBin(reef.bins[pair])}
                                    {this.renderBin(reef.bins[pair + halfReef])}
                                </Grid>
                            );
                        })}
                    </Box>
                </Grid>
            );
        }

        renderBin(binDatas) {
            let classes = this.props.classes;
            console.log(binDatas);
            let firstBinCount = binDatas[0];
            if (firstBinCount.count == null && firstBinCount.grade == null) {
                return (
                    <Grid
                        item
                        xs={6}
                        key={firstBinCount.id}
                        className={classes.recentGrade}
                    >
                        <Box
                            border={1}
                            height="100%"
                            className={classes.binInnerContainer}
                        >
                            <Grid container>
                                <Grid item lg={2} xs={12}>
                                    <Typography variant="h5">
                                        <b>{firstBinCount.bin.name}</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                );
            } else {
                let lastGradeDate = moment(firstBinCount.allocationDate);
                let className = lastGradeDate.isBefore(
                    moment().subtract(30, "days")
                )
                    ? classes.oldGrade
                    : lastGradeDate.isBefore(moment().subtract(15, "days"))
                    ? classes.middleAgeGrade
                    : classes.recentGrade;
                return (
                    <Grid
                        item
                        xs={6}
                        key={firstBinCount.id}
                        className={className}
                    >
                        <Box
                            border={1}
                            height="100%"
                            className={classes.binInnerContainer}
                        >
                            <Grid container columnSpacing={1}>
                                <Grid item xl={2} lg={3} sm={4} xs={12}>
                                    <Typography variant="h5">
                                        <b>{firstBinCount.bin.name}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xl={10} lg={9} sm={8} xs={12}>
                                    {binDatas.map((binData) => {
                                        //A bin can have multiple size oysters in it so we have to loop thos
                                        return (
                                            <div key={binData.id}>
                                                <Typography variant="h6">
                                                    {RTDisplayUtils.prettyPrintGrade(
                                                        binData.grade
                                                    )}
                                                </Typography>
                                                <div>
                                                    <Typography variant="body1">
                                                        <b>Type:</b>{" "}
                                                        {RTDisplayUtils.printShellfishType(
                                                            binData.shellfishType
                                                        )}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        <b>Count:</b>{" "}
                                                        {this.renderCount(
                                                            binData.count
                                                        )}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        <b>Date:</b>{" "}
                                                        {RTDisplayUtils.printDate(
                                                            binData.allocationDate
                                                        )}
                                                    </Typography>
                                                </div>
                                                <br></br>
                                            </div>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                );
            }
        }

        renderCount(count) {
            if (count === 0) return "???";
            else
                return (
                    <NumberFormat
                        thousandSeparator={true}
                        displayType={"text"}
                        value={count}
                    />
                );
        }

        render() {
            return (
                <div>
                    <Typography
                        variant="h2"
                        component="h2"
                        className={this.props.classes.title}
                    >
                        Bin Assignments (ALL VALUES ARE ROUGH ESTIMATES)
                    </Typography>
                    <Grid container spacing={3}>
                        {this.state.reefs.map((reef) => this.renderReef(reef))}
                    </Grid>
                </div>
            );
        }
    }
);
export default withStyles(styles)(cAllocations);
