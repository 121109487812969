import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Button,
    TextField,
    Typography,
    Stack,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormGroup,
    InputAdornment,
    Link,
    IconButton,
    Grid,
    ListSubheader,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import { errorMessage, successMessage } from "../helper/MessageMethodHelper";
import MessageHelper from "../helper/MessageHelper";
import {
    QuantFormulaVar,
    EstimateContext,
    ApprovalStatus,
} from "../../models/enums/InterventionEnums";
import MultiFileUpload from "../components/MultiFileUpload";

const FILE_UPLOAD_WIDTH = 1; // 100% in MUI sx
const FILE_UPLOAD_HEIGHT = 200;
function DeploymentEstimateCreator(props) {
    const { authState, params } = props;

    const [deploymentTitle, setDeploymentTitle] = useState("");
    const [title, setTitle] = useState("");
    const [quantFormulaVar, setQuantFormulaVar] = useState(null);
    const [carbonBuoyBatchId, setCarbonBuoyBatchId] = useState("");
    const [co2e, setCo2e] = useState("");
    const [carbonBuoyBatches, setCarbonBuoyBatches] = useState([]);
    const [metadata, setMetadata] = useState("");

    const [uploadedEvidence, setUploadedEvidence] = useState([]);

    const [isCreating, setIsCreating] = useState(false);
    const [message, setMessage] = useState({});

    useEffect(() => {
        const fetchDeploymentData = async () => {
            const apiClient = new CarbonAccountingAPIClient(authState);
            const [buoyBatches, deploymentInfo] = await Promise.all([
                apiClient.getDeploymentSubstrates(params.deployment_id),
                apiClient.getDeployment(params.deployment_id),
            ]);
            setCarbonBuoyBatches(buoyBatches);
            setDeploymentTitle(deploymentInfo.title);
        };
        fetchDeploymentData();
    }, [params.deployment_id]);

    const isCreateDisabled = () => {
        if (isCreating) return true;
        if (title === "" || quantFormulaVar === null || co2e === "")
            return true;
        else return false;
    };

    const createEstimate = async () => {
        setIsCreating(true);
        try {
            const apiClient = new CarbonAccountingAPIClient(authState);
            let estimate;
            const jsonMetadata = metadata ? JSON.parse(metadata) : "";

            if (uploadedEvidence.length > 0) {
                estimate = await apiClient.createDeploymentEstimateEvidence(
                    params.deployment_id,
                    title,
                    EstimateContext.Final,
                    co2e,
                    quantFormulaVar,
                    ApprovalStatus.Pending,
                    uploadedEvidence,
                    carbonBuoyBatchId || undefined,
                    jsonMetadata || undefined
                );
                setUploadedEvidence([]);
            } else {
                estimate = await apiClient.createDeploymentEstimate(
                    params.deployment_id,
                    title,
                    EstimateContext.Final,
                    co2e,
                    quantFormulaVar,
                    ApprovalStatus.Pending,
                    carbonBuoyBatchId || undefined,
                    jsonMetadata || undefined
                );
            }
            setMessage(successMessage(`Estimate ${estimate.title} created!`));
            setTitle("");
            setQuantFormulaVar(null);
            setCarbonBuoyBatchId("");
            setCo2e("");
            setMetadata("");
        } catch (error) {
            setMessage(errorMessage(error));
        }
        setIsCreating(false);
    };

    const onEvidenceUpload = (filenames) => {
        const uniqueFilenames = filenames.filter(
            (file) =>
                uploadedEvidence.findIndex((f) => f.name === file.name) < 0
        );
        setUploadedEvidence([...uploadedEvidence, ...uniqueFilenames]);
    };

    return (
        <Grid
            container
            columns={120}
            rowSpacing={3}
            justifyContent="space-between"
        >
            <Grid item lg={57} xs={100}>
                <Stack justifyContent="flex-start" alignItems="flex-start">
                    <Typography variant="h3">
                        <Link
                            variant="h3"
                            href={`/cdr/deployments/${params.deployment_id}`}
                            underline="hover"
                        >
                            {deploymentTitle}
                        </Link>
                        {" - "}
                        Create Intervention Estimate
                    </Typography>
                    <FormGroup>
                        <TextField
                            id="name-textfield"
                            label="Name"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                        />

                        <FormControl>
                            <InputLabel id="label-select-quantFormulaVar">
                                Impact Source
                            </InputLabel>
                            <Select
                                onChange={(event) =>
                                    setQuantFormulaVar(event.target.value)
                                }
                                id="quantFormulaVar-select"
                                value={quantFormulaVar ?? ""}
                            >
                                {Object.keys(QuantFormulaVar).map((key) => (
                                    <MenuItem
                                        key={key}
                                        value={QuantFormulaVar[key]}
                                    >
                                        {key}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl>
                            <InputLabel id="label-select-carbonBuoyBatchId">
                                Carbon Buoy Batch
                            </InputLabel>
                            <Select
                                onChange={(event) =>
                                    setCarbonBuoyBatchId(event.target.value)
                                }
                                id="carbonBuoyBatchId-select"
                                value={carbonBuoyBatchId ?? ""}
                                {...(carbonBuoyBatchId && {
                                    IconComponent: () => (
                                        <IconButton
                                            size="small"
                                            color="inherit"
                                            onMouseDown={(event) => {
                                                event.stopPropagation();
                                            }}
                                            onClick={() => {
                                                setCarbonBuoyBatchId("");
                                            }}
                                        >
                                            <CloseRoundedIcon />
                                        </IconButton>
                                    ),
                                })}
                            >
                                {carbonBuoyBatches.map((batch) => (
                                    <MenuItem
                                        key={batch.title}
                                        value={batch.id}
                                    >
                                        {batch.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            id="co2e-textfield"
                            label="CO2e" // TODO (nikhil) try for subscript 2 here
                            value={co2e}
                            onChange={(event) => setCo2e(event.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        tonnes
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            placeholder={"{ ... }"}
                            helperText="Please enter a valid JSON object"
                            id="metadata-textfield"
                            label="Metadata"
                            multiline={true}
                            rows={3}
                            value={metadata}
                            onChange={(event) =>
                                setMetadata(event.target.value)
                            }
                        />
                    </FormGroup>

                    <Button
                        id="create-estimate"
                        variant="secondary"
                        onClick={() => createEstimate()}
                        disabled={isCreateDisabled()}
                        sx={{ mt: 2 }}
                    >
                        Create
                    </Button>

                    <MessageHelper
                        message={message.message}
                        errorMessage={message.errorMessage}
                        open={message.messageOpen}
                        setState={(a) => setMessage(a)}
                    />
                </Stack>
            </Grid>
            <Grid item lg={57} xs={100}>
                <Stack justifyContent="flex-start" alignItems="flex-start">
                    <Typography variant="h3">Evidence</Typography>
                    <Typography variant="body1">
                        Upload files or link to an existing GCS Directory
                    </Typography>
                    <MultiFileUpload
                        onFilesUpload={onEvidenceUpload}
                        sxWidth={FILE_UPLOAD_WIDTH}
                    />
                    <List
                        sx={{
                            width: "100%",
                            maxWidth: FILE_UPLOAD_WIDTH,
                            bgcolor: "background.paper",
                            position: "relative",
                            overflow: "auto",
                            maxHeight: FILE_UPLOAD_HEIGHT,
                            "& ul": { padding: 0 },
                            overflowWrap: "break-word",
                        }}
                        subheader={
                            <ListSubheader id="local-files-subheader">
                                Local File Uploads
                            </ListSubheader>
                        }
                        dense={true}
                    >
                        <ul>
                            {uploadedEvidence.map((file) => (
                                <ListItem
                                    key={`local-file-${file.name}`}
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => {
                                                setUploadedEvidence(
                                                    [
                                                        ...uploadedEvidence,
                                                    ].filter(
                                                        (e) =>
                                                            e.name !== file.name
                                                    )
                                                );
                                            }}
                                        >
                                            <DeleteOutlinedIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText primary={file.name} />
                                </ListItem>
                            ))}
                        </ul>
                    </List>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default WithGoogleAuth((props) => (
    <DeploymentEstimateCreator {...props} params={useParams()} />
));
