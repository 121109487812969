function successMessage(message) {
    let data = {
        errorMessage: undefined,
        message: message,
        messageOpen: true,
    };
    return data;
}
function errorMessage(message) {
    //message can be string, or 2 types of error objects
    message =
        typeof message === "string"
            ? message
            : message.validation
            ? message.validation.reduce((acc, err) => `${acc} ${err.msg}`, "")
            : message.error
            ? message.error
            : message.message;
    let data = {
        message: undefined,
        errorMessage: message,
        messageOpen: true,
    };
    return data;
}
function clearMessage() {
    let data = {
        message: undefined,
        errorMessage: undefined,
        messageOpen: false,
    };
    return data;
}

/* eslint-disable */
exports.clearMessage = clearMessage;
exports.errorMessage = errorMessage;
exports.successMessage = successMessage;
