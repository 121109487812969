import React, { Component } from "react";
import { TextField } from "@mui/material";
import { Action } from "@running-tide/rt-api-access-control/build/PermissionValidator/constants";
import Autocomplete from "@mui/material/Autocomplete";

export class EditPermissions extends Component {
    constructor(props) {
        super(props);
        this.client = props.client;
        this.state = {
            permissions: props.permissions,
            resourceOptions: [],
            selectedOptions: [],
        };
    }

    async componentDidMount() {
        const allResources = await this.client.getKnownResources();
        if (allResources.error) return;

        const options = allResources
            .map((r) => [
                { resource: r, accessLevel: Action.CREATE },
                { resource: r, accessLevel: Action.READ },
                { resource: r, accessLevel: Action.UPDATE },
                { resource: r, accessLevel: Action.DELETE },
            ])
            .flat();
        const selectedOptions =
            this.state?.permissions.map((p) => {
                return {
                    id: p.id,
                    resource: p.resource,
                    accessLevel: p.access_level,
                };
            }) ?? [];
        this.setState((s) => ({
            ...s,
            resourceOptions: options,
            selectedOptions: selectedOptions,
        }));
    }

    render() {
        const availableOptions = this.state?.resourceOptions ?? [];
        return (
            <Autocomplete
                disablePortal
                multiple
                options={availableOptions}
                getOptionLabel={(o) => `${o.resource} - ${o.accessLevel}`}
                value={this.state?.selectedOptions ?? []}
                isOptionEqualToValue={(o, v) => {
                    return (
                        o.resource === v.resource &&
                        o.accessLevel === v.accessLevel
                    );
                }}
                onChange={(event, newValue) => {
                    this.props.onChange(newValue ?? []);
                    this.setState((s) => ({
                        ...s,
                        selectedOptions: newValue,
                    }));
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label="Permission"
                            multiline
                            placeholder="Permission"
                        />
                    );
                }}
            />
        );
    }
}
