import moment from "moment-timezone";

import { rtFetch, downloadBlob } from "./APIClient";
import config from "../config";
import { InterventionType } from "./enums/InterventionEnums";

export default class CarbonAccountingAPIClient {
    constructor(authState) {
        this.accessToken = authState.accessToken;
        this.baseUrl = config.carbonAccountingServerPath;
    }

    getJsonHeader = () => {
        const header = {
            Authorization: `Bearer ${this.accessToken}`,
            "x-request-origin": "firebase",
        };
        header["Content-Type"] = "application/json";
        return header;
    };

    getFormHeader = () => {
        const header = {
            Authorization: `Bearer ${this.accessToken}`,
            "x-request-origin": "firebase",
        };
        return header;
    };

    /**
     * Creates a new product instance.
     */
    createProductInstance = (creationDate, batchIndex, index, properties) => {
        const timestampDate = moment(creationDate).isValid()
            ? moment(creationDate).toISOString()
            : null;
        if (timestampDate === null) {
            throw new Error(`invalid timestamp passed for product creation`);
        }
        return rtFetch(this.baseUrl + `/product-instances/create`, {
            method: "POST",
            headers: this.getJsonHeader(),
            body: JSON.stringify({
                creationDate: timestampDate,
                batchIndex,
                index,
                properties,
            }),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Creates a set of product instances given a CSV
     * @param {File} file file of product instances to upload
     */
    async createProductsFromCsv(file) {
        const header = {
            Authorization: `Bearer ${this.accessToken}`,
            "x-request-origin": "firebase",
        };
        const formData = new FormData();
        formData.append("file", file, file.name);

        return rtFetch(this.baseUrl + `/product-instances/create-from-csv`, {
            method: "POST",
            headers: header,
            body: formData,
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    }

    /**
     * Creates products from row data
     * @param {string[][]} data an array representation of a csv like structure
     * @returns a JSON of successful and failed creations
     */
    async createProductsFromData(data) {
        return await rtFetch(
            this.baseUrl + `/product-instances/create-from-rows`,
            {
                method: "POST",
                headers: this.getJsonHeader(),
                body: JSON.stringify({
                    data,
                }),
            }
        ).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    }

    /**
     * Updates a product instance of the given ID with the new given properties
     */
    async updateProductInstance(id, properties) {
        return await rtFetch(this.baseUrl + `/product-instances/update`, {
            method: "POST",
            headers: this.getJsonHeader(),
            body: JSON.stringify({
                id: id,
                properties: properties,
            }),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    }

    /**
     * Creates a product group
     * @param {string} name name of the product group to create
     * @param {string} description description of the product group to create
     * @param {Array} productInstanceIds list of productInstanceIds in group
     * @returns a response JSON with either an error message or the id of the created group
     */
    async createProductGroup(name, description, productInstanceIds) {
        if (name === "" || description === "") {
            throw new Error(`name or description is empty`);
        }
        return await rtFetch(this.baseUrl + `/product-instances/create-group`, {
            method: "POST",
            headers: this.getJsonHeader(),
            body: JSON.stringify({
                name: name,
                description: description,
                productInstanceIds: productInstanceIds,
            }),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    }

    /**
     * Gets the product group associated with the given id
     * @param {string} id the product group id to get
     * @returns a json containing the product group associated with the given id
     */
    async getProductGroup(id, includeInstances = false) {
        let url = this.baseUrl + `/product-instances/group/${id}`;
        if (includeInstances) {
            url += "?" + new URLSearchParams({ includeInstances });
        }

        return await rtFetch(url, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    }

    /**
     * Gets all product groups. If a limit is non-null, will only get that amount of instances. If null it will get 1000.
     * If a startAfterId is specified
     * @param {number} limit
     * @param {string} startAfterId
     * @returns
     */
    getProductGroups = (limit, startAfterId) => {
        const params = {};
        limit && (params.limit = limit);
        startAfterId && (params.startAfterId = startAfterId);
        return rtFetch(
            this.baseUrl +
                `/product-instances/all-groups?` +
                new URLSearchParams(params),
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then((res) => res.json());
    };

    /**
     * Creates a new test instance.
     */
    createTestInstance = (
        testConceptId,
        productInstanceId,
        startDate,
        data,
        constants
    ) => {
        const newData = data.map((point) => {
            const newTimestamp = moment(point.timestamp).isValid()
                ? moment(point.timestamp)
                : null;
            if (newTimestamp === null) {
                throw new Error(`Invalid data timestamp`);
            }
            return {
                ...point,
                timestamp: newTimestamp,
            };
        });
        return rtFetch(this.baseUrl + `/tests/create-test-instance`, {
            method: "POST",
            headers: this.getJsonHeader(),
            body: JSON.stringify({
                testConceptId,
                productInstanceId,
                startDate,
                data: newData,
                constants,
            }),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Creates a set of test instances from CSV
     * @param {File} file the file of test instances to create
     * @param {string} testConceptId the test concept id to create instances on
     */
    createTestInstancesFromCsv = (file, testConceptId) => {
        const header = {
            Authorization: `Bearer ${this.accessToken}`,
            "x-request-origin": "firebase",
        };
        const formData = new FormData();
        formData.append("file", file, file.name);

        return rtFetch(
            this.baseUrl + `/tests/instance-upload-csv/${testConceptId}`,
            {
                method: "POST",
                headers: header,
                body: formData,
            }
        ).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Creates a set of test instances from csv like row data
     * @param {string[][]} data a string[][] of data needed for creation of test instances
     * @param {string} testConceptId id of test concept to create instances on
     */
    createTestInstancesFromData = (data, testConceptId) => {
        return rtFetch(this.baseUrl + `/tests/instances-from-data`, {
            method: "POST",
            headers: this.getJsonHeader(),
            body: JSON.stringify({
                data,
                testConceptId,
            }),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Updates a test instance.
     */
    updateTestInstance = (testInstanceId, endDate, data) => {
        const newData = data.map((point) => {
            const newTimestamp = moment(point.timestamp).isValid()
                ? moment(point.timestamp)
                : null;
            if (newTimestamp === null) {
                throw new Error(`Invalid data timestamp`);
            }
            return {
                ...point,
                timestamp: newTimestamp,
            };
        });
        return rtFetch(this.baseUrl + `/tests/update-test-instance`, {
            method: "POST",
            headers: this.getJsonHeader(),
            body: JSON.stringify({
                testInstanceId,
                endDate,
                data: newData,
            }),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Creates a new test concept.
     */
    createTestConcept = (name, description, data, constants, formulae) => {
        return rtFetch(this.baseUrl + `/tests/create-test-concept`, {
            method: "POST",
            headers: this.getJsonHeader(),
            body: JSON.stringify({
                name,
                description,
                data,
                constants,
                formulae,
            }),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Returns data on a product instance.
     */
    getProductInstance = (id) => {
        return rtFetch(this.baseUrl + `/product-instances/${id}`, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => res.json());
    };

    /**
     * Returns data on a test instance.
     */
    getTestInstance = (id) => {
        return rtFetch(this.baseUrl + `/tests/test-instance/${id}`, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => res.json());
    };

    /**
     * Returns data on a test concept.
     */
    getTestConcept = (id, includeInstances = false) => {
        let url = this.baseUrl + `/tests/test-concept/${id}`;
        if (includeInstances) {
            url += "?" + new URLSearchParams({ includeInstances });
        }

        return rtFetch(url, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => res.json());
    };

    /**
     * Returns all test instances associated with the given product instance.
     */
    getTestInstancesByProduct = (productInstanceId) => {
        return rtFetch(
            this.baseUrl +
                `/tests/test-instances-by-product/${productInstanceId}`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then((res) => res.json());
    };

    /**
     * Returns all test concepts.
     */
    getTestConcepts = () => {
        return rtFetch(this.baseUrl + `/tests/test-concepts`, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => res.json());
    };

    /**
     * Returns all test instances w/ option limit/startAfterId.
     *
     * @param {number} [limit]
     * @param {string} [startAfterId]
     */
    getTestInstances = (limit, startAfterId) => {
        const params = {};
        limit && (params.limit = limit);
        startAfterId && (params.startAfterId = startAfterId);
        return rtFetch(
            this.baseUrl +
                `/tests/test-instances?` +
                new URLSearchParams(params),
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then((res) => res.json());
    };

    /**
     * Returns a list of valid substrate properties.
     */
    getSubstrateProperties = () => {
        return rtFetch(
            this.baseUrl + `/product-instances/substrate-properties`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then((res) => res.json());
    };

    /**
     * Returns a list of valid substrate property types.
     */
    getSubstratePropertyTypes = () => {
        return rtFetch(
            this.baseUrl + `/product-instances/substrate-property-types`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then((res) => res.json());
    };

    /**
     * Returns all product instances w/ option limit/startAfterId.
     *
     * @param {number} [limit]
     * @param {string} [startAfterId]
     */
    getProductInstances = (limit, startAfterId) => {
        const params = {};
        limit && (params.limit = limit);
        startAfterId && (params.startAfterId = startAfterId);
        return rtFetch(
            this.baseUrl + `/product-instances?` + new URLSearchParams(params),
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then((res) => res.json());
    };

    /**
     * Returns product group info based on a groupId.
     */
    getProductGroupInfo = (id) => {
        return rtFetch(this.baseUrl + `/product-instances/group/${id}`, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => res.json());
    };

    /**
     * Adds in a new substrate property
     */
    createSubstrateProperty = (property, type) => {
        return rtFetch(
            this.baseUrl + `/product-instances/add-substrate-property`,
            {
                method: "POST",
                headers: this.getJsonHeader(),
                body: JSON.stringify({
                    property,
                    type,
                }),
            }
        ).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Download CSV of Test Results Data
     */
    downloadTestResultsCSV = (
        testConceptName,
        testConceptId,
        productInstanceIds,
        data
    ) => {
        return rtFetch(this.baseUrl + `/tests/results-csv`, {
            method: "POST",
            headers: this.getJsonHeader(),
            body: JSON.stringify({
                testConceptId,
                productInstanceIds,
                data,
            }),
        }).then(async (res) => {
            const resCSV = await res.blob();
            if (res.status === 200) {
                downloadBlob(resCSV, `${testConceptName}-data.csv`);
            } else {
                throw resCSV;
            }
        });
    };

    /*********************************************************************
     *
     * Deployments
     *
     *********************************************************************/

    /**
     * Returns all deployments w/ option pageSize/page.
     * Returns paginationResponse object with
     * data, pageNumber, pageSize, totalCount & totalPages
     * @param {number} [pageSize]
     * @param {number} [page]
     */
    getDeployments = (pageSize, page) => {
        const params = {};
        params.pageSize = pageSize ?? 25;
        params.page = page ?? 1;
        return rtFetch(
            this.baseUrl + `/intervention?` + new URLSearchParams(params),
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then((res) => res.json());
    };

    /**
     * Returns all deployed substrates for the specified deployment.
     */
    getDeploymentSubstrates = (id) => {
        return rtFetch(
            this.baseUrl +
                `/intervention/${id}/carbonBuoyDeployment/substrates`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then((res) => res.json());
    };

    /**
     * Returns data on a deployment.
     */
    getDeployment = (id) => {
        return rtFetch(this.baseUrl + `/intervention/${id}`, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => res.json());
    };

    /**
     * Returns events for a deployment.
     */
    getDeploymentEvents = (id) => {
        return rtFetch(this.baseUrl + `/intervention/${id}/event`, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => res.json());
    };

    /**
     * Returns deployment vessels
     */
    getDeploymentVessels = () => {
        return rtFetch(this.baseUrl + `/intervention/vessel`, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => res.json());
    };

    /**
     * Returns deployment ports
     */
    getDeploymentPorts = () => {
        return rtFetch(this.baseUrl + `/intervention/port`, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => res.json());
    };

    /**
     * Create a new deployment.
     *
     * @param {string} title
     * @param {string} targetDepartureDate
     * @param {string} departurePortId
     * @param {string} arrivalPortId
     * @param {string} vesselId
     *
     */
    createDeployment = (
        title,
        targetDepartureDate,
        departurePortId,
        arrivalPortId,
        vesselId
    ) => {
        return rtFetch(this.baseUrl + `/intervention/`, {
            method: "POST",
            headers: this.getJsonHeader(),
            body: JSON.stringify({
                title,
                description: "",
                type: InterventionType.CARBON_BUOY_DEPLOYMENT,
                carbonBuoyDeployment: {
                    targetDepartureDate,
                    departurePortId,
                    arrivalPortId,
                    vesselId,
                },
            }),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 201) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Edit an existing deployment.
     *
     * @param {string} id
     * @param {string} name
     * @param {string} departurePortId
     * @param {string} targetDepartureDate
     * @param {string} arrivalPortId
     * @param {string} vesselId
     * @param {string} arrivalDate
     * @param {string} gpsTracker
     * @param {string} foiId
     * @param {string} buoySelectorLabel
     * @param {string} departureDate
     *
     */
    updateDeployment = (
        id,
        title,
        departurePortId,
        targetDepartureDate,
        arrivalPortId,
        vesselId,
        arrivalDate,
        gpsTracker,
        foiId,
        buoySelectorLabel,
        departureDate
    ) => {
        return rtFetch(this.baseUrl + `/intervention/${id}`, {
            method: "PATCH",
            headers: this.getJsonHeader(),
            body: JSON.stringify({
                title,
                foiId,
                type: 0,
                updateCarbonBuoyDeployment: {
                    departurePortId,
                    targetDepartureDate,
                    arrivalPortId,
                    vesselId,
                    arrivalDate,
                    gpsTracker,
                    buoySelectorLabel,
                    departureDate,
                },
            }),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 201) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Create Deployment Events.
     *
     * @param {Object} body
     * @param {String} id
     *
     */
    createDeploymentEvents = (body, id) => {
        return rtFetch(this.baseUrl + `/intervention/${id}/event`, {
            method: "POST",
            headers: this.getJsonHeader(),
            body: JSON.stringify(body),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 201) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Delete Deployment Event
     *
     * @param {String} eventId
     *
     */
    deleteDeploymentEvent = (eventId) => {
        return rtFetch(this.baseUrl + `/intervention/event/${eventId}`, {
            headers: this.getJsonHeader(),
            method: "DELETE",
        }).then(async (res) => {
            if (res.status === 204) {
                return;
            } else {
                const resJson = await res.json();
                throw resJson;
            }
        });
    };

    /**
     * Create a new vessel.
     *
     * @param {string} name
     *
     */
    createVessel = (name) => {
        return rtFetch(this.baseUrl + `/intervention/vessel`, {
            method: "POST",
            headers: this.getJsonHeader(),
            body: JSON.stringify({
                name,
            }),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 201) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Create a new port.
     *
     * @param {string} title
     * @param {number} lat
     * @param {number} long
     *
     */
    createPort = (title, lat, long) => {
        return rtFetch(this.baseUrl + `/intervention/port`, {
            method: "POST",
            headers: this.getJsonHeader(),
            body: JSON.stringify({
                title,
                lat,
                long,
            }),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 201) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Returns gps track for a deployment.
     */
    getDeploymentTrack = (deploymentId) => {
        return rtFetch(
            this.baseUrl + `/intervention/${deploymentId}/gps-track`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    getAllVerificationBuoys = (deploymentId) => {
        return rtFetch(
            this.baseUrl + `/intervention/${deploymentId}/verification-systems`,
            {
                method: "GET",
                headers: this.getJsonHeader(),
            }
        ).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Create Intervention Estimate.
     *
     * @param {string} id (Intervention id)
     * @param {string} title
     * @param {EstimateContext} context
     * @param {number} co2e
     * @param {QuantFormulaVar} quantFormulaVar
     * @param {ApprovalStatus} approvalStatus
     * @param {string} [carbonBuoyBatchId]
     * @param {json} [metadata]
     */
    createDeploymentEstimate = (
        id,
        title,
        context,
        co2e,
        quantFormulaVar,
        approvalStatus,
        carbonBuoyBatchId,
        metadata
    ) => {
        return rtFetch(this.baseUrl + `/intervention/${id}/estimate`, {
            method: "POST",
            headers: this.getJsonHeader(),
            body: JSON.stringify({
                title,
                context,
                co2e,
                quantFormulaVar,
                approvalStatus,
                carbonBuoyBatchId,
                metadata,
            }),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 201) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Create Intervention Estimate.
     *
     * @param {string} id (Intervention id)
     * @param {string} title
     * @param {EstimateContext} context
     * @param {number} co2e
     * @param {QuantFormulaVar} quantFormulaVar
     * @param {ApprovalStatus} approvalStatus
     * @param {Express.Multer.File[]} files
     * @param {string} [carbonBuoyBatchId]
     * @param {json} [metadata]
     */
    createDeploymentEstimateEvidence = (
        id,
        title,
        context,
        co2e,
        quantFormulaVar,
        approvalStatus,
        files,
        carbonBuoyBatchId,
        metadata
    ) => {
        const formData = new FormData();

        formData.append("title", title);
        formData.append("context", context);
        formData.append("co2e", co2e);
        formData.append("quantFormulaVar", quantFormulaVar);
        formData.append("approvalStatus", approvalStatus);
        for (const file of files) {
            formData.append("files", file);
        }
        carbonBuoyBatchId &&
            formData.append("carbonBuoyBatchId", carbonBuoyBatchId);
        metadata && formData.append("metadata", metadata);

        return rtFetch(this.baseUrl + `/intervention/${id}/estimate/evidence`, {
            method: "POST",
            headers: this.getFormHeader(),
            body: formData,
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 201) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Get Intervention Estimates.
     *
     * @param {string} id (Intervention id)
     *
     */
    getDeploymentEstimates = (id) => {
        return rtFetch(this.baseUrl + `/intervention/${id}/estimates`, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => res.json());
    };

    /**
     * Get Single Intervention Estimate.
     *
     * @param {string} id (estimate id)
     *
     */
    getDeploymentEstimate = (id) => {
        return rtFetch(this.baseUrl + `/intervention/estimate/${id}`, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => res.json());
    };

    /**
     * Edit an existing deployment estimate.
     *
     * @param {string} id
     * @param {string} title
     * @param {EstimateContext} context
     * @param {number} co2e
     * @param {QuantFormulaVar} quantFormulaVar
     * @param {ApprovalStatus} approvalStatus
     * @param {string} [carbonBuoyBatchId]
     * @param {json} [metadata]
     */
    updateDeploymentEstimate = (
        id,
        title,
        context,
        co2e,
        quantFormulaVar,
        approvalStatus,
        carbonBuoyBatchId,
        metadata
    ) => {
        return rtFetch(this.baseUrl + `/intervention/estimate/${id}`, {
            method: "PATCH",
            headers: this.getJsonHeader(),
            body: JSON.stringify({
                title,
                context,
                co2e,
                quantFormulaVar,
                approvalStatus,
                carbonBuoyBatchId,
                metadata,
            }),
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 200) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    /**
     * Delete Deployment Estimate
     *
     * @param {String} id (estimate id)
     *
     */
    deleteDeploymentEstimate = (id) => {
        return rtFetch(this.baseUrl + `/intervention/estimate/${id}`, {
            headers: this.getJsonHeader(),
            method: "DELETE",
        }).then(async (res) => {
            if (res.status === 204) {
                return;
            } else {
                const resJson = await res.json();
                throw resJson;
            }
        });
    };

    /**
     * Get all evidence for given Estimate.
     *
     * @param {string} id (estimate id)
     *
     */
    getDeploymentEstimateEvidence = (id) => {
        return rtFetch(this.baseUrl + `/intervention/estimate/${id}/evidence`, {
            method: "GET",
            headers: this.getJsonHeader(),
        }).then((res) => res.json());
    };

    /**
     * Delete Estimate Evidence
     *
     * @param {String} id (Evidence id)
     *
     */
    deleteEstimateEvidence = (id) => {
        return rtFetch(this.baseUrl + `/intervention/evidence/${id}`, {
            headers: this.getJsonHeader(),
            method: "DELETE",
        }).then(async (res) => {
            if (res.status === 204) {
                return;
            } else {
                const resJson = await res.json();
                throw resJson;
            }
        });
    };

    /**
     * Add Evidence to existing Estimate.
     *
     * @param {string} id (Estimate id)
     * @param {Express.Multer.File[]} files
     */
    addEvidenceToEstimate = (id, files) => {
        const formData = new FormData();
        for (const file of files) {
            formData.append("files", file);
        }

        return rtFetch(this.baseUrl + `/intervention/estimate/${id}/evidence`, {
            method: "POST",
            headers: this.getFormHeader(),
            body: formData,
        }).then(async (res) => {
            const resJson = await res.json();
            if (res.status === 201) {
                return resJson;
            } else {
                throw resJson;
            }
        });
    };

    getAPISpecs = () => {
        return rtFetch(this.baseUrl + `/docs`, {
            headers: this.getJsonHeader(),
        }).then(async (res) => {
            if (res.status === 200) {
                const resJson = await res.json();
                return resJson;
            } else {
                throw res;
            }
        });
    };
}
