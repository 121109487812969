/* eslint-disable */

const InterventionStatus = {
    PLANNING: 0,
};

const EventType = {
    DEPARTURE: 0,
    ARRIVAL: 1,
    SUBSTRATE_DEPLOYMENT_START: 2,
    SUBSTRATE_DEPLOYMENT_STOP: 3,
    VERIFICATION_BUOY_DEPLOYMENT: 4,
    TRAJECTORY_BUOY_DEPLOYMENT: 5,
};

const InterventionType = {
    CARBON_BUOY_DEPLOYMENT: 0,
};

const QuantFormulaVar = {
    "Terr (added)": 0,
    "Terr (loss)": 1,
    "Terr (shed)": 2,
    "Terr (shal)": 3,
    "Terr (store)": 4,
    "Luc (indirect)": 5,
    "Energy (co2e)": 6,
    "Opmat (co2e)": 7,
    "Capmat (co2e)": 8,
};

const EstimateContext = {
    Anticipated: 0,
    Actual: 1,
    Final: 2,
};

const ApprovalStatus = {
    Pending: 0,
    Approved: 1,
    Rejected: 2,
    "N/A": 3,
};

module.exports = {
    InterventionStatus: Object.freeze(InterventionStatus),
    EventType: Object.freeze(EventType),
    InterventionType: Object.freeze(InterventionType),
    QuantFormulaVar: Object.freeze(QuantFormulaVar),
    EstimateContext: Object.freeze(EstimateContext),
    ApprovalStatus: Object.freeze(ApprovalStatus),
};
