import React from "react";
import { Chip, Grid, Autocomplete, TextField } from "@mui/material";

export default function SensorThingsLabelManager(props) {
    let { value, onChange } = props;
    if (value == null) value = [];

    if (props.edit) {
        return (
            <Autocomplete
                multiple
                id="selector-label-tag-editor"
                value={value}
                freeSolo={true}
                options={[]}
                onChange={(event, newValue) => {
                    onChange(newValue);
                }}
                getOptionLabel={(label) => label}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((label, index) => (
                        <Chip
                            key={label}
                            label={label}
                            style={{ margin: 0 }}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        //Prevents MaterialTable Row editing from taking over
                        event.stopPropagation();
                    }
                }}
                style={{ width: 300 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Selector Label"
                        placeholder="Labels"
                        multiline
                    />
                )}
            />
        );
    } else {
        return (
            <Grid container spacing={1}>
                {value.map((label) => (
                    <Grid item key={`key-${label}`} xs={12}>
                        <Chip label={label} key={label} color="success" />
                    </Grid>
                ))}
            </Grid>
        );
    }
}
