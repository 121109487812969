import moment from "moment-timezone";
import formatcoords from "formatcoords";

// From KelpMapComponent, gets data in the correct format to be used by MapComponent
// TODO (nikhil) transition KelpMap to use this exported function
export default function processData(buoy, startDate, endDate) {
    // List with each item corresponding to the buoys location at
    // a single point in time
    const locations = buoy.datastreams
        .filter(
            (datastream) =>
                datastream.name === "GPS" || datastream.name === "latitude"
        )
        .map((datastream) => datastream.sensorObservations)
        .flat();
    const timeData = locations.map((data) => data.phenomenonTime);
    const locationData = locations.map((data) => {
        const [lng, lat] = data.resultLocation;
        return JSON.stringify({
            type: "Point",
            coordinates: [lng, lat],
        });
    });
    let ds_id = "";
    if (locations.length === 0) {
        const locationDatastreams = buoy.datastreams.filter(
            (datastream) =>
                datastream.name === "GPS" || datastream.name === "latitude"
        );
        ds_id = locationDatastreams[0].id;
    } else {
        ds_id = locations[0].datastreamId;
    }

    let data = {
        ds_id,
        date_time: timeData,
        result_location: locationData,
        op_name: "buoy_location",
        thing_name: buoy.name,
        thing_id: buoy.id,
    };

    data = processGeoGraphData(data);
    data = filterByDate(startDate, endDate, data);
    return data;
}

// Maps data to also include a geoJson format
function processGeoGraphData(data) {
    return {
        ds_id: data.ds_id,
        type: "buoy",
        thing_name: data.thing_name,
        thing_id: data.thing_id,
        date_time: data.date_time,
        unit: "GPS Point",
        coordinates: data.result_location.map(
            (point) => JSON.parse(point).coordinates
        ),
        color: "#565656",
        geoJson: {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: data.result_location.map((point, i) => {
                    const coordinates = JSON.parse(point).coordinates,
                        [lng, lat] = coordinates;
                    return {
                        type: "Feature",
                        properties: {
                            description: `<b>Name:</b>${
                                data.thing_name
                            }</link> <br> <b>Coordinates(DMS):</b> ${formatcoords(
                                lat,
                                lng
                            ).format()}<br><b>Coordinates(DD):</b> ${coordinates} <br><b>Time:</b> ${
                                data.date_time[i]
                            }`,
                        },
                        geometry: {
                            type: "Point",
                            coordinates: coordinates,
                        },
                    };
                }),
            },
        },
        earliestStartDate: data.date_time.reduce(
            (acc, time) => (acc.isAfter(moment(time)) ? moment(time) : acc),
            moment()
        ),
    };
}

// filters the data using the provided start/end date which is passed from the picker
function filterByDate(startDate, endDate, fullData) {
    const filteredTimeData = [];
    const filteredLocationData = [];
    const filteredFeatures = [];
    let earliestStartDate = null;

    fullData.date_time.forEach((date, i) => {
        const dateObject = moment(date, "YYYY-MM-DDTHH:mm:ss.SSSZ");
        if (
            (startDate === null || dateObject > startDate) &&
            (endDate === null || dateObject < endDate)
        ) {
            filteredTimeData.push(date);
            filteredLocationData.push(fullData.coordinates[i]);
            filteredFeatures.push(fullData.geoJson.data.features[i]);

            if (earliestStartDate == null || dateObject < earliestStartDate) {
                earliestStartDate = dateObject;
            }
        }
    });

    const filteredGeoJson = { ...fullData.geoJson };
    filteredGeoJson.data.features = filteredFeatures;

    return {
        ...fullData,
        date_time: filteredTimeData,
        coordinates: filteredLocationData,
        geoJson: filteredGeoJson,
        earliestStartDate,
    };
}
