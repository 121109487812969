import config from "../config";

import { auth } from "../config/firebase";

/**
 * A wrapper around fetch that handles 403 errors by fetching a new token and
 * retrying.
 */
export const rtFetch = async (resource, init) => {
    const res = await fetch(resource, init);
    if (res.status === 403) {
        // Get a new ID token (using force refresh).
        const newToken = await auth?.currentUser?.getIdToken(true);
        if (newToken == null) {
            throw new Error("unable to refresh token");
        }

        // Update headers with the new token and try again.
        const headers = { ...init.headers };
        headers.Authorization = `Bearer ${newToken}`;

        return await fetch(resource, { ...init, headers });
    }
    return res;
};

export const downloadBlob = (blob, downloadName) => {
    // We create a link, click it, and remove it (rather than just create
    // the file and assign it to window.location) so that we can set the
    // name of the downloaded file.
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = downloadName;
    link.click();
    link.remove();
};

export default class APIClient {
    constructor(authState) {
        this.accessToken = authState.accessToken;
    }

    getAuthHeaderValue() {
        return `Bearer ${this.accessToken}`;
    }

    getAuthHeader() {
        return {
            Authorization: this.getAuthHeaderValue(),
            "x-request-origin": "firebase",
        };
    }

    getJsonHeader() {
        let header = this.getAuthHeader();
        header["Content-Type"] = "application/json";
        return header;
    }

    /**
     *
     * @param {Date} since
     * @returns
     */
    async getPhotoSessionsSince(since) {
        return rtFetch(
            config.shellfishInventoryServerPath +
                `/data/photo-sessions?since=${since}`,
            {
                headers: this.getAuthHeader(),
            }
        );
    }

    /**
     * Get's the photo session for the given id
     * @param {String} id
     * @returns
     */
    async getPhotoSession(id) {
        return rtFetch(
            config.shellfishInventoryServerPath +
                `/data/photo-session?id=${id}`,
            {
                headers: this.getAuthHeader(),
            }
        );
    }

    async getBinAllocations() {
        return rtFetch(
            config.shellfishInventoryServerPath + "/data/bin-allocations",
            {
                headers: this.getAuthHeader(),
            }
        );
    }

    async getOysterSizeData() {
        return rtFetch(
            config.shellfishInventoryServerPath + "/data/size-data",
            {
                headers: this.getAuthHeader(),
            }
        );
    }

    async exportGradingData(gradingId) {
        return rtFetch(
            config.shellfishInventoryServerPath +
                "/grading/export-grading-data?gradingId=" +
                gradingId,
            { headers: this.getAuthHeader() }
        );
    }

    async getFarmSystem() {
        return rtFetch(
            config.shellfishInventoryServerPath + "/data/farm-system",
            {
                headers: this.getAuthHeader(),
            }
        ).then((res) => res.json());
    }

    async getAllExternalContainers() {
        return rtFetch(
            config.shellfishInventoryServerPath +
                "/data/get-all-external-containers",
            {
                headers: this.getAuthHeader(),
            }
        ).then((res) => res.json());
    }

    async getShellfishHeldExternalContainersWithNoReallocation() {
        return rtFetch(
            config.shellfishInventoryServerPath +
                `/data/get-shellfish-held-external-containers-with-no-reallocation`,
            {
                headers: this.getAuthHeader(),
            }
        ).then((res) => res.json());
    }

    async getBinInformation(binId) {
        return rtFetch(
            config.shellfishInventoryServerPath +
                "/data/bin-information?binId=" +
                binId,
            {
                headers: this.getAuthHeader(),
            }
        );
    }

    async getActiveStreams() {
        return rtFetch(
            config.shellfishInventoryServerPath + "/data/active-streams",
            {
                headers: this.getAuthHeader(),
            }
        );
    }

    async getVesselStatus() {
        return rtFetch(
            config.shellfishInventoryServerPath + "/data/vessel-status",
            {
                headers: this.getAuthHeader(),
            }
        );
    }

    async getEncoderReport(gradingId) {
        return rtFetch(
            config.shellfishInventoryServerPath +
                "/grading/encoder-report?gradingId=" +
                gradingId,
            { headers: this.getAuthHeader() }
        );
    }

    /**
     *
     * @param {User} userInfo
     */
    async updateUser(userInfo) {
        return rtFetch(config.shellfishInventoryServerPath + "/user/update", {
            method: "POST",
            body: JSON.stringify(userInfo),
            headers: this.getJsonHeader(),
        })
            .then((res) => res.json())
            .catch((err) => {
                console.log(err);
            });
    }

    /**
     * Used to reallocate oysters from NGV external containers to LGV
     * @param {[]} externalShellfishHeldContainers - Array of containers
     * @param {String} binId - The id of the bin - eg: ed9568bb-4302-476f-8cc6-c51fa7d92e17
     * @param {String} reporterId - the UUID for the reporter - eg: ed9568bb-4302-476f-8cc6-c51fa7d92e17
     * @returns
     */
    async createOysterCountsWithExternalShellfishContainerHeldData(
        externalShellfishHeldContainers,
        binId,
        reporterId
    ) {
        return rtFetch(
            config.shellfishInventoryServerPath +
                "/data/move-shellfish-to-bin-from-container",
            {
                method: "POST",
                body: JSON.stringify({
                    externalShellfishHeldContainers:
                        externalShellfishHeldContainers,
                    binId: binId,
                    reporterId: reporterId,
                }),
                headers: this.getJsonHeader(),
            }
        ).then((res) => {
            if (res.status === 200) return res.json();
            else {
                throw res;
            }
        });
    }

    /**
     * @param {String} binId - The id of the bin - eg: ed9568bb-4302-476f-8cc6-c51fa7d92e17
     * @param {Number} grade - The grade of the shellfish counted
     * @param {Number} measurementType - The measurementType
     * @param {Number} count - Either the raw count or the volume in ml - eg: 200
     * @param {Date} observationDate - The date the grading started - eg: 1589715467
     * @param {String} reporterId - the UUID for the reporter - eg: ed9568bb-4302-476f-8cc6-c51fa7d92e17
     * @param {Date} reportedDate - The date the observation was reported - eg: Sun May 17 2020 07:09:20 GMT-0400 (Eastern Daylight Time)
     * @param {Boolean} brandNewCounts - If we should replace preexisting counts for this bin
     * @param {Number} shellfishType - The type of shellfish counted - eg: 0 (Oysters)
     */
    async createOysterCount(
        binId,
        externalContainerId,
        grade,
        measurementType,
        count,
        observationDate,
        reporterId,
        reportedDate,
        brandNewCounts,
        shellfishType
    ) {
        return rtFetch(
            config.shellfishInventoryServerPath + "/oyster-stream/count",
            {
                method: "POST",
                body: JSON.stringify({
                    binId: binId,
                    externalContainerId: externalContainerId,
                    grade: grade,
                    measurementType: measurementType,
                    count: count,
                    newCounts: brandNewCounts,
                    observationDate: observationDate,
                    reporterId: reporterId,
                    reportedDate: reportedDate,
                    shellfishType: shellfishType,
                }),
                headers: this.getJsonHeader(),
            }
        ).then((res) => {
            if (res.status === 200) return res.json();
            else {
                throw res;
            }
        });
    }

    /**
     *
     * @param {String} farmId
     * @param {String} reefId
     * @param {String} binId
     * @param {String} seedSource
     * @param {String} spawnId
     * @param {Number} startingSeedSizeLower
     * @param {Number} startingSeedSizeUpper
     * @param {Boolean} isNewStream
     * @param {Boolean} isBroodStock
     * @param {Number} volume
     * @param {Number} countPerML
     * @param {Number} count
     * @param {Date} observationDate
     * @param {String} reporterId
     * @param {Date} reportedDate
     * @param {Number} shellfishType
     * @param {String} species
     */
    async createOysterGrowOutTransfer(
        farmId,
        reefId,
        binId,
        seedSource,
        spawnId,
        startingSeedSizeLower,
        startingSeedSizeUpper,
        isNewStream,
        isBroodStock,
        volume,
        countPerML,
        count,
        observationDate,
        reporterId,
        reportedDate,
        shellfishType,
        species
    ) {
        return rtFetch(
            config.shellfishInventoryServerPath +
                "/oyster-stream/grow-out-transfer",
            {
                method: "POST",
                body: JSON.stringify({
                    farmId: farmId,
                    reefId: reefId,
                    binId: binId,
                    seedSource: seedSource,
                    spawnId: spawnId,
                    startingSeedSizeLower: startingSeedSizeLower,
                    startingSeedSizeUpper: startingSeedSizeUpper,
                    isNewStream: isNewStream,
                    broodstock: isBroodStock,
                    count: count,
                    volume: volume,
                    countPerML: countPerML,
                    observationDate: observationDate,
                    reporterId: reporterId,
                    reportedDate: reportedDate,
                    shellfishType: shellfishType,
                    species: species,
                }),
                headers: this.getJsonHeader(),
            }
        ).then((res) => {
            if (res.status === 200) return res.json();
            else {
                throw res;
            }
        });
    }

    /**
     *
     * @param {String} sourceStreamId
     * @param {String[]} targetBinIds
     * @param {String} targertReefId
     * @param {Date} observationDate
     * @param {String} reporterId
     * @param {Date} reportedDate
     * @param {Boolean} newCounts
     */
    async createOysterMove(
        sourceStreamId,
        targetBinIds,
        sourceBin,
        observationDate,
        reporterId,
        reportedDate,
        newCounts
    ) {
        return rtFetch(
            config.shellfishInventoryServerPath + "/oyster-stream/move-stream",
            {
                method: "POST",
                body: JSON.stringify({
                    streamId: sourceStreamId,
                    sourceBin: sourceBin,
                    binIds: targetBinIds,
                    observationDate: observationDate,
                    reporterId: reporterId,
                    reportedDate: reportedDate,
                    newCounts: newCounts,
                }),
                headers: this.getJsonHeader(),
            }
        ).then((res) => {
            if (res.status === 200) return res.json();
            else {
                throw res;
            }
        });
    }

    /**
     *
     * @param {Date} date
     */
    async getLiveVesselLocations(date) {
        return rtFetch(
            config.shellfishInventoryServerPath +
                "/location/vessel-locations?date=" +
                date.getTime(),
            { headers: this.getAuthHeader() }
        );
    }

    /**
     * Get's the estimated count based on the volume metrics
     * @param {Number} depth
     * @param {Number} grade
     * @param {Boolean} isFull
     */
    async getOysterVolumeEstimateFromDepth(depth, grade, isFull) {
        return rtFetch(
            config.shellfishInventoryServerPath +
                "/data/oyster-volume-estimate/depth?depth=" +
                depth +
                "&grade=" +
                grade +
                "&units=inch&isFull" +
                isFull,
            { headers: this.getAuthHeader() }
        ).then((res) => {
            if (res.status === 200) return res.json();
            else {
                throw res;
            }
        });
    }

    /**
     *
     * @param {Number} ml
     * @param {Number} grade
     */
    async getOysterVolumeEstimate(ml, grade) {
        return rtFetch(
            config.shellfishInventoryServerPath +
                "/data/oyster-volume-estimate/ml?volume=" +
                ml +
                "&grade=" +
                grade,
            { headers: this.getAuthHeader() }
        ).then((res) => {
            if (res.status === 200) return res.json();
            else {
                throw res;
            }
        });
    }

    /**
     *
     * @returns {Promise<GradingSummary>}
     */
    async getPhotoSessionSummary(photoSessionId) {
        return rtFetch(
            config.shellfishInventoryServerPath +
                `/data/photo-session-summary?photoSessionId=${photoSessionId}`,
            { headers: this.getAuthHeader() }
        ).then((res) => {
            if (res.status === 200) return res.json();
            else {
                throw res;
            }
        });
    }
}
