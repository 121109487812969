import React, { Component } from "react";
import {
    Chip,
    Paper,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    Fade,
} from "@mui/material";
import { ExpandMore, Sync, CloudDownload } from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";
import FirmwareBuoyConfig from "../helper/FirmwareBuoyConfigHelper";
import FirmwareConfigSelector from "./FirmwareConfigSelector";
import MessageHelper from "../helper/MessageHelper";
import { errorMessage, successMessage } from "../helper/MessageMethodHelper";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import APIClient from "../../models/APIClient";

const styles = () => ({
    accordion_container: {
        width: "100%",
        padding: "2px 0",
    },
    accordion: {
        width: "100%",
        background: "#4f4f4f",
    },
    space_between: {
        justifyContent: "space-between",
    },
    tabel_row: {
        borderBottom: "1px dotted white",
    },
    flex_div100: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    flex_div: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "5px",
    },
});

class BluetoothSensorCharacteristic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            uuid: props.uuid,
            name: props.name,
            characteristic: props.characteristic,
            requestOnConnect: props.requestOnConnect,
            typeHandler: props.typeHandler,
            csvHeader: props.csvHeader,
            config: {},
            loading: false,
            disabled: false,
            hasConfigUpdate: false,
            readLoadTimeout: null,
            success: false,
            template_name: "",
        };
        this.accordianToggle = this.accordianToggle.bind(this);
        this.handleNotifications = this.handleNotifications.bind(this);
        this.updateConfig = this.updateConfig.bind(this);
        this.getConfig = this.getConfig.bind(this);
        this.appliedUpdate = this.appliedUpdate.bind(this);
        this.setSchedule = this.setSchedule.bind(this);
        this.readTimeout = this.readTimeout.bind(this);
        this.getDashboardConfig = this.getDashboardConfig.bind(this);
        this.enable_notify_ble_characteristic();
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
    }
    getKey() {
        return this.state.uuid + this.keyCount++;
    }
    accordianToggle(event, isExpanded) {
        this.setState({ expanded: isExpanded });
    }
    handleNotifications(event) {
        let value = event.target.value;
        //ignore 0 byte messages
        if (value.byteLength) {
            if (this.state.readLoadTimeout) {
                clearTimeout(this.state.readLoadTimeout);
            }
            this.setState({
                config: this.state.typeHandler(value),
                template_name: "current Buoy schedule",
                loading: false,
                disabled: false,
                success: true,
                hasConfigUpdate: true,
                readLoadTimeout: null,
            });
        }
    }
    readTimeout() {
        this.setState(
            errorMessage(
                "Timeout reading buoy config. (Buoy may not have configs!)"
            )
        );
        this.setState({
            loading: false,
            disabled: false,
            readLoadTimeout: null,
        });
    }
    async getConfig(event) {
        try {
            event.stopPropagation();
            this.setState({
                loading: true,
                disabled: true,
                readLoadTimeout: setTimeout(this.readTimeout, 2000),
            });
            await this.state.characteristic.readValue();
        } catch (error) {
            //TODO Philipp Add proper error handling
            console.log("Argh! " + error);
        }
    }
    async updateConfig(event) {
        try {
            event.stopPropagation();
            this.setState({
                loading: true,
                disabled: true,
            });
            //takes the current configs util object and computed the binary
            this.state.config.computeBinary();
            await this.state.characteristic.writeValueWithResponse(
                this.state.config.binary
            );

            if (this.props.buoy_id) {
                const apiClient = new APIClient(this.props.authState);
                try {
                    await apiClient.createFirmwareConfigRequest(
                        this.props.buoy_id,
                        this.state.config.json,
                        false
                    );
                    this.setState(
                        successMessage(`Config successfully pushed!`)
                    );
                } catch (e) {
                    this.setState(errorMessage(e));
                }
            }
            this.setState({
                loading: false,
                disabled: false,
                success: true,
            });
        } catch (error) {
            //TODO Philipp Add proper error handling
            console.log("Argh! " + error);
        }
    }
    async getDashboardConfig(event) {
        event.stopPropagation();
        if (!this.props.buoy_id) {
            return;
        }
        this.setState({
            loading: true,
            disabled: true,
        });
        const apiClient = new APIClient(this.props.authState);
        const installedConfig = (
            await apiClient.getBuoyInstalledConfigs(this.props.buoy_id)
        )?.[0];
        if (installedConfig) {
            const config = new FirmwareBuoyConfig(null, installedConfig.config);
            this.setState({
                config,
                loading: false,
                disabled: false,
                success: true,
                template_name: "current Dashboard schedule",
                hasConfigUpdate: true,
                readLoadTimeout: null,
            });
        }
    }
    async enable_notify_ble_characteristic() {
        try {
            await this.state.characteristic.startNotifications();
            this.state.characteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleNotifications
            );
            if (this.state.requestOnConnect) {
                await this.state.characteristic.readValue();
            }
        } catch (error) {
            //TODO Philipp Add proper error handling
            console.log("Argh! " + error);
        }
    }
    setSchedule(json) {
        this.setState({ config: new FirmwareBuoyConfig(null, json) });
    }

    appliedUpdate() {
        this.setState({ hasConfigUpdate: false });
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper
                key={this.state.uuid}
                className={classes.accordion_container}
            >
                <div className={classes.accordion_container}>
                    <Accordion
                        className={classes.accordion}
                        expanded={this.state.expanded}
                        onChange={this.accordianToggle}
                    >
                        <AccordionSummary
                            className={classes.space_between}
                            expandIcon={<ExpandMore />}
                            id={this.state.uuid}
                        >
                            <div className={classes.flex_div100}>
                                <Typography
                                    sx={{ width: "30%", flexShrink: 0 }}
                                >
                                    {this.state.name}
                                </Typography>
                                <div className={classes.flex_div}>
                                    {this.state.loading && (
                                        <CircularProgress
                                            color="secondary"
                                            size={30}
                                        />
                                    )}
                                    <Fade
                                        in={this.state.success}
                                        timeout={{ enter: 0, exit: 2500 }}
                                        addEndListener={() => {
                                            this.state.success &&
                                                setTimeout(() => {
                                                    this.setState({
                                                        success: false,
                                                    });
                                                }, 1000);
                                        }}
                                    >
                                        <CheckCircleOutlineIcon
                                            color="primary"
                                            sx={{ fontSize: "30px" }}
                                        />
                                    </Fade>
                                    <Chip
                                        icon={<Sync />}
                                        label="Apply"
                                        onClick={this.updateConfig}
                                        disabled={
                                            !this.props.connected ||
                                            this.state.disabled
                                        }
                                        color="success"
                                    />
                                    <Chip
                                        icon={<CloudDownload />}
                                        label="Load from dashboard"
                                        onClick={this.getDashboardConfig}
                                        disabled={
                                            !this.props.connected ||
                                            this.state.disabled ||
                                            !this.props.buoy_id
                                        }
                                        color="success"
                                    />
                                    <Chip
                                        icon={<CloudDownload />}
                                        label="Load from buoy"
                                        onClick={this.getConfig}
                                        disabled={
                                            !this.props.connected ||
                                            this.state.disabled
                                        }
                                        color="success"
                                    />
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FirmwareConfigSelector
                                {...this.props}
                                loadDefaultSchdule={false}
                                loadDefaultFrimwareVersion={false}
                                defaultFirmwareVersion={this.props.version}
                                defaultSchedule={this.state.config.json}
                                firmwareVersionSelectable={true}
                                setSelectedSchedule={this.setSchedule}
                                appliedUpdate={this.appliedUpdate}
                                config={this.state.config}
                                hasConfigUpdate={this.state.hasConfigUpdate}
                                setRequiresConfig={() => {}}
                                setFirmwareVersion={() => {}}
                                showForm={false}
                                labels={this.props.selector_labels}
                                template_name={this.state.template_name}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
                <MessageHelper
                    message={this.state.message}
                    errorMessage={this.state.errorMessage}
                    open={this.state.messageOpen}
                    setState={(a) => this.setState(a)}
                />
            </Paper>
        );
    }
}

export { BluetoothSensorCharacteristic as BluetoothSensorCharacteristicWithoutStyles };
export { styles };

export default withStyles(styles)(BluetoothSensorCharacteristic);
