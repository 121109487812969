import React, { Component } from "react";
import {
    Autocomplete,
    Button,
    TextField,
    Typography,
    Stack,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { errorMessage, successMessage } from "../helper/MessageMethodHelper";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import MessageHelper from "../helper/MessageHelper";

const styles = (theme) => ({
    overheadTextField: {
        marginTop: "1.5rem",
        minWidth: "15rem",
    },
    minWidth: {
        minWidth: "15rem",
    },
    createButton: {
        marginTop: theme.spacing(1),
    },
});

class ProductGroupCreator extends Component {
    state = {
        name: "",
        description: "",
        productInstances: [],
        productOptions: [],
        isCreating: false,
    };

    async componentDidMount() {
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        const productOptions = await apiClient.getProductInstances();
        this.setState({ productOptions });
    }

    async createProductGroup() {
        this.setState({ isCreating: true });
        // we need to send a request to the backend endpoint to create a product group
        try {
            const apiClient = new CarbonAccountingAPIClient(
                this.props.authState
            );
            const productInstanceIds = this.state.productInstances.map(
                (product) => product.id
            );
            const id = await apiClient.createProductGroup(
                this.state.name,
                this.state.description,
                productInstanceIds
            );
            this.setState({
                ...successMessage(`Product Group ${id} created!`),
                name: "",
                description: "",
                productInstances: [],
                isCreating: false,
            });
        } catch (error) {
            this.setState({
                ...errorMessage(error),
                isCreating: false,
            });
        }
    }

    render() {
        return (
            <Stack
                direction="column"
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Typography variant="h3">Product Group Creator</Typography>
                <TextField
                    label="Name"
                    value={this.state.name}
                    onChange={(event) => {
                        this.setState({ name: event.target.value });
                    }}
                    className={this.props.classes.overheadTextField}
                />
                <TextField
                    label="Description"
                    value={this.state.description}
                    multiline
                    onChange={(event) =>
                        this.setState({ description: event.target.value })
                    }
                    className={this.props.classes.overheadTextField}
                />
                <Typography variant="h4">Product Selector</Typography>
                <Autocomplete
                    multiple
                    value={this.state.productInstances}
                    options={this.state.productOptions}
                    renderInput={(params) => (
                        <TextField {...params} multiline margin="dense" />
                    )}
                    getOptionLabel={(option) =>
                        `${option.creationDate}, ${option.batchIndex}, ${option.index}`
                    }
                    onChange={(_, products) => {
                        this.setState({ productInstances: products });
                    }}
                    className={this.props.classes.minWidth}
                />
                <Button
                    variant="secondary"
                    onClick={() => this.createProductGroup()}
                    disabled={
                        this.state.isCreating ||
                        this.state.name === "" ||
                        this.state.description === ""
                    }
                    className={this.props.classes.createButton}
                >
                    Create
                </Button>
                <MessageHelper
                    message={this.state.message}
                    errorMessage={this.state.errorMessage}
                    open={this.state.messageOpen}
                    setState={(a) => this.setState(a)}
                />
            </Stack>
        );
    }
}

export default withStyles(styles)(WithGoogleAuth(ProductGroupCreator));
