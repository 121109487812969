export default class OysterStreamUtils {
    static getStreamForBinId(streams, binId) {
        if (streams != null) {
            let res = streams.filter((s) => {
                return s.bins.includes(binId);
            });
            if (res != null && res.length > 0) return res[0];
        }

        return {};
    }

    static getBinsForBinIds(farmSystem, binIds) {
        let bins = [];
        if (binIds == null || binIds === undefined || binIds.length === 0)
            return [];

        farmSystem.forEach((farm) => {
            farm.reefs.forEach((reef) => {
                reef.bins.forEach((bin) => {
                    if (binIds.includes(bin.id)) {
                        bins.push(bin);
                    }
                });
            });
        });

        return bins;
    }
}
