// import { authHeader } from './models/auth-header';
import APIClient from "./models/APIClient";
export const UPDATE_USER = "UPDATE_USER";

export const updateUser = (token, user) => (dispatch) => {
    return new APIClient({ accessToken: token }).updateUser(user).then(
        (data) => {
            dispatch({ type: UPDATE_USER, data });
        },
        (error) => {
            console.error("Error updating user");
            console.error(error);
        }
    );
    // return fetch(process.env.REACT_APP_VESSEL_API + '/grading/status', {headers : { 'Authorization': "Basic cmVhY3Q6cGFsbXlfcGVyZmVjdG9fZ3JpZXZlX3RlYWNoaW5n"}})
    //     .then(response => {
    //         if (response.ok) {
    //             return response.json();
    //         }
    //         throw new Error(response.statusText);
    //     })
    //     .then(
    //         data => {
    //             dispatch({type: UPDATE_USER, data});
    //         },
    //         error => {
    //             console.error("Error polling for grading status")
    //             console.error(error)
    //         })
};
