import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import RTable from "./components/RTable";
import RTDisplayUtils from "@running-tide/rt-frontend-helpers/helpers";
import ShellfishInventoryAPIClient from "../models/ShellfishInventoryAPIClient";
import { WithGoogleAuth } from "../config/WithGoogleAuth";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import GradingSummaryDetails from "./GradingSummaryDetails";
import moment from "moment-timezone";
import Typography from "@mui/material/Typography";

let HGrading = WithGoogleAuth(
    class HistoricalGradings extends Component {
        constructor(props) {
            super(props);
            this.state = { data: [], farmSystem: [] };
            this.downloadImages = this.downloadImages.bind(this);
        }

        componentDidMount() {
            new ShellfishInventoryAPIClient(this.props.authState)
                .getFarmSystem()
                .then((farmSystem) => {
                    this.setState({ farmSystem: farmSystem });
                });
            new ShellfishInventoryAPIClient(this.props.authState)
                .getPhotoSessionsSince(moment().subtract(90, "days").valueOf())
                .then((res) => {
                    return res.json();
                })
                .then((photoSessions) => {
                    this.setState({ data: photoSessions });
                });
        }

        downloadImages(event, gradingId) {
            event.preventDefault();

            this.setState({ downloadInProgress: gradingId });
            this.download(gradingId);
        }
        async download(gradingId) {
            try {
                this.setState({ downloadInProgress: gradingId });
                let apiClient = new ShellfishInventoryAPIClient(
                    this.props.authState
                );
                let response = await apiClient.exportGradingData(gradingId);
                let res = await response.json();
                let url = res.url;
                let a = document.createElement("a");
                a.style = "display: none";
                document.body.appendChild(a);
                a.href = url;
                a.download = res.fileName; // gives it a name via an a tag
                a.click();
                URL.revokeObjectURL(url);
            } catch (error) {
                console.error(error);
                this.setState({ downloadInProgress: null });
            }
            this.setState({ downloadInProgress: null });
        }

        displayDownloadStatus(gradingId) {
            if (this.state.downloadInProgress != null) {
                if (this.state.downloadInProgress === gradingId) {
                    return (
                        <div>
                            <CircularProgress />
                        </div>
                    );
                } else {
                    return null;
                }
            } else {
                return (
                    <Button variant="secondary" type="submit">
                        Download
                    </Button>
                );
            }
        }

        getSystemTypeString(rowData) {
            if (rowData.vesselType === 0) {
                if (rowData.systemNumber === 0) {
                    return "Port";
                } else {
                    return "Starboard";
                }
            } else {
                if (rowData.systemNumber === 0) {
                    return "Port Grad";
                } else if (rowData.systemNumber === 1) {
                    return "Port Non-Grad";
                } else if (rowData.systemNumber === 2) {
                    return "Starboard Grad";
                } else if (rowData.systemNumber === 3) {
                    return "Starboard Non-Grad";
                } else return "Unknown";
            }
        }

        render() {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <RTable
                            title={
                                <Typography variant="h3">
                                    Photo Sessions
                                </Typography>
                            }
                            detailPanel={[
                                {
                                    render: ({ rowData }) => (
                                        <GradingSummaryDetails
                                            session_id={rowData.id}
                                            photoSession={rowData}
                                            farmSystem={this.state.farmSystem}
                                        />
                                    ),
                                },
                            ]}
                            columns={[
                                {
                                    title: "Vessel Type",
                                    field: "vesselType",
                                    render: (rowData) => {
                                        return rowData.vesselType === 0
                                            ? "NGV"
                                            : "LGV";
                                    },
                                    customFilterAndSearch: (term, rowData) => {
                                        let val =
                                            rowData.vesselType === 0
                                                ? "NGV"
                                                : "LGV";
                                        val = val.toLowerCase();
                                        return val.includes(term.toLowerCase());
                                    },
                                },
                                {
                                    title: "Grade Date",
                                    field: "startDate",
                                    render: (rowData) => {
                                        return RTDisplayUtils.printDate(
                                            rowData.startDate
                                        );
                                    },
                                },
                                {
                                    title: "Expected Grade",
                                    field: "expectedGrade",
                                    render: (rowData) => {
                                        return RTDisplayUtils.prettyPrintGrade(
                                            rowData.expectedGrade
                                        );
                                    },
                                    customFilterAndSearch: (term, rowData) => {
                                        if (term === "W") return true;
                                        else
                                            return (
                                                term.replace("W", "") ===
                                                rowData.expectedGrade.toString()
                                            );
                                    },
                                },
                                {
                                    title: "Session Type",
                                    field: "sessionType",
                                    render: (rowData) => {
                                        return rowData.type === 0
                                            ? "Non-Grad"
                                            : "Graduate";
                                    },
                                    customFilterAndSearch: (term, rowData) => {
                                        let val =
                                            rowData.type === 0
                                                ? "Non-Grad"
                                                : "Graduate";
                                        val = val.toLowerCase();
                                        return val.includes(term.toLowerCase());
                                    },
                                },
                                {
                                    title: "System",
                                    field: "System",
                                    render: (rowData) =>
                                        this.getSystemTypeString(rowData),
                                    customFilterAndSearch: (term, rowData) => {
                                        let val =
                                            this.getSystemTypeString(
                                                rowData
                                            ).toLowerCase();
                                        return val.includes(term.toLowerCase());
                                    },
                                },
                                {
                                    title: "ID",
                                    field: "id",
                                },
                            ]}
                            data={this.state.data}
                            options={{
                                search: true,
                                pageSize: 10,
                                filtering: true,
                                idSynonym: "id",
                            }}
                        ></RTable>
                    </Grid>
                </Grid>
            );
        }
    }
);

export default HGrading;
