import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { Grid, Box, Link, Typography } from "@mui/material";
import RTable from "../components/RTable";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import MessageHelper from "../helper/MessageHelper";
import APIClient from "../../models/APIClient";
import SelectorThingsLabelManager from "../components/SensorThingsLabelManager";

const styles = (theme) => ({
    table: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
});

const BuoysInfoManager = WithGoogleAuth(
    class BuoysInfoManager extends Component {
        constructor(props) {
            super(props);
            this.state = {
                buoys: [],
                isLoading: true,
            };
        }

        async componentDidMount() {
            this.getThingsBySelectorLabel();
        }

        async getThingsBySelectorLabel() {
            const apiClient = new APIClient(this.props.authState);
            try {
                const result = await apiClient.fetchThingsByThingSelector(
                    "buoy"
                );
                let things = await result.json();
                this.setState({ buoys: things, isLoading: false });
            } catch (e) {
                console.error(e);
            }
        }

        onRowUpdate = (newData) =>
            new Promise((resolve, reject) => {
                const newDataUpdate = { ...newData };
                try {
                    if (newDataUpdate.properties) {
                        newDataUpdate.properties = JSON.parse(
                            newDataUpdate.properties
                        );
                    }
                } catch (e) {
                    this.setState(
                        errorMessage("Properties - expecting valid JSON")
                    );
                    reject();
                    return;
                }

                function isBuoy(buoy) {
                    return buoy.id === newData.id;
                }
                new APIClient(this.props.authState)
                    .updateThing(newDataUpdate)
                    .then(async (res) => {
                        if (res.status !== 200) {
                            res = await res.json();
                            this.setState(errorMessage(res));
                            reject();
                            return;
                        }
                        //Update State
                        const buoys = [...this.state.buoys],
                            i = buoys.findIndex(isBuoy);
                        buoys[i] = newData;
                        this.setState({ buoys });
                        this.setState(successMessage("Successfully updated"));
                        resolve();
                    })
                    .catch((e) => {
                        this.setState(errorMessage(e));
                        reject();
                    });
            });

        onRowAdd = (newData) =>
            new Promise((resolve, reject) => {
                const newDataUpdate = { ...newData };
                try {
                    if (newDataUpdate.properties)
                        newDataUpdate.properties = JSON.parse(
                            newDataUpdate.properties
                        );
                } catch (e) {
                    this.setState(
                        errorMessage("Properties - expecting valid JSON")
                    );
                    reject();
                    return;
                }

                new APIClient(this.props.authState)
                    .createThing(newDataUpdate)
                    .then(async (res) => {
                        if (res.status !== 200) {
                            res = await res.json();
                            this.setState(errorMessage(res));
                            reject();
                            return;
                        }
                        res = await res.json();
                        newData.id = res.id;
                        const buoys = [...this.state.buoys];
                        buoys.unshift(newData);
                        this.setState({ buoys });
                        this.setState(successMessage("Successfully added"));
                        resolve();
                    })
                    .catch((e) => {
                        this.setState(errorMessage(e));
                        reject();
                    });
            });

        onRowDelete = (oldData) =>
            new Promise((resolve) => {
                new APIClient(this.props.authState)
                    .removeThing(oldData)
                    .then(async (res) => {
                        if (res.status !== 200) {
                            res = await res.json();
                            this.setState(errorMessage(res));
                            resolve();
                            return;
                        }
                        //Update State
                        res = await res.json();
                        let buoys = [...this.state.buoys];
                        buoys = buoys.filter((buoy) => buoy.id !== oldData.id);
                        this.setState({ buoys });
                        this.setState(successMessage("Successfully deleted"));
                        resolve();
                    })
                    .catch((e) => {
                        this.setState(errorMessage(e));
                        resolve();
                    });
            });

        render() {
            const { classes } = this.props;

            return (
                <Grid>
                    <Grid item lg={1} xs={12}>
                        <MessageHelper
                            message={this.state.message}
                            errorMessage={this.state.errorMessage}
                            open={this.state.messageOpen}
                            setState={(a) => this.setState(a)}
                        />
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Box>
                            <RTable
                                className={classes.table}
                                title={
                                    <Typography variant="h3">
                                        Buoys Manager
                                    </Typography>
                                }
                                columns={[
                                    {
                                        title: "ID",
                                        field: "id",
                                        editable: "never",
                                        render: (rowData) => (
                                            <Link
                                                href={`/kelp_buoy/${rowData.id}`}
                                            >
                                                {rowData.id}
                                            </Link>
                                        ),
                                    },
                                    {
                                        title: "Name *",
                                        field: "name",
                                    },
                                    {
                                        title: "Description *",
                                        field: "description",
                                    },
                                    {
                                        title: "Selector ID (Unique)",
                                        field: "selectorId",
                                    },
                                    {
                                        title: "Selector Label",
                                        field: "selectorLabels",
                                        render: (rowData) => (
                                            <SelectorThingsLabelManager
                                                value={rowData.selectorLabels}
                                                edit={false}
                                            ></SelectorThingsLabelManager>
                                        ),
                                        editComponent: (rowData) => {
                                            return (
                                                <SelectorThingsLabelManager
                                                    value={rowData.value}
                                                    onChange={rowData.onChange}
                                                    edit={true}
                                                ></SelectorThingsLabelManager>
                                            );
                                        },
                                    },
                                    {
                                        title: "Properties (JSON)",
                                        field: "properties",
                                    },
                                ]}
                                data={this.state.buoys}
                                editable={{
                                    isEditable: () => true,
                                    onRowAddCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowUpdateCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowAdd: (newData) =>
                                        this.onRowAdd(newData),
                                    onRowUpdate: (newData, oldData) =>
                                        this.onRowUpdate(newData, oldData),
                                    // edit all rows at once
                                    onBulkUpdate: (changes) =>
                                        Promise.all(
                                            Object.values(changes).map(
                                                ({ newData, oldData }) =>
                                                    this.onRowUpdate(
                                                        newData,
                                                        oldData
                                                    )
                                            )
                                        ),
                                    onRowDelete: (oldData) =>
                                        this.onRowDelete(oldData),
                                }}
                                isLoading={this.state.isLoading}
                                options={{
                                    search: true,
                                    maxColumnSort: 1,
                                    filtering: true,
                                    paging: true,
                                    // always display the newly-added row at the beginning of the table
                                    addRowPosition: "first",
                                }}
                            ></RTable>
                        </Box>
                    </Grid>
                </Grid>
            );
        }
    }
);

export default withStyles(styles)(BuoysInfoManager);
