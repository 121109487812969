import React, { useState, useEffect } from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FlagIcon from "@mui/icons-material/Flag";
import ErrorIcon from "@mui/icons-material/Error";

import RTable from "./RTable";
import APIClient from "../../models/APIClient";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import titleCase from "../helper/formatting/TitleCase";
import RTDisplayUtils from "@running-tide/rt-frontend-helpers/helpers";

function BuoyTest(props) {
    const { authState, thingId } = props;

    const [data, setData] = useState([]);
    const [buoyTests, setBuoyTests] = useState([]);
    const [categoryHeaders, setCategoryHeaders] = useState([]);
    const [testData, setTestData] = useState([]);
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        const fetchBuoyTestResults = async () => {
            const apiClient = new APIClient(authState);
            const { testResults, buoyTests, categoryHeaders } =
                await apiClient.getBuoyTestResults(thingId);
            testResults && setDisplay(true);
            setData(testResults);
            setBuoyTests(buoyTests);
            setCategoryHeaders(categoryHeaders);
        };
        fetchBuoyTestResults();
    }, [authState, thingId]);

    useEffect(() => {
        const testData = [];
        buoyTests.forEach((testCategory, index) => {
            testData.push({ header: categoryHeaders[index] });
            testCategory.forEach((test) => {
                const testResult = data[test];
                testData.push({
                    test: titleCase(test).replace(/Test /g, ""),
                    status: testResult ? testResult.status : "unreported",
                    message: testResult
                        ? testResult.message
                        : test + " unreported",
                });
            });
        });
        setTestData(testData);
    }, [data, buoyTests, categoryHeaders]);

    return display ? (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <RTable
                    title={
                        <div>
                            <Typography variant="h3">Buoy Tests</Typography>
                            <Typography variant="h4">
                                Completed at:{" "}
                                {data.timestamp != null
                                    ? RTDisplayUtils.printDate(
                                          data.timestamp,
                                          true
                                      )
                                    : "never"}
                            </Typography>
                        </div>
                    }
                    columns={[
                        {
                            title: "Test",
                            field: "test",
                            render: (rowData) =>
                                rowData.header ? (
                                    <Typography variant="h4_bold">
                                        {rowData.header}
                                    </Typography>
                                ) : (
                                    rowData.test
                                ),
                        },
                        {
                            title: "Status",
                            field: "status",
                            render: (rowData) => {
                                if (rowData.test) {
                                    if (rowData.status === "unreported") {
                                        return (
                                            <Tooltip
                                                title={"Unreported Test"}
                                                placement="right"
                                            >
                                                <FlagIcon color="warning" />
                                            </Tooltip>
                                        );
                                    } else {
                                        return rowData.status ? (
                                            <Tooltip
                                                title={"Successful Test"}
                                                placement="right"
                                            >
                                                <CheckCircleIcon color="primary" />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip
                                                title={"Failed Test"}
                                                placement="right"
                                            >
                                                <ErrorIcon color="error" />
                                            </Tooltip>
                                        );
                                    }
                                }
                            },
                        },
                        {
                            title: "Message",
                            field: "message",
                        },
                    ]}
                    data={testData}
                    options={{
                        search: true,
                        filtering: false,
                        paging: false,
                        idSynonym: "test",
                    }}
                ></RTable>
            </Grid>
        </Grid>
    ) : null;
}
export default WithGoogleAuth(BuoyTest);
