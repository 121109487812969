import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import APIClient from "../../models/APIClient";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import { Box, Grid, Typography } from "@mui/material";
import RTable from "../components/RTable";
import MessageHelper from "../helper/MessageHelper";
import {
    clearMessage,
    errorMessage,
    successMessage,
} from "../helper/MessageMethodHelper";

const styles = (theme) => ({
    root: {},
    paper: {
        padding: 10,
    },
    section: {
        marginTop: theme.spacing(5),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    paragraph: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    description: {
        paddingLeft: 30,
        paddingRight: 30,
    },
    box: {
        padding: 10,
    },
});

let LocationManager = WithGoogleAuth(
    class LocationManagerions extends Component {
        constructor(props) {
            super(props);
            this.state = {
                locations: [],
                isLoading: true,
            };
        }

        componentDidMount() {
            this.loadState();
        }
        async loadState() {
            let result = await new APIClient(
                    this.props.authState
                ).getAllLocations(),
                locations = await result.json();

            this.setState({ locations: locations });
            this.setState({ isLoading: false });
        }

        render() {
            const { classes } = this.props;
            return (
                <Grid container spacing={3}>
                    <Grid item lg={1} xs={12}>
                        <MessageHelper
                            message={this.state.message}
                            errorMessage={this.state.errorMessage}
                            open={this.state.messageOpen}
                            setState={(a) => this.setState(a)}
                        />
                    </Grid>
                    <Grid item lg={12} xs={12} className={classes.description}>
                        <div className={classes.description}>
                            <Typography className={classes.title} variant="h6">
                                Location
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                                variant="body1"
                            >
                                A Location represents a physical place on Earth,
                                identified by its latitude and longitude
                                coordinates. You can use Locations to keep track
                                of where Things are located. By assigning a
                                Location to a Thing, you can know where the
                                Thing is physically located.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Box>
                            <RTable
                                className={classes.table}
                                title={
                                    <Typography variant="h3">
                                        Location Manager
                                    </Typography>
                                }
                                columns={[
                                    {
                                        title: "ID",
                                        field: "id",
                                        editable: "never",
                                    },
                                    {
                                        title: "Name *",
                                        field: "name",
                                    },
                                    {
                                        title: "Description *",
                                        field: "description",
                                    },
                                    {
                                        title: "Latitude *",
                                        field: "latitude",
                                        editable: "onAdd",
                                        type: "numeric",
                                    },
                                    {
                                        title: "Longitude *",
                                        field: "longitude",
                                        editable: "onAdd",
                                        type: "numeric",
                                    },
                                    {
                                        title: "SRID",
                                        field: "srid",
                                        initialEditValue: 4326,
                                        editable: "onAdd",
                                        type: "numeric",
                                    },
                                ]}
                                data={this.state.locations}
                                editable={{
                                    isEditable: () => true,
                                    onRowAddCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowUpdateCancelled: () => {
                                        this.setState(clearMessage());
                                    },
                                    onRowAdd: (newData) =>
                                        new Promise((resolve, reject) => {
                                            //add location  name desc, lat, long, srid
                                            new APIClient(this.props.authState)
                                                .createLocation(newData)
                                                .then(async (res) => {
                                                    if (res.status !== 200) {
                                                        res = await res.json();
                                                        this.setState(
                                                            errorMessage(res)
                                                        );
                                                        reject();
                                                        return;
                                                    }
                                                    //Update State
                                                    res = await res.json();
                                                    newData.id = res.id;
                                                    let locations = [
                                                        ...this.state.locations,
                                                    ];
                                                    locations.unshift(newData);
                                                    this.setState({
                                                        locations,
                                                    });
                                                    this.setState(
                                                        successMessage(
                                                            "Successfully added"
                                                        )
                                                    );
                                                    resolve();
                                                })
                                                .catch((e) => {
                                                    this.setState(
                                                        errorMessage(e)
                                                    );
                                                    reject();
                                                });
                                        }),
                                    onRowUpdate: (newData) =>
                                        new Promise((resolve, reject) => {
                                            //Just update name or description
                                            function isLocation(location) {
                                                return (
                                                    location.id === newData.id
                                                );
                                            }
                                            new APIClient(this.props.authState)
                                                .updateLocation(newData)
                                                .then(async (res) => {
                                                    if (res.status !== 200) {
                                                        res = await res.json();
                                                        this.setState(
                                                            errorMessage(res)
                                                        );
                                                        reject();
                                                        return;
                                                    }
                                                    //Update State
                                                    let locations = [
                                                            ...this.state
                                                                .locations,
                                                        ],
                                                        i =
                                                            locations.findIndex(
                                                                isLocation
                                                            );
                                                    locations[i] = newData;
                                                    this.setState({
                                                        locations,
                                                    });
                                                    this.setState(
                                                        successMessage(
                                                            "Successfully updated"
                                                        )
                                                    );
                                                    resolve();
                                                })
                                                .catch((e) => {
                                                    this.setState(
                                                        errorMessage(e)
                                                    );
                                                    reject();
                                                });
                                        }),
                                    onRowDelete: (oldData) =>
                                        new Promise((resolve) => {
                                            new APIClient(this.props.authState)
                                                .removeLocation(oldData)
                                                .then(async (res) => {
                                                    if (res.status !== 200) {
                                                        res = await res.json();
                                                        this.setState(
                                                            errorMessage(res)
                                                        );
                                                        resolve();
                                                        return;
                                                    }
                                                    //Update State
                                                    let locations = [
                                                        ...this.state.locations,
                                                    ];
                                                    locations =
                                                        locations.filter(
                                                            (location) =>
                                                                location.id !==
                                                                oldData.id
                                                        );
                                                    this.setState({
                                                        locations,
                                                    });
                                                    this.setState(
                                                        successMessage(
                                                            "Successfully deleted"
                                                        )
                                                    );
                                                    resolve();
                                                })
                                                .catch((e) => {
                                                    this.setState(
                                                        errorMessage(e)
                                                    );
                                                    resolve();
                                                });
                                        }),
                                }}
                                isLoading={this.state.isLoading}
                                options={{
                                    search: true,
                                    maxColumnSort: 1,
                                    filtering: true,
                                    paging: true,
                                    addRowPosition: "first",
                                }}
                            ></RTable>
                        </Box>
                    </Grid>
                </Grid>
            );
        }
    }
);

export default withStyles(styles)(LocationManager);
