import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { WithGoogleAuth } from "./config/WithGoogleAuth";
import MainListItems from "./listItems";
import { connect } from "react-redux";
import RTLogo from "./static/rt-logo-dark.png";
import withStyles from "@mui/styles/withStyles";
import { styled } from "@mui/material/styles";
import { logout } from "./config/firebase";
import { ChevronRightOutlined, MeetingRoom } from "@mui/icons-material";
import { Avatar } from "@mui/material";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="">
                Running Tide Technologies INC
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const drawerWidth = 334.5;

const openedMixin = (theme) => ({
    backgroundColor: theme.palette.primaryUtility.main,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    backgroundColor: theme.palette.primaryUtility.main,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(8),
});

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer - 1,
    marginLeft: theme.spacing(8), // Closed drawer width
    paddingLeft: theme.spacing(5), // page content spacing (see Box padding below)
    width: `calc(100% - ${theme.spacing(8)})`,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        paddingLeft: theme.spacing(5), // page content spacing
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const styles = (theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: theme.palette.background.default,
        color: theme.palette.action.active,
    },
    toolbarIconCentered: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        borderBottom: `1px solid ${theme.palette.navListBorder}`,
        ...theme.mixins.toolbar,
    },
    toolbarIconEnd: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 19.62px",
        borderBottom: `1px solid ${theme.palette.navListBorder}`,
        ...theme.mixins.toolbar,
    },
    appbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        paddingRight: "40px",
        paddingTop: "30px",
    },
});

let dboard = WithGoogleAuth(
    class Dashboard extends Component {
        constructor(props) {
            super(props);
            this.state = { open: false, user: props.user };
            this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
            this.handleDrawerClose = this.handleDrawerClose.bind(this);
        }

        handleDrawerOpen = () => {
            this.setState({ open: true });
        };

        handleDrawerClose = () => {
            this.setState({ open: false });
        };

        render() {
            const { classes, user } = this.props;
            const userPhotoUrl =
                user?.providerData[0]?.photoURL ?? user?.photoURL;
            const userDisplayName =
                user?.providerData[0]?.displayName ?? user?.displayName;
            return (
                <Box sx={{ display: "flex" }}>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        open={this.state.open}
                        className={classes.appbar}
                    >
                        <Toolbar className={classes.toolbar}>
                            <Typography variant="h6" color="inherit" noWrap>
                                <img
                                    width={196}
                                    height={21}
                                    src={RTLogo}
                                    alt="Dashboard"
                                ></img>
                            </Typography>
                        </Toolbar>
                        <Avatar
                            alt={userDisplayName}
                            src={userPhotoUrl}
                            className={classes.avatar}
                        />
                    </AppBar>
                    <Drawer variant="permanent" open={this.state.open}>
                        {this.state.open ? (
                            <div className={classes.toolbarIconEnd}>
                                <IconButton
                                    onClick={this.handleDrawerClose}
                                    size="small"
                                    sx={{
                                        backgroundColor: "iconBackground",
                                    }}
                                >
                                    <ChevronLeftIcon />
                                </IconButton>
                            </div>
                        ) : (
                            <div className={classes.toolbarIconCentered}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={this.handleDrawerOpen}
                                    size="small"
                                    sx={{
                                        backgroundColor: "iconBackground",
                                    }}
                                >
                                    <ChevronRightOutlined />
                                </IconButton>
                            </div>
                        )}
                        <Divider />
                        <List
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                            }}
                        >
                            <MainListItems
                                sx={{ flexGrow: 1 }}
                                isMenuOpen={this.state.open}
                            />
                            <ListItemButton
                                sx={{ maxHeight: 48, mt: "auto" }}
                                onClick={() => {
                                    logout();
                                }}
                            >
                                <ListItemIcon>
                                    <MeetingRoom />
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItemButton>
                        </List>
                    </Drawer>
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 5, overflow: "auto" }}
                    >
                        <DrawerHeader />
                        <Outlet />
                        <Box pt={4}>
                            <Copyright />
                        </Box>
                    </Box>
                </Box>
            );
        }
    }
);

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(dboard));
