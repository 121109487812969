import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { Grid, Box, FormControl } from "@mui/material";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import APIClient from "../../models/APIClient";
import AutocompleteEditComponent from "../sections/AutocompleteEditComponent";

const styles = (theme) => ({
    table: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
});

const FirmwareVersionSelecor = WithGoogleAuth(
    class FirmwareVersionSelecor extends Component {
        constructor(props) {
            super(props);
            this.state = {
                firmwareVersions: [],
                isLoading: true,
                selectedFirmwareVersion: "choose firmware version",
            };
            this.updateSelectedFirmwareVersion =
                this.updateSelectedFirmwareVersion.bind(this);
        }

        async componentDidMount() {
            let promises = [this.getFirmwareVersions()];
            await Promise.all(promises);
            this.setState({
                isLoading: false,
            });
        }

        async getFirmwareVersions() {
            const apiClient = new APIClient(this.props.authState);
            try {
                const firmwareVersions =
                    (await apiClient.getFirmwareVersions()) || [];
                //convert json to text
                const sanitizedFirmwareVersions = firmwareVersions.map(
                    (firmwareVersion) => {
                        return {
                            ...firmwareVersion,
                            config: firmwareVersion.config,
                            metadata: firmwareVersion.metadata,
                        };
                    }
                );
                this.setState({
                    firmwareVersions: sanitizedFirmwareVersions,
                });
            } catch (e) {
                console.error(e);
            }
        }

        updateSelectedFirmwareVersion(id) {
            const version = this.state.firmwareVersions.filter(
                (version) => version.id == id
            )?.[0];
            this.props.selectFirmwareVersion(version);
            this.setState({ selectedFirmwareVersion: version });
        }

        render() {
            let binary_options = {};
            this.state.firmwareVersions.map((version) => {
                const targets = version.metadata?.targets;
                const targetMatch = Boolean(
                    targets?.filter((target) =>
                        this.props.labels?.includes(target)
                    ).length
                );
                //Adding extra condition to render it if the firmware does not have targets
                if (this.props.labels?.length && !targetMatch && targets) {
                    return;
                }
                const type = version.metadata?.firmware_type;
                const name = version.version;
                const description = version.description;
                const id = version.id;
                binary_options[id] = `${type} - ${targets?.join(
                    ","
                )} - ${name} - ${description}`;
            });
            return (
                <Grid>
                    <Grid item lg={12} xs={12}>
                        <Box>
                            <FormControl fullWidth>
                                <AutocompleteEditComponent
                                    label="Firmware Version"
                                    value={
                                        this.state.selectedFirmwareVersion.id
                                    }
                                    defaultValue="choose firmware version"
                                    required
                                    onChange={
                                        //TODO make sure this works
                                        this.updateSelectedFirmwareVersion
                                    }
                                    multiple={false}
                                    idsToLabels={binary_options}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
            );
        }
    }
);

export default withStyles(styles)(FirmwareVersionSelecor);
