import React from "react";
import SensorsIcon from "@mui/icons-material/Sensors";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import { Chip } from "@mui/material";
import moment from "moment-timezone";

function BuoyMessageStatus(props) {
    const { dateSent, dateInstalled, dateFailed } = props;
    if (dateInstalled) {
        return (
            <Chip icon={<SensorsIcon />} label="Installed" color="success" />
        );
    } else if (dateFailed) {
        return (
            <Chip
                icon={<RunningWithErrorsIcon />}
                label="Failed"
                color="error"
            />
        );
    } else {
        if (moment(dateSent).isBefore(moment().subtract(60, "days"))) {
            return (
                <Chip
                    icon={<NotificationsPausedIcon />}
                    label="Skipped"
                    color="warning"
                />
            );
        } else {
            return (
                <Chip
                    icon={<HourglassTopIcon />}
                    label="Queued"
                    color="completedChip"
                />
            );
        }
    }
}

export default BuoyMessageStatus;
