import {
    getCompassDirection,
    getDistance,
    getGreatCircleBearing,
} from "geolib";
import moment from "moment-timezone";
import { Decimal2DMS } from "dms-to-decimal";

/**
 * A private function to get the most recent 2 GPS coordinates
 * @param {Object} dataset - The dateset of the buoy
 * @returns an array including the most recent 2 GPS coordinates
 */
function getCoordinates(dataset) {
    if (!dataset || !dataset.coordinates || dataset.coordinates.length < 2) {
        throw new Error(
            "dataset or dataset.coordinates are not defined, or there is only a single GPS location for a given buoy!"
        );
    }
    // now we can assume dataset.coordinates[0] and dataset.coordinates[1] both exist
    const lat1 = dataset.coordinates[0][1];
    const lon1 = dataset.coordinates[0][0];
    const lat2 = dataset.coordinates[1][1];
    const lon2 = dataset.coordinates[1][0];
    return [lat1, lon1, lat2, lon2];
}

/**
 * A private function to calculate the estimated position after a time increment dt1
 * @param {Number} lat - The original latitude
 * @param {Number} lon - The original longitude
 * @param {Number} dt1 - The time increment
 * @param {Number} u1 - The speed in terms of longitude
 * @param {Number} v1 - The speed in terms of latitude
 * @returns the estimated coordinate of a buoy, e.g. [lon1, lat1]
 */
function adhocLinearEstimate(lat, lon, dt1, u1, v1) {
    const lon1 = lon + u1 * dt1;
    const lat1 = lat + v1 * dt1;
    return [lon1, lat1];
}

class GeoUtils {
    /**
     * @param {Number} i - the value of distance in meters
     * @returns the value of distance in miles
     */
    static convertMetersToMiles(i) {
        return i * 0.000621371192;
    }

    static convertMetersToNauticalMiles(i) {
        return i * 0.000539957;
    }

    /**
     * Calculate the buoy's speed based on its last two GPS locations
     * @param {Object} dataset - The dateset of the buoy
     * @returns the speed of the buoy, 0 if no speed could be calculated
     */
    static getSpeedMPH(dataset, units = "miles") {
        try {
            let lat1, lon1, lat2, lon2;
            [lat1, lon1, lat2, lon2] = getCoordinates(dataset);
            // distance in meters, accuracy = 1 meter
            const distanceInMeters = getDistance(
                { latitude: lat1, longitude: lon1 },
                { latitude: lat2, longitude: lon2 },
                1
            );

            const distance =
                units === "miles"
                    ? this.convertMetersToMiles(distanceInMeters)
                    : this.convertMetersToNauticalMiles(distanceInMeters);

            // get time
            const cur = moment(dataset.date_time[0]);
            const prev = moment(dataset.date_time[1]);
            const differenceInHours = cur.diff(prev, "hours", true);

            // get speed in miles/hr
            const speed = distance / differenceInHours;
            return Math.round(speed * 100) / 100;
        } catch (e) {
            return 0;
        }
    }

    /**
     * Calculate the buoy's direction based on its last two GPS locations
     * @param {Object} dataset - The dateset of the buoy
     * @returns the direction of the buoy, eg. [compassDirection, greatCircleBearing],
     * N if no direction could be calculated, 0 if no bearing could be calculated
     */
    static getDirection(dataset) {
        try {
            let destinationLat, destinationLon, originLat, originLon;
            [destinationLat, destinationLon, originLat, originLon] =
                getCoordinates(dataset);

            const compassDirection = getCompassDirection(
                { latitude: originLat, longitude: originLon },
                {
                    latitude: destinationLat,
                    longitude: destinationLon,
                }
            );

            const greatCircleBearing = getGreatCircleBearing(
                { latitude: originLat, longitude: originLon },
                { latitude: destinationLat, longitude: destinationLon }
            );
            return [compassDirection, greatCircleBearing];
        } catch (e) {
            return ["N", 0];
        }
    }

    /**
     * Calculate the speed in degrees/second for both longitude and latitude
     * @param {Array} lon - The 2-point vector of current and previous longitude, e.g. lon = [prev_lon, now_lon]
     * @param {Array} lat - The 2-point vector of current and previous latitude, e.g. lat = [prev_lat, now_lat]
     * @param {Number} dt - The time increment
     * @returns the speed in degrees/second of the buoy in terms of longitude and latitude, e.g. [u, v],
     * [0, 0] if no speed could be calculated
     */
    static getSpeedDegInSec(lon, lat, dt) {
        try {
            let dlon, dlat, u, v;
            // now calculate the distance (difference d) in degrees
            // difference / distance in longitude and latitude
            // in degrees
            dlon = lon[1] - lon[0];
            // in degrees
            dlat = lat[1] - lat[0];

            u = dlon / dt;
            v = dlat / dt;
            return [u, v];
        } catch (e) {
            return [0, 0];
        }
    }

    /**
     * Calculate time,and the buoy's new position in 15 minutes
     * @param {Object} dataset - The dateset of the buoy
     * @returns the time in 15 minutes, the coordinates (in decimal degrees)of the buoy, and the coordinates (in degrees, minutes, and seconds)
     * eg. ["2021-11-01 18:15:09", [-70.20935, 43.50869], [70°12'33"W, 43°30'31"N]]
     * [0, [0,0], [0,0]] if no time or position could be calculated
     */
    static getTimeCoordinatesIn15Minutes(dataset) {
        try {
            const [now_lat, now_lon, prev_lat, prev_lon] =
                getCoordinates(dataset);
            const lat = [prev_lat, now_lat];
            const lon = [prev_lon, now_lon];
            // get time duration
            const now_time = moment(dataset.date_time[0]);
            const prev_time = moment(dataset.date_time[1]);
            const dt_to_now = now_time.diff(prev_time, "seconds", true);
            // now we get the speed between t-2 and t-1, and we assume that it will be the same speed between t-1 and t
            const [u, v] = this.getSpeedDegInSec(lon, lat, dt_to_now);
            // now estimate the new coordinates after dt1, which is from t-1 to t
            // dt1 is time increment, it must be in seconds, 15 minutes should be 15*60 in seconds
            const dt1 = 15 * 60;
            // new coordinates in 15 minutes
            const [lon1, lat1] = adhocLinearEstimate(lat[1], lon[1], dt1, u, v);

            // Converting Decimal to Degrees, Minutes, Seconds
            const lon1ToDMS = Decimal2DMS(lon1, "longitude");
            const lat1ToDMS = Decimal2DMS(lat1, "latitude");

            const estimatedTime = moment(now_time).add(15, "minutes");
            // get the current date and time in UTC formatted as a string
            const estimatedTimeInUTC = estimatedTime
                .toISOString()
                .replace("T", " ")
                .substring(0, 19);
            return [estimatedTimeInUTC, [lon1, lat1], [lon1ToDMS, lat1ToDMS]];
        } catch (e) {
            return [0, [0, 0], [0, 0]];
        }
    }
}

export default GeoUtils;
