import React, { Component } from "react";
import {
    Chip,
    TextField,
    Paper,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Switch,
    FormControlLabel,
} from "@mui/material";
import { ExpandMore, Sync, CloudDownload } from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";
import Chart from "chart.js";
import moment from "moment-timezone";
import { BluetoothCharacteristicType } from "../helper/KnownBluetoothCharacteristicsAndTypes";

const styles = () => ({
    accordion_container: {
        width: "100%",
        padding: "2px 0",
    },
    accordion: {
        width: "100%",
        background: "#4f4f4f",
    },
    space_between: {
        justifyContent: "space-between",
    },
    tabel_row: {
        borderBottom: "1px dotted white",
    },
    flex_div100: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    flex_div: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "5px",
    },
    canvas: {
        // height: "auto",
        // width: "100%",
    },
});

class BluetoothWaveProcessingCharacteristic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            uuid: props.uuid,
            name: props.name,
            characteristic: props.characteristic,
            psd_north_characteristic: props.psd_north_characteristic,
            psd_east_characteristic: props.psd_east_characteristic,
            psd_down_characteristic: props.psd_down_characteristic,
            live_north_characteristic: props.live_north_characteristic,
            live_east_characteristic: props.live_east_characteristic,
            live_down_characteristic: props.live_down_characteristic,
            typeHandler: props.typeHandler,
            runtimeSeconds: 60,
            enableLiveData: 1,
            supportsLiveData: props.supportsLiveData,
            sampleFrequencyFFT: 2.0,
            packets: {
                psd_north: [],
                psd_east: [],
                psd_down: [],
            },
            psds: {
                north: [],
                east: [],
                down: [],
            },
            live_data: {
                north: [],
                east: [],
                down: [],
            },
        };
        this.accordianToggle = this.accordianToggle.bind(this);
        this.kickOffJob = this.kickOffJob.bind(this);
        this.runtimeUpdate = this.runtimeUpdate.bind(this);
        this.sampleFrequencyUpdate = this.sampleFrequencyUpdate.bind(this);
        this.handleNotificationsNorth =
            this.handleNotificationsNorth.bind(this);
        this.handleNotificationsEast = this.handleNotificationsEast.bind(this);
        this.handleNotificationsDown = this.handleNotificationsDown.bind(this);

        this.handleNotificationsEstimatedDisplacementNorth =
            this.handleNotificationsEstimatedDisplacementNorth.bind(this);
        this.handleNotificationsEstimatedDisplacementEast =
            this.handleNotificationsEstimatedDisplacementEast.bind(this);
        this.handleNotificationsEstimatedDisplacementDown =
            this.handleNotificationsEstimatedDisplacementDown.bind(this);

        this.downloadCallback = this.downloadCallback.bind(this);
        this.enable_notify_ble_characteristic();
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
        this.chartSpectrumRef = React.createRef();
        this.chartSpectrum = undefined;
        this.chartRealTimeRef = React.createRef();
        this.chartRealTime = undefined;
        this.loadSpectrumChart = this.loadSpectrumChart.bind(this);
        this.loadRealTimeChart = this.loadRealTimeChart.bind(this);
    }
    /**
     * Updates PSD graphs
     */
    componentDidUpdate() {
        if (this.chartSpectrum !== undefined) this.chartSpectrum.destroy();
        this.chartSpectrum = this.loadSpectrumChart(
            this.chartSpectrum,
            this.chartSpectrumRef.current.getContext("2d"),
            {
                north: this.state.psds.north,
                east: this.state.psds.east,
                down: this.state.psds.down,
            },
            "Wave Amplitude Spectrum"
        );

        if (this.state.enableLiveData === 1 && this.state.supportsLiveData) {
            if (this.chartRealTime !== undefined) this.chartRealTime.destroy();
            this.chartRealTime = this.loadRealTimeChart(
                this.chartRealTime,
                this.chartRealTimeRef.current.getContext("2d"),
                {
                    north: this.state.live_data.north,
                    east: this.state.live_data.east,
                    down: this.state.live_data.down,
                },
                "Live Wave Data"
            );
        }
    }
    download(content, filename) {
        let encodedUri = encodeURI(content);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    }
    downloadCallback(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.state.psds.down.length) {
            let content_down = `data:text/csv;charset=utf-8,${this.state.psds.down
                .join("\n")
                .trim()}`;
            //TODO Philipp add buoy name in file download
            let filename_down = `BUOY_NAME_PSD_DOWN${moment
                .utc()
                .format("YYYY-MM-DD_HH:mm:ss_z")}.csv`;
            this.download(content_down, filename_down);
        }
        if (this.state.psds.north.length) {
            let content_north = `data:text/csv;charset=utf-8,${this.state.psds.north
                .join("\n")
                .trim()}`;
            //TODO Philipp add buoy name in file download
            let filename_north = `BUOY_NAME_PSD_NORTH${moment
                .utc()
                .format("YYYY-MM-DD_HH:mm:ss_z")}.csv`;
            this.download(content_north, filename_north);
        }
        if (this.state.psds.east.length) {
            let content_east = `data:text/csv;charset=utf-8,${this.state.psds.east
                .join("\n")
                .trim()}`;
            //TODO Philipp add buoy name in file download
            let filename_east = `BUOY_NAME_PSD_EAST${moment
                .utc()
                .format("YYYY-MM-DD_HH:mm:ss_z")}.csv`;
            this.download(content_east, filename_east);
        }
    }
    /**
     * returns unique key for react elements
     */
    getKey() {
        return this.state.uuid + this.keyCount++;
    }
    /**
     * @param {*} event
     * @param {bool} isExpanded state to set to
     */
    accordianToggle(event, isExpanded) {
        this.setState({ expanded: isExpanded });
    }
    loadSpectrumChart(chart, myChartRef, wave_spectra, label) {
        const HALF_SPECTRUM = 2.0;
        const MM_PER_METER = 0.001;
        const sampling_frequency = this.state.sampleFrequencyFFT;
        return new Chart(myChartRef, {
            type: "line",
            data: {
                labels:
                    wave_spectra.down == []
                        ? []
                        : [...Array(wave_spectra.down.length).keys()].map(
                              function (_, i) {
                                  return (
                                      (((i + 1) / wave_spectra.down.length) *
                                          sampling_frequency) /
                                      HALF_SPECTRUM
                                  );
                              }
                          ),
                datasets: [
                    {
                        label: "North",
                        data:
                            wave_spectra.north == []
                                ? []
                                : wave_spectra.north.map(function (e) {
                                      return e * MM_PER_METER;
                                  }),
                        borderColor: "#f00",
                    },
                    {
                        label: "East",
                        data:
                            wave_spectra.east == []
                                ? []
                                : wave_spectra.east.map(function (e) {
                                      return e * MM_PER_METER;
                                  }),
                        borderColor: "#0f0",
                    },
                    {
                        label: "Down",
                        data:
                            wave_spectra.down == []
                                ? []
                                : wave_spectra.down.map(function (e) {
                                      return e * MM_PER_METER;
                                  }),
                        borderColor: "#00f",
                    },
                ],
            },
            options: {
                legend: { display: true },
                title: {
                    display: true,
                    text: label,
                },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: "Frequency (Hz)",
                            },
                        },
                    ],
                    yAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: "Amplitude (m)",
                            },
                        },
                    ],
                },
            },
        });
    }
    loadRealTimeChart(chart, chartRef, data, label) {
        const sampling_frequency = this.state.sampleFrequencyFFT;
        const time_axis = [...Array(data.down.length).keys()].map(function (
            _,
            i
        ) {
            return i / sampling_frequency;
        });
        return new Chart(chartRef, {
            type: "line",
            data: {
                labels: time_axis,
                datasets: [
                    {
                        label: "North",
                        data: data.north,
                        borderColor: "#f00",
                    },
                    {
                        label: "East",
                        data: data.east,
                        borderColor: "#0f0",
                    },
                    {
                        label: "Down",
                        data: data.down,
                        borderColor: "#00f",
                    },
                ],
            },
            options: {
                legend: { display: true },
                title: {
                    display: true,
                    text: label,
                },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: "Time (s)",
                            },
                        },
                    ],
                    yAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: "Displacement Estimate (m)",
                            },
                        },
                    ],
                },
                animation: false,
            },
        });
    }
    /**
     * BLE characteristic hander
     * receives new first part of North PSD data
     * over bluetooth when broadcasted by buoy
     * @param {*} event
     */
    handleNotificationsNorth(event) {
        let value = event.target.value;
        let psd_north = [...this.state.packets.psd_north];
        psd_north.push(value);
        let north = this.state.typeHandler(value, this.state.packets.psd_north);
        this.setState({
            packets: {
                ...this.state.packets,
                psd_north: psd_north,
            },
            psds: {
                ...this.state.psds,
                north: north,
            },
        });
        console.log("Got PSD North notification!");
    }
    /**
     * BLE characteristic hander
     * receives new first part of East PSD data
     * over bluetooth when broadcasted by buoy
     * @param {*} event
     */
    handleNotificationsEast(event) {
        let value = event.target.value;
        let psd_east = [...this.state.packets.psd_east];
        psd_east.push(value);
        let east = this.state.typeHandler(value, this.state.packets.psd_east);
        this.setState({
            packets: {
                ...this.state.packets,
                psd_east: psd_east,
            },
            psds: {
                ...this.state.psds,
                east: east,
            },
        });
        console.log("Got PSD East notification!");
    }
    /**
     * BLE characteristic hander
     * receives new first part of Down PSD data
     * over bluetooth when broadcasted by buoy
     * @param {*} event
     */
    handleNotificationsDown(event) {
        let value = event.target.value;
        let psd_down = [...this.state.packets.psd_down];
        psd_down.push(value);
        let down = this.state.typeHandler(value, this.state.packets.psd_down);

        this.setState({
            packets: {
                ...this.state.packets,
                psd_down: psd_down,
            },
            psds: {
                ...this.state.psds,
                down: down,
            },
        });

        console.log("Got PSD Down 1 notification!");
    }
    /**
     * BLE characteristic handler
     * receives new first part of live estimated North displacement
     * over bluetooth when broadcasted by buoy
     * @param {*} event
     */
    handleNotificationsEstimatedDisplacementNorth(event) {
        let value = event.target.value;
        let live_north = BluetoothCharacteristicType.FLOAT(value, false);
        this.setState({
            live_data: {
                ...this.state.live_data,
                north: [...this.state.live_data.north, live_north],
            },
        });
    }
    /**
     * BLE characteristic handler
     * receives new first part of live estimated East displacement
     * over bluetooth when broadcasted by buoy
     * @param {*} event
     */
    handleNotificationsEstimatedDisplacementEast(event) {
        let value = event.target.value;
        let live_east = BluetoothCharacteristicType.FLOAT(value, false);
        this.setState({
            live_data: {
                ...this.state.live_data,
                east: [...this.state.live_data.east, live_east],
            },
        });
    }
    /**
     * BLE characteristic handler
     * receives new first part of live estimated Down displacement
     * over bluetooth when broadcasted by buoy
     * @param {*} event
     */
    handleNotificationsEstimatedDisplacementDown(event) {
        let value = event.target.value;
        let live_down = BluetoothCharacteristicType.FLOAT(value, false);
        this.setState({
            live_data: {
                ...this.state.live_data,
                down: [...this.state.live_data.down, live_down],
            },
        });
    }
    /**
     * Start notification on PSD data channels. to start listening for compressed
     * PSD data to be sent over BLE from buoy once ready
     */
    async enable_notify_ble_characteristic() {
        try {
            await this.state.psd_north_characteristic.startNotifications();
            await this.state.psd_east_characteristic.startNotifications();
            await this.state.psd_down_characteristic.startNotifications();
            this.state.psd_north_characteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleNotificationsNorth
            );
            this.state.psd_east_characteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleNotificationsEast
            );
            this.state.psd_down_characteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleNotificationsDown
            );
            if (this.state.supportsLiveData) {
                await this.state.live_north_characteristic.startNotifications();
                await this.state.live_east_characteristic.startNotifications();
                await this.state.live_down_characteristic.startNotifications();
                this.state.live_north_characteristic.addEventListener(
                    "characteristicvaluechanged",
                    this.handleNotificationsEstimatedDisplacementNorth
                );
                this.state.live_east_characteristic.addEventListener(
                    "characteristicvaluechanged",
                    this.handleNotificationsEstimatedDisplacementEast
                );
                this.state.live_down_characteristic.addEventListener(
                    "characteristicvaluechanged",
                    this.handleNotificationsEstimatedDisplacementDown
                );
            }
        } catch (error) {
            //TODO Philipp Add proper error handling
            console.log("Argh! " + error);
        }
    }

    async kickOffJob(event) {
        event.preventDefault();
        event.stopPropagation();
        let runtimeSeconds = parseInt(this.state.runtimeSeconds);
        let enableLiveData = parseInt(this.state.enableLiveData);
        let sampleFrequencyFFT = parseInt(this.state.sampleFrequencyFFT);

        let buffer = new ArrayBuffer(4);
        if (this.state.supportsLiveData) {
            buffer = new ArrayBuffer(9);
            let dataview = new DataView(buffer, 0);
            dataview.setUint32(0, runtimeSeconds, true);
            dataview.setUint8(4, enableLiveData);
            dataview.setFloat32(5, sampleFrequencyFFT);
        } else {
            let dataview = new DataView(buffer, 0);
            dataview.setUint32(0, runtimeSeconds, true);
        }

        this.setState({
            psds: {
                north: [],
                east: [],
                down: [],
            },
            live_data: {
                north: [],
                east: [],
                down: [],
            },
        });
        await this.state.characteristic.writeValueWithResponse(buffer);
    }

    runtimeUpdate(event) {
        this.setState({
            runtimeSeconds: parseInt(event.target.value),
        });
    }

    sampleFrequencyUpdate(event) {
        this.setState({
            sampleFrequencyFFT: parseInt(event.target.value),
        });
    }

    stopProp(event) {
        event.stopPropagation();
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper
                key={this.state.uuid}
                className={classes.accordion_container}
            >
                <div className={classes.accordion_container}>
                    <Accordion
                        className={classes.accordion}
                        expanded={this.state.expanded}
                        onChange={this.accordianToggle}
                    >
                        <AccordionSummary
                            className={classes.space_between}
                            expandIcon={<ExpandMore />}
                            id={this.state.uuid}
                        >
                            <div className={classes.flex_div100}>
                                <Typography
                                    sx={{ width: "30%", flexShrink: 0 }}
                                >
                                    {this.state.name}
                                </Typography>
                                <div className={classes.flex_div}>
                                    <TextField
                                        required
                                        id="runtime-seconds"
                                        value={this.state.runtimeSeconds}
                                        label="Seconds of Runtime"
                                        variant="filled"
                                        type="number"
                                        onChange={this.runtimeUpdate}
                                        onClick={this.stopProp}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    {this.state.supportsLiveData ? (
                                        <div>
                                            <FormControlLabel
                                                label="Graph Live"
                                                labelPlacement="top"
                                                control={
                                                    <Switch
                                                        labelId="toggle-raw-data-graph-switch-label"
                                                        id="toggle-raw-data-graph-switch"
                                                        color="success"
                                                        checked={
                                                            this.state
                                                                .enableLiveData
                                                        }
                                                        onChange={(event) => {
                                                            this.setState({
                                                                enableLiveData:
                                                                    event.target
                                                                        .checked
                                                                        ? 1
                                                                        : 0,
                                                            });
                                                        }}
                                                    />
                                                }
                                            />
                                            <TextField
                                                required
                                                id="sampling-frequency-FFT-Hz"
                                                value={
                                                    this.state
                                                        .sampleFrequencyFFT
                                                }
                                                label="Wave Data Output Frequency (Hz)"
                                                variant="filled"
                                                type="number"
                                                onChange={
                                                    this.sampleFrequencyUpdate
                                                }
                                                onClick={this.stopProp}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className={classes.flex_div}>
                                    <Chip
                                        icon={<Sync />}
                                        label="Run Job"
                                        disabled={!this.props.connected}
                                        onClick={this.kickOffJob}
                                        color="success"
                                    />
                                    <Chip
                                        icon={<CloudDownload />}
                                        label="Download Spectra"
                                        onClick={this.downloadCallback}
                                        color="success"
                                    />
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <canvas
                                    id="chartSpectrumRef"
                                    ref={this.chartSpectrumRef}
                                    className={classes.canvas}
                                />
                                {this.state.supportsLiveData ? (
                                    <canvas
                                        id="chartRealTimeRef"
                                        ref={this.chartRealTimeRef}
                                        className={classes.canvas}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(BluetoothWaveProcessingCharacteristic);
