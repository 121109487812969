import React, { Component } from "react";
import moment from "moment-timezone";
import { Button, Paper, Typography, Chip, Link } from "@mui/material";
import { withStyles } from "@mui/styles";
import { BluetoothDisabled, BluetoothConnected } from "@mui/icons-material";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import BluetoothSensorCharacteristic from "./BluetoothSensorCharacteristic";
import BluetoothCameraCharacteristic from "./BluetoothCameraCharacteristic";
import BluetoothWaveProcessingCharacteristic from "./BluetoothWaveProcessingCharacteristic";
import BluetoothWaveRawSensorCharacteristic from "./BluetoothWaveRawSensorCollectCharacteristic";
import BluetoothMCUmgrCharacteristic from "./BluetoothMCUmgrCharacteristic";
import BluetoothButtonCharacteristic from "./BluetoothButtonCharacteristic";
import knownCharacteristics from "../helper/KnownBluetoothCharacteristicsAndTypes";
import BluetoothStatsCharacteristic from "./BluetoothStatsCharacteristic";
import APIClient from "../../models/APIClient";
import BluetoothConfigCharacteristic from "./BluetoothConfigCharacteristic";

const styles = (theme) => ({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    width100: {
        width: "100%",
    },
    connected: {
        margin: "10px 0",
    },
});

moment.utc();

const BluetoothBuoySetup = WithGoogleAuth(
    class BluetoothBuoySetup extends Component {
        constructor(props) {
            super(props);
            this.serviceUUID = `00000001-d3a4-4cc0-8980-479a49d7e621`;
            //DFU Device firmware update service
            this.serviceDFUUUID = `8d53dc1d-1db7-4cd3-868b-8a527460aa84`;
            this.state = {
                bluetooth_support: false,
                title: "Connect to a Buoy",
                sensor_components: [],
                job_components: [],
                hibernate_component: null,
                connected: false,
                device: null,
                characteristics: [],
                buoy_name: "",
                buoy_data: null,
                installed_firmware_hash: "",
                available_firmware_versions: [],
                dashboard_installed_firmware_version: null,
                firmware_version: null,
            };
            this.connect_to_ble = this.connect_to_ble.bind(this);
            this.disconnect_from_ble = this.disconnect_from_ble.bind(this);
            this.onBleDisconnected = this.onBleDisconnected.bind(this);
            this.setIMEI = this.setIMEI.bind(this);
            this.syncFirmwareVersion = this.syncFirmwareVersion.bind(this);
            this.setActiveFirmwareHash = this.setActiveFirmwareHash.bind(this);
            this.setAvailableFirmwareVersions =
                this.setAvailableFirmwareVersions.bind(this);

            this.checkBluetoothBrowserSupport();
        }
        async checkBluetoothBrowserSupport() {
            let availabe;
            try {
                availabe = await navigator.bluetooth.getAvailability();
            } catch (e) {
                availabe = false;
            }
            this.setState({ bluetooth_support: availabe });
        }
        async chooseBLEDevice() {
            try {
                const accelBuoyDeviceName = "Accelerometer buoy";
                const camLiteBuoyDeviceName = "Cam-Lite buoy";
                const options = {
                    filters: [
                        {
                            name: accelBuoyDeviceName,
                        },
                        {
                            name: camLiteBuoyDeviceName,
                        },
                    ],
                    optionalServices: [this.serviceUUID, this.serviceDFUUUID],
                };
                let device = await navigator.bluetooth.requestDevice(options);
                return device;
            } catch (error) {
                //TODO Philipp Add proper error handling
                console.log("Argh! " + error);
            }
        }
        onBleDisconnected() {
            this.setState({
                connected: false,
                device: null,
                installed_firmware_hash: "",
            });
        }
        async connectBLEDevice(device) {
            try {
                if (!device) {
                    //TODO Philipp Add proper error handling
                    return;
                }

                // Reset child component states, or else dashboard buttons will not communicate with buoy after disconnection/re-connection
                this.setState({
                    job_components: [],
                    characteristics: [],
                    sensor_components: [],
                    buoy_name: "Loading...",
                });

                device.addEventListener(
                    "gattserverdisconnected",
                    this.onBleDisconnected
                );
                let server = await device.gatt.connect();

                if (!device.gatt.connected) {
                    //TODO Philipp Add proper error handling
                    return;
                }
                this.setState({ connected: true, device: device });

                let services = await server.getPrimaryServices(
                    this.serviceUUID
                );

                let service = services?.[0];

                if (service) {
                    let characteristics = await service.getCharacteristics();
                    characteristics.forEach((characteristic) => {
                        let knownCharacteristic = knownCharacteristics.filter(
                            (c) => c.uuid === characteristic.uuid
                        )?.[0];
                        if (knownCharacteristic) {
                            if (knownCharacteristic.type === "hibernate") {
                                this.setState(() => {
                                    return {
                                        hibernate_component: (
                                            <BluetoothButtonCharacteristic
                                                uuid={knownCharacteristic.uuid}
                                                name={knownCharacteristic.name}
                                                text="Hibernate"
                                                characteristic={characteristic}
                                                key={knownCharacteristic.uuid}
                                                confirm="Hibernating the buoy will disable its Bluetooth services and its job schedule until woken up with magnet. Proceed?"
                                            />
                                        ),
                                    };
                                });
                            }
                        }
                    });
                    this.setState({ characteristics });
                }
            } catch (error) {
                //TODO Philipp Add proper error handling
                console.log("Argh! " + error);
            }
        }
        async connect_to_ble() {
            let device = await this.chooseBLEDevice();
            await this.connectBLEDevice(device);
        }
        async disconnect_from_ble() {
            let device = this.state.device;
            device.gatt.disconnect();
        }
        async setIMEI(data) {
            const apiClient = new APIClient(this.props.authState);
            const thing_selector = String(data.value);
            // Load buoy metadata
            const rawBuoyData = await apiClient.getThingAll(
                thing_selector,
                1,
                null,
                null
            );
            const buoyDescriptor =
                rawBuoyData.length === 1
                    ? rawBuoyData[0].name
                    : "uncrcogniced (has not been set up on dashboard)";
            this.setState({
                buoy_name: buoyDescriptor,
                buoy_data: rawBuoyData[0],
            });
            if (!(rawBuoyData.length === 1)) {
                return;
            }
            // load buoy currently assigned firmware version
            const firmwareVersion = await apiClient.getBuoyFirmwareVersion(
                rawBuoyData[0].id
            );
            if (!firmwareVersion) {
                //no firmware version has been set for buoy
                this.syncFirmwareVersion(
                    this.state.installed_firmware_hash,
                    firmwareVersion,
                    this.state.available_firmware_versions,
                    true,
                    rawBuoyData[0].id
                );
                return;
            }
            //Convert string to JSON if it is not converted already
            if (typeof firmwareVersion.config !== "object") {
                firmwareVersion.config = JSON.parse(firmwareVersion.config);
            }
            if (firmwareVersion) {
                this.setState({
                    dashboard_installed_firmware_version: firmwareVersion,
                });
                this.syncFirmwareVersion(
                    this.state.installed_firmware_hash,
                    firmwareVersion,
                    this.state.available_firmware_versions,
                    false,
                    rawBuoyData[0].id
                );
            }
        }

        async syncFirmwareVersion(
            installed_hash,
            dashboard_version,
            versions,
            out_of_sync,
            thing_id
        ) {
            //if out of sync we want to search the versions on dashboard and compare to installed hash
            if (out_of_sync && installed_hash?.length && versions?.length) {
                const new_version = versions.filter(
                    (version) => version.metadata?.hash == installed_hash
                )?.[0];
                if (new_version) {
                    const apiClient = new APIClient(this.props.authState);
                    await apiClient.createBuoyFirmwareVersion({
                        version_id: new_version.id,
                        thing_id: thing_id,
                    });
                    this.setState({ firmware_version: new_version });
                } else {
                    //set as unknown version if it has not be configured on the dashboard
                    this.setState({
                        firmware_version: {
                            version: "unrecognized firmware version",
                            description:
                                "Please update firmware with a know version",
                        },
                    });
                }
                return;
                //if not out of sync
            } else if (
                !out_of_sync &&
                installed_hash?.length &&
                versions?.length &&
                dashboard_version?.metadata?.hash
            ) {
                // check if actually out of sync
                if (installed_hash != dashboard_version.metadata.hash) {
                    this.syncFirmwareVersion(
                        installed_hash,
                        dashboard_version,
                        versions,
                        true,
                        thing_id
                    );
                } else {
                    this.setState({ firmware_version: dashboard_version });
                }
            }
            //else not enough info so return
            return;
        }

        setActiveFirmwareHash(hash_string) {
            this.setState({ installed_firmware_hash: hash_string });
            this.syncFirmwareVersion(
                hash_string,
                this.state.dashboard_installed_firmware_version,
                this.state.available_firmware_versions,
                false,
                this.state.buoy_data?.id
            );
        }

        setAvailableFirmwareVersions(firmware_versions) {
            this.setState({ available_firmware_versions: firmware_versions });
            this.syncFirmwareVersion(
                this.state.installed_firmware_hash,
                this.state.dashboard_installed_firmware_version,
                firmware_versions,
                false,
                this.state.buoy_data?.id
            );
        }

        getSensorComponents() {
            const components = this.state.characteristics.map(
                (characteristic) => {
                    let knownCharacteristic = knownCharacteristics.filter(
                        (c) => c.uuid === characteristic.uuid
                    )?.[0];
                    if (knownCharacteristic) {
                        if (
                            knownCharacteristic.type === "sensor" &&
                            knownCharacteristic.typeTemplate === "Camera"
                        ) {
                            return (
                                <BluetoothCameraCharacteristic
                                    uuid={knownCharacteristic.uuid}
                                    name={knownCharacteristic.name}
                                    typeHandler={
                                        knownCharacteristic.typeHandler
                                    }
                                    characteristic={characteristic}
                                    requestOnConnect={
                                        knownCharacteristic.requestOnConnect
                                    }
                                    csvHeader={knownCharacteristic.csvHeader}
                                    key={knownCharacteristic.uuid}
                                    connected={this.state.connected}
                                />
                            );
                        } else if (knownCharacteristic.type === "sensor") {
                            return (
                                <BluetoothSensorCharacteristic
                                    uuid={knownCharacteristic.uuid}
                                    name={knownCharacteristic.name}
                                    typeHandler={
                                        knownCharacteristic.typeHandler
                                    }
                                    characteristic={characteristic}
                                    requestOnConnect={
                                        knownCharacteristic.requestOnConnect
                                    }
                                    csvHeader={knownCharacteristic.csvHeader}
                                    key={knownCharacteristic.uuid}
                                    connected={this.state.connected}
                                />
                            );
                        }
                    }
                }
            );

            if (components.length) {
                const { classes } = this.props;
                return (
                    <div>
                        <Typography className={classes.title} variant="h3">
                            Sensors:
                        </Typography>
                        {components}
                    </div>
                );
            }
        }

        getStatsComponents() {
            const components = this.state.characteristics.map(
                (characteristic) => {
                    let knownCharacteristic = knownCharacteristics.filter(
                        (c) => c.uuid === characteristic.uuid
                    )?.[0];
                    if (knownCharacteristic) {
                        if (
                            knownCharacteristic.type === "stats" &&
                            knownCharacteristic.typeTemplate === "imei"
                        ) {
                            return (
                                <BluetoothStatsCharacteristic
                                    uuid={knownCharacteristic.uuid}
                                    name={knownCharacteristic.name}
                                    typeHandler={
                                        knownCharacteristic.typeHandler
                                    }
                                    characteristic={characteristic}
                                    requestOnConnect={
                                        knownCharacteristic.requestOnConnect
                                    }
                                    csvHeader={knownCharacteristic.csvHeader}
                                    key={knownCharacteristic.uuid}
                                    connected={this.state.connected}
                                    delegate={this.setIMEI}
                                />
                            );
                        }
                    }
                }
            );
            if (components.length) {
                const { classes } = this.props;
                return (
                    <div>
                        <Typography className={classes.title} variant="h3">
                            Stats:
                        </Typography>
                        {components}
                        {this.state.firmware_version && (
                            <Typography>
                                <Link
                                    href={
                                        this.state.firmware_version.metadata
                                            ?.github_url
                                    }
                                >
                                    {this.state.firmware_version.version} -{" "}
                                    {this.state.firmware_version.description}
                                </Link>
                            </Typography>
                        )}
                    </div>
                );
            }
        }

        getJobComponents() {
            let job_components = [];
            this.state.characteristics.forEach((characteristic) => {
                let knownCharacteristic = knownCharacteristics.filter(
                    (c) => c.uuid === characteristic.uuid
                )?.[0];
                if (knownCharacteristic) {
                    if (
                        knownCharacteristic.type === "job" &&
                        knownCharacteristic.typeTemplate === "wave_processing"
                    ) {
                        console.log(`processing ${characteristic}`);
                        let psd_north_characteristic =
                            this.state.characteristics.filter(
                                (c) =>
                                    c.uuid ===
                                    knownCharacteristic
                                        .related_characteristics_uuids.psd_north
                            )?.[0];
                        let psd_east_characteristic =
                            this.state.characteristics.filter(
                                (c) =>
                                    c.uuid ===
                                    knownCharacteristic
                                        .related_characteristics_uuids.psd_east
                            )?.[0];
                        let psd_down_characteristic =
                            this.state.characteristics.filter(
                                (c) =>
                                    c.uuid ===
                                    knownCharacteristic
                                        .related_characteristics_uuids.psd_down
                            )?.[0];
                        let live_north_characteristic =
                            this.state.characteristics.filter(
                                (c) =>
                                    c.uuid ===
                                    knownCharacteristic
                                        .related_characteristics_uuids
                                        .live_data_north
                            )?.[0];
                        let live_east_characteristic =
                            this.state.characteristics.filter(
                                (c) =>
                                    c.uuid ===
                                    knownCharacteristic
                                        .related_characteristics_uuids
                                        .live_data_east
                            )?.[0];
                        let live_down_characteristic =
                            this.state.characteristics.filter(
                                (c) =>
                                    c.uuid ===
                                    knownCharacteristic
                                        .related_characteristics_uuids
                                        .live_data_down
                            )?.[0];
                        // If any of the live wave data characteristics are not found/available, assume buoy firmware does not support it.
                        let supportsLiveData =
                            live_north_characteristic !== undefined &&
                            live_east_characteristic !== undefined &&
                            live_down_characteristic !== undefined;
                        job_components.push(
                            <BluetoothWaveProcessingCharacteristic
                                uuid={knownCharacteristic.uuid}
                                name={knownCharacteristic.name}
                                typeHandler={knownCharacteristic.typeHandler}
                                characteristic={characteristic}
                                psd_north_characteristic={
                                    psd_north_characteristic
                                }
                                psd_east_characteristic={
                                    psd_east_characteristic
                                }
                                psd_down_characteristic={
                                    psd_down_characteristic
                                }
                                live_north_characteristic={
                                    live_north_characteristic
                                }
                                live_east_characteristic={
                                    live_east_characteristic
                                }
                                live_down_characteristic={
                                    live_down_characteristic
                                }
                                supportsLiveData={supportsLiveData}
                                key={knownCharacteristic.uuid}
                                connected={this.state.connected}
                            />
                        );
                    }
                    if (
                        knownCharacteristic.type === "job" &&
                        knownCharacteristic.typeTemplate ===
                            "wave_raw_sensor_collect"
                    ) {
                        console.log(`processing ${characteristic}`);
                        let raw_accelerometer_x_characteristic =
                            this.state.characteristics.filter(
                                (c) =>
                                    c.uuid ===
                                    knownCharacteristic
                                        .related_characteristics_uuids
                                        .raw_accelerometer_x
                            )?.[0];
                        let raw_accelerometer_y_characteristic =
                            this.state.characteristics.filter(
                                (c) =>
                                    c.uuid ===
                                    knownCharacteristic
                                        .related_characteristics_uuids
                                        .raw_accelerometer_y
                            )?.[0];
                        let raw_accelerometer_z_characteristic =
                            this.state.characteristics.filter(
                                (c) =>
                                    c.uuid ===
                                    knownCharacteristic
                                        .related_characteristics_uuids
                                        .raw_accelerometer_z
                            )?.[0];
                        let raw_gyroscope_x_characteristic =
                            this.state.characteristics.filter(
                                (c) =>
                                    c.uuid ===
                                    knownCharacteristic
                                        .related_characteristics_uuids
                                        .raw_gyroscope_x
                            )?.[0];
                        let raw_gyroscope_y_characteristic =
                            this.state.characteristics.filter(
                                (c) =>
                                    c.uuid ===
                                    knownCharacteristic
                                        .related_characteristics_uuids
                                        .raw_gyroscope_y
                            )?.[0];
                        let raw_gyroscope_z_characteristic =
                            this.state.characteristics.filter(
                                (c) =>
                                    c.uuid ===
                                    knownCharacteristic
                                        .related_characteristics_uuids
                                        .raw_gyroscope_z
                            )?.[0];
                        job_components.push(
                            <BluetoothWaveRawSensorCharacteristic
                                uuid={knownCharacteristic.uuid}
                                name={knownCharacteristic.name}
                                typeHandler={knownCharacteristic.typeHandler}
                                characteristic={characteristic}
                                raw_accelerometer_x_characteristic={
                                    raw_accelerometer_x_characteristic
                                }
                                raw_accelerometer_y_characteristic={
                                    raw_accelerometer_y_characteristic
                                }
                                raw_accelerometer_z_characteristic={
                                    raw_accelerometer_z_characteristic
                                }
                                raw_gyroscope_x_characteristic={
                                    raw_gyroscope_x_characteristic
                                }
                                raw_gyroscope_y_characteristic={
                                    raw_gyroscope_y_characteristic
                                }
                                raw_gyroscope_z_characteristic={
                                    raw_gyroscope_z_characteristic
                                }
                                key={knownCharacteristic.uuid}
                                connected={this.state.connected}
                            />
                        );
                    }
                }
            });

            if (job_components.length) {
                const { classes } = this.props;
                return (
                    <div>
                        <Typography className={classes.title} variant="h3">
                            Jobs:
                        </Typography>
                        {job_components}
                    </div>
                );
            }
        }

        getUtilComponents() {
            const components = this.state.characteristics.map(
                (characteristic) => {
                    let knownCharacteristic = knownCharacteristics.filter(
                        (c) => c.uuid === characteristic.uuid
                    )?.[0];
                    if (knownCharacteristic) {
                        if (
                            knownCharacteristic.type === "utils" &&
                            knownCharacteristic.typeTemplate === "config"
                        ) {
                            return (
                                <BluetoothConfigCharacteristic
                                    {...this.props}
                                    version={this.state.firmware_version}
                                    selector_labels={
                                        this.state.buoy_data?.selectorLabels ||
                                        []
                                    }
                                    buoy_id={this.state.buoy_data?.id || null}
                                    uuid={knownCharacteristic.uuid}
                                    name={knownCharacteristic.name}
                                    typeHandler={
                                        knownCharacteristic.typeHandler
                                    }
                                    characteristic={characteristic}
                                    requestOnConnect={
                                        knownCharacteristic.requestOnConnect
                                    }
                                    key={knownCharacteristic.uuid}
                                    connected={this.state.connected}
                                />
                            );
                        }
                    }
                }
            );
            if (this.state.connected) {
                const { classes } = this.props;
                return (
                    <div>
                        <Typography className={classes.title} variant="h3">
                            Utilities:
                        </Typography>
                        <BluetoothMCUmgrCharacteristic
                            name={`Update Firmware`}
                            device={this.state.device}
                            buoy_data={this.state.buoy_data}
                            setActiveFirmwareHash={this.setActiveFirmwareHash}
                            setAvailableFirmwareVersions={
                                this.setAvailableFirmwareVersions
                            }
                        />
                        {components}
                    </div>
                );
            }
        }

        render() {
            const { classes } = this.props;

            return (
                <Paper>
                    <div className={classes.container}>
                        {this.state.bluetooth_support ? (
                            <div className={classes.width100}>
                                <Typography
                                    className={classes.title}
                                    variant="h5"
                                >
                                    {this.state.title}
                                </Typography>
                                <div className={classes.container}>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        onClick={
                                            this.state.connected
                                                ? this.disconnect_from_ble
                                                : this.connect_to_ble
                                        }
                                    >
                                        {this.state.connected
                                            ? "Disconnect"
                                            : "Connect"}{" "}
                                        Buoy
                                    </Button>
                                    {this.state.hibernate_component != null &&
                                    this.state.connected
                                        ? this.state.hibernate_component
                                        : ""}
                                    <div className={classes.connected}>
                                        {this.state.connected ? (
                                            <Chip
                                                icon={<BluetoothConnected />}
                                                label="Connected"
                                                color="success"
                                            />
                                        ) : (
                                            <Chip
                                                icon={<BluetoothDisabled />}
                                                label="Disconnected"
                                                color="error"
                                            />
                                        )}
                                    </div>
                                </div>
                                {this.state.buoy_name && (
                                    <Typography
                                        className={classes.title}
                                        variant="h3"
                                    >
                                        Connected to: {this.state.buoy_name}
                                    </Typography>
                                )}
                                {this.getSensorComponents()}
                                {this.getJobComponents()}
                                {this.getStatsComponents()}
                                {this.getUtilComponents()}
                            </div>
                        ) : (
                            <Typography className={classes.title} variant="h5">
                                Bluetooth not supported in this browser.
                            </Typography>
                        )}
                    </div>
                </Paper>
            );
        }
    }
);

export default withStyles(styles)(BluetoothBuoySetup);
