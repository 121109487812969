import React, { Component } from "react";
import { Grid, Typography, Stack, Link, ListItem } from "@mui/material";
import { FixedSizeList } from "react-window";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { DeploymentEventEnum } from "@running-tide/farm-system/models/enums";
import CarbonAccountingAPIClient from "../../../models/CarbonAccountingAPIClient";
import { WithGoogleAuth } from "../../../config/WithGoogleAuth";
import MessageHelper from "../../helper/MessageHelper";
import { errorMessage, successMessage } from "../../helper/MessageMethodHelper";

function renderRow(props) {
    const { index, style, data } = props;
    const { deploymentInfo, events, editing, deleteEvent } = data;
    let rowContent = ``;
    // TODO (nikhil) Links direct to vessel / port pages once they've been created
    switch (events[index].eventType) {
        case DeploymentEventEnum.DEPARTURE:
            rowContent = (
                <span>
                    {" "}
                    Vessel
                    {" " + deploymentInfo.vessel?.name + " "}
                    departed from Port
                    {" " + deploymentInfo.departurePort?.name + " "}
                    <DeleteButton
                        editing={editing}
                        deleteEvent={deleteEvent}
                        eventId={events[index].id}
                    />
                </span>
            );
            break;
        case DeploymentEventEnum.ARRIVAL:
            rowContent = (
                <span>
                    {" "}
                    Vessel {" " + deploymentInfo.vessel?.name + " "}
                    arrived at Port{" "}
                    {" " + deploymentInfo.arrivalPort?.name + " "}
                    <DeleteButton
                        editing={editing}
                        deleteEvent={deleteEvent}
                        eventId={events[index].id}
                    />
                </span>
            );
            break;
        case DeploymentEventEnum.SUBSTRATE_DEPLOYMENT_START:
            rowContent = (
                <span>
                    {" "}
                    Started deploying substrate{" "}
                    {events[index].recipeName ? (
                        <Link
                            href={`/product/group/${events[index].productGroupId}`}
                            underline="hover"
                        >
                            {events[index].recipeName}
                        </Link>
                    ) : (
                        "**"
                    )}
                    {" at [" + events[index].coordinates.lat},{" "}
                    {events[index].coordinates.long + "]"}
                    <DeleteButton
                        editing={editing}
                        deleteEvent={deleteEvent}
                        eventId={events[index].id}
                    />
                </span>
            );
            break;
        case DeploymentEventEnum.SUBSTRATE_DEPLOYMENT_STOP:
            rowContent = (
                <span>
                    {" "}
                    Stopped deploying substrate{" "}
                    {events[index].recipeName ? (
                        <Link
                            href={`/product/group/${events[index].productGroupId}`}
                            underline="hover"
                        >
                            {events[index].recipeName}
                        </Link>
                    ) : (
                        "**"
                    )}
                    {" at [" + events[index].coordinates.lat},{" "}
                    {events[index].coordinates.long + "]"}
                    <DeleteButton
                        editing={editing}
                        deleteEvent={deleteEvent}
                        eventId={events[index].id}
                    />
                </span>
            );
            break;
        case DeploymentEventEnum.VERIFICATION_BUOY_DEPLOYMENT:
            rowContent = (
                <span>
                    {" "}
                    <Link
                        href={`/kelp_buoy/${events[index].instrumentId}`}
                        underline="hover"
                    >
                        {events[index].deployedBuoyName ?? "CBXX"}
                    </Link>{" "}
                    deployed at {"[" + events[index].coordinates.lat},{" "}
                    {events[index].coordinates.long + "]"}
                    <DeleteButton
                        editing={editing}
                        deleteEvent={deleteEvent}
                        eventId={events[index].id}
                    />
                </span>
            );
            break;
        case DeploymentEventEnum.TRAJECTORY_BUOY_DEPLOYMENT:
            rowContent = (
                <span>
                    {" "}
                    <Link
                        href={`/kelp_buoy/${events[index].instrumentId}`}
                        underline="hover"
                    >
                        {events[index].deployedBuoyName ?? "TBXX"}
                    </Link>{" "}
                    deployed at {"[" + events[index].coordinates.lat},{" "}
                    {events[index].coordinates.long + "]"}
                    <DeleteButton
                        editing={editing}
                        deleteEvent={deleteEvent}
                        eventId={events[index].id}
                    />
                </span>
            );
            break;
        default:
            rowContent = (
                <span> Unidentified event type {events[index].eventType}</span>
            );
    }

    return (
        // TODO (nikhil) Link directs to event page once it exists
        <ListItem style={style} key={index} disablePadding>
            {new Date(events[index].occurrenceDate)
                .toISOString()
                .replace(".000Z", "")}{" "}
            &nbsp; {"--"} &nbsp;{rowContent}
        </ListItem>
    );
}

/**
 *
 * @param {bool} editing
 * @param {function} deleteEvent
 * @param {String} eventId
 * @param {String} deploymentId
 * @returns
 */
const DeleteButton = ({ editing, deleteEvent, eventId }) => {
    return (
        editing && (
            <Link
                onClick={() => {
                    deleteEvent(eventId);
                }}
            >
                <RemoveCircleOutlineIcon
                    style={{
                        marginLeft: "10px",
                        position: "relative",
                        top: "6px",
                    }}
                />
            </Link>
        )
    );
};

function updateEventsWithDetail(events, buoysMap, substratesMap) {
    return events.map((event) => {
        let substrate = substratesMap.get(event.instrumentId);
        if (
            (substrate &&
                event.eventType ===
                    DeploymentEventEnum.SUBSTRATE_DEPLOYMENT_START) ||
            event.eventType === DeploymentEventEnum.SUBSTRATE_DEPLOYMENT_STOP
        ) {
            return {
                ...event,
                productGroupId: substrate.productGroupId,
                recipeName: substrate.name,
            };
        }

        let buoy = buoysMap.get(event.instrumentId);
        if (
            buoy &&
            (event.eventType ===
                DeploymentEventEnum.VERIFICATION_BUOY_DEPLOYMENT ||
                event.eventType ===
                    DeploymentEventEnum.TRAJECTORY_BUOY_DEPLOYMENT)
        ) {
            return { ...event, deployedBuoyName: buoy.name };
        }

        return { ...event };
    });
}

class DeploymentEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            errorMessage: "",
            events: [],
        };
    }

    static getDerivedStateFromProps(props) {
        return {
            events: props.events,
        };
    }

    async deleteEvent(eventId) {
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        try {
            await apiClient.deleteDeploymentEvent(eventId);
            const events = this.state.events.filter((e) => e.id !== eventId);
            this.props.eventsUpdate(events);
            this.setState({
                ...successMessage(`Deleted Event`),
            });
        } catch (error) {
            this.setState({
                ...errorMessage(error),
            });
        }
    }

    render() {
        return (
            <Grid item lg={57} xs={100}>
                <Stack variant="splitHeader">
                    <Typography variant="h3">EVENT LOG</Typography>
                    {!this.props.editing && (
                        <Link
                            href={`/cdr/deployments/${this.props.deploymentInfo.id}/events/create`}
                        >
                            <ControlPointIcon />
                        </Link>
                    )}
                </Stack>
                <FixedSizeList
                    height={420}
                    itemSize={30}
                    itemCount={this.state.events.length}
                    overscanCount={5}
                    itemData={{
                        deploymentInfo: this.props.deploymentInfo,
                        events: this.state.events,
                        editing: this.props.editing,
                        deleteEvent: (eventId) => this.deleteEvent(eventId),
                    }}
                    dense={true}
                >
                    {renderRow}
                </FixedSizeList>
                <MessageHelper
                    message={this.state.message}
                    errorMessage={this.state.errorMessage}
                    open={this.state.messageOpen}
                    setState={(a) => this.setState(a)}
                />
            </Grid>
        );
    }
}

const de = WithGoogleAuth(DeploymentEvents);
export { de as DeploymentEvents, updateEventsWithDetail };
