import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Chip } from "@mui/material";

function DeploymentStatus(props) {
    return props.arrivalDate && new Date(props.arrivalDate) <= new Date() ? (
        <Chip icon={<DoneIcon />} label="Completed" color="completedChip" />
    ) : props.departureDate ? (
        <Chip
            icon={<EventRepeatIcon />}
            label="In Progress"
            color="progressChip"
        />
    ) : (
        <Chip
            icon={<AccessTimeIcon />}
            label="Upcoming"
            color="secondaryUtility"
        />
    );
}

export default DeploymentStatus;
