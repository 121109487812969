/**
 * FormControl combining Input & label to give the functionality of
 * a file upload input to a customized Card display
 *
 * Custom Props
 *
 * Name               | Type      | Default
 *
 * onFilesUpload      | function  | none
 *
 */

import React, { useState } from "react";
import {
    Card,
    FormControl,
    Input,
    CardContent,
    Typography,
    Link,
} from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

const MultiFileUpload = (props) => {
    const { onFilesUpload, sxWidth } = props;
    const [dragActive, setDragActive] = useState(false);

    // Note: takes a files list object, not an array
    const handleUpload = (files) => {
        const selectedFiles = Array.prototype.slice.call(files);
        onFilesUpload(selectedFiles);
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files.length > 0) {
            handleUpload(e.dataTransfer.files);
        }
    };

    return (
        <FormControl sx={{ width: sxWidth }}>
            <Input
                id="fileUpload"
                type="file"
                inputProps={{ multiple: true }}
                sx={{ display: "none" }}
                onChange={({ target }) => {
                    handleUpload(target.files);
                }}
            />
            <label id="fileUpload-label" htmlFor="fileUpload">
                <Card
                    sx={{
                        background: dragActive ? "rgb(220, 220, 220)" : "white",
                        cursor: "pointer",
                        borderStyle: dragActive ? "dashed" : "none",
                        borderColor: dragActive
                            ? "primary.main"
                            : "transparent",
                    }}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                >
                    <CardContent
                        sx={{
                            color: "primaryUtility.main",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <CloudUploadOutlinedIcon fontSize="large" />
                        <Typography variant="body1">
                            Drag & drop files or <Link>Browse</Link>
                        </Typography>
                    </CardContent>
                </Card>
            </label>
        </FormControl>
    );
};

export default MultiFileUpload;
