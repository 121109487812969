import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import withTheme from "@mui/styles/withTheme";
import Chart from "chart.js";
import moment from "moment-timezone";
import GraphDateRangePickerComponent from "../GraphDateRangePickerComponent";

const styles = () => ({
    graphContainer: {
        height: "100%",
        width: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
        minHeight: "100%",
        minWidth: "100%",
    },
    canvas: {
        height: "100%",
        width: "100%",
    },
    lastUpdated: {
        display: "inline-block",
        fontSize: ".7rem",
        padding: 10,
    },
    datePicker: {
        "padding-left": "10px",
    },
});

let Graph = class GraphComponent extends Component {
    constructor(props) {
        super(props);
        this.datasets = props.datasets;
        this.update = props.update;
        this.state = { datasets: props.datasets };
        this.dateRange = this.getDateRange(this.datasets);
        this.picker = props.picker;
        this.chartRef = React.createRef();
        this.unit =
            this.datasets && this.datasets.length
                ? this.datasets[0].unit
                : null;
        if (this.datasets && this.datasets.length) {
            let possibleTitles = this.datasets.map((data) => data.label);
            this.title = possibleTitles.filter(
                (title) => !title.includes("Set") && !title.includes("Mode")
            );
        }
    }

    getDateRange(datasets) {
        return {
            from: datasets.reduce(
                (acc, dataset) =>
                    moment.min(acc, moment(dataset.earliestStartDate)),
                moment()
            ),
            to: moment(),
        };
    }

    componentDidMount() {
        this.loadState();
    }
    componentWillUnmount() {
        this.update = () => {};
        this.loadState = () => {};
        clearInterval(this.liveInterval);
    }
    async loadState() {
        const myChartRef = this.chartRef.current.getContext("2d");
        if (this.currentChart) this.currentChart.destroy();
        var yLabels = {
            1: "Auto",
            2: "Manual",
            "-1": "Fill",
            "-2": "Clean",
        };
        Chart.defaults.global.defaultFontColor =
            this.props.theme.palette.tertiaryUtility.main;
        this.currentChart = new Chart(myChartRef, {
            type: "line",
            data: {
                datasets: this.state.datasets,
            },
            options: {
                animation: {
                    duration: 0,
                },
                legend: {
                    position: "top",
                    labels: {
                        boxWidth: 12,
                    },
                },
                title: {
                    display: true,
                    text: this.title,
                    fontSize: 19,
                },
                scales: {
                    xAxes: [
                        {
                            type: "time",
                            time: {
                                displayFormats: {
                                    minute: "MMM D h:mm a",
                                    hour: "MMM D h a",
                                    day: "MMM D h a",
                                    week: "ll",
                                },
                            },
                            scaleLabel: {
                                display: true,
                                labelString: "Date Time",
                            },
                        },
                    ],
                    yAxes: [
                        {
                            id: "y",
                            position: "left",
                            type: "linear",
                        },
                        {
                            id: "y1",
                            position: "right",
                            type: "linear",
                            ticks: {
                                callback: function (value) {
                                    if (value % 1 === 0) {
                                        return yLabels[value];
                                    }
                                },
                                min: -2,
                                max: 2,
                            },
                            gridLines: {
                                color: "red",
                                lineWidth: 0.3,
                            },
                        },
                    ],
                },
            },
        });
    }

    async loadNew(startDate, endDate) {
        let [datasets] = await this.update(startDate, endDate, this.unit);
        datasets = datasets !== undefined ? datasets : [];
        this.setState({ datasets: datasets });
        this.currentChart.data.datasets = datasets;
        this.currentChart.update();
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.graphContainer}>
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                    className={classes.canvas}
                />
                <GraphDateRangePickerComponent
                    picker={this.picker}
                    dateRange={this.dateRange}
                    update={async (startDate, endDate) =>
                        await this.loadNew(startDate, endDate)
                    }
                />
            </div>
        );
    }
};

export default withTheme(withStyles(styles)(Graph));
