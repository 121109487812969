import React, { Component } from "react";
import {
    Autocomplete,
    IconButton,
    Button,
    TextField,
    Typography,
    Stack,
    Tooltip,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ClearIcon from "@mui/icons-material/Clear";

import { errorMessage, successMessage } from "../helper/MessageMethodHelper";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import MessageHelper from "../helper/MessageHelper";

const styles = (theme) => ({
    propertiesTitle: {
        marginTop: theme.spacing(1),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    propertiesFields: {
        display: "flex",
        flexDirection: "row",
    },
    propertyField: {
        minWidth: 170,
        marginRight: theme.spacing(2),
    },
    createButton: {
        marginTop: theme.spacing(1),
    },
    clearButton: {
        marginTop: "1.5rem",
        marginBottom: "0.5rem",
    },
});

class TestConceptCreator extends Component {
    state = {
        propertyTypes: [],
        name: "",
        description: "",
        data: [],
        isCreating: false,
        formulae: [],
        constants: [],
    };

    async componentDidMount() {
        // Fetch a list of substrate property types to populate the property type
        // dropdowns.
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        const propertyTypes = await apiClient.getSubstratePropertyTypes();
        this.setState({ propertyTypes });
    }

    async createTestConcept() {
        this.setState({ isCreating: true });

        const apiClient = new CarbonAccountingAPIClient(this.props.authState);

        try {
            const data = {};
            this.state.data.forEach(({ name, type }) => {
                if (name !== "") {
                    data[name] = type;
                }
            });
            const constants = {};
            this.state.constants.forEach(({ name, type }) => {
                if (name !== "") {
                    constants[name] = type;
                }
            });
            const id = await apiClient.createTestConcept(
                this.state.name,
                this.state.description,
                data,
                constants,
                this.state.formulae
            );
            this.setState({
                ...successMessage(`Test Concept ${id} created!`),
                name: "",
                description: "",
                data: [],
                constants: [],
                formulae: [],
                isCreating: false,
            });
        } catch (error) {
            this.setState({
                ...errorMessage(error),
                isCreating: false,
            });
        }
    }

    onAddProperty = () => {
        this.setState({
            data: [...this.state.data, { name: "", type: null }],
        });
    };

    onRemoveProperty = (index) => {
        const data = this.state.data.filter((_, i) => i !== index);
        this.setState({
            data,
        });
    };

    onChangeProperty = (name, type, index) => {
        const data = this.state.data;
        data[index] = { name, type };
        this.setState({ data });
    };

    onAddConstant = () => {
        this.setState({
            constants: [...this.state.constants, { name: "", type: null }],
        });
    };

    onChangeConstant = (name, type, index) => {
        const constants = this.state.constants;
        constants[index] = { name, type };
        this.setState({ constants });
    };

    onRemoveConstant = (index) => {
        const constants = this.state.constants.filter((_, i) => i !== index);
        this.setState({ constants });
    };

    onAddFormula = () => {
        this.setState({
            formulae: [...this.state.formulae, { name: "", logic: [] }],
        });
    };

    onRemoveFormula = (index) => {
        const formulae = this.state.formulae.filter((_, i) => i !== index);
        this.setState({
            formulae,
        });
    };

    onChangeFormula = (name, logic, index) => {
        const formulae = this.state.formulae;
        formulae[index] = { name, logic };
        this.setState({ formulae });
    };

    render() {
        return (
            <Stack justifyContent="flex-start" alignItems="flex-start">
                <Typography variant="h3">Details</Typography>
                <TextField
                    label="Name"
                    value={this.state.name}
                    onChange={(event) =>
                        this.setState({ name: event.target.value })
                    }
                />
                <TextField
                    label="Description"
                    value={this.state.description}
                    multiline
                    onChange={(event) =>
                        this.setState({ description: event.target.value })
                    }
                />
                <div className={this.props.classes.propertiesTitle}>
                    <Typography variant="h3">Data</Typography>
                    {!this.state.data.length && (
                        <IconButton
                            color="primary"
                            onClick={() => this.onAddProperty()}
                        >
                            <AddBoxIcon />
                        </IconButton>
                    )}
                </div>
                {/* Display a dropdown and text field for each property (for 
                    the name and type, respectively). */}
                {this.state.data.map(({ name, type }, i) => (
                    <Stack key={i} direction="row">
                        <TextField
                            label="Name"
                            value={name}
                            onChange={({ target }) =>
                                this.onChangeProperty(target.value, type, i)
                            }
                            className={this.props.classes.propertyField}
                        />
                        <Autocomplete
                            value={type}
                            onChange={(_, newType) => {
                                this.onChangeProperty(name, newType, i);
                            }}
                            options={this.state.propertyTypes}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Type"
                                    className={this.props.classes.propertyField}
                                />
                            )}
                        />
                        <Tooltip title="Delete Property" placement="right">
                            <IconButton
                                color="primary"
                                onClick={() => this.onRemoveProperty(i)}
                                className={this.props.classes.clearButton}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                ))}
                {this.state.data.length > 0 && (
                    <IconButton
                        color="primary"
                        onClick={() => this.onAddProperty()}
                    >
                        <AddBoxIcon />
                    </IconButton>
                )}
                <div className={this.props.classes.propertiesTitle}>
                    <Typography variant="h3">Constants</Typography>
                    {!this.state.constants.length && (
                        <IconButton
                            color="primary"
                            onClick={() => this.onAddConstant()}
                        >
                            <AddBoxIcon />
                        </IconButton>
                    )}
                </div>
                {this.state.constants.map(({ name, type }, i) => (
                    <Stack key={i} direction="row">
                        <TextField
                            label="Name"
                            value={name}
                            onChange={({ target }) => {
                                this.onChangeConstant(target.value, type, i);
                            }}
                            className={this.props.classes.propertyField}
                        />
                        <Autocomplete
                            value={type}
                            onChange={(_, newType) => {
                                this.onChangeConstant(name, newType, i);
                            }}
                            options={this.state.propertyTypes}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Type"
                                    className={this.props.classes.propertyField}
                                />
                            )}
                        />
                        <Tooltip title="Delete Constant" placement="right">
                            <IconButton
                                color="primary"
                                onClick={() => this.onRemoveConstant(i)}
                                className={this.props.classes.clearButton}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                ))}
                {this.state.constants.length > 0 && (
                    <IconButton
                        color="primary"
                        onClick={() => this.onAddConstant()}
                    >
                        <AddBoxIcon />
                    </IconButton>
                )}
                <div className={this.props.classes.propertiesTitle}>
                    <Typography variant="h3">Formulae</Typography>
                    {!this.state.formulae.length && (
                        <IconButton
                            color="primary"
                            onClick={() => this.onAddFormula()}
                        >
                            <AddBoxIcon />
                        </IconButton>
                    )}
                </div>
                {this.state.formulae.map(({ name, logic }, i) => (
                    <Stack key={i} direction="row" alignItems="center">
                        <TextField
                            label="Formula Name"
                            value={name}
                            onChange={({ target }) =>
                                this.onChangeFormula(target.value, logic, i)
                            }
                            className={this.props.classes.propertyField}
                        />
                        <TextField
                            label="Formula Logic"
                            value={logic}
                            onChange={({ target }) =>
                                this.onChangeFormula(name, target.value, i)
                            }
                            className={this.props.classes.propertyField}
                        />
                        <Typography>
                            {
                                this.state.formulae[
                                    this.state.formulae.findIndex(
                                        (obj) => obj.name === name
                                    )
                                ].logic
                            }
                        </Typography>
                        <Tooltip title="Delete Formula" placement="right">
                            <IconButton
                                color="primary"
                                onClick={() => this.onRemoveFormula(i)}
                                className={this.props.classes.clearButton}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                ))}
                {this.state.formulae.length > 0 && (
                    <IconButton
                        color="primary"
                        onClick={() => this.onAddFormula()}
                    >
                        <AddBoxIcon />
                    </IconButton>
                )}
                <Button
                    variant="secondary"
                    onClick={() => this.createTestConcept()}
                    disabled={this.state.isCreating || !this.state.data.length}
                    className={this.props.classes.createButton}
                >
                    Create
                </Button>

                {/** TODO(hannah): Fix styling. */}
                <MessageHelper
                    message={this.state.message}
                    errorMessage={this.state.errorMessage}
                    open={this.state.messageOpen}
                    setState={(a) => this.setState(a)}
                />
            </Stack>
        );
    }
}

export default withStyles(styles)(WithGoogleAuth(TestConceptCreator));
