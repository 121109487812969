import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Button,
    TextField,
    Typography,
    Stack,
    Select,
    MenuItem,
    InputAdornment,
    Link,
    Grid,
    Divider,
    IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import {
    AUTH_ADMINISTRATION,
    Action,
} from "@running-tide/rt-api-access-control/build/PermissionValidator/constants";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import { hasValidPermissions } from "../helper/PermissionChecker";

import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import { errorMessage, successMessage } from "../helper/MessageMethodHelper";
import MessageHelper from "../helper/MessageHelper";
import {
    ApprovalStatus,
    EstimateContext,
    QuantFormulaVar,
} from "../../models/enums/InterventionEnums";
import IconButtonWithTooltip from "../helper/IconButtonWithTooltip";
import ButtonWithTooltip from "../helper/ButtonWithTooltip";
import DeploymentEstimateEvidence from "./helpers/DeploymentEstimateEvidence";

// Amount of ms to wait before redirecting user after estimate deletion
const redirectTimeout = 3000;
function DeploymentEstimate(props) {
    const { authState, params, loggedInUser } = props;
    const navigate = useNavigate();

    const [deploymentTitle, setDeploymentTitle] = useState("");
    const [title, setTitle] = useState("");
    const [quantFormulaVar, setQuantFormulaVar] = useState(null);
    const [carbonBuoyBatchId, setCarbonBuoyBatchId] = useState("");
    const [co2e, setCo2e] = useState("");
    const [carbonBuoyBatches, setCarbonBuoyBatches] = useState([]);
    const [metadata, setMetadata] = useState("");
    const [estimate, setEstimate] = useState({});
    const [evidence, setEvidence] = useState([]);
    const [editing, setEditing] = useState(false);

    const [isSaving, setIsSaving] = useState(false);
    const [message, setMessage] = useState({});

    const aaUser = hasValidPermissions(loggedInUser, [
        `${AUTH_ADMINISTRATION}:${Action.DELETE}`,
        `${AUTH_ADMINISTRATION}:${Action.UPDATE}`,
    ]);

    useEffect(() => {
        const fetchDeploymentEstimateData = async () => {
            const apiClient = new CarbonAccountingAPIClient(authState);
            const [buoyBatches, deploymentInfo, estimate, evidence] =
                await Promise.all([
                    apiClient.getDeploymentSubstrates(params.deployment_id),
                    apiClient.getDeployment(params.deployment_id),
                    apiClient.getDeploymentEstimate(params.estimate_id),
                    apiClient.getDeploymentEstimateEvidence(params.estimate_id),
                ]);
            setEstimate(estimate);
            setEvidence(evidence);
            setCarbonBuoyBatches(buoyBatches);
            setDeploymentTitle(deploymentInfo.title);
        };
        fetchDeploymentEstimateData();
    }, [params.deployment_id, params.estimate_id]);

    useEffect(() => {
        resetEstimateFields();
    }, [estimate]);

    const reloadEvidence = async () => {
        const apiClient = new CarbonAccountingAPIClient(authState);
        const evidence = await apiClient.getDeploymentEstimateEvidence(
            params.estimate_id
        );
        setEvidence(evidence);
    };

    const resetEstimateFields = () => {
        setTitle(estimate.title);
        setQuantFormulaVar(estimate.quantFormulaVar);
        setCarbonBuoyBatchId(estimate.carbonBuoyBatchId ?? "");
        setCo2e(estimate.co2e);
        setMetadata(JSON.stringify(estimate.metadata));

        setEditing(false);
    };

    const saveEdits = async () => {
        setIsSaving(true);
        try {
            const apiClient = new CarbonAccountingAPIClient(authState);
            const estimate = await apiClient.updateDeploymentEstimate(
                params.estimate_id,
                title,
                EstimateContext.Final,
                co2e,
                quantFormulaVar,
                ApprovalStatus.Pending,
                carbonBuoyBatchId || undefined,
                metadata ? JSON.parse(metadata) : undefined
            );
            setMessage({ ...message, messageOpen: false });
            setEstimate(estimate);
        } catch (error) {
            setMessage(errorMessage(error));
        }
        setIsSaving(false);
    };

    const deleteEstimate = async () => {
        try {
            const apiClient = new CarbonAccountingAPIClient(authState);
            await apiClient.deleteDeploymentEstimate(params.estimate_id);
            setMessage(
                successMessage(
                    `Estimate ${estimate.title} deleted! Redirecting back to deployment details`
                )
            );
            setTimeout(
                () => navigate(`/cdr/deployments/${params.deployment_id}`),
                redirectTimeout
            );
        } catch (error) {
            setMessage(errorMessage(error));
        }
    };

    return (
        <Grid
            container
            columns={120}
            rowSpacing={3}
            justifyContent="space-between"
        >
            <Grid item xs={120}>
                <Grid item lg={57} xs={100}>
                    <Stack variant="tablelike">
                        <Typography variant="h3">
                            <Link
                                variant="h3"
                                href={`/cdr/deployments/${params.deployment_id}`}
                                underline="hover"
                            >
                                {deploymentTitle}
                            </Link>
                            {" - "}
                            {estimate.title}
                        </Typography>
                        {!editing && (
                            <IconButtonWithTooltip
                                id="edit-estimate"
                                tooltipText={
                                    aaUser
                                        ? "Edit Estimate"
                                        : "User not authorized"
                                }
                                color="primary"
                                onClick={() => setEditing(true)}
                                disabled={!aaUser}
                            >
                                <EditIcon />
                            </IconButtonWithTooltip>
                        )}
                    </Stack>
                </Grid>
            </Grid>
            <Grid item lg={57} xs={100}>
                <Stack variant="tablelike">
                    <Typography variant="body1_bold">Name</Typography>
                    {editing ? (
                        <TextField
                            id="name-textfield"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                        />
                    ) : (
                        <Typography
                            id="estimate-title"
                            color="secondaryUtility.main"
                        >
                            {title}
                        </Typography>
                    )}
                </Stack>
                <Divider />
                <Stack variant="tablelike">
                    <Typography variant="body1_bold">Impact Source</Typography>
                    {editing ? (
                        <Select
                            onChange={(event) =>
                                setQuantFormulaVar(event.target.value)
                            }
                            id="quantFormulaVar-select"
                            value={quantFormulaVar ?? ""}
                        >
                            {Object.keys(QuantFormulaVar).map((key) => (
                                <MenuItem
                                    key={key}
                                    value={QuantFormulaVar[key]}
                                >
                                    {key}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <Typography
                            id="estimate-quantFormulaVar"
                            color="secondaryUtility.main"
                        >
                            {Object.keys(QuantFormulaVar).find(
                                (key) =>
                                    QuantFormulaVar[key] === quantFormulaVar
                            )}
                        </Typography>
                    )}
                </Stack>
                <Divider />
                <Stack variant="tablelike">
                    <Typography variant="body1_bold">
                        Carbon Buoy Batch
                    </Typography>
                    {editing ? (
                        <Select
                            onChange={(event) =>
                                setCarbonBuoyBatchId(event.target.value)
                            }
                            id="carbonBuoyBatchId-select"
                            value={carbonBuoyBatchId ?? ""}
                            {...(carbonBuoyBatchId && {
                                IconComponent: () => (
                                    <IconButton
                                        size="small"
                                        color="inherit"
                                        onMouseDown={(event) => {
                                            event.stopPropagation();
                                        }}
                                        onClick={() => {
                                            setCarbonBuoyBatchId("");
                                        }}
                                    >
                                        <CloseRoundedIcon />
                                    </IconButton>
                                ),
                            })}
                        >
                            {carbonBuoyBatches.map((batch) => (
                                <MenuItem key={batch.title} value={batch.id}>
                                    {batch.title}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <Typography
                            id="estimate-carbonBuoyBatchId"
                            color="secondaryUtility.main"
                        >
                            {carbonBuoyBatches.find(
                                (batch) => batch.id === carbonBuoyBatchId
                            )?.title || "N/A"}
                        </Typography>
                    )}
                </Stack>
                <Divider />
                <Stack variant="tablelike">
                    <Typography variant="body1_bold">CO2e</Typography>{" "}
                    {/* TODO (nikhil) try for subscript 2 here */}
                    {editing ? (
                        <TextField
                            id="co2e-textfield"
                            value={co2e}
                            onChange={(event) => setCo2e(event.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        tonnes
                                    </InputAdornment>
                                ),
                            }}
                        />
                    ) : (
                        <Typography
                            id="estimate-co2e"
                            color="secondaryUtility.main"
                        >
                            {co2e}
                        </Typography>
                    )}
                </Stack>
                <Divider />
                <Stack variant="tablelike">
                    <Typography variant="body1_bold">Metadata</Typography>
                    {editing ? (
                        <TextField
                            placeholder={"{ ... }"}
                            helperText="Please enter a valid JSON object"
                            id="metadata-textfield"
                            label="Metadata"
                            multiline={true}
                            rows={3}
                            value={metadata}
                            onChange={(event) =>
                                setMetadata(event.target.value)
                            }
                        />
                    ) : (
                        <Typography
                            id="estimate-metadata"
                            color="secondaryUtility.main"
                        >
                            {metadata}
                        </Typography>
                    )}
                </Stack>
                <Divider />
                {editing ? (
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={3}
                        mt={2}
                    >
                        <Button
                            variant="string"
                            onClick={() => {
                                setMessage({ ...message, messageOpen: false });
                                resetEstimateFields();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            id="save-estimate"
                            variant="secondary"
                            onClick={() => {
                                saveEdits();
                            }}
                            disabled={isSaving}
                        >
                            Save
                        </Button>
                    </Stack>
                ) : (
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={3}
                        mt={2}
                    >
                        <ButtonWithTooltip
                            id="delete-estimate"
                            startIcon={<DeleteOutlinedIcon />}
                            tooltipText={aaUser ? "" : "User not authorized"}
                            color="error"
                            onClick={() => deleteEstimate()}
                            disabled={!aaUser}
                        >
                            Delete Estimate
                        </ButtonWithTooltip>
                    </Stack>
                )}
                <MessageHelper
                    message={message.message}
                    errorMessage={message.errorMessage}
                    open={message.messageOpen}
                    setState={(a) => setMessage(a)}
                />
            </Grid>
            <DeploymentEstimateEvidence
                evidence={evidence}
                reloadEvidence={reloadEvidence}
                message={message}
                setMessage={setMessage}
                authState={authState}
                aaUser={aaUser}
            />
        </Grid>
    );
}

export default WithGoogleAuth((props) => (
    <DeploymentEstimate {...props} params={useParams()} />
));
