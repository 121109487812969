import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";
import { WithGoogleAuth } from "../config/WithGoogleAuth";
import ShellfishInventoryAPIClient from "../models/ShellfishInventoryAPIClient";
import RTDisplayUtils from "@running-tide/rt-frontend-helpers/helpers";
import NumberFormat from "react-number-format";
import { Paper, Box, Link } from "@mui/material";
import RTable from "./components/RTable";

const styles = (theme) => ({
    root: {
        padding: 0,
        margin: 0,
        backgroundColor: theme.palette.background.component,
    },
    paper: {
        padding: theme.spacing(3),
        margin: theme.spacing(2),
        textAlign: "left",
        backgroundColor: theme.palette.background.default,
    },
    binLocation: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
    },
});

const tableOptions = {
    paging: false,
    search: false,
    maxColumnSort: 0,
};

let bi = WithGoogleAuth(
    class BinInformation extends Component {
        constructor(props) {
            super(props);
            this.state = {
                growOutTransfers: [],
                currentAllocations: [],
                counts: [],
                binName: "",
                oysterStream: { growOutTransfers: [] },
            };
        }

        componentDidMount() {
            console.log("Component mounted");
            new ShellfishInventoryAPIClient(this.props.authState)
                .getBinInformation(this.props.binId)
                .then((res) => res.json())
                .then((res) => {
                    console.log(res);
                    this.setState(res);
                });
        }

        render() {
            const { classes } = this.props;
            let latestGrowouts;
            if (this.state.growOutTransfers.length > 0) {
                latestGrowouts = this.state.growOutTransfers;
            } else if (this.state.oysterStream != null) {
                latestGrowouts = [
                    {
                        species: "",
                        spawnId: this.state.oysterStream.spawnId,
                        seedSource: "",
                    },
                ];
            } else {
                latestGrowouts = [{ species: "", spawnId: "", seedSource: "" }];
            }

            let latestGrades =
                this.state.currentAllocations.length > 0
                    ? this.state.currentAllocations
                          .map((ca) =>
                              RTDisplayUtils.prettyPrintGrade(ca.grade)
                          )
                          .join(", ")
                    : "None";
            let latestCounts =
                this.state.currentAllocations.length > 0
                    ? this.state.currentAllocations
                          .map((ca) => ca.count)
                          .reduce((a, b) => a + b)
                    : "None";
            let shellfishType = this.state.oysterStream
                ? RTDisplayUtils.printShellfishType(
                      this.state.oysterStream.shellfishType
                  )
                : "";
            return (
                <Grid container className={classes.root} spacing={4}>
                    <Grid item xs={6}>
                        <Paper
                            variant="outlined"
                            className={classes.binLocation}
                        >
                            <Box style={{ display: "flex" }}>
                                <img
                                    width={100}
                                    height={100}
                                    src={
                                        "/images/reef/bin-" +
                                        this.props.binOrder +
                                        ".jpg"
                                    }
                                    alt="reef"
                                    style={{
                                        marginRight: "1rem",
                                        alignSelf: "center",
                                    }}
                                ></img>
                                <Box>
                                    <div>
                                        <Typography variant="body1">
                                            <b>Shellfish Type: </b>
                                            {shellfishType}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body1">
                                            <b>Species: </b>
                                            {Array.from(
                                                new Set(
                                                    latestGrowouts.map(
                                                        (growout) =>
                                                            growout.species
                                                    )
                                                )
                                            ).join(", ")}
                                        </Typography>
                                    </div>
                                    <div></div>
                                    <Typography variant="body1">
                                        <b>Spawn Id: </b>
                                        {Array.from(
                                            new Set(
                                                latestGrowouts.map(
                                                    (growout) => growout.spawnId
                                                )
                                            )
                                        ).join(", ")}
                                    </Typography>
                                    <div>
                                        <Typography variant="body1">
                                            <b>Seed Source: </b>
                                            {Array.from(
                                                new Set(
                                                    latestGrowouts.map(
                                                        (growout) =>
                                                            growout.seedSource
                                                    )
                                                )
                                            ).join(", ")}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body1">
                                            <b>Bin Id: </b>
                                            {this.props.binId}
                                        </Typography>
                                    </div>
                                </Box>
                                <Box>
                                    <div>
                                        <Typography variant="body1">
                                            <b>Grade: </b>
                                            {latestGrades}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body1">
                                            <b>Count: </b>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                displayType={"text"}
                                                value={latestCounts}
                                            />
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body1">
                                            <b>Bin Id: </b>
                                            {this.props.binId}
                                        </Typography>
                                    </div>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    {this.state.counts.length > 0 && (
                        <Grid item xs={6}>
                            <Paper variant="outlined" className={classes.paper}>
                                <Typography variant="body1">
                                    <i>
                                        NOTE: This is all counts that have ever
                                        happened for this bin. This is not
                                        reflective of the current values of the
                                        bin
                                    </i>
                                </Typography>
                                <RTable
                                    title={
                                        <Typography variant="h3">
                                            All Historical Counts
                                        </Typography>
                                    }
                                    columns={[
                                        {
                                            title: "Grade",
                                            render: (rowData) =>
                                                RTDisplayUtils.prettyPrintGrade(
                                                    rowData.grade
                                                ),
                                        },
                                        {
                                            title: "Measurement Type",
                                            render: (rowData) =>
                                                rowData.measurementType.name ||
                                                "",
                                        },
                                        {
                                            title: "Count",
                                            render: (rowData) => (
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    displayType={"text"}
                                                    value={rowData.count}
                                                />
                                            ),
                                        },
                                        {
                                            title: "Occurance Date",
                                            render: (rowData) =>
                                                RTDisplayUtils.printDate(
                                                    rowData.occuranceDate
                                                ),
                                        },

                                        {
                                            title: "Reporter",
                                            field: "reporter.displayName",
                                        },
                                        {
                                            title: "Reported Date",
                                            render: (rowData) =>
                                                RTDisplayUtils.printDate(
                                                    rowData.reportedDate
                                                ),
                                        },
                                        {
                                            title: "Photo Session",
                                            render: (rowData) => {
                                                const { photoSessionIds } =
                                                    rowData;
                                                if (photoSessionIds == null) {
                                                    return null;
                                                }
                                                return photoSessionIds.map(
                                                    (sessionId) => (
                                                        <Link
                                                            key={sessionId}
                                                            href={`/photo-session/${sessionId}`}
                                                        >
                                                            {sessionId}
                                                        </Link>
                                                    )
                                                );
                                            },
                                        },
                                    ]}
                                    data={this.state.counts}
                                    options={tableOptions}
                                />
                            </Paper>
                        </Grid>
                    )}
                    {this.state.oysterStream !== null &&
                        this.state.growOutTransfers.length > 0 && (
                            <Grid item xs={12}>
                                <Paper
                                    variant="outlined"
                                    className={classes.paper}
                                >
                                    <RTable
                                        title={
                                            <Typography variant="h3">
                                                Seed Addition
                                            </Typography>
                                        }
                                        columns={[
                                            {
                                                title: "Species",
                                                field: "species",
                                            },
                                            {
                                                title: "Seed Source",
                                                field: "seedSource",
                                            },
                                            {
                                                title: "Spawn Id",
                                                field: "spawnId",
                                            },
                                            {
                                                title: "Starting Size",
                                                render: (rowData) =>
                                                    RTDisplayUtils.printOysterSizeRange(
                                                        rowData.startingSeedSizeLower,
                                                        rowData.startingSeedSizeUpper
                                                    ),
                                            },
                                            {
                                                title: "Brood Stock",
                                                field: "broodStock",
                                            },
                                            {
                                                title: "Count",
                                                render: (rowData) => (
                                                    <NumberFormat
                                                        thousandSeparator={true}
                                                        displayType={"text"}
                                                        value={rowData.count}
                                                    />
                                                ),
                                            },
                                            {
                                                title: "Date",
                                                render: (rowData) =>
                                                    RTDisplayUtils.printDate(
                                                        rowData.occuranceDate
                                                    ),
                                            },
                                            {
                                                title: "Reporter",
                                                field: "reporter.displayName",
                                            },
                                        ]}
                                        data={this.state.growOutTransfers}
                                        options={tableOptions}
                                    />
                                </Paper>
                            </Grid>
                        )}
                </Grid>
            );
        }
    }
);
export default withStyles(styles)(bi);
