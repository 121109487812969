import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { Button, Stack, Typography } from "@mui/material";
import moment from "moment-timezone";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

const styles = () => ({
    lastUpdated: {
        display: "inline-block",
        fontSize: ".7rem",
        paddingTop: 10,
        paddingBottom: 10,
        width: 125,
    },
    datePicker: {
        width: 266,
    },
});

class GraphDateRangePickerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeButton: props.initialActiveButton || "all",
            lastUpdated: moment(),
            dateRangeStartDate: props.dateRange.from,
            dateRangeEndDate: props.dateRange.to,
            now: moment(),
        };

        this.chartRef = React.createRef();
    }

    componentDidMount() {
        this.currentTimeInterval = setInterval(
            this.updateCurrentTime.bind(this),
            1 * 1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.currentTimeInterval);
    }

    updateCurrentTime() {
        this.setState({ now: moment() });
    }

    loadNewTimerange(timerange) {
        let startDate = moment(),
            endDate = moment();
        switch (timerange) {
            case "year":
                startDate = startDate.subtract(1, "years");
                break;
            case "month":
                startDate = startDate.subtract(1, "months");
                break;
            case "week":
                startDate = startDate.subtract(1, "weeks");
                break;
            case "day":
                startDate = startDate.subtract(1, "days");
                break;
            case "all":
            default:
                startDate = moment("01/01/2010");
        }
        this.setState({
            activeButton: timerange,
            dateRangeStartDate: startDate,
            dateRangeEndDate: endDate,
        });
        this.loadNew(startDate, endDate);
    }

    loadNewDateRange([from, to]) {
        from = moment(from);
        to = moment(to);
        this.setState({
            activeButton: null,
            dateRangeStartDate: from,
            dateRangeEndDate: to,
        });
        this.loadNew(from, to);
    }

    async loadNew(startDate, endDate) {
        await this.props.update(startDate, endDate);
    }

    getButtonVariant(timeRange) {
        /* TODO These are default MUI variants, the current goal is
            to be using RT defined primary/secondary/tertiary
            but while "contained" == "secondary" 
            there is no current equivelant to "outlined" */
        return this.state.activeButton === timeRange ? "contained" : "outlined";
    }

    render() {
        const { classes } = this.props;
        return (
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                flexWrap="wrap"
                marginLeft={2}
                marginRight={2}
                marginBottom={1}
            >
                <Stack direction="row" spacing={0}>
                    <Button
                        size="small"
                        variant={this.getButtonVariant("year")}
                        onClick={() => this.loadNewTimerange("year")}
                    >
                        Year
                    </Button>
                    <Button
                        size="small"
                        variant={this.getButtonVariant("month")}
                        onClick={() => this.loadNewTimerange("month")}
                    >
                        Month
                    </Button>
                    <Button
                        size="small"
                        variant={this.getButtonVariant("week")}
                        onClick={() => this.loadNewTimerange("week")}
                    >
                        Week
                    </Button>
                    <Button
                        size="small"
                        variant={this.getButtonVariant("day")}
                        onClick={() => this.loadNewTimerange("day")}
                    >
                        Day
                    </Button>
                    <Button
                        size="small"
                        variant={this.getButtonVariant("all")}
                        onClick={() => this.loadNewTimerange("all")}
                    >
                        All
                    </Button>
                </Stack>
                <DateRangePicker
                    onChange={(range) => this.loadNewDateRange(range)}
                    value={[
                        this.state.dateRangeStartDate.toDate(),
                        this.state.dateRangeEndDate.toDate(),
                    ]}
                    className={classes.datePicker}
                />
                <Typography className={classes.lastUpdated} variant="body1">
                    last updated:{" "}
                    {Math.ceil(
                        moment
                            .duration(
                                this.state.now.diff(this.state.lastUpdated)
                            )
                            .asSeconds()
                    )}
                    s ago
                </Typography>
            </Stack>
        );
    }
}

export default withStyles(styles)(GraphDateRangePickerComponent);
