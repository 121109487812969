import React, { Component } from "react";
import { useParams } from "react-router-dom";
import {
    Button,
    TextField,
    Typography,
    Grid,
    Stack,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormGroup,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import APIClient from "../../models/APIClient";
import MessageHelper from "../helper/MessageHelper";
import { DeploymentEventEnum } from "@running-tide/farm-system/models/enums";
import {
    errorMessage,
    successMessage,
    clearMessage,
} from "../helper/MessageMethodHelper";
import {
    DeploymentEvents,
    updateEventsWithDetail,
} from "./helpers/DeploymentEvents";
import {
    CARBON_SEQUESTRATION,
    Action,
} from "@running-tide/rt-api-access-control/build/PermissionValidator/constants";
import cleanDeploymentInfo from "./helpers/cleanDeploymentInfo";

const styles = (theme) => ({
    formControl: {
        minWidth: 200,
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(3),
    },
    overheadLabel: {
        minWidth: 200,
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
    createButton: {
        marginTop: theme.spacing(1),
    },
});

class DeploymentEventCreator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deploymentName: "",
            selectedTrajectoryBuoys: [],
            selectedVerificationBuoys: [],
            trajectoryBuoysWithoutEvents: [],
            verificationBuoysWithoutEvents: [],
            eventType: 0,
            trajectoryBuoyPlacementDate: "",
            selectedSubstrate: "",
            isCreating: false,
            events: [],
            buoysMap: {},
        };
        this.eventsUpdated = this.eventsUpdated.bind(this);
    }

    async componentDidMount() {
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        const cloudApi = new APIClient(this.props.authState);

        const [deploymentInfo, eventList, substrateList] = await Promise.all([
            apiClient.getDeployment(this.props.params.deployment_id),
            apiClient.getDeploymentEvents(this.props.params.deployment_id),
            apiClient.getDeploymentSubstrates(this.props.params.deployment_id),
        ]);
        cleanDeploymentInfo(deploymentInfo);

        const deploymentVerificationBuoys = await cloudApi.getThingAll(
            deploymentInfo.buoySelectorLabel,
            null,
            null,
            null,
            "GPS Point"
        );

        const trajectoryBuoys = deploymentVerificationBuoys.filter((buoy) =>
            buoy.selectorLabels.includes("trajectory-buoy")
        );
        const verificationBuoys = deploymentVerificationBuoys.filter(
            (buoy) => !buoy.selectorLabels.includes("trajectory-buoy")
        );

        let buoysMap = new Map();
        trajectoryBuoys.forEach((buoy) => {
            buoysMap.set(buoy.id, {
                id: buoy.id,
                name: buoy.name,
            });
        });

        verificationBuoys.forEach((buoy) => {
            buoysMap.set(buoy.id, {
                id: buoy.id,
                name: buoy.name,
            });
        });
        const deployedVerificationBuoys = eventList
            .filter((e) => {
                return (
                    e.eventType ===
                        DeploymentEventEnum.VERIFICATION_BUOY_DEPLOYMENT ||
                    e.eventType ===
                        DeploymentEventEnum.TRAJECTORY_BUOY_DEPLOYMENT
                );
            })
            .map((e) => e.instrumentId);

        const trajectoryBuoysWithoutEvents = trajectoryBuoys
            .filter((buoy) => !deployedVerificationBuoys.includes(buoy.id))
            .sort((a, b) => a.name.localeCompare(b.name));

        const verificationBuoysWithoutEvents = verificationBuoys
            .filter((buoy) => !deployedVerificationBuoys.includes(buoy.id))
            .sort((a, b) => a.name.localeCompare(b.name));

        const substratesMap = new Map();
        const substrates = await Promise.all(
            substrateList.map(async (substrate) => {
                const { productGroup } = await apiClient.getProductGroupInfo(
                    substrate.substrateProductGroupId
                );
                substratesMap.set(substrate.id, {
                    name: productGroup.name,
                    id: substrate.id,
                    productGroupId: substrate.substrateProductGroupId,
                });
                return {
                    name: productGroup.name,
                    productGroupId: substrate.substrateProductGroupId,
                    id: substrate.id,
                };
            })
        );
        const events = updateEventsWithDetail(
            eventList,
            buoysMap,
            substratesMap
        );

        this.setState({
            deploymentVerificationBuoys,
            substrates,
            deploymentInfo,
            trajectoryBuoysWithoutEvents,
            verificationBuoysWithoutEvents,
            events,
            buoysMap,
            substratesMap,
        });
    }

    updateUsedEventTypes(events) {
        let trajectoryBuoysWithoutEvents =
            this.state.trajectoryBuoysWithoutEvents;
        let verificationBuoysWithoutEvents =
            this.state.verificationBuoysWithoutEvents;
        for (const i in events) {
            if (
                events[i].eventType ===
                DeploymentEventEnum.TRAJECTORY_BUOY_DEPLOYMENT
            ) {
                trajectoryBuoysWithoutEvents =
                    trajectoryBuoysWithoutEvents.filter(
                        (buoy) => buoy.id !== events[i].instrumentId
                    );
            } else if (
                events[i].eventType ===
                DeploymentEventEnum.VERIFICATION_BUOY_DEPLOYMENT
            ) {
                verificationBuoysWithoutEvents =
                    verificationBuoysWithoutEvents.filter(
                        (buoy) => buoy.id !== events[i].instrumentId
                    );
            }
        }

        this.setState({
            trajectoryBuoysWithoutEvents,
            verificationBuoysWithoutEvents,
        });
    }

    eventsUpdated(events) {
        this.setState({ events });
    }

    async createDeploymentEvent() {
        this.setState({ isCreating: true });

        let deploymentEventPayload = {
            eventType: this.state.eventType,
            occurrenceDate: this.state.occurrenceDate,
        };

        if (
            this.state.eventType ===
                DeploymentEventEnum.SUBSTRATE_DEPLOYMENT_START ||
            this.state.eventType ===
                DeploymentEventEnum.SUBSTRATE_DEPLOYMENT_STOP
        ) {
            deploymentEventPayload.lat = this.state.lat;
            deploymentEventPayload.long = this.state.long;
            deploymentEventPayload.buoyIds = [this.state.selectedSubstrate];
        } else if (
            this.state.eventType ===
            DeploymentEventEnum.TRAJECTORY_BUOY_DEPLOYMENT
        ) {
            deploymentEventPayload.lat = this.state.lat;
            deploymentEventPayload.long = this.state.long;
            deploymentEventPayload.buoyIds = this.state.selectedTrajectoryBuoys;
        } else if (
            this.state.eventType ===
            DeploymentEventEnum.VERIFICATION_BUOY_DEPLOYMENT
        ) {
            deploymentEventPayload.lat = this.state.lat;
            deploymentEventPayload.long = this.state.long;
            deploymentEventPayload.buoyIds =
                this.state.selectedVerificationBuoys;
        }

        const apiClient = new CarbonAccountingAPIClient(this.props.authState);
        try {
            let newEvents = await apiClient.createDeploymentEvents(
                deploymentEventPayload,
                this.props.params.deployment_id
            );

            newEvents = updateEventsWithDetail(
                newEvents,
                this.state.buoysMap,
                this.state.substratesMap
            );

            let events = this.state.events.concat(newEvents);
            this.updateUsedEventTypes(newEvents);
            this.setState({
                ...successMessage(`Created ${newEvents.length} event(s)`),
                events,
                selectedTrajectoryBuoys: [],
                selectedVerificationBuoys: [],
                selectedSubstrate: "",
            });
            setTimeout(() => this.setState(clearMessage()), 2000);
        } catch (error) {
            this.setState({
                ...errorMessage(error),
                isCreating: false,
            });
            setTimeout(() => this.setState(clearMessage()), 5000);
        }
    }

    isCreateEnabled() {
        if (this.state.isCreating) return false;

        if (
            this.state.eventType === DeploymentEventEnum.ARRIVAL ||
            this.state.eventType === DeploymentEventEnum.DEPARTURE
        )
            return !this.state.occurrenceDate;
        else if (
            this.state.eventType ===
                DeploymentEventEnum.SUBSTRATE_DEPLOYMENT_START ||
            this.state.eventType ===
                DeploymentEventEnum.SUBSTRATE_DEPLOYMENT_STOP
        )
            return (
                !this.state.occurrenceDate ||
                !this.state.selectedSubstrate ||
                !this.state.lat ||
                !this.state.long
            );
        else if (
            this.state.eventType ===
            DeploymentEventEnum.TRAJECTORY_BUOY_DEPLOYMENT
        )
            return (
                !this.state.occurrenceDate ||
                this.state.selectedTrajectoryBuoys.length === 0 ||
                !this.state.lat ||
                !this.state.long
            );
        else if (
            this.state.eventType ===
            DeploymentEventEnum.VERIFICATION_BUOY_DEPLOYMENT
        )
            return (
                !this.state.occurrenceDate ||
                this.state.selectedVerificationBuoys.length === 0 ||
                !this.state.lat ||
                !this.state.long
            );
        else return false;
    }

    displayLatLongFields() {
        return (
            <FormGroup>
                <TextField
                    label="Latitude"
                    type="number"
                    value={this.state.lat}
                    onChange={(event) => {
                        this.setState({
                            lat: event.target.value,
                        });
                    }}
                    InputLabelProps={{
                        shrink: false,
                        variant: "overhead",
                    }}
                    className={this.props.classes.overheadLabel}
                />
                <TextField
                    label="Longitude"
                    type="number"
                    value={this.state.long}
                    onChange={(event) => {
                        this.setState({
                            long: event.target.value,
                        });
                    }}
                    InputLabelProps={{
                        shrink: false,
                        variant: "overhead",
                    }}
                    className={this.props.classes.overheadLabel}
                />
            </FormGroup>
        );
    }

    displayoccurrenceDateField(label) {
        return (
            <TextField
                label={label}
                type="datetime-local"
                value={this.state.occurrenceDate}
                onChange={(event) => {
                    this.setState({
                        occurrenceDate: event.target.value,
                    });
                }}
                InputLabelProps={{
                    shrink: false,
                    variant: "overhead",
                }}
                className={this.props.classes.overheadLabel}
            />
        );
    }

    displayDepartureFields() {
        return this.displayoccurrenceDateField("Departure Date");
    }

    displayArrivalFields() {
        return this.displayoccurrenceDateField("Arrival Date");
    }

    displaySubstrateDeploymentFields() {
        return (
            <FormGroup>
                <FormControl className={this.props.classes.formControl}>
                    <InputLabel id="label-select-substrateTypeId">
                        Substrate Type
                    </InputLabel>
                    <Select
                        onChange={(event) =>
                            this.setState({
                                selectedSubstrate: event.target.value,
                            })
                        }
                        id="eventType-select"
                        value={this.state.selectedSubstrate}
                    >
                        {this.state.substrates.map((substrate) => (
                            <MenuItem key={substrate.id} value={substrate.id}>
                                {substrate.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {this.displayoccurrenceDateField("Placement Date (UTC)")}
                {this.displayLatLongFields()}
            </FormGroup>
        );
    }

    displayVerificationBuoyFields() {
        return (
            <FormGroup>
                <FormControl className={this.props.classes.formControl}>
                    <InputLabel id="label-select-verification-buoys">
                        Verification Buoys
                    </InputLabel>
                    <Select
                        onChange={(event) =>
                            this.setState({
                                selectedVerificationBuoys: event.target.value,
                            })
                        }
                        id="eventType-select"
                        value={this.state.selectedVerificationBuoys}
                        multiple
                    >
                        {this.state.verificationBuoysWithoutEvents.map(
                            (buoy) => (
                                <MenuItem key={buoy.id} value={buoy.id}>
                                    {buoy.name}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
                {this.displayoccurrenceDateField("Placement Date (UTC)")}
                {this.displayLatLongFields()}
            </FormGroup>
        );
    }

    displayTrajectoryBuoyFields() {
        return (
            <FormGroup>
                <FormControl className={this.props.classes.formControl}>
                    <InputLabel id="label-select-trajectory-buoys">
                        Trajectory Buoys
                    </InputLabel>
                    <Select
                        onChange={(event) =>
                            this.setState({
                                selectedTrajectoryBuoys: event.target.value,
                            })
                        }
                        id="eventType-select"
                        value={this.state.selectedTrajectoryBuoys}
                        multiple
                    >
                        {this.state.trajectoryBuoysWithoutEvents.map((buoy) => (
                            <MenuItem key={buoy.id} value={buoy.id}>
                                {buoy.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {this.displayoccurrenceDateField("Placement Date (UTC)")}
                {this.displayLatLongFields()}
            </FormGroup>
        );
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h3">
                        Create Deployment Event
                    </Typography>
                    <Stack justifyContent="flex-start" alignItems="flex-start">
                        <FormGroup>
                            <FormControl
                                className={this.props.classes.formControl}
                            >
                                <InputLabel id="label-select-event-type">
                                    Event Type
                                </InputLabel>
                                <Select
                                    onChange={(event) =>
                                        this.setState({
                                            eventType: event.target.value,
                                        })
                                    }
                                    id="eventType-select"
                                    value={this.state.eventType}
                                >
                                    {Object.keys(DeploymentEventEnum).map(
                                        (key) => (
                                            <MenuItem
                                                key={key}
                                                value={DeploymentEventEnum[key]}
                                            >
                                                {key}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                            <FormControl>
                                {this.state.eventType ===
                                    DeploymentEventEnum.ARRIVAL &&
                                    this.displayArrivalFields()}
                                {this.state.eventType ===
                                    DeploymentEventEnum.DEPARTURE &&
                                    this.displayDepartureFields()}
                                {this.state.eventType ===
                                    DeploymentEventEnum.SUBSTRATE_DEPLOYMENT_START &&
                                    this.displaySubstrateDeploymentFields()}
                                {this.state.eventType ===
                                    DeploymentEventEnum.SUBSTRATE_DEPLOYMENT_STOP &&
                                    this.displaySubstrateDeploymentFields()}
                                {this.state.eventType ===
                                    DeploymentEventEnum.TRAJECTORY_BUOY_DEPLOYMENT &&
                                    this.displayTrajectoryBuoyFields()}
                                {this.state.eventType ===
                                    DeploymentEventEnum.VERIFICATION_BUOY_DEPLOYMENT &&
                                    this.displayVerificationBuoyFields()}
                            </FormControl>
                        </FormGroup>
                        <Button
                            variant="secondary"
                            onClick={() => this.createDeploymentEvent()}
                            disabled={this.isCreateEnabled()}
                            className={this.props.classes.createButton}
                        >
                            Create
                        </Button>
                    </Stack>
                    <DeploymentEvents
                        deploymentInfo={this.state.deploymentInfo}
                        events={this.state.events}
                        editing={true}
                        eventsUpdate={this.eventsUpdated}
                    />
                </Grid>

                <MessageHelper
                    message={this.state.message}
                    errorMessage={this.state.errorMessage}
                    open={this.state.messageOpen}
                    setState={(a) => this.setState(a)}
                />
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedInUser: state.data.loggedInUser,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withStyles(styles)(
        WithGoogleAuth(
            (props) => (
                <DeploymentEventCreator {...props} params={useParams()} />
            ),
            [
                `${CARBON_SEQUESTRATION}:${Action.CREATE}`,
                `${CARBON_SEQUESTRATION}:${Action.UPDATE}`,
            ]
        )
    )
);
