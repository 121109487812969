/**
 * Combination Tooltip / Button that allows for
 * tooltip display even when button is disabled
 *
 * Custom Props
 *
 * Name               | Type      | Default
 *
 * tooltipText        | string    | ''
 * disabled           | bool      | false
 */

import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Button, Tooltip } from "@mui/material";

const ButtonWithTooltip = ({
    tooltipText = "",
    disabled = false,
    href,
    onClick,
    ...other
}) => {
    const PointerButton = withStyles({
        root: {
            "&.Mui-disabled": {
                pointerEvents: "auto",
            },
        },
    })(Button);
    const adjustedButtonProps = {
        disabled: disabled,
        component: disabled ? "div" : undefined,
        href: disabled ? undefined : href,
        onClick: disabled ? undefined : onClick,
    };
    return (
        <Tooltip placement="right" title={tooltipText}>
            <PointerButton {...other} {...adjustedButtonProps} />
        </Tooltip>
    );
};

export default ButtonWithTooltip;
