/**
 * Wrapper around material-table that gives us more control over
 * styling & default props. Import & use as you would MaterialTable
 *
 * Custom Props
 *
 * Name               | Type      | Default
 *
 * disableAltRowColor | bool      | false
 *
 */

import React from "react";
import MaterialTable, { MTableEditRow } from "@material-table/core";
import { makeStyles, useTheme } from "@mui/styles";

const useStyles = makeStyles({
    editRowBorderless: {
        borderBottomStyle: "none !important",
    },
});

function RTable(props) {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <MaterialTable
            {...props}
            components={{
                EditRow: (props) => (
                    <MTableEditRow
                        {...props}
                        className={classes.editRowBorderless}
                    />
                ),
                ...props.components,
            }}
            options={{
                rowStyle: (_, index) =>
                    !props.disableAltRowColor &&
                    (index % 2 === 0
                        ? {}
                        : {
                              backgroundColor: theme.palette.altRow,
                          }),
                ...props.options,
            }}
        />
    );
}

export default RTable;
