import React, { Component } from "react";
import { useParams } from "react-router-dom";
import { Grid, Stack, Link, TextField } from "@mui/material";
import RTable from "../components/RTable";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";

import MessageHelper from "../helper/MessageHelper";
import { errorMessage, successMessage } from "../helper/MessageMethodHelper";
import CarbonAccountingAPIClient from "../../models/CarbonAccountingAPIClient";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";

const styles = (theme) => ({
    title: {
        marginBottom: theme.spacing(2),
    },
    selectWidth: {
        minWidth: 190,
    },
});

class TestInstanceDetails extends Component {
    state = {
        id: "",
        testConcept: {},
        testData: [],
        constants: [],
        data: [],
        calculatedData: [],
        formulae: [],
    };

    // returns data and calculated data grouped by timestamp
    getTimeGroupedData(data, calculatedData) {
        const uniqueTimestamps = [
            ...new Set([
                ...data.map((data) => data.timestamp),
                ...calculatedData.map((data) => data.timestamp),
            ]),
        ];

        const dataGroupings = uniqueTimestamps.map((timestamp) => [
            ...data.filter((val) => val.timestamp === timestamp),
            ...calculatedData.filter((val) => val.timestamp === timestamp),
        ]);

        const dataColumns = [];
        for (const timestampGroup of dataGroupings) {
            const timestamp = timestampGroup[0].timestamp;
            const groupObj = { timestamp: timestamp };
            for (const dataInGroup of timestampGroup) {
                groupObj[dataInGroup.name] = dataInGroup.value;
            }
            dataColumns.push(groupObj);
        }
        return dataColumns;
    }

    // returns all column headers
    getColumnHeaders(testConcept, formulae) {
        const headers = [
            {
                title: "Timestamp",
                field: "timestamp",
                initialEditValue: "",
                render: (rowData) => new Date(rowData.timestamp).toISOString(),
                editComponent: (props) => (
                    <TextField
                        type="datetime-local"
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                    />
                ),
            },
            ...Object.keys(testConcept.data).map((name) => {
                return {
                    title: name,
                    field: name,
                    initialEditValue: "",
                    editComponent: (props) => (
                        <TextField
                            value={props.value}
                            onChange={(e) => props.onChange(e.target.value)}
                        />
                    ),
                };
            }),
            ...formulae.map((formula) => {
                return {
                    title: formula.name,
                    field: formula.name,
                    editable: false,
                };
            }),
        ];
        return headers;
    }

    async componentDidMount() {
        // Fetch information associated with this test instance
        const apiClient = new CarbonAccountingAPIClient(this.props.authState);

        const {
            id,
            testConceptId,
            productInstanceId,
            startDate,
            endDate,
            data,
            calculatedData,
            constants,
            formulae,
        } = await apiClient.getTestInstance(this.props.params.test_instance_id);
        const { testConcept } = await apiClient.getTestConcept(testConceptId);

        const timeGroupedData = this.getTimeGroupedData(data, calculatedData);
        const headers = this.getColumnHeaders(testConcept, formulae);

        this.setState({
            id,
            testConcept,
            testData: [
                {
                    key: "Product Instance",
                    value: (
                        <Link href={`/product/${productInstanceId}`}>
                            Details
                        </Link>
                    ),
                },
                { key: "Start Date", value: startDate },
                { key: "Completion Date", value: endDate },
            ],
            data,
            calculatedData,
            constants,
            formulae,
            headers,
            timeGroupedData,
        });
    }

    render() {
        return (
            <Grid container rowSpacing={2}>
                <Grid item xs={9}>
                    <Stack>
                        <Typography
                            variant="h3"
                            className={this.props.classes.title}
                        >
                            Test Instance
                        </Typography>
                        <Stack direction={"row"}>
                            <Typography variant="body1_bold">
                                Concept:{" "}
                                <Link
                                    href={`/test/concept/${this.state.testConcept.id}`}
                                >
                                    {this.state.testConcept.name}
                                </Link>
                            </Typography>
                        </Stack>
                        <Typography variant="body1">
                            {this.state.testConcept.description}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item lg={4}>
                    <RTable
                        disableAltRowColor
                        title={<Typography variant="h3">Test Data</Typography>}
                        columns={[
                            { field: "key", editable: "never" },
                            {
                                field: "value",
                                editComponent: (props) => (
                                    <TextField
                                        type="date"
                                        value={props.value}
                                        onChange={(e) =>
                                            props.onChange(e.target.value)
                                        }
                                    />
                                ),
                            },
                        ]}
                        data={this.state.testData}
                        options={{
                            header: false,
                            paging: false,
                            search: false,
                            maxColumnSort: 0,
                            actionsColumnIndex: -1,
                        }}
                        editable={{
                            isEditable: (rowData) =>
                                rowData.key === "Completion Date", // only end date allowed
                            isEditHidden: (rowData) =>
                                rowData.key !== "Completion Date",
                            isDeleteHidden: () => true,
                            onRowUpdate: async (newData, oldData) => {
                                const apiClient = new CarbonAccountingAPIClient(
                                    this.props.authState
                                );
                                return await apiClient
                                    .updateTestInstance(
                                        this.state.id,
                                        newData.value,
                                        []
                                    )
                                    .then(
                                        () => {
                                            let testData = this.state.testData;
                                            testData[
                                                oldData.tableData.index
                                            ].value = newData.value;
                                            this.setState({
                                                ...successMessage(
                                                    `End date updated`
                                                ),
                                                testData,
                                            });
                                        },
                                        (error) => {
                                            this.setState({
                                                ...errorMessage(error),
                                            });
                                        }
                                    );
                            },
                        }}
                    />
                </Grid>
                <Grid item lg={4}>
                    <RTable
                        disableAltRowColor
                        title={<Typography variant="h3">Constants</Typography>}
                        columns={[{ field: "name" }, { field: "value" }]}
                        data={this.state.constants}
                        options={{
                            header: false,
                            paging: Object.keys(this.state.constants) > 5,
                            search: false,
                            maxColumnSort: 0,
                        }}
                    />
                </Grid>
                <Grid item lg={4}>
                    <RTable
                        disableAltRowColor
                        title={<Typography variant="h3">Formulae</Typography>}
                        columns={[{ field: "name" }, { field: "logic" }]}
                        data={this.state.formulae}
                        options={{
                            header: false,
                            paging: Object.keys(this.state.constants) > 5,
                            search: false,
                            maxColumnSort: 0,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RTable
                        title={<Typography variant="h3">Data</Typography>}
                        columns={this.state.headers}
                        data={this.state.timeGroupedData}
                        options={{
                            paging: false,
                            search: false,
                            filtering: true,
                            addRowPosition: "first",
                            actionsColumnIndex: -1,
                        }}
                        editable={{
                            isEditHidden: () => true,
                            isDeleteHidden: () => true,
                            onRowAdd: async (newData) => {
                                const apiClient = new CarbonAccountingAPIClient(
                                    this.props.authState
                                );
                                const datapointNames = Object.keys(
                                    newData
                                ).filter(
                                    (key) => key !== "timestamp" && newData[key]
                                );
                                const testDatums = datapointNames.map(
                                    (label) => {
                                        return {
                                            timestamp: newData.timestamp,
                                            name: label,
                                            value: newData[label],
                                        };
                                    }
                                );
                                try {
                                    return await apiClient
                                        .updateTestInstance(
                                            this.state.id,
                                            null,
                                            testDatums
                                        )
                                        .then(
                                            () => {
                                                this.setState({
                                                    ...successMessage(
                                                        `Data added`
                                                    ),
                                                    data: [
                                                        ...this.state.data,
                                                        newData,
                                                    ],
                                                });
                                            },
                                            (error) => {
                                                this.setState({
                                                    ...errorMessage(error),
                                                });
                                            }
                                        );
                                } catch (e) {
                                    this.setState({
                                        ...errorMessage(e),
                                    });
                                }
                            },
                        }}
                    />
                </Grid>
                <MessageHelper
                    message={this.state.message}
                    errorMessage={this.state.errorMessage}
                    open={this.state.messageOpen}
                    setState={(a) => this.setState(a)}
                />
            </Grid>
        );
    }
}

export default withStyles(styles)(
    WithGoogleAuth((props) => (
        <TestInstanceDetails {...props} params={useParams()} />
    ))
);
