import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import RTable from "./components/RTable";
import { Link, Typography } from "@mui/material";

let HatcheryGraphs = class HatcheryGraphs extends Component {
    render() {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <RTable
                        title={
                            <Typography variant="h3">Hatchery Tanks</Typography>
                        }
                        columns={[
                            {
                                title: "Tank Name",
                                field1: "tankName",
                                field2: "url",
                                render: (rowData) => (
                                    <Link href={rowData.url}>
                                        {rowData.tankName}
                                    </Link>
                                ),
                            },
                            {
                                title: "Fluorometer Name",
                                field: "fluoroName",
                            },
                        ]}
                        data={[
                            {
                                tankName: "S3",
                                fluoroName: "RT3.1",
                                url: "hatchery/e4f93b30-7d4f-11ec-9599-d132c97da6f5",
                            },
                            {
                                tankName: "S4",
                                fluoroName: "RT3.1",
                                url: "hatchery/ee92f6e0-7d4f-11ec-9599-d132c97da6f5",
                            },
                            {
                                tankName: "L1",
                                fluoroName: "RT3.4",
                                url: "hatchery/fcbadf50-f6de-11eb-9623-6314318f6533",
                            },
                            {
                                tankName: "L2",
                                fluoroName: "RT3.3",
                                url: "hatchery/581c3e60-74a3-11ec-a60e-91cb023d2e90",
                            },
                            {
                                tankName: "C6",
                                fluoroName: "RT3.5",
                                url: "hatchery/43499040-7ad5-11ec-b29e-65952e516291",
                            },
                            {
                                tankName: "C7",
                                fluoroName: "RT3.6",
                                url: "hatchery/4ab08d70-7ad5-11ec-b29e-65952e516291",
                            },
                            {
                                tankName: "UPW3",
                                fluoroName: "RT3.7",
                                url: "hatchery/012ffaa0-7d50-11ec-9599-d132c97da6f5",
                            },
                            {
                                tankName: "UPW4",
                                fluoroName: "RT3.8",
                                url: "hatchery/07e9eef0-7d50-11ec-9599-d132c97da6f5",
                            },
                            {
                                tankName: "BN1",
                                fluoroName: "RT3.9",
                                url: "hatchery/174694c0-7d50-11ec-9599-d132c97da6f5",
                            },
                            {
                                tankName: "BN2",
                                fluoroName: "RT3.10",
                                url: "hatchery/226d5910-7d50-11ec-9599-d132c97da6f5",
                            },
                        ]}
                        options={{
                            search: false,
                            filtering: true,
                            paging: false,
                            idSynonym: "tankName",
                        }}
                    ></RTable>
                </Grid>
            </Grid>
        );
    }
};
export default HatcheryGraphs;
