import React, { Component } from "react";
import { Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
    disabledButton: {
        color: theme.palette.darkUtility.main,
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});

class BluetoothButtonCharacteristic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: props.uuid,
            name: props.name,
            text: props.text,
            characteristic: props.characteristic,
            disabled: false,
            confirm: props.confirm,
        };
        this.clickCallback = this.clickCallback.bind(this);
    }

    async clickCallback(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.state.confirm != null && !window.confirm(this.state.confirm)) {
            return;
        }
        this.state.characteristic.writeValue(Uint8Array.of(1));
    }

    render() {
        let classes = this.props.classes;
        return (
            <Button
                className={classes.button}
                variant="contained"
                onClick={this.clickCallback}
                disabled={this.state.disabled}
            >
                {this.state.text}
            </Button>
        );
    }
}

export default withStyles(styles)(BluetoothButtonCharacteristic);
