import React, { Component } from "react";
import { useParams } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import { WithGoogleAuth } from "../config/WithGoogleAuth";
import { Grid } from "@mui/material";
import GraphComponent from "./components/GraphComponent";
import APIClient from "../models/APIClient";

const styles = (theme) => ({
    root: {},
    paper: {
        padding: 10,
    },
    section: {
        marginTop: theme.spacing(5),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    box: {
        padding: 10,
    },
});

let DataDashboard = WithGoogleAuth(
    class DataDashboards extends Component {
        state = {
            foi_ids: [],
        };

        componentDidMount() {
            this.loadState();
        }
        async loadState() {
            let result = await new APIClient(
                    this.props.authState
                ).getDataDashboard({
                    id: this.props.params.dash_id,
                }),
                data = await result.json(),
                foi_ids = data.map((dash) => dash.feature_of_interest_id);
            this.setState({ foi_ids: foi_ids || [] });
        }

        getGraphs() {
            return this.state.foi_ids.map((foi_id) => (
                <Grid key={foi_id} item lg={12} xs={12}>
                    <GraphComponent picker={true} foi_id={foi_id} />
                </Grid>
            ));
        }

        render() {
            return (
                <Grid container spacing={3}>
                    {this.getGraphs()}
                </Grid>
            );
        }
    }
);

export default withStyles(styles)((props) => (
    <DataDashboard {...props} params={useParams()} />
));
