import React, { Component } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import MessageHelper from "../helper/MessageHelper";
import APIClient from "../../models/APIClient";
import { errorMessage, successMessage } from "../helper/MessageMethodHelper";
import { WithGoogleAuth } from "../../config/WithGoogleAuth";

const styles = (theme) => ({
    container: {
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: "30%",
    },
    titleBar: {
        display: "flex",
        width: "30%",
        flexFlow: "row",
        justifyContent: "space-between",
    },
    button: {
        marginTop: theme.spacing(2),
    },
});

const BuoyDataRequest = WithGoogleAuth(
    class BuoyDataRequest extends Component {
        constructor(props) {
            super(props);
            this.state = {
                requestText: "",
                selectedType: "",
                sentSuccessfully: false,
            };
            this.requestTypeTemplate = {
                "image-request": {
                    "image-props": [
                        {
                            "image-width": 300,
                            "image-height": 200,
                            "image-quality": 10,
                            "image-name": "CAM_3_2021-09-13_19-29-55.webp",
                        },
                    ],
                },
                "log-request": {
                    "log-props": [
                        {
                            "log-name": "hs_21-09-14_17:18:38.log",
                        },
                    ],
                },
                "gps-request": {
                    "from-datetime": "2021-07-29 13:02:09",
                    "to-datetime": "2021-09-15 13:02:09",
                    "sample-interval-sec": 0,
                },
                "sensor-data-request": {
                    "from-datetime": "2021-07-29 13:02:09",
                    "to-datetime": "2021-09-15 13:02:09",
                    "sample-interval-sec": 0,
                    sensors: [3, 4, 5],
                },
                "ls-request": {
                    images: true,
                    logs: false,
                    from_datetime: "2022-01-28 10:11:09",
                    max_files: 9,
                },
            };
        }

        getRequestObject() {
            if (this.state.requestText !== "") {
                const input = this.state.requestText;
                const requestText = `{
                    "${this.state.selectedType}": ${input}
                }`;

                try {
                    const requestObject = JSON.parse(requestText);
                    return requestObject;
                } catch (e) {
                    return {};
                }
            } else {
                return {};
            }
        }

        sendRequest = async (requestObject) => {
            const apiClient = new APIClient(this.props.authState);
            // this shouldn't actually happen (because the button would be disabled)
            if (Object.entries(requestObject).length === 0) {
                return;
            }

            try {
                await apiClient.createDataRequest(
                    this.props.params.thing_id,
                    requestObject,
                    ""
                );
                this.setState({ sentSuccessfully: true });
                this.setState(
                    successMessage(`Data request successfully sent!`)
                );
            } catch (e) {
                this.setState(errorMessage(e));
            }
        };

        async cancelRequest() {
            const apiClient = new APIClient(this.props.authState);
            // if the user unselects the data request type after sending the request, we disable the cancel button, so this shouldn't actually happen
            if (this.state.selectedType === "") {
                return;
            }

            try {
                // get the valid selected type form: eg. 'ls', 'image', 'log', etc.
                const typeString = this.state.selectedType;
                // remove "-request"
                const type = typeString.replace("-request", "");
                await apiClient.cancelDataRequest(
                    this.props.params.thing_id,
                    type,
                    ""
                );
                this.setState(
                    successMessage(`Data request successfully cancelled!`)
                );
                // in case the user wants to send another request, set the state to false again to disable this button
                this.setState({ sentSuccessfully: false });
            } catch (e) {
                this.setState(errorMessage(e));
            }
        }

        render() {
            const { classes } = this.props;

            return (
                <Paper>
                    <div className={classes.container}>
                        <Typography className={classes.title} variant="h5">
                            Select a Request Type
                        </Typography>

                        <Box className={classes.titleBar}>
                            <select
                                value={this.state.selectedType}
                                onChange={(e) => {
                                    const option = e.target.value;
                                    this.setState({
                                        requestText: JSON.stringify(
                                            this.requestTypeTemplate[option],
                                            undefined,
                                            2
                                        ),
                                        selectedType: option,
                                    });
                                }}
                            >
                                <option value="">Choose One</option>
                                {Object.keys(this.requestTypeTemplate).map(
                                    (key, index) => {
                                        return (
                                            <option key={index} value={key}>
                                                {key}
                                            </option>
                                        );
                                    }
                                )}
                            </select>
                        </Box>
                        <textarea
                            id="type"
                            className={classes.textField}
                            rows="11"
                            value={this.state.requestText}
                            onChange={(event) =>
                                this.setState({
                                    requestText: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className={classes.container}>
                        <Button
                            className={classes.button}
                            // the send button will be disabled if the input is not a valid JSON object
                            disabled={
                                Object.entries(this.getRequestObject())
                                    .length === 0
                            }
                            variant="contained"
                            onClick={() =>
                                this.sendRequest(this.getRequestObject())
                            }
                        >
                            Send
                        </Button>
                    </div>
                    <div className={classes.container}>
                        <Button
                            className={classes.button}
                            disabled={
                                !this.state.sentSuccessfully ||
                                this.state.selectedType === ""
                            }
                            variant="contained"
                            onClick={() => this.cancelRequest()}
                        >
                            Cancel
                        </Button>
                    </div>
                    <MessageHelper
                        message={this.state.message}
                        errorMessage={this.state.errorMessage}
                        open={this.state.messageOpen}
                        setState={(a) => this.setState(a)}
                    />
                </Paper>
            );
        }
    }
);

export default withStyles(styles)(BuoyDataRequest);
